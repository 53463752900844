import React, { useState } from 'react';
import { SidebarLogoType } from './components/logotype';
import { SidebarSlider } from './components/slider';
import styles from './style.module.css';
import clsx from 'clsx';
import { SidebarOrderTypes } from './components/orderTypes';
import { SidebarDivider } from './components/divider';
import { SidebarNotification } from './components/notification';
import { SidebarProfile } from './components/profile';

export const Sidebar = ({getIsOpen}:any) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const HandlesetIsOpen = React.useCallback((e:any) => {

        setIsOpen(e)
        getIsOpen(e)
      }, [isOpen]);

      
    return <div className={clsx(styles.Sidebar , isOpen && styles.isOpen)}>

                {/* <SidebarLogoType isOpen={isOpen} /> */}
                <SidebarSlider setIsOpen={HandlesetIsOpen} isOpen={isOpen}  />
                <SidebarOrderTypes isOpen={isOpen} />
                <SidebarDivider style={{marginBottom:"10px"}} />
                <SidebarNotification  isOpen={isOpen} />
                <SidebarDivider style={{marginBottom:"10px",marginTop:"10px"}} />
                <SidebarProfile isOpen={isOpen} />
    </div>

};




