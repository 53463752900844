import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import link from "../../../../../assets/icon-link.svg";
import cancell from "../../../../../assets/ic-close.svg";
import refresh from "../../../../../assets/refresh.png";
import linkicon from "../../../../../assets/icon-link-copy-3.svg";
import end from "../../../../../assets/ic-close-copy.svg";
import Shipmentlabel from "../../../../../assets/ic_shipment_label.svg";
import price from "../../../../../assets/ic-edit-price.svg";
import driver from "../../../../../assets/ic_driver.svg";
import edit from "../../../../../assets/ic_addresses.svg";
import icedit from "../../../../../assets/ic-edit.svg";
import { withTranslation, Trans } from "react-i18next";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import EditDriver from "./footerComponents/Driver/Driver";
import EditPrice from "./footerComponents/Price&Earning/index";
import Factor_Price from "./footerComponents/Price&Earning/factor_Price";
import PaymentIcon from "react-payment-icons";

import priceinfo from "../../../../../assets/link.svg";
import { isEmpty } from "lodash";

import {
  OpenCloseEditTravel,
  EditTravelSetPrice,
  EditTRravelSetServiceVehicleTypes,
  EditTravelSetServiceActiveVehicleType,
  EditTravelSetCustomer,
  EditTravelAddServiceOption,
  EditTravelSetDriverAuto,
  EditTravelSetManualDriver,
  Edit_Travel_Set_Auto_ActiveVehicleType,
  EditTravelSetAutoDriver,
  Edit_Driver_Add_Driver_Option,
  Edit_Travel_Set_Pickup,
  Edit_Travel_Set_Dropoffs,
  Edit_Travel_PaymentMethod,
  EditTravelFeeChange,
  EditTravelSurchargesChange,
  EditTravelSurcharges,
  Edit_Travel_Operate_Changed,
  AdvancedAssaigning_handleDialoge,
  ChangeNewTravelDialoge,
  SetSharedDataServices,
} from "../../../../../Redux/actions/actionNames";
import {
  CanEditTravel,
  CanEndTravel,
  CanCancelTravel,
  isOrderInProgress,
  isOrderInDone,
  isOrderInProgressOrCancelDone,
  CanRepeatTravel,
  repeatReuqest,
  canChangeStatus,
  CanEditPriceEarning,
} from "../../../../../helper/module";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import {
  SocketOrderCancel,
  SocketOrderDone,
} from "../../../../../service/socket/Emit_handelers";
import {
  get_accessToken,
  get_id,
  get_currencyCode,
  get_lang,
} from "../../../../../helper/UserData/userdate";
import { OrderInfoAction, OrderEditondemandAction, CancelOrderAction, DoneOrderAction, ServicesVehicletypeAction } from "../../../../../Redux/actions/actions";
import { ServicesApi } from "../../../../../service/API/service";
import history from "../../../../../service/history";

var _ = require("lodash");
var numeral = require("numeral");

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showpoper: false,
      cancelTravel: false,
      finishTravel: false,
      priceChange: false,
      EditPriceOpen: false,
      fee: "0",
      surcharges: [],
    };
    this.anchorEl = React.createRef();
    this.anchorEl2 = React.createRef();

  }

  savePrice = () => {
    this.props.dispatch(
      OrderEditondemandAction(
        this.props.dispatch,
        {
          baseFarePrice: Number(this.state.fee),
          surcharges: this.state.surcharges.map((item) => {
            return { price: Number(item.price), id: item.id };
          }),
          userId: get_id(),
        },
        null,
        null,
        this.props.Data.id,
      )
    );
  };

  repeatOrder = async () => {
    // this.props.dispatch(ChangeNewTravelDialoge());
    const Services = new ServicesApi();
    const servicesData = await Services.Services()
    this.props.dispatch(SetSharedDataServices(servicesData.Services))
    history.push("/neworder")    

    // this.props.dispatch(
    //   ServicesVehicletypeAction(this.props.dispatch, this.props.center)
    // );
    repeatReuqest(this.props.pureData, this.props.dispatch);
  };

  Shipmentlabel = () => {
    window.open(this.props.Data.shipmentLabelUrl + "?lng=" + get_lang(), '_blank').focus();
    this.setState({ showpoper: false });
  }

  feeOnchange = (e) => {
    this.props.dispatch(Edit_Travel_Operate_Changed());

    this.setState({
      showpoper: false
    });
  };

  SurchargesOnchange = (e, id) => {
    this.props.dispatch(Edit_Travel_Operate_Changed());

    this.setState({
      surcharges: this.state.surcharges.map((item) => {
        return item.id == id ? { ...item, price: e.target.value } : item;
      }),
    });
  };

  priceChange = (key) => {
    

    this.setState({
      EditPriceOpen: key
    })

    this.setState({ showpoper: false });

  }

  EditPrice = () => { };

  EditTravel = () => {
    this.setState({ showpoper: false });

    if (this.props.Data) {
      this.props.dispatch(
        EditTravelSetPrice({
          price: this.props.Data.price,
          currencyCode: this.props.Data.currencyCode,
        })
      );
      this.props.dispatch(
        EditTRravelSetServiceVehicleTypes(this.props.serviceVehicleTypes)
      );
      try {
        this.props.dispatch(
          EditTravelSetServiceActiveVehicleType(this.props.Data.service
          )
        );
      } catch (error) { }
      this.props.dispatch(
        EditTravelSetCustomer({
          ...this.props.Data.customer,
          person: !!this.props.Data.customerId,
          BusinessName: this.props.Data.business ? this.props.Data.business.title : "",
          BusinessNumber: this.props.Data.business ? this.props.Data.business.phone : "",
          BusinessId: this.props.Data.businessId,
          id: this.props.Data.customerId,
          balance: this.props.Data.customer.balance,
        })
      );
      
      this.props.Data.service.options.map((service) => {
        return this.props.dispatch(
          EditTravelAddServiceOption(
            service.type === "list"
              ? {
                id: service.id,
                item: {
                  id: service.dataId,
                  title: service.dataTitle,
                },
                name: undefined,
                type: "list",
              }
              : {
                id: service.id,
                name: undefined,
                type: "boolean",
              }
          )
        );
      });

      this.props.dispatch(
        EditTravelSetDriverAuto(
          _.isEmpty(this.props.Data.driver) ? true : false
        )
      );

      if (!_.isEmpty(this.props.Data.driver)) {
        let VehicleType = null;

        try {
          let VehicleType = this.props.auto_suggestListData.filter(
            (service) => service.id === this.props.Data.vehicleType.id
          )[0];
          this.props.dispatch(
            EditTravelSetManualDriver({
              manual_activeVehicleType: VehicleType || {
                options: [],
                ...this.props.Data.vehicleType,
              },
              manual_code: this.props.Data.driver.username,
              // manual_family: this.props.Data.driver.familyName,
              manual_family: "",
              manual_id: this.props.Data.driverId,
              // manual_name: this.props.Data.driver.givenName,
              manual_name: this.props.Data.driver.name,
              manual_number: this.props.Data.driver.phone,
            })
          );
        } catch (error) {

          this.props.dispatch(
            EditTravelSetManualDriver({
              manual_activeVehicleType: VehicleType || {
                options: [],
                ...this.props.Data.vehicleType,
              },
              manual_code: this.props.Data.driver.username,
              // manual_family: this.props.Data.driver.familyName,
              manual_family: "",
              manual_id: this.props.Data.driverId,
              // manual_name: this.props.Data.driver.givenName,
              manual_name: this.props.Data.driver.name,
              manual_number: this.props.Data.driver.phone,
            })
          );
        }
      } else {
        let VehicleType = null;
        try {
          let VehicleType = this.props.auto_suggestListData.filter(
            (service) => service.id === this.props.Data.vehicleType.id
          )[0];

          this.props.dispatch(
            EditTravelSetAutoDriver({
              auto_activeVehicleType: VehicleType || this.props.Data.vehicleType,
            })
          );
        } catch (error) {
          this.props.dispatch(
            EditTravelSetAutoDriver({
              auto_activeVehicleType: null,
            })
          );
        }
      }

      !isEmpty(this.props.Data.vehicleType) &&  this.props.Data.vehicleType.options.map((option) => {
        return this.props.dispatch(
          Edit_Driver_Add_Driver_Option(
            option.type === "list"
              ? {
                id: option.id,
                item: {
                  id: option.dataId,
                  title: option.dataTitle,
                },
                name: undefined,
                type: "list",
              }
              : {
                id: option.id,
                name: undefined,
                type: "boolean",
              }
          )
        );
      });

      this.props.dispatch(EditTravelFeeChange(this.props.Data.baseFarePrice));
      let surcharges = this.props.Data.surcharges.map((item) => {
        return { id: item.id, price: item.price };
      });
      this.props.dispatch(EditTravelSurcharges(this.props.Data.surcharges));

      if(["Pickup", "PickupDelivery" , "Ondemand"].indexOf(this.props.orderType) > -1){
        let Pickupphone = this.props.Data.pickup.phone;
        if (Pickupphone && Pickupphone.length > 2 && Pickupphone.substring(0, 2) == "00") {
          Pickupphone = "+" + Pickupphone.substring(2, Pickupphone.length)
        }
  
        this.props.dispatch(
          Edit_Travel_Set_Pickup({
            address: this.props.Data.pickup.address,
            buildingBlock: this.props.Data.pickup.buildingBlock,
            adresss_loc: {
              lng: this.props.Data.pickup.coordinates[0],
              lat: this.props.Data.pickup.coordinates[1],
            },
            noteForDriver: this.props.Data.pickup.customerDescription,
            floor: this.props.Data.pickup.floor,
            senderFullName: this.props.Data.pickup.fullName,
            senderPhoneNumber: Pickupphone,
            email: this.props.Data.pickup.email,
            unit: this.props.Data.pickup.room,
            scheduleDateAfter: this.props.Data.pickup.scheduleDateAfter,
            scheduleDateBefore: this.props.Data.pickup.scheduleDateBefore,
          })
        );
      }

     
      let otherDropOffs = JSON.parse(JSON.stringify(this.props.Data.dropoffs));
      otherDropOffs.shift();
      otherDropOffs = otherDropOffs.map((drop) => {


        let otherDropOffPhone = drop.phone;
        if (otherDropOffPhone && otherDropOffPhone.length > 2 && otherDropOffPhone.substring(0, 2) == "00") {
          otherDropOffPhone = "+" + otherDropOffPhone.substring(2, otherDropOffPhone.length)
        }

        return {
          address: drop.address,
          adresss_loc: {
            lng: drop.coordinates[0],
            lat: drop.coordinates[1],
          },
          id: drop.id,
          block: drop.buildingBlock,
          floor: drop.floor,
          unit: drop.room,
          email: drop.email,
          recieverFullName: drop.fullName,
          recieverPhoneNumber: otherDropOffPhone,
          noteForDriver: drop.customerDescription,
          scheduleDateAfter: drop.scheduleDateAfter,
          scheduleDateBefore: drop.scheduleDateBefore,
        };
      });

      /* */ if(["Delivery", "PickupDelivery"].indexOf(this.props.orderType) > -1) {
        let DropoffPhone = this.props.Data.dropoff.phone;
        if (DropoffPhone && DropoffPhone.length > 2 && DropoffPhone.substring(0, 2) == "00") {
          DropoffPhone = "+" + DropoffPhone.substring(2, DropoffPhone.length)
        }
  
  
        this.props.dispatch(
          Edit_Travel_Set_Dropoffs({
            firstDropOff: {
              ...this.props.Data.dropoff,
              address: this.props.Data.dropoff.address,
              adresss_loc: {
                lng: this.props.Data.dropoff.coordinates[0],
                lat: this.props.Data.dropoff.coordinates[1],
              },
              email: this.props.Data.dropoff.email,
              scheduleDateAfter: this.props.Data.dropoff.scheduleDateAfter,
              scheduleDateBefore: this.props.Data.dropoff.scheduleDateBefore,
              block: this.props.Data.dropoff.buildingBlock,
              floor: this.props.Data.dropoff.floor,
              unit: this.props.Data.dropoff.room,
              recieverFullName: this.props.Data.dropoff.fullName,
              recieverPhoneNumber: DropoffPhone,
              noteForDriver: this.props.Data.dropoff.customerDescription,
            },
            otherDropOffs: [],
          })
        );
  
      }else if(["Ondemand"].indexOf(this.props.orderType) > -1){

        let DropoffPhone = this.props.Data.dropoffs[0].phone;
        if (DropoffPhone && DropoffPhone.length > 2 && DropoffPhone.substring(0, 2) == "00") {
          DropoffPhone = "+" + DropoffPhone.substring(2, DropoffPhone.length)
        }
  
  
        this.props.dispatch(
          Edit_Travel_Set_Dropoffs({
            firstDropOff: {
              ...this.props.Data.dropoffs[0],
              address: this.props.Data.dropoffs[0].address,
              adresss_loc: {
                lng: this.props.Data.dropoffs[0].coordinates[0],
                lat: this.props.Data.dropoffs[0].coordinates[1],
              },
              email: this.props.Data.dropoffs[0].email,
              scheduleDateAfter: this.props.Data.dropoffs[0].scheduleDateAfter,
              scheduleDateBefore: this.props.Data.dropoffs[0].scheduleDateBefore,
              block: this.props.Data.dropoffs[0].buildingBlock,
              floor: this.props.Data.dropoffs[0].floor,
              unit: this.props.Data.dropoffs[0].room,
              recieverFullName: this.props.Data.dropoffs[0].fullName,
              recieverPhoneNumber: DropoffPhone,
              noteForDriver: this.props.Data.dropoffs[0].customerDescription,
            },
            otherDropOffs: otherDropOffs,
          })
        );
  
      }


      



      /* */
      this.props.dispatch(
        Edit_Travel_PaymentMethod({
          paymentMethod: this.props.Data.paymentMethod,
          paymentSide: this.props.Data.paymentSide,
          title:
            this.props.Data.paymentMethod == "Cash" &&
              this.props.Data.paymentSide == "Receiver"
              ? "Reciver"
              : this.props.Data.paymentMethod == "Cash" &&
                this.props.Data.paymentSide == "Sender"
                ? "Cash"
                : this.props.Data.paymentMethod == "Wallet" &&
                  this.props.Data.paymentSide == "Sender"
                  ? "Balance"
                  : "Reciver",
        })
      );

      setTimeout(() => {
        this.props.dispatch(OpenCloseEditTravel(this.props.Data.id));
      }, 500);
    }
  };

  idgenerator = () => {
    var number = Math.random(); // 0.9394456857981651
    number.toString(36); // '0.xtis06h6'
    var id = number.toString(36).substr(2, 9); // 'xtis06h6'
    return id.toString();
  };

  cancelTravel = () => {
    this.props.dispatch(
      CancelOrderAction(this.props.dispatch, this.props.Data.id, (call) => {
        this.props.dispatch(
          OrderInfoAction(this.props.dispatch, {
            orderId: this.props.Data.id
          })
        );
      })
    );
  };

  finshTravel = () => {
    this.props.dispatch(
      DoneOrderAction(this.props.dispatch, this.props.Data.id, (call) => {
        this.props.dispatch(
          OrderInfoAction(this.props.dispatch, {
            orderId: this.props.Data.id
          })
        );
      })
    );
  };

  saveToClipboardTrackOrder = () => {
    var copyText = document.getElementById("trackOrder");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");
    this.setState({ showpoper: false });
  };


  saveToClipboardTrackPickupOrder = () => {
    var copyText = document.getElementById("pickupOrderTrack");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");
    this.setState({ showpoper: false });
  };


  saveToClipboardTrackDeliveryOrder = () => {
    var copyText = document.getElementById("deliveryOrderTrack");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");
    this.setState({ showpoper: false });
  };



  canShowEditStatusPriceEditOrder = () => {
    return CanEditTravel(this.props.Data.status) || canChangeStatus(this.props.Data.status ,this.props.Data.type )
  }



  ShowEditStatusDialoge = () => {
    this.setState({ showpoper: false });
    this.props.dispatch(AdvancedAssaigning_handleDialoge(true))
  };

  render() {
    return (
      <nRFooter>
        <img src={priceinfo} onClick={this.priceChange} className="coifhefc" />
        {/* <EditPrice noC open={this.state.EditPriceOpen} priceChange={this.priceChange} /> */}
        <Factor_Price open={this.state.EditPriceOpen} priceChange={this.priceChange} />
        <costConrainer>
          <cost>
            {(this.props.Data &&
              Number(this.props.Data.price - (this.props.Data.promoAmount > 0 ? this.props.Data.promoAmount : 0)).toLocaleString(get_lang(), {
                style: "currency",
                currency: get_currencyCode(),
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })) ||
              0}
          </cost>
          <price>
            {" "}
            <Trans i18nKey="Price"> </Trans>{" "}
          </price>
        </costConrainer>
        <vr />
        <paymentMethodContainer>
          <byReciver
            className={
              this.props.Data &&
              (this.props.Data.paymentSide === "Sender"
                ? this.props.Data.paymentMethod === "Cash"
                  ? "Cash"
                  : "Balance"
                : "Reciver")
            }
          >
            {" "}
            {this.props.Data.paymentSide + this.props.Data.paymentMethod == "SenderCard" ? (

              <div className="opdrtmff">
                <PaymentIcon
                  id={this.props.Data.cardInfo.brand}
                  className="dgieh"
                />
                

                <span className="pfkwuhh"> {`**** ${this.props.Data.cardInfo.lastNumbers}`} </span>

              </div>


            ) : <Trans i18nKey={this.props.Data.paymentSide === "Sender"
              ? this.props.Data.paymentMethod === "Cash"
                ? "Cash"
                : "Wallet"
              : "Receiver"}> </Trans>}

          </byReciver>

          <key>
            <Trans i18nKey={"paymentmethod"}> </Trans>
          </key>
        </paymentMethodContainer>
        <span
          onClick={() => {
            this.setState({ showpoper: true });
          }}
          ref={this.anchorEl}
          className="thdot"
        >
          ...
        </span>

        {/* {
          <span
            onClick={() => {
              this.setState({ showpoperEdit: true });
            }}
            ref={this.anchorEl2}
            className="thdot"
          >
            <img src={icedit} className="img1" />
          </span>
        } */}

        {isOrderInDone(this.props.Data.status) && !isEmpty(this.props.Data.customerReceiptUrl) && (
          <span
            className="ReceiptContainer"
            onClick={() => {
              try {
                var url = new URL(this.props.Data.customerReceiptUrl);
                url.searchParams.append("lng", get_lang());

                window.open(url, "_blank");
              } catch (error) { }
            }}
          >
            <img src={linkicon} />
            <span>
              {" "}
              <Trans i18nKey={"Receipt"}> </Trans>
            </span>
          </span>
        )}

        <input
          type="text"
          value={this.props.Data.trackOrder + "?lng=" + get_lang()}
          id="trackOrder"
          style={{
            opacity: "0",
            width: "1px",
            height: "1px",
            color: "white",
            border: "none",
            outline: "none",
          }}
        />

        <input
          type="text"
          value={this.props.Data.pickupOrderTrackUrl + "?lng=" + get_lang()}
          id="pickupOrderTrack"
          style={{
            opacity: "0",
            width: "1px",
            height: "1px",
            color: "white",
            border: "none",
            outline: "none",
          }}
        />


        <input
          type="text"
          value={this.props.Data.deliveryOrderTrackUrl + "?lng=" + get_lang()}
          id="deliveryOrderTrack"
          style={{
            opacity: "0",
            width: "1px",
            height: "1px",
            color: "white",
            border: "none",
            outline: "none",
          }}
        />

        <input
          type="text"
          value={this.props.Data.shipmentLabelUrl + "?lng=" + get_lang()}
          id="Shipmentlabel"
          style={{
            opacity: "0",
            width: "1px",
            height: "1px",
            color: "white",
            border: "none",
            outline: "none",
          }}
        />

        <Popper
          open={this.state.showpoper}
          anchorEl={this.anchorEl.current}
          role={undefined}
          placement={"top-end"}
          style={{
            width: "160px",
            marginBottom: "4px",
            border: "solid 1px rgba(128, 128, 128, 0.2)",
            borderRadius: "10px",
            // position: "absolute",
            // left: "17px",
            backgroundColor: "white",
            boxShadow: "0 2px 8px 0 rgba(128, 128, 128, 0.24)",
            zIndex: "1"
          }}
          className="Popperxxx"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Paper>
              <ClickAwayListener
                onClickAway={(e) => {
                  this.setState({ showpoper: false });
                }}
              >
                <div autoFocusItem={this.state.activestop} id="menu-list-grow">
                  {(CanEditTravel(this.props.Data.status) || canChangeStatus(this.props.status,this.props.Data.type )) && <p className="editTitleMenu">
                    <Trans i18nKey={"Edit"}> </Trans>{" "}
                  </p>
                  }

                  {canChangeStatus(this.props.status,this.props.Data.type ) && (
                    <thdotItem className="menuNewItem" onClick={this.ShowEditStatusDialoge}>
                      {" "}
                      <img src={driver} />{" "}
                      <span>
                        {" "}
                        <Trans i18nKey={"StatusandDriver"}> </Trans>
                      </span>
                    </thdotItem>
                  )}
                  {(CanEditPriceEarning(this.props.Data.status ,this.props.orderType) )&&   <EditPrice />}

                  {(CanEditTravel(this.props.Data.status) && !this.props.Data.isPickupDelivery )&&  (
                    <thdotItem className="menuNewItem" onClick={this.EditTravel}>
                      {" "}
                      <img src={edit} />{" "}
                      <span>
                        {" "}
                        <Trans i18nKey={"EditPickupDropOff"}> </Trans>
                      </span>
                    </thdotItem>
                  )}


                  {isOrderInProgressOrCancelDone(this.props.Data.status) && (
                    <React.Fragment>
                      <p className="editTitleMenu">
                        <Trans i18nKey={"Other"}> </Trans>{" "}
                      </p>
                      {/* 
                      <thdotItem className="menuNewItem" onClick={this.saveToClipboardTrackOrder}>
                        {" "}
                        <img src={link} />{" "}
                        <span className="spanblue">
                          {" "}
                          <Trans i18nKey={"Copylink"}> </Trans>{" "}
                        </span>
                      </thdotItem> */}

                      <thdotItem className="menuNewItem" onClick={this.saveToClipboardTrackOrder}>
                        {" "}
                        <img src={link} />{" "}
                        <span className="spanblue">
                          {" "}
                          <Trans i18nKey={"CopyOrderlink"}> </Trans>{" "}
                        </span>
                      </thdotItem>
                      {(this.props.Data.pickupOrderTrackUrl && this.props.Data.pickupOrderTrackUrl.length > 0) &&
                        <thdotItem className="menuNewItem" onClick={this.saveToClipboardTrackPickupOrder}>
                          {" "}
                          <img src={link} />{" "}
                          <span className="spanblue">
                            {" "}
                            <Trans i18nKey={"CopyPickuplink"}> </Trans>{" "}
                          </span>
                        </thdotItem>
                      }

                      {(this.props.Data.deliveryOrderTrackUrl && this.props.Data.deliveryOrderTrackUrl.length > 0) &&
                        <thdotItem className="menuNewItem" onClick={this.saveToClipboardTrackDeliveryOrder}>
                          {" "}
                          <img src={link} />{" "}
                          <span className="spanblue">
                            {" "}
                            <Trans i18nKey={"CopyDeliverylink"}> </Trans>{" "}
                          </span>
                        </thdotItem>
                      }
                      {CanRepeatTravel(this.props.Data.status) && (
                        <thdotItem className="menuNewItem" onClick={this.repeatOrder}>
                          {" "}
                          <img src={refresh} />{" "}
                          <span>
                            {" "}
                            <Trans i18nKey={"repeatOrder"}> </Trans>
                          </span>
                        </thdotItem>
                      )}

                      <thdotItem className="menuNewItem" onClick={this.Shipmentlabel}>
                        {" "}
                        <img src={Shipmentlabel} />{" "}
                        <span>
                          {" "}
                          <Trans i18nKey={"Shipmentlabel"}> </Trans>
                        </span>
                      </thdotItem>

                    </React.Fragment>
                  )}



                </div>
              </ClickAwayListener>
            </Paper>
          )}
        </Popper>


        <Popper
          open={this.state.showpoperEdit}
          anchorEl={this.anchorEl2.current}
          role={undefined}
          placement={"top-end"}
          style={{
            width: "160px",
            marginBottom: "4px",
            border: "solid 1px rgba(128, 128, 128, 0.2)",
            borderRadius: "10px",
            // position: "absolute",
            // left: "17px",
            backgroundColor: "white",
            boxShadow: "0 2px 8px 0 rgba(128, 128, 128, 0.24)",
          }}
          className="Popperxxx"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Paper>
              <ClickAwayListener
                onClickAway={(e) => {
                  this.setState({ showpoperEdit: false });
                }}
              >
                <div autoFocusItem={this.state.activestop} id="menu-list-grow">

                  {canChangeStatus(this.props.status,this.props.Data.type ) && (
                    <thdotItem className="menuNewItem" onClick={this.ShowEditStatusDialoge}>
                      {" "}
                      <img src={driver} className="thdotItemdriver" />{" "}
                      <span>
                        {" "}
                        <Trans i18nKey={"StatusandDriver"}> </Trans>
                      </span>
                    </thdotItem>
                  )}
                  <EditPrice />
                  {CanEditTravel(this.props.Data.status) &&  (
                    <thdotItem className="menuNewItem" onClick={this.EditTravel}>
                      {" "}
                      <img src={edit} />{" "}
                      <span>
                        {" "}
                        <Trans i18nKey={"EditPickupDropOff"}> </Trans>
                      </span>
                    </thdotItem>
                  )}


                </div>
              </ClickAwayListener>
            </Paper>
          )}
        </Popper>



        <Dialog
          style={{}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.cancelTravel}
          onClose={() => {
            this.setState({ cancelTravel: false });
          }}
          disableAutoFocus
          disableEnforceFocus 
          disableRestoreFocus 
        >
          {" "}
          <p
            style={{
              direction: this.props.direction,
              lineHeight: "1.7",
              width: "320px",
              maxWidth: "320px",
              marginBottom: "0px",
              paddingRight: "21px",
              paddingLeft: "21px",
              marginTop: "16px",
              color: "black",
              fontSize: "13px",
              fontFamily: "IRANSans",
              textAlign: this.props.direction == "ltr" ? "left" : "right",
              fontWeight: "bold",
            }}
          >
            <Trans i18nKey="Canceltravel"> </Trans>
          </p>
          <DialogContent>
            <DialogContentText
              style={{
                direction: this.props.direction,
                lineHeight: "1.7",
                width: "320px",
                color: "#242424",
                fontSize: "13px",
                fontFamily: "IRANSans",
                textAlign: this.props.direction == "ltr" ? "left" : "right",
              }}
              id="alert-dialog-description"
            >
              <Trans i18nKey="CancellText"> </Trans>
            </DialogContentText>
          </DialogContent>
          <div style={{ direction: "ltr", height: "42px" }}>
            <Button
              onClick={() => {
                this.setState({ cancelTravel: false });
                this.cancelTravel();
              }}
              style={{
                boxShadow: "0 0 2px 0 rgba(191, 191, 191, 0.5)",
                fontFamily: "IRANSans",
                color: "white",
                height: "30px",
                minHeight: "30px",
                marginLeft: "3%",
                marginTop: "1px",
                marginRight: "1%",
                width: "129px",
                fontSize: "12px",
                backgroundColor: "#3583e0",
                border: "none",
              }}
              variant="contained"
            >
              <Trans i18nKey="confirmCancel"> </Trans>
            </Button>
            <Button
              onClick={() => {
                this.setState({ cancelTravel: false });
              }}
              style={{
                boxShadow: "0 0 2px 0 rgba(191, 191, 191, 0.5)",
                fontFamily: "IRANSans",
                color: "#148be9",
                height: "30px",
                minHeight: "30px",
                marginLeft: "1%",
                marginTop: "1px",
                marginRight: "auto",
                width: "101px",
                fontSize: "12px",
                backgroundColor: "transparent",
                border: "none",
              }}
              variant="contained"
            >
              <Trans i18nKey="Cancel"> </Trans>
            </Button>
          </div>
        </Dialog>

        <Dialog
          style={{}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.finishTravel}
          onClose={() => {
            this.setState({ finishTravel: false });
          }}
          disableAutoFocus
          disableEnforceFocus 
          disableRestoreFocus 
        >
          {" "}
          <p
            style={{
              direction: this.props.direction,
              lineHeight: "1.7",
              width: "320px",
              maxWidth: "320px",
              marginBottom: "0px",
              paddingRight: "21px",
              paddingLeft: "21px",
              marginTop: "16px",
              color: "black",
              fontSize: "13px",
              fontFamily: "IRANSans",
              textAlign: this.props.direction == "ltr" ? "left" : "right",
              fontWeight: "bold",
            }}
          >
            <Trans i18nKey="Endtravel"> </Trans>
          </p>
          <DialogContent>
            <DialogContentText
              style={{
                direction: this.props.direction,
                lineHeight: "1.7",
                width: "320px",
                color: "#242424",
                fontSize: "13px",
                fontFamily: "IRANSans",
                textAlign: this.props.direction == "ltr" ? "left" : "right",
              }}
              id="alert-dialog-description"
            >
              <Trans i18nKey="EndlText"> </Trans>
            </DialogContentText>
          </DialogContent>
          <div style={{ direction: "ltr", height: "42px" }}>
            <Button
              onClick={() => {
                this.setState({ finishTravel: false });
                this.finshTravel();
              }}
              style={{
                boxShadow: "0 0 2px 0 rgba(191, 191, 191, 0.5)",
                fontFamily: "IRANSans",
                color: "white",
                height: "30px",
                minHeight: "30px",
                marginLeft: "3%",
                marginTop: "1px",
                marginRight: "1%",
                width: "110px",
                fontSize: "12px",
                backgroundColor: "#3583e0",
                border: "none",
              }}
              variant="contained"
            >
              <Trans i18nKey="confirmEnd"> </Trans>
            </Button>
            <Button
              onClick={() => {
                this.setState({ finishTravel: false });
              }}
              style={{
                boxShadow: "0 0 2px 0 rgba(191, 191, 191, 0.5)",
                fontFamily: "IRANSans",
                color: "#148be9",
                height: "30px",
                minHeight: "30px",
                marginLeft: "1%",
                marginTop: "1px",
                marginRight: "auto",
                width: "101px",
                fontSize: "12px",
                backgroundColor: "transparent",
                border: "none",
              }}
              variant="contained"
            >
              <Trans i18nKey="End"> </Trans>
            </Button>
          </div>
        </Dialog>


      </nRFooter>
    );
  }
}

const mapStateToProps = (state) => ({
  pureData: state.ViewTravel.pureData,
  lang: state.LocalData.lang,
  socket: state.Socket.socket,
  direction: state.LocalData.direction,
  Data: state.ViewTravel.pureData ? state.ViewTravel.pureData : false,
  status: state.ViewTravel.pureData ? state.ViewTravel.pureData.status : "",
  serviceVehicleTypes: state.NewOrder.newOrder.service.serviceVehicleTypes,
  auto_suggestListData:
    state.NewOrder.newOrder.driver.auto_driver.auto_suggestListData,
  baseFarePrice: state.ViewTravel.pureData ? state.ViewTravel.pureData.baseFarePrice : 0,
  surcharges: state.ViewTravel.pureData ? state.ViewTravel.pureData.surcharges : [],
  lang: state.LocalData.lang,

  orderType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
      ? state.ViewTravel.pureData.type
      : null
    : null,
    center: state.Map.center,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
