import React, { useLayoutEffect, useState } from 'react';
import styles from './main.module.css';
import { Navbar } from '../../components/main/navbar'
import { OrderList } from '../../components/main/order-list'
import { Filter } from '../../components/main/filter'
import { MapContainer } from '../../components/shared/map-container'
import { useMapContainerWidthHeight } from '../../hooks/useMapContainerWidthHeight';
import { InitialMain } from './initial-main';
import { useEffect } from 'react';
import { Monitoring } from '../../components/main/moitoring';
import NewOrder from "../../Entries/Main/components/NewOrder";
import ViewOrder from "../../Entries/Main/components/ViewOrder";
import EditOrder from "../../Entries/Main/components/EditPickup&DropOff";
import AdvancedAssaigning from "../../Entries/Main/components/AdvancedAssaigning/Driver";
import Voip from "../../Entries/Voip";
import { useDispatch, useSelector } from 'react-redux';
import { DriverList } from "../../components/main/driver-list";
import MapNewOrderPin from '../../components/shared/Map-NewOrder-Select-pin';
import MapEditOrderPin from '../../components/shared/Map-EditOrder-Select-pin';
import { MapNewOrderPinOnMap } from '../../components/shared/Map-NewOrder-pin-onMap';
import { MapViewOrderPinOnMap } from '../../components/shared/Map-ViewOrder-pin-onMap';
import { MapEditOrderPinOnMap } from '../../components/shared/Map-EditOrder-pin-onMap';
import { MapTrackDriverPinOnMap } from '../../components/shared/Map-TrackDriver-pin-onMap';
import { MapOrderRouteOnMap } from '../../components/shared/Map-Order-Route-OnMap';
import EstimatedRoutePanel from '../../components/shared/Map-Estimated-Route-Panel';
import New_Fav_SelectedGenerator from '../../components/shared/Map-New-Fav-SelectedGenerator';
import New_Event_SelectedGenerator from '../../components/shared/Map-New-Event-SelectedGenerator';
import { MapCircleRipple } from '../../components/shared/Map-CircleRipple';
import { MapNewEventPinOnMap } from '../../components/shared/Map-New-Events-Pins-On-Map';
import { ErrorHandling } from '../../components/shared/error-handling';
import { useMapContainerHeight } from '../../hooks/useMapContainerHeight';
import { useCSSTOTEXT } from '../../hooks/useCSSTOTEXT';
import { ViewOrderMonitoring } from '../../components/view-order/monitoring';
import ViewOrderPage from "../../page/view-order";
import Main from "../../page/main";
import { Route, useHistory, useLocation, useParams } from 'react-router-dom';
import { useViewOrderMapContainerWidthHeight } from '../../hooks/useViewOrderMapContainerWidthHeight';
import { ResetViewTravel, Table_Init } from '../../Redux/actions/actionNames';
import { UpdateOrdersStatistics } from '../../utils/update-ordersStatistics';
import NewOrderPage from '../../page/new-order';
import { Sidebar } from '../../components/shared/side-bar';
// import TableList from '../../components/table/table-list';
import TableFilter from '../../components/table/filter';
import TableSelectedOrders from '../../components/table/selectedOrders';
import TableLoading from '../../components/table/table-loading';
import TableError from '../../components/table/table-error';
import TableNoOrder from '../../components/table/table-noOrder';
import EditStatusSummary from '../../components/table/EditStatusSummary';
import TableAddRoute from '../../components/table/selectedOrders/components/addRoute';
import Checkbox from '../../design-system/lib/Checkbox';
import RouteList from '../../components/table/route-list';
import TableList from '../../components/shared/table';
import { getTableDefaultColumn } from '../../components/shared/table/action/getTableDefaultColumn';
import TableNoOrderFilter from '../../components/table/table-noOrder-filter';
import { isEqual } from 'lodash';
import { initTable } from '../../Redux/reducers/table';
import { RouteDetails } from '../route-details/RouteDetails';
import { useTranslation } from 'react-i18next';
import MapTableFilters from '../../components/table/filter/mapFilter';
import { PHUBD_Map } from '../../components/main/phubd_map';
import RouteListNavbar from '../../components/table/route-list/route-list-navbar';
import { RouteListMonitoring } from '../../components/route-details/monitoring';
import { get_tab_orderTypes } from '../../helper/UserData/userdate';
import { MapOrderDetailsPinsOnMap } from '../../components/shared/Map-order-details-pinsOnMap';

export function Sharedayout(props) {
    const MapContainerWidth = useMapContainerWidthHeight();
    const ViewOrderMapContainerWidthHeight = useViewOrderMapContainerWidthHeight();
    const history = useHistory();
    const selectedOrders = useSelector((state) => state.Table.selectedOrders)
    const ActiveOrderType = useSelector((state) => state.OrderList.ActiveOrderType)
    const TableisLoading = useSelector((state) => state.Table.isLoading)
    const TablehasError = useSelector((state) => state.Table.hasError)
    const TableListData = useSelector((state) => state.Table.list.orders)
    const routeListISopen = useSelector((state) => state.Table.routeList.open)
    const selectedBulkEditStatusSummary = useSelector((state) => state.Table.selectedBulkEditStatusSummary)
    const hasNextPage = useSelector((state) => state.Table.list.hasNextPage)
    const hasPreviousPage = useSelector((state) => state.Table.list.hasPreviousPage)
    const totalCount = useSelector((state) => state.Table.list.totalCount)
    const perPage = useSelector((state) => state.Table.perPage)
    const page = useSelector((state) => state.Table.page)
    const table = useSelector((state) => state.Table.tableInit)
    const tableFilters = useSelector((state) => state.Table.filters)
    const { t, i18n } = useTranslation();

    let { MapContainerHeight, hideNavbar } = useMapContainerHeight();
    let CSSTOTEXT = useCSSTOTEXT()
    const location = useLocation();
    const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

    const [loaded, setLoaded] = useState(false);
    const [routeDetails, setRouteDetails] = useState(null);
    const [routeDetailsfilteredText, setRouteDetailsfilteredTexts] = useState("");

    const [isViewOrder, setIsViewOrder] = useState(true);

    const AdvancedAssaigningOpenDialoge = useSelector((state) => state.AdvancedAssaigning.OpenDialoge)
    const mainLoaded = useSelector((state) => state.SharedData.mainLoaded)
    const orderTypes = useSelector((state) => state.SharedData.settings?.orderTypes)

    const pureData = useSelector((state) => state.ViewTravel.pureData)
    const dispatch = useDispatch();
    const [tableLayoutUsedHeight, setTableLayoutUsedHeight] = useState(0)



    useEffect(() => {
        !mainLoaded && InitialMain((call) => {
            setLoaded(call);
        })
    }, [mainLoaded])

    useEffect(() => {
        if (String(location.pathname).length == 7) {
            setIsViewOrder(false)
            dispatch(ResetViewTravel());
            UpdateOrdersStatistics()
        } else {
            setIsViewOrder(true)
        }

    }, [location]);


    useEffect(() => {
        // history.replace replaces current URL with new URL

        if (loaded || mainLoaded) {
            var priority = ["Ondemand", "Pickup", "Delivery", "PickupDelivery"];
            let SortedOrderTypes = orderTypes.sort((a, b) => priority.indexOf(a) - priority.indexOf(b))

            if (String(location.pathname).length < 4 && SortedOrderTypes.length > 0) {
                history.replace(`/${SortedOrderTypes[0]}`);
            }

        }



    }, [loaded, mainLoaded])


    function mapOnCenterChange(value) {

    }

    const isRoutesOpen = () => {
        if (String(window.location.href).includes("routes")) {
            return true
        } else {
            return false
        }
    }

    const InitTable = (REF) => {
        dispatch(Table_Init(REF))

    }


    const canClearAll = React.useCallback(() => {
        return !isEqual(initTable.filters, tableFilters)
    }, [tableFilters]);


    const getMapStyle = React.useCallback((defaultMapStyle) => {
        if (String(window.location.href).includes("neworder")) {
            return { width: "calc(100vw - 466px)", height: "100vh", zIndex: "5", position: "relative" }
        } if (String(window.location.href).includes("/order/")) {
            return { width: defaultMapStyle.width, height: defaultMapStyle.height, zIndex: "6", position: "absolute", right: "0px" }
        } if (String(window.location.href).includes("/Ondemand")) {
            return { width: (sidebarIsOpen ? `calc(${MapContainerWidth.width} - 86px)` : MapContainerWidth.width), height: MapContainerWidth.height, zIndex: "7", position: "absolute", transition: ".1s all ease-in-out", right: "0px", bottom: "0px" }
        } if (String(window.location.href).includes("/Pickup")) {
            return { width: (sidebarIsOpen ? `calc(${MapContainerWidth.width} - 86px)` : MapContainerWidth.width), height: MapContainerWidth.height, zIndex: "7", position: "absolute", transition: ".1s all ease-in-out", right: "0px", bottom: "0px" }
        } if (String(window.location.href).includes("/Delivery")) {
            return { width: (sidebarIsOpen ? `calc(${MapContainerWidth.width} - 86px)` : MapContainerWidth.width), height: MapContainerWidth.height, zIndex: "7", position: "absolute", transition: ".1s all ease-in-out", right: "0px", bottom: "0px" }
        } if (String(window.location.href).includes("/routes")) {
            return { width: `calc(100% - ${sidebarIsOpen ? "615px" : "529px"})`, height: defaultMapStyle.height, zIndex: "7", position: "absolute", transition: ".1s all ease-in-out", right: "0px", bottom: "0px" }
        } else {
            return { width: "calc(100vw - 466px)", height: "100vh", zIndex: "5", position: "absolute", right: "0px", bottom: "0px" }
        }
    }, [isViewOrder, ViewOrderMapContainerWidthHeight, MapContainerWidth, sidebarIsOpen]);



    if (loaded || mainLoaded) {

        console.log("routeDetails ",routeDetails , routeDetailsfilteredText)
        let defaultMapStyle = isViewOrder ? ViewOrderMapContainerWidthHeight : MapContainerWidth
        let styless = getMapStyle(defaultMapStyle)
        return <div
            className={styles.App}
        >

            <Sidebar getIsOpen={setSidebarIsOpen} />

            {!selectedBulkEditStatusSummary && <Route path="/route/:id/:stop">
                <RouteDetails setRouteDetails={setRouteDetails} setRouteDetailsfilteredTexts={setRouteDetailsfilteredTexts} />
            </Route>}
            {!selectedBulkEditStatusSummary && <Route path="/routes">
                <div className={styles.innerApp}>
                    <RouteListNavbar />

                    <RouteList />
                    {/* {!props.matches && <MapContainer customBounds={null} style={{ width: `calc(100% - 465px)`, height: defaultMapStyle.height, zIndex: "6", position: "relative" }} className={styles.mainMapContainer} onCenterChange={mapOnCenterChange} >
                        <Monitoring force={true} />
                        <MapNewOrderPinOnMap />

                        <MapViewOrderPinOnMap />

                        <MapEditOrderPinOnMap />
                        <MapTrackDriverPinOnMap />
                        <MapCircleRipple />
                        <MapOrderRouteOnMap />
                        <MapNewEventPinOnMap />

                    </MapContainer>} */}
                    {/* <DriverList className={styles.driversList} style={{ [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "0px" }} /> */}
                    {/* <div className={styles.monitoringContainer} style={{ [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "24px" }}>
                        <RouteListMonitoring />
                    </div> */}
                </div>
            </Route>}


            {selectedBulkEditStatusSummary &&
                <div className={styles.innerApp}>
                    <EditStatusSummary />
                </div>
            }

            {!selectedBulkEditStatusSummary && <>
                <Route path="/Delivery" exact>
                    <div className={styles.innerApp}>
                        <Navbar isViewOrder={false} />
                        {/* {!props.matches && <MapContainer customBounds={null} style={{ width: MapContainerWidth.width, height: MapContainerWidth.height, zIndex: "6", position: "relative", transition: ".3s all ease-in-out" }} className={styles.mainMapContainer} onCenterChange={mapOnCenterChange} >
                            <Monitoring force={true} />
                            <MapNewOrderPinOnMap />

                            <MapViewOrderPinOnMap />

                            <MapEditOrderPinOnMap />
                            <MapTrackDriverPinOnMap />
                            <MapCircleRipple />
                            <MapOrderRouteOnMap />
                            <MapNewEventPinOnMap />

                        </MapContainer>} */}
                        {/* <DriverList className={styles.driversList} style={{ [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "0px" }} /> */}
                        {/* <div className={styles.monitoringContainer} style={{ [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "24px" }}>
                            <ViewOrderMonitoring />
                        </div> */}


                        <Main />

                    </div>
                </Route>


                <Route path="/Pickup" exact>
                    <div className={styles.innerApp}>
                        <Navbar isViewOrder={false} />
                        {/* {!props.matches && <MapContainer customBounds={null} style={{ width: MapContainerWidth.width, height: MapContainerWidth.height, zIndex: "6", position: "relative", transition: ".3s all ease-in-out" }} className={styles.mainMapContainer} onCenterChange={mapOnCenterChange} >
                            <Monitoring force={true} />
                            <MapNewOrderPinOnMap />

                            <MapViewOrderPinOnMap />

                            <MapEditOrderPinOnMap />
                            <MapTrackDriverPinOnMap />
                            <MapCircleRipple />
                            <MapOrderRouteOnMap />
                            <MapNewEventPinOnMap />

                        </MapContainer>} */}
                        {/* <DriverList className={styles.driversList} style={{ [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "0px" }} /> */}
                        {/* <div className={styles.monitoringContainer} style={{ [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "24px" }}>
                            <ViewOrderMonitoring />
                        </div> */}


                        <Main />

                    </div>
                </Route>

                <Route path="/Ondemand" exact>
                    <div className={styles.innerApp}>
                        <Navbar isViewOrder={false} />
                        <Main />

                        {/* {!props.matches && <MapContainer customBounds={null} style={{ width: MapContainerWidth.width, height: MapContainerWidth.height, zIndex: "6", position: "relative", transition: ".3s all ease-in-out" }} className={styles.mainMapContainer} onCenterChange={mapOnCenterChange} >
                            <Monitoring force={true} />
                            <MapNewOrderPinOnMap />

                            <MapViewOrderPinOnMap />

                            <MapEditOrderPinOnMap />
                            <MapTrackDriverPinOnMap />
                            <MapCircleRipple />
                            <MapOrderRouteOnMap />
                            <MapNewEventPinOnMap />

                        </MapContainer>} */}
                        {/* <DriverList className={styles.driversList} style={{ [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "0px" }} /> */}

                        {/* <div className={styles.monitoringContainer} style={{ [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "24px" }}>
                            <ViewOrderMonitoring />
                        </div> */}


                    </div>
                </Route>

                <Route path="/PickupDelivery" exact>
                    <div className={styles.innerApp}>
                        <Navbar isViewOrder={false} />
                        <TableFilter heightOnChange={setTableLayoutUsedHeight} />
                        {(!TablehasError && (TableListData?.length >= 0 || !TableListData)) && <> <TableSelectedOrders />
                            <TableList TablehasError={TablehasError} canClearAll={canClearAll} selectedOrders={selectedOrders} data={TableListData} hasNextPage={hasNextPage} hasPreviousPage={hasPreviousPage} totalCount={totalCount}
                                perPage={perPage} page={page} TableisLoading={TableisLoading} conatinerWidth={"100%"} conatinerHeight={`calc(100vh - ${Object.keys(selectedOrders).length > 0 ? "52" : tableLayoutUsedHeight}px)`}
                                tableColumns={getTableDefaultColumn(t)} table={table} initTableRef={InitTable} type={"PickupDelivery"}
                            />
                        </>}

                        {(TablehasError && !TableisLoading) && <div className={styles.innerApp}>
                            <TableError />
                        </div>}

                        {(!TablehasError && !canClearAll() && (TableListData && TableListData.length == 0 && !TableisLoading)) && <div className={styles.innerApp}>
                            <TableNoOrder />
                        </div>}

                        {(!TablehasError && canClearAll() && (TableListData && TableListData.length == 0) && !TableisLoading) && <div className={styles.innerApp}>
                            <TableNoOrderFilter />
                        </div>}
                        <DriverList className={styles.driversList} style={{ [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "0px" }} />


                    </div>
                </Route>
                <Route path="/PickupDelivery/map" exact>
                    <div className={styles.innerApp}>
                        <Navbar isViewOrder={false} />
                        <MapTableFilters heightOnChange={setTableLayoutUsedHeight} />
                        {!props.matches && <MapContainer customBounds={null} style={{ width: "100%", height: `calc(100vh - ${tableLayoutUsedHeight}px)`, zIndex: "6", position: "relative" }} className={styles.mainMapContainer} onCenterChange={mapOnCenterChange} >
                            <PHUBD_Map />

                        </MapContainer>}
                        <DriverList className={styles.driversList} style={{ [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "0px" }} />
                        <div className={styles.monitoringContainer} style={{ [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "24px" }}>
                            <ViewOrderMonitoring />
                        </div>
                    </div>
                </Route>

                {/* {(ActiveOrderType != "Ondemand" && !isViewOrder && !isRoutesOpen() && !selectedBulkEditStatusSummary) &&
             <div className={styles.innerApp}>
                <Navbar isViewOrder={isViewOrder || Object.keys(selectedOrders).length > 0} />
                <TableFilter heightOnChange={setTableLayoutUsedHeight} />
                {(!TablehasError && (TableListData?.length >= 0 || !TableListData)) && <> <TableSelectedOrders />
                    <TableList TablehasError={TablehasError} canClearAll={canClearAll} selectedOrders={selectedOrders} data={TableListData} hasNextPage={hasNextPage} hasPreviousPage={hasPreviousPage} totalCount={totalCount}
                        perPage={perPage} page={page} TableisLoading={TableisLoading} conatinerWidth={"100%"} conatinerHeight={`calc(100vh - ${Object.keys(selectedOrders).length > 0 ? "52" : tableLayoutUsedHeight}px)`}
                        tableColumns={getTableDefaultColumn(t)} table={table} initTableRef={InitTable}
                    /></>}

                {(TablehasError && !TableisLoading) && <div className={styles.innerApp}>
                    <TableError />
                </div>}

                {(!TablehasError && !canClearAll() && (TableListData && TableListData.length == 0 && !TableisLoading)) && <div className={styles.innerApp}>
                    <TableNoOrder />
                </div>}

                {(!TablehasError && canClearAll() && (TableListData && TableListData.length == 0) && !TableisLoading) && <div className={styles.innerApp}>
                    <TableNoOrderFilter />
                </div>}

            </div>} */}

                {/* {(ActiveOrderType == "Ondemand" || isViewOrder) && !isRoutesOpen() && !selectedBulkEditStatusSummary && 
            <div className={styles.innerApp}>
                <Navbar isViewOrder={isViewOrder} />
       
                <DriverList className={styles.driversList} style={{ [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "0px" }} />



                <Route path="/orders">
                    <Main />
                </Route>
                <Route path="/order/:orderId">

                    <ViewOrderPage />

                </Route>
            </div>} */}


                {/* </Route> */}

                <Route path="/neworder">

                    <NewOrderPage />

                </Route>

                <Route path="/order/:orderId">

                    <ViewOrderPage />

                    <DriverList className={styles.driversList} style={{ [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "0px" }} />

                </Route>

            </>}


            {(!selectedBulkEditStatusSummary && (!String(window.location.href).includes("/PickupDelivery"))) && !props.matches && <MapContainer customBounds={null} style={styless} className={styles.mainMapContainer} onCenterChange={mapOnCenterChange} >
                /route/
                {(String(location.pathname).includes("/route/") && routeDetails) && <MapOrderDetailsPinsOnMap STOPS={routeDetails.stops.filter((item) =>{ if(String(routeDetailsfilteredText).length == 0){return true}else{return String(item.address).toLocaleLowerCase().includes(String(routeDetailsfilteredText).toLocaleLowerCase())}  })} ROUTE={routeDetails.route} />}

                {String(location.pathname).includes("/routes") && <Monitoring force={true} justShowDrivers={true} />}
                {String(location.pathname).includes("/routes") && <MapNewOrderPinOnMap />}
                {String(location.pathname).includes("/routes") && <MapViewOrderPinOnMap />}
                {String(location.pathname).includes("/routes") && <MapTrackDriverPinOnMap />}
                {String(location.pathname).includes("/routes") && <MapCircleRipple />}
                {String(location.pathname).includes("/routes") && <MapOrderRouteOnMap />}
                {String(location.pathname).includes("/routes") && <MapNewEventPinOnMap />}
                {String(location.pathname).includes("/routes") && <MapEditOrderPinOnMap />}


                {/* {String(location.pathname).includes("/routes") && <>
                         <Monitoring force={true} />
                    <MapNewOrderPinOnMap />

                    <MapViewOrderPinOnMap />

                    <MapEditOrderPinOnMap />
                    <MapTrackDriverPinOnMap />
                    <MapCircleRipple />
                    <MapOrderRouteOnMap />
                    <MapNewEventPinOnMap />
                    </>} */}

                {String(location.pathname).includes("/Delivery") && <Monitoring />}
                {String(location.pathname).includes("/Delivery") && <MapNewOrderPinOnMap />}
                {String(location.pathname).includes("/Delivery") && <MapViewOrderPinOnMap />}
                {String(location.pathname).includes("/Delivery") && <MapEditOrderPinOnMap />}
                {String(location.pathname).includes("/Delivery") && <MapTrackDriverPinOnMap />}
                {String(location.pathname).includes("/Delivery") && <MapCircleRipple />}
                {String(location.pathname).includes("/Delivery") && <MapOrderRouteOnMap />}
                {String(location.pathname).includes("/Delivery") && <MapNewEventPinOnMap />}

                {/* {String(location.pathname).includes("/Delivery") && <>
                                  <Monitoring force={true} />
                    <MapNewOrderPinOnMap />
                    <MapViewOrderPinOnMap />
                    <MapEditOrderPinOnMap />
                    <MapTrackDriverPinOnMap />
                    <MapCircleRipple />
                    <MapOrderRouteOnMap />
                    <MapNewEventPinOnMap />
                    </>} */}

                {String(location.pathname).includes("/Pickup") && <Monitoring />}
                {String(location.pathname).includes("/Pickup") && <MapNewOrderPinOnMap />}
                {String(location.pathname).includes("/Pickup") && <MapViewOrderPinOnMap />}
                {String(location.pathname).includes("/Pickup") && <MapEditOrderPinOnMap />}
                {String(location.pathname).includes("/Pickup") && <MapTrackDriverPinOnMap />}
                {String(location.pathname).includes("/Pickup") && <MapCircleRipple />}
                {String(location.pathname).includes("/Pickup") && <MapOrderRouteOnMap />}
                {String(location.pathname).includes("/Pickup") && <MapNewEventPinOnMap />}

                {/* {String(location.pathname).includes("/Pickup") && <>
                          <Monitoring force={true} />
                    <MapNewOrderPinOnMap />

                    <MapViewOrderPinOnMap />

                    <MapEditOrderPinOnMap />
                    <MapTrackDriverPinOnMap />
                    <MapCircleRipple />
                    <MapOrderRouteOnMap />
                    <MapNewEventPinOnMap />
                    </>} */}
                {String(location.pathname).includes("/Ondemand") && <Monitoring force={true} />}
                {String(location.pathname).includes("/Ondemand") && <MapNewOrderPinOnMap />}
                {String(location.pathname).includes("/Ondemand") && <MapViewOrderPinOnMap />}
                {String(location.pathname).includes("/Ondemand") && <MapEditOrderPinOnMap />}
                {String(location.pathname).includes("/Ondemand") && <MapTrackDriverPinOnMap />}
                {String(location.pathname).includes("/Ondemand") && <MapCircleRipple />}
                {String(location.pathname).includes("/Ondemand") && <MapOrderRouteOnMap />}
                {String(location.pathname).includes("/Ondemand") && <MapNewEventPinOnMap />}

                {/* {String(location.pathname).includes("/Ondemand") && <>
                            <Monitoring force={true} />
                    <MapNewOrderPinOnMap />

                    <MapViewOrderPinOnMap />

                    <MapEditOrderPinOnMap />
                    <MapTrackDriverPinOnMap />
                    <MapCircleRipple />
                    <MapOrderRouteOnMap />
                    <MapNewEventPinOnMap />
                    </>} */}
                {String(location.pathname).includes("/PickupDelivery/map") && <PHUBD_Map />}

                {/* {String(location.pathname).includes("/PickupDelivery/map") && <>
                                        <PHUBD_Map />

                    </>}
       */}


                {String(location.pathname).includes("/neworder") && <Monitoring force={true} />}
                {String(location.pathname).includes("/neworder") && <MapNewOrderPinOnMap />}

                {/* {String(location.pathname).includes("/neworder") && <>
                    <Monitoring force={true} />

<MapNewOrderPinOnMap />
                    </>} */}
                {String(location.pathname).includes("/order/") && <Monitoring force={false} justShowDrivers={true} />}
                {String(location.pathname).includes("/order/") && <MapCircleRipple />}
                {String(location.pathname).includes("/order/") && <MapViewOrderPinOnMap />}
                {String(location.pathname).includes("/order/") && <MapEditOrderPinOnMap />}
                {String(location.pathname).includes("/order/") && <MapTrackDriverPinOnMap />}
                {String(location.pathname).includes("/order/") && <MapOrderRouteOnMap />}
                {String(location.pathname).includes("/order/") && <MapNewEventPinOnMap />}


                {/* {String(location.pathname).includes("/order/:orderId") && <>
                    {pureData && <>
                    <Monitoring force={false} />
                        <MapCircleRipple />
                        <MapViewOrderPinOnMap />

                        <MapEditOrderPinOnMap />
                        <MapTrackDriverPinOnMap />
                        <MapCircleRipple />
                        <MapOrderRouteOnMap />
                        <MapNewEventPinOnMap />
                        </>}
                    </>} */}

            </MapContainer>}

            <Route path="/Ondemand" exact>
                <div className={styles.monitoringContainer} style={{ [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "24px", zIndex: "7" }}>
                    <ViewOrderMonitoring />
                </div>

                <DriverList className={styles.driversList} style={{ [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "0px" }} />

            </Route>

            {!selectedBulkEditStatusSummary && <Route path="/routes">
                <div className={styles.monitoringContainer} style={{ [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "24px", zIndex: "7" }}>
                    <RouteListMonitoring />
                </div>
                <DriverList className={styles.driversList} style={{ [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "0px" }} />

            </Route>}

            {!selectedBulkEditStatusSummary && <>
                <Route path="/Delivery" exact>
                <div className={styles.monitoringContainer} style={{ [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "24px", zIndex: "7" }}>
                            <ViewOrderMonitoring />
                        </div>
                        <DriverList className={styles.driversList} style={{ [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "0px" }} />

                </Route>


                <Route path="/Pickup" exact>
                <div className={styles.monitoringContainer} style={{ [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "24px", zIndex: "7"  }}>
                            <ViewOrderMonitoring />
                        </div>
                        <DriverList className={styles.driversList} style={{ [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "0px" }} />

                </Route>
                </>
            }

            <Voip />


        </div>
    } else {
        return <div style={{ float: "left", marginLeft: "calc(50vw - 40px)", marginTop: 'calc(50vh - 40px)' }}>
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>


    }


}


