import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import styles from './style.module.css';
import { SearchListProps } from "./SearchList.types";
import { CLOSE } from "../../../assets";
import IconProvider from "../IconProvider";
import ProgressIndicator from "../ProgressIndicator";
import Typography from "../Typography";
import Button from "../Button";



const SearchList: React.FC<SearchListProps> = ({ list,inputIcon,containerClassName , error, noResult, ListItemChildren, listItemOnclick, placeHolder, errorActionOnClick, errorActionTitle, errorDescription, errorIcon, errorTitle, inputInnerChildren, isloading, noResultActionOnClick, noResultActionTitle, noResultDescription, noResultIcon, noResultTitle, onChange, value ,style}: SearchListProps) => {

    const [isOpen, setIsOpen] = useState(false);
    const [focus, setFocus] = useState(false);
    const dropdownNode = useClickOutside(() => {
        setIsOpen(false);
        setTimeout(() => {
            // onChange(null)
        }, 150);
    })


    return (
        <div  ref={dropdownNode}  className={clsx(styles.SearchList, containerClassName && containerClassName , isOpen && (list || error || noResult) && styles.SearchListActive , (!isloading && list && list.length == 1) && styles.SearchListActiveOneItem)}  style={style ? style : {}} >

            <div className={clsx(styles.SearchListInput ,inputInnerChildren && styles.inputInnerChildren , focus && styles.focus)} >
                {inputIcon ? inputIcon : null}
                <input  onFocus={()=>{setFocus(true) ;setIsOpen(true) ; onChange({target:{value:value}})}} onBlur={()=>{setFocus(false)}}  type="text" placeholder={placeHolder} value={value} onClick={() => { setIsOpen(true); setFocus(true) }} onChange={onChange}/>
                {String(value).length > 0 && <IconProvider Icon={CLOSE} size="12" style={{ cursor: "pointer" }} onClick={()=>{onChange({target:{value:""}}) ; setFocus(false) ; setIsOpen(false)}} />}
                {inputInnerChildren ? inputInnerChildren : null}
            </div>

            {(isloading && isOpen && (list || error || noResult)) && <div className={styles.loadingBody}>

                <ProgressIndicator theme="On_Light" style={{ fontSize: "12px" }} />

            </div>}

            {(!isloading && (isOpen) && list) && <div className={styles.listItemCBody}>


                {list.map((user: any, i: any) => {
                    return <ListItemChildren data={user} onclick={() => { listItemOnclick && listItemOnclick(user, i) ; setFocus(false) ; setIsOpen(false) ;onChange(null) }} />
                })}

            </div>}


            {(!isloading && error && isOpen) && <div className={styles.loadingBody}>

                {errorIcon && <IconProvider Icon={errorIcon} size="24" />}
                {errorTitle && <Typography weight="Body_Middle_Bold" text={errorTitle} style={{ marginTop: "16px" }} />}
                {errorDescription && <Typography weight="Body_Tiny_Regular" text={errorDescription} style={{ width: "231px", textAlign: "center", marginTop: "4px" }} />}
                {errorActionTitle && <Button size="Medium" type="Primary" text={errorActionTitle} style={{ marginTop: "16px" }} onClick={()=>{ setIsOpen(false); setFocus(false)  ;errorActionOnClick()}} />}

            </div>}


            {(!isloading && noResult && isOpen) && <div className={styles.loadingBody}>

                {noResultIcon && <IconProvider Icon={noResultIcon} size="24" />}
                {noResultTitle && <Typography weight="Body_Middle_Bold" text={noResultTitle} style={{ marginTop: "16px" }} />}
                {noResultDescription && <Typography weight="Body_Tiny_Regular" text={noResultDescription} style={{ width: "231px", textAlign: "center", marginTop: "4px" }} />}
                {noResultActionTitle && <Button size="Medium" type="Primary" text={noResultActionTitle} style={{ marginTop: "16px" }} onClick={noResultActionOnClick} />}

            </div>}

        </div>
    )
};

export default SearchList;



const useClickOutside = (handler: any) => {
    const domNode = useRef<HTMLInputElement>();
    useEffect(() => {
        const eventHandler = (event: any) => {
            if (!domNode.current.contains(event.target)) {
                handler();
            }
            // uncomment this for dropdown close on menu click
            // handler();
        }
        document.addEventListener("mousedown", eventHandler);
        return () => {
            document.removeEventListener('mousedown', eventHandler);
        }
    });
    return domNode;
}