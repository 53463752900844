import "./index.css";

import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import styles from './style.module.css';
import colors from '../colors/colors.module.css';
import IconProvider from "../IconProvider";
import ProgressIndicator from "../ProgressIndicator";
import { ARROW_DOWN_BOLD, ArrowRight1, CLOSE, TIMER ,filterPlus , ic_circle_close } from "../../../assets/index";
import { CSSTransition } from 'react-transition-group';
import Typography from "../Typography";
import { DateFilterProps } from "./DateFilter.types";
import DropDown from "../DropDown";
import Input from "../Input";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import faIR from 'date-fns/locale/fa-IR';
import enGB from 'date-fns/locale/en-GB';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "react-modern-calendar-datepicker";
import CustomModal from "../CustomModal";
import momentJalaali from "moment-jalaali";
import { isString } from "lodash";
import { v4 as uuidv4 } from 'uuid';

registerLocale('enGB', enGB)
setDefaultLocale('enGB')


const statesSetter = (states :any)=>{
    return states.map((state :any)=>{ 
        return { key: state, title: state }
    })
}



const DateFilter: React.FC<DateFilterProps> = ({states , From, To, clearOnClick, errorText, i18, onChange, placement, state, value, title }: DateFilterProps) => {
    const [activeStates, setActiveStates] = useState(statesSetter(states));
    const [isOpen, setIsOpen] = useState(false);
    const [currentState, setCurrentState] = useState(states[0]);
    const [currentValue, setCurrentValue] = useState<any>(value);
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const [selectedDay, setSelectedDay] = useState<any>(null);
    const [showFrom, setShowFrom] = useState<any>(false);
    const [showTo, setShowTo] = useState<any>(false);
    const [uuid] = useState(uuidv4());    
    const [currentplacement, setCurrentplacement] = useState(placement);

    const formatInputValue = () => {
        if (!selectedDay) return '';
        return `Day: ${selectedDay.day}`;
    };


    const dropdownNode: any = useClickOutside(() => {
        setIsOpen(false);
    })


    function ItemsList(data: any, currentState: any, setCurrentState: any) {
        return (
            <div className={styles.itemsConatiner}>
                {data.map((item: any) => {
                    return <div onClick={() => { setCurrentState(item.key) }}>
                        <Typography weight={currentState == item.key ? 'Body_Tiny_Bold' : 'Body_Tiny_Regular'} textColor={'extNormal'} text={item.title} className={styles.dropItem} />
                    </div>
                })}
            </div>
        )
    }

    const buttonOncick = useCallback(() => {
        if (!isOpen) {
            // setTimeout(() => {
            //     if (!value.equal || (!value.gte && !value.lte)) {
            //         setIsOpen(true);
            //     }else{
            //         setCurrentValue(null); onChange({equal: null, gte: null, lte: null})

            //     }
            // }, 50);
            setIsOpen(true);
            setCurrentValue(value)
            if(value.equal){
                setCurrentState("Isequal")
                setSelectedDay(value.equal)
            }else{
                setCurrentState("Isbetween")     
                setStartDate(value.gte)
                setEndDate(value.lte)
            }
            // if(currentState != "Isbetween"){
            //     const [startDate, setStartDate] = useState<any>(null);
            //     const [endDate, setEndDate] = useState<any>(null);
            //     const [selectedDay, setSelectedDay] = useState<any>(null);
            // }
        }


        
        const myDiv = document.getElementById(uuid);
        const rect = myDiv?.getBoundingClientRect();
    
     
        if((window.innerWidth - Number(rect?.right)) < 300){
            setCurrentplacement("bottomEnd")
        }else{
            setCurrentplacement("bottomStart")
        }

    }, [isOpen, value, currentState,uuid]);

    function setDateRangePicker(e: any, func: any) {


        let from = momentJalaali(
            e.day +
            "/" +
            e.month +
            "/" +
            e.year,
            "DD/MM/YYYY"
        );


        let from_save = from.toString()
        func(e)
        setShowFrom(false)
        setShowTo(false)
    };


    const getStandardDate = useCallback((ee:any ,isTo:any , noTime :any) => {
        if(ee && ee.day && noTime){
            return momentJalaali(ee.day + "/" + ee.month + "/" + ee.year , "DD/MM/YYYY").format("D MMM")

        }else if(ee && ee.day && isTo){
            return momentJalaali(ee.day + "/" + ee.month + "/" + ee.year +" 23:59", "DD/MM/YYYY HH:mm").format("D MMM HH:mm")

        }else if(ee && ee.day){
            return momentJalaali(ee.day + "/" + ee.month + "/" + ee.year, "DD/MM/YYYY").format("D MMM HH:mm")
        }else{
            return "---"
        }
    }, []);


    return (
        <div ref={dropdownNode} onClick={buttonOncick}  id={uuid} className={clsx(styles.button,(value && (value.gte || value.lte || value.equal) )&& styles.Activebutton , isOpen && styles.buttonNOhOVER)} >

            {(value?.gte || value?.lte || value?.equal) ? <>
                 <IconProvider Icon={ic_circle_close} size="14" onClick={(e)=>{
                            e.stopPropagation();
                            setCurrentValue(null); onChange({equal: null, gte: null, lte: null})
                }} /><span className={styles.title}> {`${title}`}<div style={{height:"12px",width:"1px" ,backgroundColor:"rgba(128, 128, 128, 1)",borderRadius:"2px"}} /><Typography weight='Body_Small_Medium' textColor={'TextDark'} text={isString(value) ? value : `${(value.gte || value.equal) ? getStandardDate((value.gte || value.equal) ,false , value.lte ? (value.equal ? true : false)  :true ):""}${value.lte ? ' - ' : ''}${value.lte ? getStandardDate(value.lte , true):""}`} /> </span>
               
            </> : <span className={styles.title}><IconProvider Icon={filterPlus} size="14"  onClick={(e)=>{
                
            }} /> {title}</span>}



            <CSSTransition
                in={isOpen}
                timeout={300}
                classNames="dropdown-animation"
                unmountOnExit
                onEnter={() => setIsOpen(true)}
                onExited={() => setIsOpen(false)}
            >
                <div className={clsx(styles.dropdownBox, styles[currentplacement])} style={{ width: "280px" }}>
                   {states?.length > 1 && <DropDown ItemsList={ItemsList( activeStates, currentState, setCurrentState)} text={currentState} placement="over" size="Small" data={[{ key: "Isequal", title: "Isequal" }, { key: "Isbetween", title: "Isbetween" }, { key: "Contains", title: "Contains" }]} checkSelected="full" className={styles.drop} />}

                    {currentState == "Isbetween" && <div className={styles.IsbetweenContainer}>
                    <div className={styles.buttonInput} onClick={() => setShowFrom(true)} style={{ color: startDate ? "rgba(36, 36, 36, 1)" : "rgba(128, 128, 128, 1)" }}>
                            <IconProvider Icon={TIMER} size="16" />{startDate ? (momentJalaali(startDate.day + "/" + startDate.month + "/" + startDate.year, "DD/MM/YYYY").format("D MMM HH:mm")) : "Select date"}
                        </div>
                        <IconProvider Icon={ArrowRight1} size="16" />
                        <div className={styles.buttonInput} onClick={() => setShowTo    (true)} style={{ color: endDate ? "rgba(36, 36, 36, 1)" : "rgba(128, 128, 128, 1)" }}>
                            <IconProvider Icon={TIMER} size="16" />{endDate ? (momentJalaali(endDate.day + "/" + endDate.month + "/" + endDate.year +" 23:59", "DD/MM/YYYY HH:mm").format("D MMM HH:mm")) : "Select date"}
                        </div>
                        
                    </div>}

                    {currentState != "Isbetween" &&
                        //  <DatePicker customInput={<ExampleCustomInputfullWith placeHolder="Select date" />} withPortal dateFormat="dd MMM hh:mm" monthsShown={2} showTimeSelect selected={startDate} onChange={(date) => setStartDate(date)} />
                        <div className={styles.buttonInputfullWith} onClick={() => setShowFrom(true)} style={{ color: startDate ? "rgba(36, 36, 36, 1)" : "rgba(128, 128, 128, 1)" }}>
                            <IconProvider Icon={TIMER} size="16" />{selectedDay ? (momentJalaali(selectedDay.day + "/" + selectedDay.month + "/" + selectedDay.year, "DD/MM/YYYY").format("D MMM")) : "Select date"}
                        </div>

                    }
                    {/* <Typography weight='Body_Small_Regular' textColor={'Red'} text={"Error Text"} className={styles.Error} /> */}

                    <div className={styles.footer}>
                        <Typography weight='Body_Tiny_Bold' textColor={'TextDark'} text={"Clear"} className={styles.dropItem} style={{ cursor: "pointer" }} onClick={(e)=>{
                            e.stopPropagation();
                            setCurrentValue(null); onChange({equal: null, gte: null, lte: null})
                }}/>
                        <Typography weight='Body_Tiny_Bold' textColor={'Primary'} text={"Apply"} className={styles.dropItem} style={{ opacity: (selectedDay || startDate || endDate) ? "1" : ".4", cursor: "pointer" }} onClick={()=>{ (selectedDay || startDate || endDate) && onChange({gte:startDate , lte : endDate , equal:selectedDay}); (selectedDay || startDate || endDate) && setTimeout(() => {
                            setIsOpen(false)
                        }, 100)}} />

                    </div>
                </div>
            </CSSTransition>

            {(showFrom ) && <CustomModal onclose={() => setShowFrom(false)}>
                <Calendar
                    value={currentState != "Isbetween" ? selectedDay :startDate}
                    onChange={(e) => setDateRangePicker(e, currentState != "Isbetween" ? setSelectedDay :setStartDate)}
                    shouldHighlightWeekends
                    colorPrimary="#148be9" // added this
                    colorPrimaryLight="#148be940" // and this
                />
            </CustomModal>}

            {(showTo) && <CustomModal onclose={() => setShowTo(false)}>
                <Calendar
                    value={endDate}
                    onChange={(e) => setDateRangePicker(e, setEndDate)}
                    shouldHighlightWeekends
                    colorPrimary="#148be9" // added this
                    colorPrimaryLight="#148be940" // and this
                />
            </CustomModal>}
        </div>

    );
};

export default DateFilter;



const useClickOutside = (handler: any) => {
    const domNode = useRef<any>();
    useEffect(() => {
        const eventHandler = (event: any) => {
            if (!domNode.current.contains(event.target)) {
                handler();
            }
            // uncomment this for dropdown close on menu click
            // handler();
        }
        document.addEventListener("mousedown", eventHandler);
        return () => {
            document.removeEventListener('mousedown', eventHandler);
        }
    });
    return domNode;
}


const ExampleCustomInputfullWith = forwardRef(({ value, onClick, placeHolder }: any, ref: any) => (
    <div className={styles.buttonInputfullWith} onClick={onClick} ref={ref} style={{ color: value ? "rgba(36, 36, 36, 1)" : "rgba(128, 128, 128, 1)" }}>
        <IconProvider Icon={TIMER} size="16" />{value ? value : placeHolder}
    </div>
));

const ExampleCustomInput
    = forwardRef(({ value, onClick, placeHolder }: any, ref: any) => (
        <div className={styles.buttonInputfullWith} onClick={onClick} ref={ref} style={{ color: value ? "rgba(36, 36, 36, 1)" : "rgba(128, 128, 128, 1)", width: "109px" }}>
            <IconProvider Icon={TIMER} size="16" />{value ? value : placeHolder}
        </div>
    ));



