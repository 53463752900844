import React, { useCallback, useRef, useState } from 'react'
import styles from './style.module.css';
import { useSelector } from 'react-redux';
import Button from '../../../design-system/lib/Button';
import { ARROW_LEFT, BARCODE, CLOSE, DRIVER_COLOR, Edit, LINK_CIRCLE, MORE, PATH, RECEIPT, ROUTE_OPTIMIZE, TIMER, box_gray, trash } from '../../../assets';
import Typography from '../../../design-system/lib/Typography';
import { get_lang } from '../../../helper/UserData/userdate';
import jalali from "jalali-moment";
import Tag from '../../../design-system/lib/Tag';
import { ClickAwayListener, Paper, Popper } from '@material-ui/core';
import IconProvider from '../../../design-system/lib/IconProvider';
import { Link } from "react-router-dom";
import EditRouteInfo from '../../table/route-list/components/editInfo';


function Header({ name, date, data, status, distanceMeters, durationSeconds, stopsCount, setIsreorder , setOptDialoge , onchange , setEditStatus}) {
  const [editAnchorEl, setEditAnchorEl] = useState(null);
  const [showPopper, setShowPopper] = useState(false);
  const Popperref = useRef(null);
  const [showEditInfoD, setShowEditInfoD] = useState(false);


  const handleEditClick = (event) => {
    setShowPopper(true)
    setEditAnchorEl(editAnchorEl ? null : event.currentTarget);
  };



  return (
    <div className={styles.container} >
      <div className={styles.firstrow}  ref={Popperref}>
      <Link to="/routes" >
        <Button icon={ARROW_LEFT} size='Medium' type='Secondary' />
        </Link>
        <div className={styles.titleDescriptionContainer}>
          <Typography weight='Body_Middle_Bold' text={name} />
          <Typography weight='Subtitle_Tiny_Regular' text={jalali(date).locale(get_lang()).format("D MMM HH:mm")} />
        </div>
        <Tag backgroundColor='GreenUltraLight' color='Green' size='Large' text={status} weight='Body_Small_Medium' className={styles[status]} style={{ borderRadius: "8px", marginInlineStart: "12px" , marginInlineEnd: "auto"}} />
        <Button icon={MORE} size='Medium' type='Secondary' ref={Popperref} onClick={handleEditClick}  />

      </div>


      <div className={styles.secorow}>
        {stopsCount > 0 && <Tag backgroundColor='GrayUltraLight' color='TextNormal' iconSize='16' size='Medium' icon={box_gray} text={stopsCount} weight='Body_Small_Medium' style={{ borderRadius: "8px" }} />
        }       <Tag backgroundColor='GrayUltraLight' iconSize='16' color='TextNormal' size='Medium' text={`${Number(distanceMeters / 1000).toFixed(0)} km`} icon={PATH} weight='Body_Small_Medium' style={{ borderRadius: "8px" }} />
        <Tag backgroundColor='GrayUltraLight' iconSize='16' color='TextNormal' size='Medium' text={`Duration: ${Number(durationSeconds / 60).toFixed(0)}m`} icon={TIMER} weight='Body_Small_Medium' style={{ borderRadius: "8px" }} />

      </div>



      <Popper
        open={showPopper}
        anchorEl={Popperref.current}
        role={undefined}
        placement={"bottom-end"}
        id='routedetailseditAnchorEl'
        // style={{
        //   // width: "160px",
        //   // marginBottom: "4px",
        //   // border: "solid 1px rgba(128, 128, 128, 0.2)",
        //   borderRadius: "10px",
        //   // backgroundColor: "white",
        //   boxShadow: "0 1px 12px 0 rgba(128, 128, 128, 0.2)",
        //   position:"fixed !important"

        //   // zIndex: "1"
        // }}
        //   className="Popperxxx"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Paper style={{ borderRadius: "8px" ,zIndex:"2" }}>
            <ClickAwayListener
              onClickAway={(e) => {
                setShowPopper(false)
              }}
            >
              <div className={styles.popupItemContainer}>
              <div className={styles.item}> <IconProvider Icon={Edit} size='16' /> <Typography weight='Body_Middle_Regular' text='Edit Route info' onClick={()=>{setShowEditInfoD(true)}} /> </div>

                <div className={styles.item}> <IconProvider Icon={DRIVER_COLOR} size='16' /> <Typography weight='Body_Middle_Regular' text='Edit Status & Driver' onClick={()=>{setEditStatus(true)}} /> </div>
                {/* <div className={styles.item}> <IconProvider Icon={trash} size='16' /> <Typography weight='Body_Middle_Regular' text='Remove route' /> </div> */}

                <div className={styles.item} onClick={()=>{setIsreorder(true)}}> <IconProvider Icon={LINK_CIRCLE} size='16' /> <Typography weight='Body_Middle_Regular' text='Reorder Stops' /> </div>
                <div className={styles.item} onClick={()=>{setOptDialoge(true)}}> <IconProvider Icon={ROUTE_OPTIMIZE} size='16' /> <Typography weight='Body_Middle_Regular' text='Optimize' /> </div>
                {/* <div className={styles.item}> <IconProvider Icon={LINK_CIRCLE} size='16' /> <Typography weight='Body_Middle_Regular' text='Track Link' /> </div>
                <div className={styles.item}> <IconProvider Icon={BARCODE} size='16' /> <Typography weight='Body_Middle_Regular' text='Shipment Label' /> </div>
                <div className={styles.item}> <IconProvider Icon={RECEIPT} size='16' /> <Typography weight='Body_Middle_Regular' text='Run Sheet' /> </div> */}

              </div>
            </ClickAwayListener>
          </Paper>
        )}
      </Popper>
      
     {showEditInfoD && <EditRouteInfo data={data} onClose={setShowEditInfoD} onchange={onchange} />}
    </div>
  )
}


export default Header