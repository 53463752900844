import React from 'react';
import { Sharedayout} from '../layout/shared/shared'


function SharedPage({matches}) {
    return <Sharedayout matches={matches} />

}

export default SharedPage;

