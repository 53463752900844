import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { CanEditEarning, CanEditPrice, CanEditPriceEarning, CanEditTravel } from "../../../../../../../helper/module";
import { Edit_Travel_Operate_Changed } from "../../../../../../../Redux/actions/actionNames";
import price from "../../../../../../../assets/ic_dollar.svg";
import cancell from "../../../../../../../assets/ic-close.svg";
import Dialog from "@material-ui/core/Dialog";
import {
  get_currencyCode,
  get_lang,
} from "../../../../../../../helper/UserData/userdate";
import Price from "./price";
import Earning from "./earning";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fee: "0",
      surcharges: [],
      priceChange: false,
      active: 1,
    };
  }

  handleTab = (num) => {
    this.setState({
      active: num,
    });
  };

  EditpriceOnChange = () => {
    this.props.priceChange && this.props.priceChange(false)
    if (this.state.priceChange) {
      this.setState({
        priceChange: !this.state.priceChange,
        surcharges: [],
        fee: "0",
      });
    } else {
      this.setState({
        priceChange: !this.state.priceChange,
        surcharges: this.props.surcharges,
        fee: this.props.baseFarePrice,
      });
    }
  };

  closeDialoge = () => {
    this.props.priceChange && this.props.priceChange(false)
    this.setState({
      priceChange: false,
      surcharges: [],
      fee: "0",
    });
    this.props.func()
  }

  feeOnchange = (e) => {
    this.props.dispatch(Edit_Travel_Operate_Changed());

    this.setState({
      fee: e.target.value,
    });
  };


  render() {
    const { t } = this.props;
    let canShowEarning = CanEditEarning(this.props.Data.type, this.props.Data.status)
    let canShowPrice = CanEditPrice(this.props.Data.status) && !this.props.Data.isPickupDelivery
    return (
      <React.Fragment>
        {/* {CanEditTravel(this.props.Data.status) && ( */}
        {
          !this.props.noC && <span onClick={this.EditpriceOnChange}>
           {this.props.children}
          </span>
        }

        {/* )} */}

        <Dialog
          style={{}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.priceChange || this.props.open}
          // onClose={this.closeDialoge} 
          className="EditpriceOnChange"
          disableAutoFocus
          disableEnforceFocus 
          disableRestoreFocus 
        >
          <div
            className="EditPriceContainer"
            style={{ height: this.props.noC ? "312px" : "376px", width: "416px", overflow: "hide" }}
          >
            <div className="header">
              <img
                className="close"
                src={cancell}
                onClick={this.closeDialoge}
                style={{ border: "1px solid rgba(128, 128, 128, 0.2)" }}
              />
              <span className="title">                     
                  {(CanEditEarning(this.props.Data.type, this.props.Data.status) && (CanEditPrice(this.props.Data.status) && !this.props.Data.isPickupDelivery)) ? this.props.t("EditPrice") : ((CanEditPrice(this.props.Data.status) && !this.props.Data.isPickupDelivery) ? this.props.t("EditPrice2") : CanEditEarning(this.props.Data.type, this.props.Data.status) ? this.props.t("EditEarning") : this.props.t("Pricedetails"))}

              </span>
            </div>


            <div
              className="content"
              style={{
                paddingBottom: "0px",
                height: "325px",
                overflow: "hide",
                position: "relative",
              }}
            >
              {(canShowEarning && canShowPrice) && <div className="switchDriverInfo switchPriceInfo" style={{ borderBottom: "1px solid rgba(128, 128, 128, 0.2)" }}>
                {canShowPrice && <span
                  className={`tab ${this.state.active === 1 && "tabActive"}`}
                  onClick={() => {
                    this.handleTab(1);
                  }}
                >
                  {" "}
                  <Trans i18nKey={"Price"}> </Trans>
                </span>}
                {canShowEarning && <span
                  className={`tab ${this.state.active === 2 && "tabActive"}`}
                  onClick={() => {
                    this.handleTab(2);
                  }}
                >
                  {" "}
                  <Trans i18nKey={"Earning"}> </Trans>{" "}
                </span>}
              </div>}

              {(canShowEarning && canShowPrice) ? (this.state.active === 1 ? <Price noC={this.props.noC} Data={this.props.Data} close={this.closeDialoge} /> : <Earning noC={this.props.noC} close={this.closeDialoge} />) : (canShowEarning ? <Earning noC={this.props.noC}  close={this.closeDialoge} /> : <Price noC={this.props.noC} Data={this.props.Data} close={this.closeDialoge}/>)}
              {/* {this.state.active === 1? <Price noC={this.props.noC} Data={this.props.Data} /> : <Earning noC={this.props.noC} />} */}
            </div>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  Data: state.ViewTravel.pureData ? state.ViewTravel.pureData : false,
  baseFarePrice: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.baseFarePrice
    : 0,
  surcharges: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.surcharges
    : [],
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
