export const GermanLabel = {
    "Enter_the_following_information_to_enter": "Bitte folgende Informationen angeben:",
    "login": "Login",
    "Username": "Benutzername",
    "password": "Passwort",
    "Orders": "Bestellungen",
    "Online": "Online",
    "Offline": "Offline",
    "Info": "Info",
    "email": "Email",
    "vehicletypes": "Servicetypen",
    "loginstatus": "Login Status",
    "lastonlinetime": "Zuletzt online",
    "fetchingdata": "empfange Daten",
    "poperLoading": "Bitte warten",
    "Noinformationfound": "Keine Informationen gefunden",
    "Drivers": "Fahrer",
    "otherdropoffs": "weiter Ziele",
    "paymentmethod": "Zahlungsmethode",
    "Receiver": "Vom Empfänger",
    "Sender": "Vom Versender",
    "Balance": "Saldo",
    "Cash": "Bar",
    "Wallet": "Konto",
    "Close": "schliessen",
    "noVehicleType": "Kein Fahrzeugtyp",
    "Fname": "Vorname",
    "Lname": "Nachname",
    "Pnumber": "Telefonnummer",
    "driver": "Fahrer",
    "Pleasetryanotherkeyword": "Bitte versuche eine anderes Keywort",
    "DropOff": "Ziel / Lieferadresse",
    "Noaddressavailable": "Keine Adresse verfügbar",
    "Managefavoriteaddresses": "Adressen verwalten",
    "address": "Adresse",
    "Saved": "gespeichert",
    "block": "Block",
    "Floor": "Etage",
    "Unit": "Einheit",
    "Recieverfullname": "Empfänger Name",
    "Recieverphonenumber": "Empfänger Nummer",
    "notesfordriver": "Nachricht für Fahrer",
    "Price": "Preis",
    "EditOrder": "Bestellung anpassen",
    "Firstname": "Vorname",
    "Lastname": "Nachname",
    "Phonenumber": "Telefonnummer",
    "senderfullname": "Versender Name",
    "senderphonenumber": "Versender Nummer",
    "Search": "Suchen",
    "Service": "Service",
    "Passenger": "Kunde",
    "Pickup": "PickUp",
    "Pricedetails": "Preisdetails",
    "Fee": "Gebühr",
    "Fornow": "für jetzt",
    "Scheduled": "geplant / terminiert",
    "before": "vor:",
    "Settime": "Zeit bestimmen",
    "after": "nach:",
    "confirm": "bestätigen",
    "Cancel": "löschen",
    "Addresses": "Adressen",
    "Savedaddresses": "Adressen gespeichert",
    "new": "Neu",
    "Editaddress": "Adresse anpassen",
    "Saveaddress": "Adresse gespeichert",
    "Language": "Sprache",
    "en": "English",
    "fa": "Farsi",
    "English": "English",
    "Persian": "Persian",
    "Logout": "Ausloggen",
    "logotTitle": "Vom Konto abmelden",
    "logoutDescription": "Du wirst vom Konto abgemeldet und musst dich später neu anmelden",
    "confirmLogout": "Ja, ausloggen",
    "skipLogout": "löschen",
    "Ascending": "Aufsteigend",
    "Descending": "Absteigend",
    "sort[createdAt]": "Datum",
    "sort[pickupScheduleDate]": "PickUp planen (Zeit)",
    "sort[statusUpdatedAt]": "Sortieren nach (update am)",
    "SelectDateRange": "Datumsbereich",
    "StartDate": "Startdatum",
    "EndDate": "Enddatum",
    "Today": "Heute",
    "Yesterday": "Gestern",
    "ThisWeek": "Diese Woche",
    "LastWeek": "Letzte Woche",
    "Last7Days": "Die letzten 7 Tage",
    "ThisMonth": "Dieser Monat",
    "LastMonth": "Letzter Monat",
    "monitoring": "Überwachen",
    "Haveorder": "haben Bestellungen",
    "Estimated": "geschätzt",
    "ReceiverCash": "Bar (vom Empfänger)",
    "SenderCash": "Bar",
    "SenderWallet": "Wallet",
    "Auto": "Automatisch",
    "Manual": "Manuell",
    "SendOrderr": "Bestellung einreichen",
    "Neworder": "Neue Bestellung",
    "Person": "Individuum",
    "Business": "Firma",
    "Businessname": "Fimenname",
    "phonenumber": "Telefonnummer",
    "Adddropoff": "Ziel hinzufügen",
    "Searchbynameorphonenumber": "Suchen (nach Name oder Telefonnummer)",
    "Fullname": "Name",
    "type": "Typ",
    "only": "nur xxx",
    "ChooseDriver": "Fahrer wählen",
    "AllKey": "Alle",
    "MeKey": "Ich",
    "Vehicletype": "Fahrzeugtyp",
    "status": "Staat",
    "statusInProgress": "In Bearbeitung",
    "statusCanceled": "gelöscht",
    "statusPending": "pendent",
    "statusUnassigned": "nicht zugewiesen",
    "statusDone": "erledigt",
    "dispatcher": "Operator",
    "customer": "Mobile",
    "regexcode": "Code",
    "regexcustomerPhone": "vom Kunden gelöscht",
    "regexcustomerName": "Name (Kunde)",
    "regexdriverPhone": "Telefonnummer (Fahrer)",
    "regexdriverName": "Name (Fahrer)",
    "regexdriverUsername": "Nutzername (Fahrer)",
    "pickupDescription": "PickUp Beschreibung",
    "filter": "Filter",
    "clear": "Clear",
    "orderInfoError": "Fehlermeldungen / Informationen",
    "Details": "Details",
    "History": "Historie",
    "Phone": "Telefon",
    "km": "Km",
    "Espath": "geschätzer Weg",
    "Receipt": "Quittung",
    "Copylink": "Link kopieren",
    "repeatOrder": "Bestellung wiederholen",
    "Edit": "ändern",
    "EditPrice": "Preis ändern",
    "Endtrip": "Bestellung ändern",
    "Canceltravel": "Bestellung löschen",
    "CancellText": "Nach dem löschen, wir auch die Bestellung für den Fahrer gelöscht",
    "confirmCancel": "Ja",
    "Endtravel": "vollständig",
    "EndlText": "Nach Erfüllung der Bestellung wir der Betrag direkt vom Kundenkonto abgezogen",
    "confirmEnd": "Ja",
    "End": "Bestellung löschen",
    "Deliveredat": "Geliefert am",
    "pod": "Zustellnachweis",
    "Notes": "Notizen",
    "Nonotes": "keine Notizen",
    "Photo": "Foto",
    "Signature": "Unterschrift",
    "Nosignature": "Keine Unterschrift",
    "Nophoto": "Kein Foto",
    "ServiceOptions": "Service Optionen",
    "Events": "Ereignisse",
    "status2": "Status",
    "Creator": "Erfasser",
    "Creationdate": "Kreiert (Datum)",
    "Orderid": "Bestell ID",
    "Pickup2": "PickUp",
    "Dropoff2": "Ziel / Lieferadresse",
    "PleaseSelect": "Bitte auswählen",
    "CreateEvent": "Ereignis erfassen",
    "Description": "Beschreibung",
    "detailsAboutEvent": "Details über das Ereignis",
    "CustomerVisibility": "Kundensichtbarkeit",
    "LocationOnMap": "Ort auf der Karte",
    "Pending": "Pendent",
    "Unassigned": "nicht zugewiesen",
    "Done": "erledigt",
    "CustomerCanceled": "gelöscht",
    "DriverCanceled": "gelöscht",
    "SupportCanceled": "gelöscht",
    "Createdsuccessfully": "erfolgreich erfasst",
    "Estime": "geschätzte Zeit",
    "Promocode": "Promo Code",
    "Updatedsuccessfully": "erfolgreiches update",
    "statusAssigned": "zugwiesen",
    "statusStarted": "gestartet",
    "statusPickedUp": "gestartet",
    "statusDriverCanceled": "vom Fahrer gelöscht",
    "statusArrived": "angekommen",
    "statusSupportCanceled": "vom Support gelöscht",
    "Title": "Titel",
    "ContactFullname": "Kontakt Name",
    "Contactphonenumber": "Kontakt Telefonnummer",
    "Commission": "Kommission",
    "surcharge": "Zuschlag",
    "haslogined": "hat eingeloggd",
    "Notloggedin": "nicht eingeloggd",
    "Schedule": "Termine",
    "Date": "Datum",
    "Time": "Zeit",
    "noDateToShow": "Kein Datum zum anzeigen",
    "noTimeToShow": "Keine Zeit zum anzeigen",
    "Apply": "Anwenden",
    "lowBalance": "tiefer Saldo",
    "balanceError": "Dein Saldo muss mindestens dem Lieferpreis entsprechen",
    "Methods": "Methoden",
    "Addressbook": "Adressbuch",
    "AddressbookSearchbar": "Nach Adresse, Titel, ID suchen",
    "AddressbookDropOf": "Adressbuch (Ziele, Lieferadressen)",
    "AddressbookPickup": "Adressbuch (PickUp, Abholadressen)",
    "hide": "verbergen",
    "Nosavedaddress": "keine gespeicherten Adressen",
    "Romanian": "Romänisch",
    "business": "Firma",
    "username": "Nutzername",
    "rememberme": "erinnere mich",
    "SendOrder": "Bestellung einreichen",
    "code": "Code",
    "noorder": "keine Bestellung",
    "pleaseSellectParsel": "Bitte einen Service auswählen",
    "Order": "Bestellung einreichen",
    "Code": "Code",
    "Editorder": "Bestellung anpassen",
    "all": "Alle",
    "Address": "Adresse",
    "Block/Building": "Block/Gebäude",
    "Pickedupat": "Abgeholt am/um",
    "for": "Für",
    "fr": "Französisch",
    "AllVehicletypes": "Alle Servicetypen",
    "AllServices": "Alle Services",
    "French": "Französisch",
    "enterDataCorrectly": "Informationen korrekt eingeben",
    "controlThatbeforeLoweEqualAfter": "Zeit \"Vor:\" kann nicht kleiner als \"Nach:\" sein",
    "ForceAssign": "Zuweisung forcieren",
    "Allvehicletypes": "Alle Fahrzeugtypen",
    "waitforconfirm": "xxx (auf Bestätigung warten)",
    "waitforconfirm2": "(Auf Bestätigung warten)",
    "Dispatching": "Versand",
    "NoDriver": "Kein Fahrer",
    "OtherDetails": "Weitere Details",
    "StatusandDriver": "Status und Fahrer",
    "EditStatusandDriver": "ändern des Status und Fahrer",
    "Current": "Aktuell",
    "EDAS_Pending": "Pendent",
    "EDAS_Assigned": "zugewiesen",
    "EDAS_PickedUp": "PickedUp",
    "EDAS_Unassigned": "nicht zugewiesen",
    "EDAS_Started": "gestartet",
    "EDAS_Done": "erledigt",
    "EDAS_CustomerCanceled": "gelöscht vom Kunden",
    "EDAS_DriverCanceled": "gelöscht vom Fahrer",
    "EDAS_SupportCanceled": "gelöscht vom Support",
    "EDAS_Arrived": "angekommen",
    "Pleasechooseadrivertochangestatus": "Ändern des Status kann den Preis beinflussen",
    "icWarningEdit": "Fahrzeugtyp wird geändert",
    "Vehicletypewillbechanged": "Dein aktueller Fahrzeugtyp wird geändert. Dies beeinflusst den Preis",
    "Rejected": "Zurückgewiesen",
    "Rejected2": "(Zurückgewiesen)",
    "DriverVehicleTypeError": "Es gibt keinen Fahrzeugtyp für diesen Fahrer",
    "CodeNameFamilyPhonenumber": "Code, Name, Familie, Telefonnummer",
    "youcanselectdriverintheedit": "Du kannst den Fahrer im /ändern des Fahrer und Status/ auswählen",
    "Earning": "Verdienst",
    "savePrice": "Preis speichern",
    "saveEarning": "Verdienst speichern",
    "Total": "Total",
    "EditPickupDropOff": "Adressen",
    "noMoreData": "Keine zusätzlichen Informationen",
    "OnDemand": "Auf Abruf",
    "PD": "P & D",
    "PD2": "P&D",
    "PickupDropoff": "PickUp, Dropoff",
    "PickupFlag": "P",
    "DropOffFlag": "D",
    "PickupandDelivery": "PickUp and Delivery",
    "PickupDelivery": "Pickup and Delivery",
    "Delivery": "Delivery",
    "Collecting": "Am Einladen",
    "Transit": "Transit",
    "Delivering": "Am Ausliefern",
    "EDAS_Collecting": "Am Einladen",
    "EDAS_Transit": "Transit",
    "EDAS_Delivering": "Am Ausliefern",
    "statusCollecting": "Am Einladen",
    "statusTransit": "Transit",
    "statusDelivering": "Am Ausliefern",
    "serviceCustomerError1": "xxx ist für diesen Kunden nicht verfügbar",
    "serviceCustomerError2": "Er/Sie kann nur den xxx Service wählen",
    "serviceCustomerError3": "Er/Sie kann nur den xxx Service wählen",
    "OrderCode": "BestellCode",
    "event_Created": "Kreiert",
    "event_Unassigned": "nicht zugewiesen",
    "event_Assigned": "zugewiesen",
    "event_Started": "gestartet",
    "event_Arrived": "angekommen",
    "event_PickedUp": "Abgeholt",
    "event_OrderDone": "Bestellung erledigt",
    "event_DropoffDone": "Abgabe erledigt",
    "event_CustomerFeedback": "Kundenfeedback",
    "event_AddPod": "Zustellnachweis hinzufügen",
    "event_CustomerCanceled": "gelöscht vom Kunden",
    "event_DriverCanceled": "gelöscht vom Fahrer",
    "event_SupportCanceled": "gelöscht vom Support",
    "event_Updated": "Updated",
    "event_DriverAssignment": "Fahrer zugewiesen",
    "event_DriverReject": "Vom Fahrer abgewiesen",
    "event_Collecting": "Am Einladen",
    "event_InTransit": "In Transit",
    "event_Delivering": "Am Ausliefern",
    "event": "Ereignis",
    "tab_Delivery": "Lieferung",
    "tab_Ondemand": "Auf Abruf",
    "tab_Pickup": "PickUp",
    "tab_PickupDelivery": "P&D",
    "CurrentStatus": "Aktueller Status",
    "Changestatus": "Status ändern",
    "Newstatus": "Neuer Status",
    "Savechanges": "Änderungen speichern",
    "Other": "Weitere",
    "Shipmentlabel": "Versandetikett",
    "pleaseSellectService": "Please Select Service Type",
    "now": "Now",
    "ascending": "Ascending",
    "descending": "Descending",
    "Last3Month": "Last 3 Month",
    "Pickupnote": "Pickup Note",
    "EditPrice2": "Price",
    "EditEarning": "Earning",
    "pop": "Proof of pickup",
    "Assigned": "In Progress",
    "PickedUp": "In Progress",
    "Started": "In Progress",
    "Arrived": "In Progress",
    "statusCustomerCanceled": "Customer Canceled",
    "Yourcurrentvehicletypeanditwillbechangedandeffectsonprice": "Your current vehicle type will be changed and effects on price",
    "Dutch": "dutch",
    "Ondemand": "On Demand",
    "todayat": "Today at",
    "tomorrowat": "Tomorrow at",
    "nextWeekat": "Next Week at",
    "lastDayat": "Last Day at",
    "lastWeekat": "Last Week at",
    "CopyOrderlink": "Copy Order link",
    "CopyPickuplink": "Copy Pickup link",
    "CopyDeliverylink": "Copy Delivery link",
    "Bulkimport": "Bulk import",
    "Downloadsamplecsv": "Download sample CSV",
    "processing": "Proccessing",
    "done": "Finished",
    "failed": "Failed",
    "Draft": "Draft",
    "Uploading": "Uploading",
    "Draganddropyourfile": "Drag and drop your file",
    "Onlycsvformatissupported": "Only .csv format is supported",
    "ORyoucan": "OR you can",
    "Openfile": "Open file",
    "Noimportsyet": "No imports yet",
    "NoimportsyetDesc": "You can see the list of your imports here and check their status",
    "nosupportdialogeTitle": "File Formart not supported",
    "nosupportdialogeText": "xxx file is not supported. you can only upload .csv file including order info",
    "ok": "ok",
    "Import": "Import",
    "previewTitle": "Preview and choose Options",
    "previewDesc": "This is the first item of your files to check your info is in the right structure",
    "Upload": "Upload",
    "Remove": "Remove",
    "ServiceandOrdertype": "Service and Order type",
    "Fileisproccessing": "File is proccessing ...",
    "proccessingDesc": "You can refresh to see if the result is ready",
    "serverError": "Server Error",
    "isRequired": "xxx is required",
    "Result": "Result",
    "ResultText": "You can see the problems here and download these rows to fix seperatly",
    "ErrorDetails": "Orders with error",
    "ErrorDetailsText": "Orders with errors are listed in the file that you can download below. It contains error details for each row.",
    "DownloadErroredRows": "Download errored rows",
    "importedsuccessfully": "xxx orders are imported successfully",
    "ordershaserrors": "xxx orders has errors",
    "refresh": "Refresh",
    "CashOnDelivery": "Cash on Delivery",
    "EnterAmount": "Enter amount",
    "COD": "Cash on Delivery",
    "Surchargexxx": "Surcharge xxx",
    "AddCreditCard": "Add Credit/Debit Card",
    "CustomerWallet": "Customer Wallet",
    "Add": "Add",
    "Addedsuccessfully": "Added successfully",
    "NoRecentAddress": "No recent address",
    "NoRecentAddressD": "Customer recent addresses will be listed here",
    "CustomerIsNotChoosed": "Customer is not choosed",
    "CustomerIsNotChoosedD": "Choose customer to show his/her recent address",
    "SearchAddress": "Search address",
    "NoCustomerfound": "No Customer found",
    "NoCustomerfoundD": "You can create the customer if it’s a new one",
    "NewCustomer": "New Customer",
    "Existing": "Existing",
    "EmailOtional": "Email ( Optional )",
    "phonenumberisnotcorrect": "phone number is not correct",
    "Emailisnotcorrect": "Email is not correct",
    "Registercustomer": "Register customer",
    "incoming": "Incomming Call",
    "outgoing": "outgoing Call",
    "recording": "recording Call",
    "driveroperatorCallStatus": "Driver called operator",
    "customeroperatorCallStatus": "Customer called operator",
    "newCustomeroperatorCallStatus": "New customer called operator",
    "operatordriverCallStatus": "Operator called driver",
    "operatorcustomerCallStatus": "Operator called customer",
    "operatornewCustomerCallStatus": "Operator called new customer",
    "dispatcher_Voip": "Operator",
    "driver_Voip": "Driver",
    "customer_Voip": "Customer",
    "activeorders": "xxx active orders",
    "Noactiveorder": "No active order ",
    "CreateOrder": "Create Order",
    "call": "Call",
    "spanish": "Spanish",
    "es": "Spanish",
    "networkError": "No Internet Connection",
    "createdAt": "Created At",
    "dueDate": "Due Date",
    "Noresultfound": "No result found",
    "loadMore": "Load more",
    "Searchresult": "Search result",
    "AssignedAt": "Assign in xxx min",
    "StartedAt": "Start in xxx min",
    "ArrivedAt": "Arrive in xxx min",
    "PickedUpAt": "Pickup in xxx min",
    "DoneAt": "Finish in xxx min",
    "mindelay": "xxxm delay",
    "minlate": "xxxm late",
    "PinTop": "Pin top",
    "UnPin": "Unpin",
    "Itemsonmap": "Items on map",
    "min": "xxx min",
    "Confirming": "Confirming",
    "delay": "Delay",
    "late": "Late",
    "OrderDetails": "Order details",
    "Profile": "Profile",
    "Waitingforavailabledrivers": "Waiting for drivers get available",
    "Queue": "Queue",
    "OfferingToDrivers": "Offering to xxx drivers",
    "WaitforAccept": "Wait for Accept",
    "Thereisnoorder": "There is no order",
    "mapitems": "Map items",
    "Offerings": "Offerings",
    "Reject": "Reject",
    "dropoffCustomerDescription": "Drop off Note",
    "customerPhoto": "Customer Photo",
    "driverPhoto": "Driver Photo",
    "NocustomerPhoto": "No Customer Photo",
    "NodriverPhoto": "No Driver Photo",
    "initial": "initial",
    "notifying": "notifying",
    "received": "received",
    "assigned": "assigned",
    "rejected": "rejected",
    "canceled": "canceled",
    "standby": "standby",
    "terminated": "terminated",
    "urgentTitle": "Change to Urgent Dispatching?",
    "urgentDesc": "By enabling urgent, the order will be in the first priority for dispatcing",
    "urgentConfirm": "Yes, Enable Urgent",
    "urgentClose": "No, Cancel",
    "Urgent": "Urgent",
    "photos": "Photos",
    "Portuguese": "Portuguese",
    "Dispatchat": "Dispatch at xxx",
    "AutoDispatch": "Auto Dispatch",
    "Dispatchtime": "Dispatch time",
    "Later": "Later",
    "DispatchingNow": "Dispatching Now",
    "Required": "Required",
    "NoResultfromLocalDatabase": "No Result from Local Database",
    "NoResultfromLocalDatabaseD": "Please check it again or try another keyword",
    "NoResultfromMapService": "No Result from Map Service",
    "SearchinMapService": "Search in Map Service",
    "SearchinLocalDatabase": "Search in Local Database",
    "Creditcardnotadded": "Credit card not added",
    "OptimizeDropoffs": "Optimize Dropoffs",
    "Greek": "Greek",
    "el": "Greek",
    "ReferenceID": "Reference ID",
    "EnterId": "Enter id",
    "de": "German",
    "German": "German",
    "AustrianGerman": "Austrian German",
    "Options": "Options",
    "VehicleOptions": "Vehicle Options",
    "tryagain":"Something went wrong, please try again",



    "Selected date and time":"Selected date and time",
    "Reorder stops":"Reorder stops",
    "Bulk Edit Status Summary":"Bulk Edit Status Summary",
    "Successful":"Successful",
    "Failed":"Failed",
    "Order ID":"Order ID",
    "Details":"Details",
    "Result":"Result",
    "Pickup Routed":"Pickup Routed",
    "Delivery Routed":"Delivery Routed",
    "Ref. xxx":"Ref. xxx",
    "Zone":"Zone",
    "Clear":"Clear",
    "Apply":"Apply",
    "No result found":"No result found",
    "Type your keyword":"Type your keyword",
    "Type in the input to start search":"Type in the input to start search",
    "We are sorry ...":"We are sorry ...",
    "The page you are trying to access has restricted access.":"The page you are trying to access has restricted access.",
    "Please refer to your system administrator.":"Please refer to your system administrator.",
    "Failed":"Failed",
    "Done": "Done",
    "Schedule":"Schedule",
    "Proof of delivery":"Proof of delivery",
    "Signature":"Signature",
    "Driver Photo":"Driver Photo",
    "Notes":"Notes",
    "Order Info":"Order Info",
    "Order":"Order",
    "Price":"Price",
    "P_HUB_D Orders":'P_HUB_D Orders',
    "Routes":"Routes",
    "Default pickup":"Default pickup",
    'More':'More',
    'Route name':'Route name',
    'Driver':'Driver',
    'Pickup Orders':'Pickup Orders',
    'Delivery Orders':'Delivery Orders',
    'Ready For Pickup':'Ready For Pickup'
}; 