import styles from './style.module.css';
import sprof from "../../../assets/sprof.svg";
import driver_wait_confirm from "../../../assets/driver_wait_confirm.svg";
import call from "../../../assets/call.svg";
import star from "../../../assets/star.svg";
import { useDispatch, useSelector } from 'react-redux';
import deliveryProfile from "../../../assets/delivery-man.png";
import _ from 'lodash';
import { Voip_OutgoingCall } from '../../../Redux/actions/actions';
import { get_id } from '../../../helper/UserData/userdate';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import noDriver from '../../../assets/ic-driver-not-selected.svg';
import queue from '../../../assets/ic-queue.svg';
import radar from '../../../assets/ic-radar.svg';
import rejected from '../../../assets/ic-driver-rejected.svg';
import radar_color from '../../../assets/radar_color.svg';
import urgent from '../../../assets/urgent.svg';

import clsx from 'clsx';
import { OrderApi } from '../../../service/API/order';
import { useCallback, useEffect, useState } from 'react';
import { OfferingDetailsOpen, PriceDetailsOpen, SetViewOrderPickupDropoffDetails, show_eventTimeLine } from '../../../Redux/actions/actionNames';
import { Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import info from "../../../assets/info.svg";

export const DriverVehicle = () => {
    const status = useSelector((state) => state.ViewTravel.pureData.status)
    const driver = useSelector((state) => state.ViewTravel.pureData.driver)
    const vehicletype = useSelector((state) => state.ViewTravel.pureData.vehicleType)
    const orderId = useSelector((state) => state.ViewTravel.pureData.id)
    const service = useSelector((state) => state.ViewTravel.pureData.service)
    const driverId = useSelector((state) => state.ViewTravel.pureData.driverId)
    const driverAssignmentModel = useSelector((state) => state.ViewTravel.pureData.driverAssignmentModel)
    const candidateDriversCount = useSelector((state) => state.ViewTravel.pureData.candidateDriversCount)
    const currentCandidateDrivers = useSelector((state) => state.ViewTravel.pureData.currentCandidateDrivers)
    const orderType = useSelector((state) => state.ViewTravel.pureData.type)
    const isUrgent = useSelector((state) => state.ViewTravel.pureData.isUrgent)
    const offering_details = useSelector((state) => state.ViewTravel.offering_details)
    const [urgentOpenMenu, setUrgentOpenMenu] = useState(false)
    const { t, i18n } = useTranslation();
    const pickup_dropoff_details = useSelector((state) => state.ViewTravel.pickup_dropoff_details)

    const dispatch = useDispatch();

    function makeOutgoingCall() {
        dispatch(Voip_OutgoingCall(dispatch, {
            "to": driver.phone,
            "orderId": orderId,
            "customerId": null,
            "driverId": driverId,
            "dispatcherId": get_id()
        }))
    }

    function isNoDriver() {
        return (_.isEmpty(driver) && ["Pending"].indexOf(status) == -1)
    }

    function isQueue() {
        return (["Pending"].indexOf(status) > -1 && driverAssignmentModel == "automatic" && _.isEmpty(driver) && candidateDriversCount == 0 /* && _.isEmpty(currentCandidateDrivers) */)
    }

    function isOffering() {
        return (["Pending"].indexOf(status) > -1 && driverAssignmentModel == "automatic" && _.isEmpty(driver) && candidateDriversCount > 0)
    }

    function isWaitforAccept() {
        return (["Pending"].indexOf(status) > -1 && driverAssignmentModel == "manual" && _.isEmpty(driver) && currentCandidateDrivers[0] && ["received", "notifying", "initial"].indexOf(currentCandidateDrivers[0].status) > -1)
    }

    function isRejected() {
        return (["Pending"].indexOf(status) > -1 && driverAssignmentModel == "manual" && _.isEmpty(driver) && currentCandidateDrivers[0] && ["rejected"].indexOf(currentCandidateDrivers[0].status) > -1)
    }



    function vehicleOptionsNameExtracter() {
        let names = [];
        if (vehicletype) {
            vehicletype.options.map((option) => {
                if (option.type === "boolean") {
                    names.push(option.title);
                } else {
                    names.push(option.dataTitle);
                }
            });
            return names
        } else {
            return [];
        }
    };


    const offeringDialoge = useCallback(() => {
        dispatch(OfferingDetailsOpen(!offering_details))
        dispatch(show_eventTimeLine(false))
        dispatch(PriceDetailsOpen(false))
        dispatch(SetViewOrderPickupDropoffDetails(null))
    }, [offering_details]);




    const markUrgent = useCallback(() => {
        let Order = new OrderApi()
        Order.MarkUrgent(orderId)
        setUrgentOpenMenu(false)
    }, [isUrgent]);



    const dropOffInfo = useCallback(() => {
        if (pickup_dropoff_details && String(pickup_dropoff_details.type) == String("VehicleOptions")) {
            dispatch(SetViewOrderPickupDropoffDetails(null))
        } else {
            dispatch(SetViewOrderPickupDropoffDetails({ type: "VehicleOptions", data: vehicletype.options }))
        }
        dispatch(OfferingDetailsOpen(false))
        dispatch(show_eventTimeLine(false))
        dispatch(PriceDetailsOpen(false))
    }, [pickup_dropoff_details , vehicletype]);

    useEffect(() => {

        if(pickup_dropoff_details && String(pickup_dropoff_details.type) == String("VehicleOptions")){
            dispatch(SetViewOrderPickupDropoffDetails({ type: "VehicleOptions", data: vehicletype.options }))
        }
    
    }, [vehicletype ])


    if (orderType == "PickupDelivery") {
        return null
    }

    return <div className={styles.DriverVehicle}>

        {(!_.isEmpty(driver) || isWaitforAccept() || isRejected()) && <>
            <span className={styles.profileContainer}>
                <img className={styles.dprof} src={(driver && driver.avatarPhoto.length > 0) ? driver.avatarPhoto : deliveryProfile} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = deliveryProfile;
                }} />
                {driver && <span className={styles.starContainer} ><img src={star} /><span>{driver.rate}</span></span>
                }

            </span>


            {!_.isEmpty(driver) && <div className={styles.nameUsernameContainer}>
                <span className={styles.name}>{driver.name}</span>
                <span className={styles.username}> @{driver.username} </span>
            </div>}

            {(isWaitforAccept() || isRejected()) && <div className={styles.nameUsernameContainer}>
                <span className={styles.name}>{currentCandidateDrivers[0].name}</span>
                <span className={styles.username}>@{currentCandidateDrivers[0].username} </span>
            </div>}

            {!_.isEmpty(driver) && <img className={styles.call} src={call} onClick={() => makeOutgoingCall()} />}

            <hr className={styles.hr} />

        </>
        }

        {/* <span className={styles.profileContainer}>
            <img className={styles.dprof} src={(driver && driver.avatarPhoto.length > 0) ? driver.avatarPhoto : deliveryProfile} onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = deliveryProfile;
            }} />
            {driver && <span className={styles.starContainer} ><img src={star} /><span>{driver.rate}</span></span>
            }     
            
            </span> */}

        {/* 
        {!_.isEmpty(driver) && <div className={styles.nameUsernameContainer}>
            <span className={styles.name}>{driver.name}</span>
            <span className={styles.username}> {t("Username")} </span>
        </div>} */}

        {/* {(isWaitforAccept() || isRejected()) && <div className={styles.nameUsernameContainer}>
            <span className={styles.name}>{currentCandidateDrivers[0].name}</span>
            <span className={styles.username}> {t("Username")} </span>
        </div>} */}

        {/* {(_.isEmpty(driver) && !isWaitforAccept() && !isRejected() && !isUrgent) && <span className={styles.serviceName}> { driverAssignmentModel == "automatic" ? "Auto Dispatch" : "Manual"} </span>} */}

        {/* {(_.isEmpty(driver) && !isWaitforAccept() && !isRejected() && isUrgent) && <div className={styles.nameUsernameContainer}>
            <span className={styles.name}> { driverAssignmentModel == "automatic" ? "Auto Dispatch" : "Manual"} </span>
            <span className={styles.username} style={{ color: "#d01400" }}> {t("Urgent")} </span>
        </div>} */}


        {/* {isNoDriver() && <span className={styles.driverStatus}> <img src={noDriver} /> {t("NoDriver")} </span>}
        {isQueue() && <span className={styles.driverStatus}> <img src={queue} /> {t("Queue")} </span>}
        {isOffering() && <span className={styles.driverStatus}> <img src={radar} /> {String(t("OfferingToDrivers")).replace("xxx", candidateDriversCount)} </span>}
        {isWaitforAccept() && <span className={styles.driverStatus}> <img src={driver_wait_confirm} /> {t("waitforconfirm2")} </span>}
        {isRejected() && <span className={styles.driverStatus}> <img src={rejected} /> {t("Rejected")} </span>}

        {!_.isEmpty(driver) && <img className={styles.call} src={call} onClick={() => makeOutgoingCall()} />}
        {(["Pending"].indexOf(status) > -1 && currentCandidateDrivers.length > 0) && <img className={clsx(styles.urgent, offering_details && styles.isUrgent)} src={radar_color} onClick={offeringDialoge} />
        }
        {(["Pending"].indexOf(status) > -1 && driverAssignmentModel == "automatic" && !isUrgent) && <img className={clsx(styles.urgent, isUrgent && styles.isUrgent)} src={urgent} onClick={() => setUrgentOpenMenu(true)} />
        } */}

        {!_.isEmpty(vehicletype) && <>




            <div className={styles.xdop}>
                <div>
                    <img src={
                        vehicletype &&
                        vehicletype.icon
                    } className={styles.serviceImage} />

                    {(!_.isEmpty(driver) && driver.vehicle) && <div className={styles.nameUsernameContainer}>
                        <span className={styles.name}>{driver.vehicle.model +
                            " " +
                            driver.vehicle.color}</span>
                        <span className={styles.username}> {driver.vehicle.plate} </span>
                    </div>}
                    {<span className={styles.serviceName}> {vehicletype.title} </span>}


                </div>
                <div className={styles.serviceOptions}>
                    {/* {vehicleOptionsNameExtracter().map((item) => {
                        return <span className={styles.option}>{item}</span>
                    })} */}

                    {vehicletype.options.length > 0 && <span className={styles.option}>{`${vehicletype.options.length} ${t("Options")}`}</span>
                    }

                    {vehicletype.options.length > 0 && <img className={clsx(styles.info, (pickup_dropoff_details && String(pickup_dropoff_details.type) == String("VehicleOptions")) && styles.infoActive)} src={info} onClick={() => { dropOffInfo() }} />
                    }
                </div>
            </div>
        </>
        }



        <Dialog
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open={urgentOpenMenu}
            onClose={() => setUrgentOpenMenu(false)}
            className="logoutContainer"
            disableAutoFocus
            disableEnforceFocus
            disableRestoreFocus
        >
            {" "}
            <p
                className="logotTitle"
            >

                {t("urgentTitle")}
            </p>
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-description"
                    className="logoutDescription"
                >
                    {t("urgentDesc")}

                </DialogContentText>
            </DialogContent>
            <div style={{ direction: "ltr", height: "42px" }}>
                <span
                    onClick={() => markUrgent()}
                    className="confirmLogout"
                    variant="contained"
                >
                    {t("urgentConfirm")}

                </span>
                <span
                    onClick={() => setUrgentOpenMenu(false)}
                    variant="contained"
                    className="skipLogout"

                >
                    {t("urgentClose")}

                </span>
            </div>
        </Dialog>

    </div>

};




