import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './style.module.css';
import styles2 from '../../../table-list/style.module.css';

import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../../../design-system/lib/Modal';
import Typography from '../../../../../design-system/lib/Typography';
import DropDown from '../../../../../design-system/lib/DropDown';
import { ADD_SQUARE, EDIT, Edit, ROUTE_OPTIMIZE, Routes_deactive, Status, QUESTION, route_status, SEARCH_NOT_FOUND, CLOSE_DARK } from '../../../../../assets';
import Button from '../../../../../design-system/lib/Button';
import { RouteApi } from '../../../../../service/API/route';
import { OrderApi } from '../../../../../service/API/order';
import { get_id } from '../../../../../helper/UserData/userdate';
import { snack } from '../../../../../utils/SnackbarUtilsConfigurator';
import TableAddRoute from '../editInfo';
import clsx from 'clsx';
import { SetSharedDataVehicleTypes, Table_ClearSelectedOrders } from '../../../../../Redux/actions/actionNames';
import { DispatcherApi } from '../../../../../service/API/dispatcher';
import item from '../../../../../Entries/Main/components/ViewOrder/components/event_TimeLine/listEvent/item';
import Tag from '../../../../../design-system/lib/Tag';
import { SearchDriverAction, Voip_OutgoingCall } from '../../../../../Redux/actions/actions';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import DriverListItem from '../../../../shared/DriverListItem';
import SearchList from '../../../../../design-system/lib/SearchList';
import deliveryProfile from "../../../../../assets/delivery-man.png";
import call from "../../../../../assets/call.svg";
import star from "../../../../../assets/star.svg";
import IconProvider from '../../../../../design-system/lib/IconProvider';
import { ServicesApi } from '../../../../../service/API/service';



function EditStatusRoute({ data , setEditStatus }) {
  const auto_suggestListData = useSelector((state) => state.SharedData.vehicleTypes)
  const manual_activeVehicleType = useSelector((state) => state.NewOrder.newOrder.driver.manual_driver.manual_activeVehicleType)
  const manual_suggestListLoading = useSelector((state) => state.NewOrder.newOrder.driver.manual_driver.manual_suggestListLoading)
  const manual_suggestListData = useSelector((state) => state.NewOrder.newOrder.driver.manual_driver.manual_suggestListData)
  const manual_suggestListOrdered = useSelector((state) => state.NewOrder.newOrder.driver.manual_driver.manual_suggestListOrdered)

  const [selectedFailureReason, setSelectedFailureReason] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [deliveryStageStatus, setDeliveryStageStatus] = useState(null);

  const mapRef = useSelector((state) => state.Map.mapRef)
  const center = useSelector((state) => state.Map.center)
  const [driver, setDriver] = useState(null);
  const [driverSearch, setDriverSearch] = useState("");
  const [loading, setLoading] = useState(false);

  

  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const SearchDriver = React.useCallback((input, manual) => {

    dispatch(
      SearchDriverAction(dispatch, {
        searchRegex: input ? input : driverSearch,
        vehicleTypes: manual ? manual.id : (selectedVehicle ? selectedVehicle.id : ""),
      })
    );

  }, [driver, selectedVehicle]);

  const debouncedSearchDriver = useCallback(
    debounce(SearchDriver, 500)
    , []);

  function makeOutgoingCall() {
    dispatch(Voip_OutgoingCall(dispatch, {
      "to": driver.phone,
      "orderId": null,
      "customerId": null,
      "driverId": driver.id,
      "dispatcherId": get_id()
    }))
  }



  useEffect(() => {
    async function fetchData() {
      const Services = new ServicesApi();
      const data = await Services.VehicleType()
      dispatch(SetSharedDataVehicleTypes(data.VehicleTypes))
    }

    fetchData()

    let arr = [{ key: "Assigned", title: "Assigned" }, { key: "Started", title: "Started" }/*, { key: "Done", title: "Done" }*/]

    let inde = arr.findIndex((item) => { return item.key == data.route.status })

    if (inde > -1) {
      setSelectedStatus(arr[inde])
    } else {
      setSelectedStatus(arr[0])
    }

    setDeliveryStageStatus({ key: "ReadyForDelivery", title: "ReadyForDelivery" })
    data.route.driver && setDriver(data.route.driver)
  }, []);



  const handleSave = React.useCallback(async() => {
    console.log("handleSave ",data,driver,selectedStatus)
    setLoading(true)
    let Route = new RouteApi()
    let datax = await Route.UpdateStatus({
      "routeId": data.route.id,
      "driverId": driver.id,
      "dispatcherId": get_id(),
      "status": selectedStatus.key,
      "deliveryStageStatus": deliveryStageStatus.key // optional 'ReadyForDelivery', 'OutForDelivery'
    })



    if(datax && !datax.error){
      snack.BatchOperationalSnackbar(JSON.stringify({
        "id": datax.id,
        "status": datax.status,
        "action": datax.action,
        "totalRecordsCount": datax.totalRecordsCount,
        "successfulRecordsCount": datax.successfulRecordsCount,
        "failedRecordsCount": datax.failedRecordsCount,
        "lastProcessedRecordIndex": datax.lastProcessedRecordIndex,
        "records": datax.records,
        "createdAt": datax.createdAt,
        "doneAt": datax.doneAt,
      }))
      setLoading(false)
      setEditStatus(false)

    }else{

      if(datax.message){
        snack.error(datax.message)
      }
      setLoading(false)
      setEditStatus(false)
      setEditStatus(false)

    }
  }, [driver , data , selectedStatus]);


  const FindLocalVehicletype = React.useCallback((driverVehicletypes, localservives, call) => {
    let vehicletype = [];

    driverVehicletypes.forEach((element) => {
      localservives.forEach((localservive) => {
        if (element == localservive.id) {
          vehicletype.push(localservive);
        }
      });
    });

    call(vehicletype);
  }, [dispatch]);




  const handleSelectVehicle = React.useCallback((e) => {

    setSelectedVehicle(e)

    debouncedSearchDriver(driverSearch, e)


  }, [driver, selectedVehicle]);

  const InputOnChange = (e) => {


    if (e) {
      setDriverSearch(e.target.value)
      debouncedSearchDriver(e.target.value)
    }

  };


  const handleSelectedItem = (item) => {
    setDriver(item)
  };



  return (
    <>


      <Modal bodyStyle={{ padding: "0px", width: "100%" }} ConfirmationLoading={loading} ConfirmationDisabled={!driver} size='Large' title='Edit Route Status & Driver' ConfirmationText='Save' onclose={() => {setEditStatus(false) }} ConfirmationOnClick={handleSave}

        body={<div className={styles.bodyContainer}>
          <div className={styles.firstRow}>
            <div className={styles.l1}>
              <Typography weight='Body_Small_Bold' textColor='TextDark' text={'Route name'} />
              <Tag backgroundColor='GreenUltraLight' color='Green' size='Large' text={data.route.status} weight='Body_Small_Medium' className={styles[data.route.status]} style={{ borderRadius: "8px", marginInlineStart: "12px", marginInlineEnd: "auto" }} />
            </div>
            <div className={styles.divider} />
            <DropDown placement='bottomStart' icon={route_status} size='Medium' data={[{ key: "Assigned", title: "Assigned" }, { key: "Started", title: "Started" } /*, { key: "Done", title: "Done" } */]} text={selectedStatus?.title} onClick={item => {
              setSelectedStatus(item)
            }} className={styles.DropDown} checkSelected='full' />
          </div>

          {!driver && <div className={styles.firstRow} style={{ height: "61px", display: "block" }}>
            <div className={styles.l1}>
              <Typography weight='Body_Small_Bold' textColor='TextDark' text={'Driver'} />
            </div>
            <SearchList containerClassName={styles.driverSearchSelelct} onChange={InputOnChange} style={{ zIndex: "1" }} value={driverSearch} placeHolder={"Code, Name, Family, Phone number"} isloading={manual_suggestListLoading} listItemOnclick={handleSelectedItem} error={false} errorDescription={""} errorTitle={""} errorIcon={null}
              ListItemChildren={DriverListItem} list={(manual_suggestListOrdered && String(driverSearch).length > 0) ? (manual_suggestListData.length > 0 ? manual_suggestListData : null) : null}
              noResult={String(driverSearch).length > 0 && (!manual_suggestListData || manual_suggestListData.length == 0)} noResultIcon={SEARCH_NOT_FOUND} noResultTitle='No result found'
              inputInnerChildren={<DropDown style={{ zIndex: "10" }} size='Small' placement='topEnd' text={selectedVehicle ? selectedVehicle.title : t("Allvehicletypes")} data={auto_suggestListData} onClick={handleSelectVehicle} />}
            />
          </div>}

          {driver && <div className={styles.firstRow} style={{ height: "46px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
            <span className={styles.profileContainer}>
              <img className={styles.dprof} src={(driver && driver?.avatarPhoto?.length > 0) ? driver?.avatarPhoto : deliveryProfile} onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = deliveryProfile;
              }} />
              {driver && <span className={styles.starContainer} ><img src={star} /><span>{driver.rate}</span></span>
              }

            </span>

            <div className={styles.nameUsernameContainer}>
              <span className={styles.name}>{driver.name}</span>
              <span className={styles.username}> @{driver.username} </span>
            </div>

            <img className={styles.call} src={call} onClick={() => makeOutgoingCall()} />
            <IconProvider Icon={CLOSE_DARK} style={{ marginInlineStart: "20px", cursor: "pointer" }} onClick={() => { setDriver(null) }} />
          </div>}


          {((selectedStatus?.key != data.route.status) && data.route.status == "Unassigned") && <>
            <div className={styles.firstRow}>
              <div className={styles.l1}>
                <Typography weight='Body_Small_Bold' textColor='TextDark' text={'Pickup Orders'} />
                <Tag backgroundColor='GreenUltraLight' color='Green' size='Large' text={"Pickup Routed"} weight='Body_Small_Medium' className={styles["Assigned"]} style={{ borderRadius: "8px", marginInlineStart: "12px", marginInlineEnd: "auto" }} />
              </div>
              <div className={styles.divider} />
              <DropDown noArrowIcon={true} placement='bottomStart' icon={Status} size='Medium' data={[]} text={'Ready For Pickup'} onClick={item => {

              }} className={styles.DropDown} checkSelected='full' />
            </div>
            <div className={styles.firstRow}>
              <div className={styles.l1}>
                <Typography weight='Body_Small_Bold' textColor='TextDark' text={'Delivery Orders'} />
                <Tag backgroundColor='GreenUltraLight' color='Green' size='Large' text={"Delivery Routed"} weight='Body_Small_Medium' className={styles["Assigned"]} style={{ borderRadius: "8px", marginInlineStart: "12px", marginInlineEnd: "auto" }} />
              </div>
              <div className={styles.divider} />
              <DropDown placement='bottomStart' icon={Status} size='Medium' data={[{ key: "ReadyForDelivery", title: "ReadyForDelivery" }, { key: "OutForDelivery", title: "OutForDelivery" }]} text={deliveryStageStatus?.title} onClick={item => {
                setDeliveryStageStatus(item)
              }} className={styles.DropDown} checkSelected='full' />
            </div>
          </>}



          {/* <div className={styles.divider} /> */}

          {/* <DropDown placement='bottomStart' icon={Status} size='Medium' data={GetStatusList()} text={selectedStatus ? selectedStatus.title : 'Select Status'} onClick={item => {
            setSelectedStatus(item)
            setSelectedFailureReason(null)
            setFailureReasonsList([])

          }} className={styles.DropDown} checkSelected='full' /> */}

          {/* {(canSelectFaildReason() && failureReasonsList.length > 0) && <DropDown style={{ marginTop: "12px" }} placement='bottomStart' icon={QUESTION} size='Medium' data={failureReasonsList.map((item)=>{return {...item , title:item.text}})} text={selectedFailureReason ? selectedFailureReason.title : 'Select Reason'} onClick={item => {
            setSelectedFailureReason(item)
          }} className={styles.DropDown} checkSelected='full' />} */}

          {/* {(selectedStatus && selectedStatus.route) && <><div className={styles.divider} />

            <div className={styles.firstRow}>
              <DropDown placement='bottomStart' icon={ROUTE_OPTIMIZE} size='Medium' data={GetStatusList()} text={selectedRoute ? selectedRoute.name : 'Select Route'} onClick={item => {
                setSelectedStatus(item)
              }} className={styles.RouteDropDown} checkSelected='full' ItemsList={ItemsList(routesList, selectedRoute, setSelectedRoute)} />
              <Button size='Medium' type='Secondary' icon={EDIT} style={{ marginInlineStart: "10px", paddingTop: "9px", paddingBottom: "9px" }} onClick={() => { }} />
              <Button size='Medium' type='Secondary' icon={ADD_SQUARE} text='Add Route' style={{ marginInlineStart: "10px", paddingTop: "9px", paddingBottom: "9px" }} onClick={() => { setShowAddRoute(true) }} />
            </div></>} */}
          {/* {(hasDriverAssignError() || hasRouteAssignError()) && <Typography weight='Body_Middle_Regular' textColor='Red' text={hasDriverAssignError() ? 'You must assign driver to select this route' : 'You must choose an unassigned route or unassign selected route'} className={styles.error} />} */}
        </div>} />



    </>

  )
}


export default EditStatusRoute




function ItemsList(data, selectedRoute, setSelectedRoute) {
  return (
    <div className={styles.itemsConatiner}>
      {data.map((route) => {
        return <div onClick={() => { setSelectedRoute(route) }}>

          <img src={Routes_deactive} />
          <Typography weight={selectedRoute?.id == route.id ? "Body_Middle_Bold" : 'Body_Middle_Regular'} textColor='TextNormal' text={route.name} className={styles.itemName} />
          <Typography weight='Body_Small_Regular' textColor='TextLight' text={route.driver ? route.driver?.name : "Unassigned"} className={styles.itemDriverName} />

        </div>
      })}
    </div>
  )
}


function GetGroups({ selectedOrders }) {

  var objectToArray = Object.keys(selectedOrders).map((key) => selectedOrders[key]);

  const result = Object.groupBy(objectToArray, ({ status }) => status);


  return (
    <div className={styles.groupStatus}>

      {Object.keys(result).map((group) => {
        return <div className={clsx(styles.groupStatusdiv, styles2[group])}>
          <Typography weight='Body_Small_Medium' textColor='TextDark' text={group} />
          <span className={styles.groupStatusspan}>
            <Typography weight='Body_Tiny_Bold' textColor='TextDark' text={result[group].length} />
          </span>
        </div>
      })}


    </div>
  )
}


export const GetStatusList = () => [
  {
    title: "Draft",
    stage: "P",
    driver: false,
    route: false
  },
  {
    title: "Confirmed",
    stage: "P",
    driver: false,
    route: false
  },
  {
    title: "PickupRouted",
    stage: "P",
    driver: false,
    route: true
  },
  {
    title: "ReadyForPickup",
    stage: "P",
    driver: true,
    route: true
  },
  {
    title: "PickedUp",
    stage: "P",
    driver: true,
    route: true
  },
  {
    title: "AtWarehouse",
    stage: "D",
    driver: false,
    route: false
  },
  {
    title: "DeliveryRouted",
    stage: "D",
    driver: false,
    route: true
  },
  {
    title: "ReadyForDelivery",
    stage: "D",
    driver: true,
    route: true
  },
  {
    title: "OutForDelivery",
    stage: "D",
    driver: true,
    route: true
  },
  {
    title: "Delivered",
    stage: "D",
    driver: true,
    route: true
  },
  {
    title: "NotDelivered",
    stage: "D",
    driver: false,
    route: true
  },
  {
    title: "ForReturn",
    stage: "D",
    driver: false,
    route: false
  },
  {
    title: "Returned",
    stage: "D",
    driver: false,
    route: false
  },
  {
    title: "CustomerCanceled",
    stage: null,
    driver: false,
    route: false
  },
  {
    title: "PickupFailed",
    stage: "P",
    driver: false,
    route: true
  },
  {
    title: "SupportCanceled",
    stage: null,
    driver: false,
    route: false
  }, {
    title: "Lost",
    stage: null,
    driver: false,
    route: false
  },
]