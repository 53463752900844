import "./index.css";

import React, { useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import styles from './style.module.css';
import colors from '../colors/colors.module.css';
import IconProvider from "../IconProvider";
import ProgressIndicator from "../ProgressIndicator";
import { ARROW_DOWN_BOLD, ArrowRight1, CLOSE, IC_SEARCH, search, filterPlus, ic_circle_close } from "../../../assets/index";

import { CSSTransition } from 'react-transition-group';
import Typography from "../Typography";
import { InputSerachFilterProps } from "./InputSerachFilter.types";
import DropDown from "../DropDown";
import Input from "../Input";
import Checkbox from "../Checkbox";
import { isEqual } from "lodash";
import { v4 as uuidv4 } from 'uuid';
import SearchList from "../SearchList";


const InputSerachFilter: React.FC<InputSerachFilterProps> = ({ value, onChange, SearchList_ListItemChildren, SearchList_ListItemOnClick, SearchList_isloading, SearchList_list, placement, title }: InputSerachFilterProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentValue, setCurrentValue] = useState<any>("");
    const [uuid] = useState(uuidv4());
    const [currentplacement, setCurrentplacement] = useState(placement);


    const dropdownNode: any = useClickOutside(() => {
        setIsOpen(false);
    })


    useEffect(() => {
        try {
            if (isOpen) {
              const element = document.getElementById('googlemapUniqeId');
              element.style.zIndex = '6';
            } else {
              const element = document.getElementById('googlemapUniqeId');
              element.style.zIndex = '7';
            }
        } catch (error) {
          
        }
      
      
        }, [isOpen]);
      

    const buttonOncick = useCallback(() => {
        if (!isOpen) {
            setTimeout(() => {
                if (!value) {
                    setCurrentValue("")
                    setIsOpen(true);
                }
                setCurrentValue([]);
                onChange(null)
                SearchList_ListItemOnClick(null)
            }, 50);
        }


        const myDiv = document.getElementById(uuid);
        const rect = myDiv?.getBoundingClientRect();


        if ((window.innerWidth - Number(rect?.right)) < 300) {
            setCurrentplacement("bottomEnd")
        } else {
            setCurrentplacement("bottomStart")
        }

    }, [isOpen, value, uuid]);

    // const isCheked = useCallback((item:any) => {
    //      let x = currentValue.findIndex((zon:any) => {return zon == item})
    //      return x > -1
    //   }, [currentValue ]);


    //   const valueToString = useCallback(() => {
    //     let x:any = []
    //     value.forEach((elementx:any) => {
    //         list.forEach((elementy:any) => {
    //             if(elementy.key == elementx){
    //                 x.push(elementy.value)
    //             }
    //         });
    //     });

    //     return x.toString()
    //  }, [value , list ]);


    // const checkBoxOnchange = useCallback((item:any) => {
    //     if(isCheked(item.key)){
    //         let x = currentValue.filter((zon:any) => {return zon != item.key})
    //         setCurrentValue(x)
    //     }else{
    //         let xx = [...currentValue , item.key]
    //         // onChange(xx)
    //         setCurrentValue(xx)
    //     }
    //  }, [currentValue ]);


    return (
        <div ref={dropdownNode} onClick={buttonOncick} id={uuid} className={clsx(styles.button, value && styles.Activebutton, isOpen && styles.buttonNOhOVER)} >

            {value ? <>
                <IconProvider Icon={ic_circle_close} size="14" onClick={(e) => {
                
                }} /> <span className={styles.title}> {`${title}`}<div style={{ height: "12px", width: "1px", backgroundColor: "rgba(128, 128, 128, 1)", borderRadius: "2px" }} /><Typography weight='Body_Small_Medium' textColor={'TextDark'} text={value.name} /> </span>

            </> : <span className={styles.title}><IconProvider Icon={filterPlus} size="14" onClick={(e) => {

            }} />  {title}</span>}

            {/* {value  ? <>
                <span className={styles.title}> {`${title}`}<div style={{height:"12px",width:"1px" ,backgroundColor:"rgba(128, 128, 128, 1)",borderRadius:"2px"}} /><Typography weight='Body_Small_Medium' textColor={'TextDark'} text={value.name}/> </span>
                <IconProvider Icon={CLOSE} size="10"  />
            </> :<span className={styles.title}> {title}</span>} */}


            <CSSTransition
                in={isOpen}
                timeout={300}
                classNames="dropdown-animation"
                unmountOnExit
                onEnter={() => setIsOpen(true)}
                onExited={() => setIsOpen(false)}
            >
                <div className={clsx(styles.dropdownBox, styles[currentplacement])}>
                    <Input type={"text"} iconStart={IC_SEARCH} placeHolder="Search" value={currentValue} onChange={(e: any) => { setCurrentValue(e.target.value); onChange(e.target.value) }} />
                    {(!SearchList_isloading && SearchList_list?.length > 0) && <div className={styles.itemsContainer}>
                        {SearchList_list.map((user: any, i: any) => {
                            return <SearchList_ListItemChildren data={user} onclick={() => { /*listItemOnclick && listItemOnclick(user, i) ; */ setIsOpen(false); SearchList_ListItemOnClick(user) }} />

                        })}
                    </div>}
                    {(SearchList_list && SearchList_list?.length == 0 && (!SearchList_isloading)) && <div className={styles.alertContainer}>
                        <IconProvider Icon={search} />
                        <Typography weight="Body_Middle_Bold" text={"No result found"} style={{ marginTop: "12px" }} />

                    </div>}

                    {(!SearchList_list && SearchList_list?.length == 0 && (!SearchList_isloading) && currentValue?.length > 0) && <div className={styles.alertContainer}>
                        <IconProvider Icon={search} />
                        <Typography weight="Body_Middle_Bold" text={"Type your keyword"} style={{ marginTop: "12px" }} />
                        <Typography weight="Body_Tiny_Regular" text={"Type in the input to start search"} />

                    </div>}

                    {SearchList_isloading && <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                        <ProgressIndicator theme='On_Light' className={styles.ProgressIndicator} />
                    </div>}
                    {/* <SearchList onChange={SearchList_onChange} style={SearchList_style} value={SearchList_value} placeHolder={SearchList_placeHolder} isloading={SearchList_isloading} listItemOnclick={SearchList_listItemOnclick} error={SearchList_error} errorDescription={SearchList_errorDescription} errorTitle={SearchList_errorTitle} errorIcon={SearchList_errorIcon}
              ListItemChildren={SearchList_ListItemChildren} noResult={SearchList_noResult} noResultIcon={SearchList_noResultIcon} noResultTitle={SearchList_noResultTitle} list={SearchList_list} inputIcon={SearchList_inputIcon} containerClassName={styles.end}
            /> */}

                </div>
            </CSSTransition>
        </div>

    );
};

export default InputSerachFilter;



const useClickOutside = (handler: any) => {
    const domNode = useRef<any>();
    useEffect(() => {
        const eventHandler = (event: any) => {
            if (!domNode.current.contains(event.target)) {
                handler();
            }
            // uncomment this for dropdown close on menu click
            // handler();
        }
        document.addEventListener("mousedown", eventHandler);
        return () => {
            document.removeEventListener('mousedown', eventHandler);
        }
    });
    return domNode;
}




