import { makeData } from "../../components/table/table-list/makeData2";
import * as actionTypes from "../actions/actionTypes";

export const initRoute = {

  filters: {

    status: [] /* [""] */,
    driverId: null,
    createdAt: { equal: null, gte: null, lte: null },
    name: "",


  }

};
export const Route = (state = initRoute, action) => {
  switch (action.type) {



    case actionTypes.handleEditRouteFilter_Name:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.state.key]: action.state.value
        }
      };

    case actionTypes.clearAll_Route_ActiveFilter:

      return { ...initRoute };
    default:
      return state;
  }
};

export default Route;