import React from "react";
import { connect } from "react-redux";
import close from "../../../../../src/assets/icon-back.svg";
import { withTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import triangleDown from "../../../../../src/assets/ic/triangle_down.svg";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import OnDemand from "../../../../../src/assets/OnDemand.csv";
import Delivery from "../../../../../src/assets/Delivery.csv";
import Pickup from "../../../../../src/assets/Pickup.csv";
import PD from "../../../../../src/assets/P&D.csv";
import { SetActiveImport } from "../../../../Redux/actions/actionNames";


class App extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          showpoper: false,
        };
        this.anchorEl = React.createRef();
        this.handleshowpoper = this.handleshowpoper.bind(this);
      }

      
  handleshowpoper = (e) => {
    this.setState({
        showpoper: !this.state.showpoper,
      });
  };


    render() {
        let { t } = this.props;

        return (
            <div className="bulkImport_Header_Container">
                <Link to="/Ondemand" onClick={()=>{this.props.dispatch(SetActiveImport(null,null))}}> <img className="close" src={close} /></Link>
                <span className="title">
                    <Trans i18nKey={"Bulkimport"}> </Trans>
                </span>


                <span className="downloadSampleCSVContainer" onClick={this.handleshowpoper} ref={this.anchorEl}>
                    <Trans i18nKey={"Downloadsamplecsv"}> </Trans>
                    <img src={triangleDown} />
                </span>



                <Popper
                    open={this.state.showpoper}
                    anchorEl={this.anchorEl.current}
                    role={undefined}
                    placement={"bottom"}
                    className="monitoringListSericeType"
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            timeout={{ enter: 150, exit: 150 }}
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom",
                            }}
                        >
                            <Paper style={{ borderRadius: "9px" }}>
                                <ClickAwayListener onClickAway={this.handleshowpoper}>
                                    <MenuList
                                        autoFocusItem={this.state.activestop}
                                        id="menu-list-grow"
                                    >
                                        <MenuItem 
                                            onClick={this.handleshowpoper}
                                            className="DownloadsamplecsvItem"
                                        >   
                                            <a href={"/download_CSV/OnDemand.csv"} download>
                                                    <Trans i18nKey={"tab_Ondemand"}> </Trans>
                                            </a>
                                        </MenuItem>
                                        <MenuItem
                                        onClick={this.handleshowpoper}
                                            className="DownloadsamplecsvItem"
                                        >
                                             <a href={"/download_CSV/Pickup.csv"} download>
                                            <Trans i18nKey={"tab_Pickup"}> </Trans>
                                            </a>
                                        </MenuItem>
                                        <MenuItem
                                        onClick={this.handleshowpoper}
                                            className="DownloadsamplecsvItem"
                                        >
                                             <a href={"/download_CSV/Delivery.csv"} download>
                                                 <Trans i18nKey={"tab_Delivery"}> </Trans>
                                             </a>
                                            
                                        </MenuItem>
                                        <MenuItem
                                        onClick={this.handleshowpoper}
                                            className="DownloadsamplecsvItem"
                                        >
                                             <a href={"/download_CSV/P&D.csv"} download>
                                                 <Trans i18nKey={"PickupandDelivery"}> </Trans>
                                             </a>
                                            
                                        </MenuItem>


                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>

            </div>
        );
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
    dispatch: dispatch,
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(App));
