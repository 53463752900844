import _ from "lodash";
import * as actionTypes from "../actions/actionTypes";

const init = {

  orders: {
    open: false,
    data: {}
  },
  drivers: {
    open: false,
    data: []
  },
  selectedOrderDriver: null,
  selectedOrder: null,
  DriverPopUp: null,
  OrderPinPopUp: null,
  OrderPinPopUpPosition: null,
  ordersBoundingList: [],
  ordersBoundingDriver: null
};


export const Monitoring = (state = init, action) => {
  switch (action.type) {


    case actionTypes.addMonitoringOrdersBounding:
      return {
        ...state,
        ordersBoundingList: action.state.ordersBoundingList,
        ordersBoundingDriver: action.state.ordersBoundingDriver
      };

    case actionTypes.setOrderPinPopUp:
      return {
        ...state,
        OrderPinPopUp: action.state
      };
    case actionTypes.removeMonitoringOrders:
      let newOrders = state.orders.data
      delete newOrders[action.state.id]

      return {
        ...state,
        orders: {
          ...state.orders,
          data: {
            ...newOrders
          }
        },
        OrderPinPopUp: action.state.id == state.OrderPinPopUp ? null : state.OrderPinPopUp
      };
    case actionTypes.setOrderPinPopUpPosition:
      return {
        ...state,
        OrderPinPopUpPosition: action.state
      };

    case actionTypes.updateMonitoringOrdersList:
      return {
        ...state,
        orders: {
          ...state.orders,
          data: {
            ...state.orders.data,
            [action.state.id]: action.state
          }
        }
      };


    case actionTypes.setSelectedMonitoringOrder:
      return {
        ...state,
        selectedOrder: action.state,
        ordersBoundingList: [],
        ordersBoundingDriver: null
      };

    case actionTypes.setSelectedMonitoringDriver:
      return {
        ...state,
        selectedOrderDriver: action.state
      };

    case actionTypes.setDriverPopUp:
      return {
        ...state,
        DriverPopUp: action.state,
        ordersBoundingList: !action.state ? [] : state.ordersBoundingList,
        ordersBoundingDriver: !action.state ? null : state.ordersBoundingDriver
      };


    case actionTypes.monitoringHandleActiveOrders:
      return {
        ...state,
        orders: {
          ...state.orders,
          open: action.state
        }
      };


    case actionTypes.monitoringHandleActiveDrivers:
      return {
        ...state,
        drivers: {
          ...state.drivers,
          open: action.state
        }
      };


    case actionTypes.monitoringSetDrivers:
      return {
        ...state,
        drivers: state.drivers.open ? {
          ...state.drivers,
          data: action.state.data
        }: {} 
      };

    case actionTypes.monitoringSetOrders:
      return {
        ...state,
        orders: state.orders.open ? {
          ...state.orders,
          data: _.keyBy(action.state.data, "id"),
        } : {}
      };

    default:
      return state;
  }
};

