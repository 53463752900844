  import styles from './style.module.css';
  import close from "../../../assets/close.svg";
  import thrdot from "../../../assets/thrdot.svg";
  import edit from "../../../assets/editMore.svg";
  import events from "../../../assets/Events.svg";
  import panddIcon from "../../../assets/panddIcon.svg";
  import { useDispatch, useSelector } from 'react-redux';
  import { useTranslation } from 'react-i18next';
  import { get_lang } from '../../../helper/UserData/userdate';
  import jalali from "jalali-moment";
  import { AdvancedAssaigning_handleDialoge, ChangeNewTravelDialoge, EditTravelAddServiceOption, EditTravelFeeChange, EditTravelSetAutoDriver, EditTravelSetCustomer, EditTravelSetDriverAuto, EditTravelSetManualDriver, EditTravelSetPrice, EditTravelSetServiceActiveVehicleType, EditTravelSurcharges, EditTRravelSetServiceVehicleTypes, Edit_Driver_Add_Driver_Option, Edit_Travel_PaymentMethod, Edit_Travel_Set_Dropoffs, Edit_Travel_set_pickup, OfferingDetailsOpen, OpenCloseEditTravel, PriceDetailsOpen, ResetViewTravel, SetSharedDataServices, SetViewOrderPickupDropoffDetails, Set_New_Event, Set_viewOrder_events, show_eventTimeLine, SetSharedDataVehicleTypes, SetSelectedMonitoringDriver, SetSelectedMonitoringOrder, Set_Manual_ActiveVehicleType, Set_auto_suggestListData, Set_OrderList_OrderTypes } from '../../../Redux/actions/actionNames';
  import { OrderGetEventsAction } from '../../../Redux/actions/actions';
  import { useCallback, useRef, useState } from 'react';
  import { ClickAwayListener, Paper } from '@material-ui/core';
  import Popover from 'newmaterial-ui/Popover';

  import statusAndDriver from "../../../assets/statusAndDriver.svg";
  import pricing from "../../../assets/pricing.svg";
  import editAddress from "../../../assets/editAddress.svg";
  import copytlink from "../../../assets/copytlink.svg";
  import copyorderid from "../../../assets/copyorderid.svg";
  import shimentlabel from "../../../assets/shimentlabel.svg";
  import repeatorder from "../../../assets/repeatorder.svg";
  import { canChangeStatus, CanEditPriceEarning, CanEditTravel, repeatReuqest } from '../../../helper/module';
  import EditPrice from "../../../Entries/Main/components/ViewOrder/components/footerComponents/Price&Earning/index";
  import _, { isEmpty } from 'lodash';
  import { ServicesApi } from '../../../service/API/service';
  import clsx from 'clsx';
  import { Link } from 'react-router-dom';
  import { useLocalServices } from '../../../hooks';
  import history from '../../../service/history';
  import Typography from '../../../design-system/lib/Typography';
  import { HASHTAG } from '../../../assets';
  import { restClient } from '../../../service/API/axios';
  import { snack } from '../../../utils/SnackbarUtilsConfigurator';
  import { v4 as uuidv4 } from 'uuid';
  import ProgressIndicator from '../../../design-system/lib/ProgressIndicator';
  import { CopyToClipboard } from 'react-copy-to-clipboard';


const stylesxx = {
    paper: styles.ssdsdsds
  }



  export const Header = () => {
    const status = useSelector((state) => state.ViewTravel.pureData.status)
    const orderType = useSelector((state) => state.ViewTravel.pureData.type)
    const createdAt = useSelector((state) => state.ViewTravel.pureData.createdAt)
    const isPickupDelivery = useSelector((state) => state.ViewTravel.pureData.isPickupDelivery)
    const pickupDeliveryOrderId = useSelector((state) => state.ViewTravel.pureData.pickupDeliveryOrderId)
    const showEventTimeLine = useSelector((state) => state.ViewTravel.eventTimeLine.show)
    const orderId = useSelector((state) => state.ViewTravel.pureData.id)
    const dispatch = useDispatch();
    const [showeditAnchorEl, setShowEditAnchorEl] = useState(false);
    const [showmoreAnchorEl, setShowMoreAnchorEl] = useState(false);
    const [shipmentlabelLoading, setShipmentlabelLoading] = useState(false);

    const Data = useSelector((state) => state.ViewTravel.pureData)
    const services = useSelector((state) => state.SharedData.services)
    const vehicleTypes = useSelector((state) => state.SharedData.vehicleTypes)
    const order_Service = useLocalServices(Data.service.id);
    const code = useSelector((state) => state.ViewTravel.pureData.code)
    const referenceID = useSelector((state) => state.ViewTravel.pureData.referenceId)
    const editAnchorEl = useRef(null);
    const moreAnchorEl = useRef(null);

    const { t } = useTranslation();

    const handleEditClick = (event) => {
      setShowEditAnchorEl(showeditAnchorEl ? false : true);
    };


    const handleMoreClick = (event) => {
      setShowMoreAnchorEl(showmoreAnchorEl ? false : true);
    };
    const ordertypeI18 = () => {
      switch (orderType) {
        case "Pickup": return "tab_Pickup"; break;
        case "Delivery": return "tab_Delivery"; break;
        case "PickupDelivery": return "tab_PickupDelivery"; break;
        default: return "tab_Ondemand"; break;
      }
    }


    const ordertypeClose = useCallback(() => {

      switch (orderType) {
        case "Pickup": return "Pickup"; break;
        case "Delivery": return "Delivery"; break;
        case "PickupDelivery": return "PickupDelivery"; break;
        default: return "Ondemand"; break;
      }
    }, [orderType]);


    const isPandD = () => {
      if (["Pickup", "Delivery"].indexOf(orderType) > -1 && isPickupDelivery) {
        return true
      } else {
        return false
      }
    }

    const eventShowHandler = useCallback(() => {
      dispatch(PriceDetailsOpen(false))
      dispatch(show_eventTimeLine(!showEventTimeLine))
      dispatch(SetViewOrderPickupDropoffDetails(null))
      dispatch(OfferingDetailsOpen(false))

      if (showEventTimeLine) {
        dispatch(Set_New_Event({ canSelectLocation: false }))
        dispatch(Set_New_Event({ show: false }));
        dispatch(Set_New_Event({ mapLocation: null }))
        dispatch(Set_viewOrder_events({ activeEventCordinator: null }))
      } else {
        dispatch(OrderGetEventsAction(dispatch, orderId))

      }

    }, [showEventTimeLine]);

    const repeatOrder = useCallback(async () => {

      dispatch(OfferingDetailsOpen(false))
      dispatch(SetViewOrderPickupDropoffDetails(null))
      dispatch(PriceDetailsOpen(false))
      dispatch(show_eventTimeLine(false))

      // history.push(`/neworder`);
      // dispatch(ChangeNewTravelDialoge());
      const Services = new ServicesApi();
      const servicesData = await Services.Services()
      // dispatch(
      //   ServicesVehicletypeAction(dispatch, center)
      // );
      if (servicesData) {
        dispatch(SetSharedDataServices(servicesData.Services))
        const vehiclesData = await Services.VehicleType({ serviceId: Data.service.id })
        if (vehiclesData) {
          dispatch(Set_Manual_ActiveVehicleType({ driverAllvehicletypes: vehiclesData.VehicleTypes }))
          dispatch(Set_auto_suggestListData(vehiclesData.VehicleTypes))

          repeatReuqest(Data, dispatch);
          setShowMoreAnchorEl(false)
        }
      }


    }, [Data]);

    const Shipmentlabel = useCallback(() => {
      setShipmentlabelLoading(true)
      // fetch('https://rest.swiftee.co.uk/api/v1/dispatcher/order/shipment-label/export-pdf', {
      //   method: 'POST',
      //   headers: {
      //     'Accept': 'application/json',
      //     'Content-Type': 'application/json' ,
      //     'authorization':'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Im4wdUNvcm9zSlFoVk9ZWTJua1RoSyIsInNvdXJjZSI6ImRpc3BhdGNoZXIiLCJyb2xlIjp7ImJhc2UiOnRydWV9LCJpYXQiOjE3MjMwNjI2NzIsImV4cCI6MTcyMzA2NjI3Mn0.Ar-HUJJLkL97_Em8jwhdBV6u5LFNsrVOnCsolFZbyTM'
      //   },
      //   body: JSON.stringify({ ids: ["09HNk6lyff1on_oNcYrBU"] }),
      // })
      // .then((response) => {
      //   const disposition = response.headers.get('Content-Disposition');
      //   console.log("Shipmentlabel ",disposition)

      //   return response.blob()})
      // .then(blob => {
      //   console.log("Shipmentlabel",blob)
      // }).finally((jojo)=>{
      //   console.log("Shipmentlabel",jojo)
    
      // })
      restClient.post('/api/v1/dispatcher/order/shipment-label/export-pdf', {
        ids: [`${Data.id}`]
      }, {
        responseType: 'blob',
        headers: {
          withCredentials: true,
          'x-bypass-auth': true
        }
      }).then(function (response) {
        console.log("ShipmentLabelPdfExport x1",response);
        // window.open(URL.createObjectURL(response.data));

        var blob = new Blob([response.data], { type: 'application/pdf' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = response.headers["content-disposition"].replace("attachment; filename=", '');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        snack.success_i18('Shipmentlabeldownloaded')
        setShipmentlabelLoading(false)

      }).catch(function (err) {
        console.log(err);
        setShipmentlabelLoading(false)

      })

    }, [Data]);





    const saveToClipboardTrackOrder = useCallback(() => {
      var copyText = document.getElementById("trackOrder");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");
      setShowMoreAnchorEl(false)
    }, []);


    const saveToClipboardTrackPickupOrder = useCallback(() => {
      var copyText = document.getElementById("pickupOrderTrack");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");
      setShowMoreAnchorEl(false)
    }, []);


    const saveToClipboardTrackDeliveryOrder = useCallback(() => {
      var copyText = document.getElementById("deliveryOrderTrack");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");
      setShowMoreAnchorEl(false)
    }, []);

    const ShowEditStatusDialoge = useCallback(() => {
      setShowEditAnchorEl(false)
      dispatch(AdvancedAssaigning_handleDialoge(true))
    });


    const EditTravel = useCallback(() => {
      setShowEditAnchorEl(false)

      if (Data) {
        try {

          dispatch(
            EditTravelSetServiceActiveVehicleType(order_Service ? order_Service : Data.service
            )
          );
        } catch (error) { }

        dispatch(
          EditTravelSetPrice({
            price: Data.price,
            currencyCode: Data.currencyCode,
          })
        );

        dispatch(
          EditTRravelSetServiceVehicleTypes(services)
        );


        dispatch(
          EditTravelSetCustomer({
            ...Data.customer,
            person: !!Data.customerId,
            BusinessName: Data.business ? Data.business.title : "",
            BusinessNumber: Data.business ? Data.business.phone : "",
            BusinessId: Data.businessId,
            id: Data.customerId,
            balance: Data.customer.balance,
          })
        );

        Data.service.options.map((service) => {
          return dispatch(
            EditTravelAddServiceOption(
              service.type === "list"
                ? {
                  id: service.id,
                  item: {
                    id: service.dataId,
                    title: service.dataTitle,
                  },
                  name: undefined,
                  type: "list",
                }
                : {
                  id: service.id,
                  name: undefined,
                  type: "boolean",
                }
            )
          );
        });

        dispatch(
          EditTravelSetDriverAuto(
            _.isEmpty(Data.driver) ? true : false
          )
        );

        if (!_.isEmpty(Data.driver) && "PickupDelivery" != orderType) {
          let VehicleType = null;

          try {
            let VehicleType = vehicleTypes.filter(
              (service) => service.id === Data.vehicleType.id
            )[0];
            dispatch(
              EditTravelSetManualDriver({
                manual_activeVehicleType: VehicleType || {
                  options: [],
                  ...Data.vehicleType,
                },
                manual_code: Data.driver.username,
                // manual_family: Data.driver.familyName,
                manual_family: "",
                manual_id: Data.driverId,
                // manual_name: Data.driver.givenName,
                manual_name: Data.driver.name,
                manual_number: Data.driver.phone,
              })
            );
          } catch (error) {

            dispatch(
              EditTravelSetManualDriver({
                manual_activeVehicleType: VehicleType || {
                  options: [],
                  ...Data.vehicleType,
                },
                manual_code: Data.driver.username,
                // manual_family: Data.driver.familyName,
                manual_family: "",
                manual_id: Data.driverId,
                // manual_name: Data.driver.givenName,
                manual_name: Data.driver.name,
                manual_number: Data.driver.phone,
              })
            );
          }
        } else if ("PickupDelivery" != orderType) {
          let VehicleType = null;
          try {
            let VehicleType = vehicleTypes.filter(
              (service) => service.id === Data.vehicleType.id
            )[0];

            dispatch(
              EditTravelSetAutoDriver({
                auto_activeVehicleType: VehicleType || Data.vehicleType,
              })
            );
          } catch (error) {
            dispatch(
              EditTravelSetAutoDriver({
                auto_activeVehicleType: null,
              })
            );
          }
        }

        !isEmpty(Data.vehicleType) && Data.vehicleType.options.map((option) => {
          return dispatch(
            Edit_Driver_Add_Driver_Option(
              option.type === "list"
                ? {
                  id: option.id,
                  item: {
                    id: option.dataId,
                    title: option.dataTitle,
                  },
                  name: undefined,
                  type: "list",
                }
                : {
                  id: option.id,
                  name: undefined,
                  type: "boolean",
                }
            )
          );
        });

        dispatch(EditTravelFeeChange(Data.baseFarePrice));
        dispatch(EditTravelSurcharges(Data.surcharges));

        if (["Pickup", "PickupDelivery", "Ondemand"].indexOf(orderType) > -1) {
          let Pickupphone = Data.pickup.phone;
          if (Pickupphone && Pickupphone.length > 2 && Pickupphone.substring(0, 2) == "00") {
            Pickupphone = "+" + Pickupphone.substring(2, Pickupphone.length)
          }

          dispatch(
            Edit_Travel_set_pickup({
              address: Data.pickup.address,
              buildingBlock: Data.pickup.buildingBlock,
              adresss_loc: {
                lng: Data.pickup.coordinates[0],
                lat: Data.pickup.coordinates[1],
              },
              noteForDriver: Data.pickup.customerDescription,
              floor: Data.pickup.floor,
              senderFullName: Data.pickup.fullName,
              senderPhoneNumber: Pickupphone,
              email: Data.pickup.email,
              unit: Data.pickup.room,
              scheduleDateAfter: Data.pickup.scheduleDateAfter,
              scheduleDateBefore: Data.pickup.scheduleDateBefore,
            })
          );
        }

        let otherDropOffs = Data.dropoffs ? JSON.parse(JSON.stringify(Data.dropoffs)) : null;

        if ("PickupDelivery" != orderType) {
          otherDropOffs.shift();
          otherDropOffs = otherDropOffs.map((drop) => {


            let otherDropOffPhone = drop.phone;
            if (otherDropOffPhone && otherDropOffPhone.length > 2 && otherDropOffPhone.substring(0, 2) == "00") {
              otherDropOffPhone = "+" + otherDropOffPhone.substring(2, otherDropOffPhone.length)
            }

            return {
              address: drop.address,
              adresss_loc: {
                lng: drop.coordinates[0],
                lat: drop.coordinates[1],
              },
              id: drop.id,
              block: drop.buildingBlock,
              floor: drop.floor,
              unit: drop.room,
              email: drop.email,
              recieverFullName: drop.fullName,
              recieverPhoneNumber: otherDropOffPhone,
              noteForDriver: drop.customerDescription,
              scheduleDateAfter: drop.scheduleDateAfter,
              scheduleDateBefore: drop.scheduleDateBefore,
            };
          });
        }



        /* */ if (["Delivery"].indexOf(orderType) > -1) {
          let DropoffPhone = Data.dropoff.phone;
          if (DropoffPhone && DropoffPhone.length > 2 && DropoffPhone.substring(0, 2) == "00") {
            DropoffPhone = "+" + DropoffPhone.substring(2, DropoffPhone.length)
          }


          dispatch(
            Edit_Travel_Set_Dropoffs({
              firstDropOff: {
                ...Data.dropoff,
                address: Data.dropoff.address,
                adresss_loc: {
                  lng: Data.dropoff.coordinates[0],
                  lat: Data.dropoff.coordinates[1],
                },
                email: Data.dropoff.email,
                scheduleDateAfter: Data.dropoff.scheduleDateAfter,
                scheduleDateBefore: Data.dropoff.scheduleDateBefore,
                block: Data.dropoff.buildingBlock,
                floor: Data.dropoff.floor,
                unit: Data.dropoff.room,
                recieverFullName: Data.dropoff.fullName,
                recieverPhoneNumber: DropoffPhone,
                noteForDriver: Data.dropoff.customerDescription,
              },
              otherDropOffs: [],
            })
          );

        } else if (["Ondemand"].indexOf(orderType) > -1) {

          let DropoffPhone = Data.dropoffs[0].phone;
          if (DropoffPhone && DropoffPhone.length > 2 && DropoffPhone.substring(0, 2) == "00") {
            DropoffPhone = "+" + DropoffPhone.substring(2, DropoffPhone.length)
          }

          dispatch(
            Edit_Travel_Set_Dropoffs({
              firstDropOff: {
                ...Data.dropoffs[0],
                address: Data.dropoffs[0].address,
                adresss_loc: {
                  lng: Data.dropoffs[0].coordinates[0],
                  lat: Data.dropoffs[0].coordinates[1],
                },
                email: Data.dropoffs[0].email,
                scheduleDateAfter: Data.dropoffs[0].scheduleDateAfter,
                scheduleDateBefore: Data.dropoffs[0].scheduleDateBefore,
                block: Data.dropoffs[0].buildingBlock,
                floor: Data.dropoffs[0].floor,
                unit: Data.dropoffs[0].room,
                recieverFullName: Data.dropoffs[0].fullName,
                recieverPhoneNumber: DropoffPhone,
                noteForDriver: Data.dropoffs[0].customerDescription,
              },
              otherDropOffs: otherDropOffs,
            })
          );

        } else if ("PickupDelivery" == orderType) {
          let DropoffPhone = Data.delivery.phone;
          if (DropoffPhone && DropoffPhone.length > 2 && DropoffPhone.substring(0, 2) == "00") {
            DropoffPhone = "+" + DropoffPhone.substring(2, DropoffPhone.length)
          }


          dispatch(
            Edit_Travel_Set_Dropoffs({
              firstDropOff: {
                ...Data.delivery,
                address: Data.delivery.address,
                adresss_loc: {
                  lng: Data.delivery.coordinates[0],
                  lat: Data.delivery.coordinates[1],
                },
                email: Data.delivery.email,
                scheduleDateAfter: Data.delivery.scheduleDateAfter,
                scheduleDateBefore: Data.delivery.scheduleDateBefore,
                block: Data.delivery.buildingBlock || "",
                floor: Data.delivery.floor || "",
                unit: Data.delivery.room || "",
                recieverFullName: Data.delivery.fullName || "",
                recieverPhoneNumber: DropoffPhone || "",
                noteForDriver: Data.delivery.customerDescription || "",
              },
              otherDropOffs: [],
            })
          );
        }







        /* */
        dispatch(
          Edit_Travel_PaymentMethod({
            paymentMethod: Data.paymentMethod,
            paymentSide: Data.paymentSide,
            title:
              Data.paymentMethod == "Cash" &&
                Data.paymentSide == "Receiver"
                ? "Reciver"
                : Data.paymentMethod == "Cash" &&
                  Data.paymentSide == "Sender"
                  ? "Cash"
                  : Data.paymentMethod == "Wallet" &&
                    Data.paymentSide == "Sender"
                    ? "Balance"
                    : "Reciver",
          })
        );

        setTimeout(() => {
          dispatch(OpenCloseEditTravel(Data.id));
        }, 500);
      }
    }, [Data, status, orderType, order_Service]);

    const resetView = useCallback(() => {
      dispatch(SetSelectedMonitoringDriver(null))
      dispatch(SetSelectedMonitoringOrder(null))
      dispatch(ResetViewTravel());
      dispatch(Set_OrderList_OrderTypes(ordertypeClose()))
      history.push(`/${ordertypeClose()}`)
    }, []);


    return <div className={styles.header}>
      <img className={styles.back} src={close} onClick={resetView} />
      <div className={styles.orderTypeDateContainer}>
        <span className={styles.orderType}>{t(ordertypeI18())}</span>
        <span className={styles.date}> {createdAt &&
          jalali(createdAt)
            .locale(get_lang())
            .format("D MMM HH:mm")} </span>
      </div>
      {isPandD() && <Link to={`/order/${pickupDeliveryOrderId}`} className={styles.pandd}><img src={panddIcon} /> {t("PD2")} </Link>
      }
      <img ref={moreAnchorEl} className={styles.thrdot} onClick={handleMoreClick} id={"351a089c"} src={thrdot} />
      {((canChangeStatus(status, orderType) || CanEditPriceEarning(Data.status, orderType, Data.paymentSide, Data.paymentMethod) || CanEditTravel(status)) && "PickupDelivery" != orderType) && <img ref={editAnchorEl} className={styles.editAddress} src={edit} onClick={handleEditClick} />}
      <span className={clsx(styles.events, showEventTimeLine && styles.eventsActive)} style={{ marginInlineEnd: "PickupDelivery" != orderType ? "" : "0px" }} onClick={eventShowHandler}> <img src={events} className={styles.eventsIcon} /> {t("Events")} </span>

      <hr className={styles.hr} />

      <div className={styles.row}>
        <Typography weight='Body_Small_Regular' text={code} style={{ color: "rgba(36, 36, 36, 1)" }} />
        {(referenceID ? String(referenceID).length > 0 : false) && <><img src={HASHTAG} style={{ marginInlineStart: 'auto', width: '16px', height: "16px", marginInlineEnd: "6px" }} /><Typography weight='Body_Small_Regular' text={"Ref. " + referenceID} textColor='TextNormal' /></>}
      </div>

      <Popover
        open={showeditAnchorEl}
        anchorEl={editAnchorEl.current}
        role={undefined}
        id='editAnchorEl'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={(e) => {
          setShowEditAnchorEl(false)
        }}
        // style={{
        //   borderRadius: "10px",
        //   boxShadow:"0px 1px 12px 0px rgba(128, 128, 128, 0.20)"
        // }}
        classes={stylesxx}
        className="Popoverxxx"
      >

        <div className={styles.popupItemContainer}>
          {canChangeStatus(status, orderType) && <span onClick={ShowEditStatusDialoge}><img src={statusAndDriver} /> {t("StatusandDriver")} </span>}
          {/* <span><img src={pricing} /> {t("EditPrice")}</span> */}
          {(CanEditPriceEarning(Data.status, orderType, Data.paymentSide, Data.paymentMethod) && "PickupDelivery" != orderType) && <EditPrice func={(e) => {
            setShowEditAnchorEl(false)
          }} >
            <span><img src={pricing} /> {t("EditPrice")}</span>

          </EditPrice>}

          {(CanEditTravel(status) && !Data.isPickupDelivery && "PickupDelivery" != orderType) &&
            <span onClick={EditTravel} ><img src={editAddress} /> {t("EditPickupDropOff")} </span>
          }

        </div>

      </Popover>

      <Popover
        open={showmoreAnchorEl}
        anchorEl={moreAnchorEl.current}
        role={undefined}
        id='moreAnchorEl'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={(e) => {
          setShowMoreAnchorEl(false)
        }}

        //   className="Popoverxxx"
        classes={stylesxx}
        transition
        disablePortal
      >

        <div className={styles.popupItemContainer}>

          <CopyToClipboard text={Data.trackOrder + "?lng=" + get_lang()}
            onCopy={() => snack.success(t("Saved"))}>
            <span onClick={saveToClipboardTrackOrder}><img src={copytlink} /> {t("CopyOrderlink")} </span>
          </CopyToClipboard>
          {(Data.pickupOrderTrackUrl && Data.pickupOrderTrackUrl.length > 0) && <span onClick={saveToClipboardTrackPickupOrder} ><img src={copytlink} /> {t("CopyPickuplink")} </span>}
          {(Data.deliveryOrderTrackUrl && Data.deliveryOrderTrackUrl.length > 0) && <span onClick={saveToClipboardTrackDeliveryOrder}><img src={copytlink} /> {t("CopyDeliverylink")} </span>}
          {/* <span><img src={copyorderid   } /> {t("EditPrice")}</span> */}
          <span onClick={Shipmentlabel}>{shipmentlabelLoading ? <ProgressIndicator theme='On_Light' style={{ width: "16px", height: "16px", marginInlineEnd: "6px" }} /> : <img src={shimentlabel} />} {t("Shipmentlabel")} </span>
          <span onClick={repeatOrder} ><img src={repeatorder} /> {t("repeatOrder")} </span>

        </div>

      </Popover>

      <input
        type="text"
        value={Data.trackOrder + "?lng=" + get_lang()}
        id="trackOrder"
        style={{
          opacity: "0",
          width: "1px",
          height: "1px",
          color: "white",
          border: "none",
          outline: "none",
        }}
      />

      <input
        type="text"
        value={Data.pickupOrderTrackUrl + "?lng=" + get_lang()}
        id="pickupOrderTrack"
        style={{
          opacity: "0",
          width: "1px",
          height: "1px",
          color: "white",
          border: "none",
          outline: "none",
        }}
      />


      <input
        type="text"
        value={Data.deliveryOrderTrackUrl + "?lng=" + get_lang()}
        id="deliveryOrderTrack"
        style={{
          opacity: "0",
          width: "1px",
          height: "1px",
          color: "white",
          border: "none",
          outline: "none",
        }}
      />

      <input
        type="text"
        value={Data.shipmentLabelUrl + "?lng=" + get_lang()}
        id="Shipmentlabel"
        style={{
          opacity: "0",
          width: "1px",
          height: "1px",
          color: "white",
          border: "none",
          outline: "none",
        }}
      />

    </div>

  };




