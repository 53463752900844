import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Collapse from "@material-ui/core/Collapse";
import { withTranslation, Trans } from "react-i18next";
import cancell from "../../../../../src/assets/ic-close.svg";
import ic_warning from "../../../../../src/assets/ic_warning.svg";
import { withStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Dialog from "@material-ui/core/Dialog";
import { isEmpty } from "lodash";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import {
  AdvancedAssaigning_handleDialoge,
  AdvancedAssaigning_reset,
  AdvancedAssaigning_setVehicleType,
  SetSharedDataVehicleTypes,
} from "../../../../Redux/actions/actionNames";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import Loading from "./loading";
import StatusSelector from "./statusSelector";
import OndemandDriver from "./ondemandDriver/index";
import DispatchTime from "./dispatchTime";
import PickupDispatchTime from "./pickupDispatchTime";
import DeliveryDispatchTime from "./deliveryDispatchTime";

import PickupDriver from "./pickupDriver/index";
import DeliveryDriver from "./deliveryDriver/index";
import PickupandDeliveryDriver from "./pickup and delivery Driver/index";
import icWarningEdit from "../../../../../src/assets/ic-warning-edit.svg";
import {
  GetVehicletypeByService_AdvancedAssaigning_Action,
  AdvancedAssaigning_OrderPickupAction,
  AdvancedAssaigning_OrderDeliveryAction,
  Update_Order_Status_Action,
  Update_Order_PickupDelivery_Status_Action,
  Update_Order_Pickup_Status_Action,
  Update_Order_Delivery_Status_Action,
} from "../../../../Redux/actions/actions";
import { get_id } from "../../../../helper/UserData/userdate";
import { getCenterMap } from "../../../../helper/module";
import { ServicesApi } from "../../../../service/API/service";
var _ = require("lodash");

const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#00000",
    }}
  />
));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requested: false,
      dispatchLater:
        props.status == "Pending" &&
        !props.isDispatching &&
        props.driverAssignmentModel == "automatic",
      selectedTimeStamp: props.dispatchAt,
      canEdit: false,

      pickupDispatchLater: false,
      pickupSelectedTimeStamp: null,
      pickupcanEdit: false,

      deliveryDispatchLater: false,
      deliverySelectedTimeStamp: null,
      deliverycanEdit: false,
    };
    this.isLoading = this.isLoading.bind(this);
    this.CanSaveChange = this.CanSaveChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.PickupOrderInfo && !this.props.PickupOrderInfo) {
      this.setState({
        pickupDispatchLater:
          nextProps.PickupOrderInfo.status == "Pending" &&
          !nextProps.PickupOrderInfo.isDispatching &&
          nextProps.PickupOrderInfo.driverAssignmentModel == "automatic",
      });
    }

    if (nextProps.DliveryOrderInfo && !this.props.DliveryOrderInfo) {
      this.setState({
        deliveryDispatchLater:
          nextProps.DliveryOrderInfo.status == "Pending" &&
          !nextProps.DliveryOrderInfo.isDispatching &&
          nextProps.DliveryOrderInfo.driverAssignmentModel == "automatic",
      });
    }
  }

  handleSelectedTimeStamp = (val) => {
    this.setState({
      selectedTimeStamp: val,
    });
  };

  handleCanEdit = (key) => {
    this.setState({
      [key]: true,
    });
  };

  handleDispatchLater = (val) => {
    this.setState({
      dispatchLater: val,
    });
  };

  handlePickupSelectedTimeStamp = (val) => {
    this.setState({
      pickupSelectedTimeStamp: val,
    });
  };

  handlePickupDispatchLater = (val) => {
    this.setState({
      pickupDispatchLater: val,
    });
  };

  handleDeliverySelectedTimeStamp = (val) => {
    this.setState({
      deliverySelectedTimeStamp: val,
    });
  };

  handleDeliveryDispatchLater = (val) => {
    this.setState({
      deliveryDispatchLater: val,
    });
  };

  componentDidMount = () => {
    if (this.props.orderType == "PickupDelivery") {
      this.props.dispatch(
        AdvancedAssaigning_OrderPickupAction(
          this.props.dispatch,
          this.props.pickupOrderId
        )
      );
      this.props.dispatch(
        AdvancedAssaigning_OrderDeliveryAction(
          this.props.dispatch,
          this.props.deliveryOrderId
        )
      );
    }

    let center = getCenterMap(this.props.mapRef, this.props.center);

    this.getVehicleTypes();
    // this.props.dispatch(GetVehicletypeByService_AdvancedAssaigning_Action(this.props.dispatch, {
    //   latitude: center.lat,
    //   longitude: center.lng,
    //   serviceId: this.props.service,
    //   // customerId: this.props.customerId
    // }, (vt) => {

    // }))
  };

  getVehicleTypes = async () => {
    let center = getCenterMap(this.props.mapRef, this.props.center);

    const Services = new ServicesApi();
    const data = await Services.VehicleType({
      latitude: center.lat,
      longitude: center.lng,
      serviceId: this.props.service,
      // customerId: this.props.customerId
    });
    this.props.dispatch(
      AdvancedAssaigning_setVehicleType({
        vehicleTypes: data.VehicleTypes,
        vehicleTypesLoading: false,
        vehicleTypesError: false,
      })
    );
  };

  onChangeOpenDialoge = () => {
    this.props.dispatch(AdvancedAssaigning_handleDialoge(false));
    this.props.dispatch(AdvancedAssaigning_reset());
  };

  isLoading = () => {
    if (this.props.orderType == "PickupDelivery") {
      if (
        this.props.PickupOrderInfo &&
        this.props.DliveryOrderInfo &&
        this.props.vehicleTypes
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (this.props.vehicleTypes) {
        return false;
      } else {
        return true;
      }
    }
  };
  detectOndemandOptionChange = () => {
    return this.props.activeRules && this.props.activeRules.MustsetDriver
      ? !_.isEqual(
          this.props.OrderPureDataOptions.map((a) => a.id).sort(),
          this.props.options_f.map((a) => a.id).sort()
        )
      : false;
  };

  detectPickupDeliveryOptionChange = () => {    
    if(!this.props.PickupOrderInfoOptions || !this.props.DliveryOrderOptions) {
      return false
    }
   
    return (
      !_.isEqual(
        this.props.PickupOrderInfoOptions.map((a) => a.id).sort(),
        this.props.options_f.map((a) => a.id).sort()
      ) ||
      !_.isEqual(
        this.props.DliveryOrderOptions.map((a) => a.id).sort(),
        this.props.options_S.map((a) => a.id).sort()
      )
    );
  };

  detectPickuporDeliveryOptionChange = () => {
    return !_.isEqual(
      this.props.OrderPureDataOptions.map((a) => a.id).sort(),
      this.props.options_f.map((a) => a.id).sort()
    );
  };

  showChangeOptions = () => {
    if (!this.CanSaveChange()) {
      return false;
    }
    if (this.props.orderType == "Ondemand") {
      return this.detectOndemandOptionChange();
    } else if (this.props.orderType == "PickupDelivery") {
      return this.detectPickupDeliveryOptionChange();
    } else {
      return this.detectPickuporDeliveryOptionChange();
    }
  };

  CanSaveChangeOndemand = () => {
    let vehicleType_f = this.props.auto
      ? this.props.auto_activeVehicleType
      : this.props.manual_activeVehicleType;
    let driver_f = this.props.manual_id;

    let rule = this.props.activeRules || this.props.initialRule;
    if (rule) {
      return rule.validate(vehicleType_f, driver_f);
    } else {
      return false;
    }
  };

  CanSaveChangePickupDelivery = () => {
    let vehicleType_f = this.props.auto
      ? this.props.auto_activeVehicleType
      : this.props.manual_activeVehicleType;
    let driver_f = this.props.manual_id;

    let vehicleType_s = this.props.auto_s
      ? this.props.auto_activeVehicleType_s
      : this.props.manual_activeVehicleType_s;
    let driver_s = this.props.manual_id_s;

    let rule = this.props.activeRules || this.props.initialRule;
    if (rule) {
      return rule.validate(vehicleType_f, driver_f, vehicleType_s, driver_s);
    } else {
      return false;
    }
  };

  CanSaveChangePickuporDelivery = () => {
    let vehicleType_f = this.props.auto
      ? this.props.auto_activeVehicleType
      : this.props.manual_activeVehicleType;
    let driver_f = this.props.manual_id;

    let rule = this.props.activeRules || this.props.initialRule;
    if (rule) {
      return rule.validate(vehicleType_f, driver_f);
    } else {
      return false;
    }
  };

  CanSaveChange() {
    if (this.props.orderType == "Ondemand") {
      return this.CanSaveChangeOndemand();
    } else if (this.props.orderType == "PickupDelivery") {
      return this.CanSaveChangePickupDelivery();
    } else {
      return this.CanSaveChangePickuporDelivery();
    }
  }

  getOndemandObject = () => {
    let rule = this.props.activeRules || this.props.initialRule;

    return {
      status: rule.to,
      dispatcherId: get_id(),
      dispatchAt: this.state.canEdit
        ? this.state.dispatchLater
          ? this.state.selectedTimeStamp
          : Date.now()
        : null,
      driverAssignmentModel: this.props.auto ? "automatic" : "manual",
      driverId: this.props.auto
        ? null
        : isEmpty(this.props.manual_id)
        ? null
        : this.props.manual_id,
      vehicleType: rule.NotshowDriverPanel
        ? null
        : {
            id: this.props.auto
              ? this.props.auto_activeVehicleType
                ? this.props.auto_activeVehicleType.id
                : null
              : this.props.manual_activeVehicleType
              ? this.props.manual_activeVehicleType.id
              : null,
            options: this.props.options_f.map((op) => {
              return { id: op.id, dataId: op.item ? op.item.id : "" };
            }),
          },
    };
  };

  OndemandSend = () => {
    this.props.dispatch(
      Update_Order_Status_Action(
        this.props.dispatch,
        {
          id: this.props.OrderPureData.id,
          data: this.removeNull(this.getOndemandObject()),
        },
        (call) => {
          this.setState({
            request: call.request,
            openEditDriver: call.open,
            requested: false,
          });
          this.onChangeOpenDialoge();
        }
      )
    );
  };

  getPickupDeliveryObject = () => {
    let rule = this.props.activeRules || this.props.initialRule;

    return {
      status: rule.to,
      dispatcherId: get_id(),
      pickup: rule.showDriver
        ? {
            dispatchAt:this.state.pickupDispatchLater
                ? this.state.pickupSelectedTimeStamp
                : Date.now() ,
            vehicleType: {
              id: this.props.auto
                ? this.props.auto_activeVehicleType
                  ? this.props.auto_activeVehicleType.id
                  : null
                : this.props.manual_activeVehicleType
                ? this.props.manual_activeVehicleType.id
                : null,
              options: this.props.options_f.map((op) => {
                return { id: op.id, dataId: op.item ? op.item.id : "" };
              }),
            },
            driverAssignmentModel: this.props.auto ? "automatic" : "manual",
            driverId: this.props.auto
              ? null
              : isEmpty(this.props.manual_id)
              ? null
              : this.props.manual_id,
          }
        : null,
      delivery: rule.showDriver
        ? {
            dispatchAt: this.state.deliveryDispatchLater
                ? this.state.deliverySelectedTimeStamp
                : Date.now(),
            vehicleType: {
              id: this.props.auto_s
                ? this.props.auto_activeVehicleType_s
                  ? this.props.auto_activeVehicleType_s.id
                  : null
                : this.props.manual_activeVehicleType_s
                ? this.props.manual_activeVehicleType_s.id
                : null,
              options: this.props.options_S.map((op) => {
                return { id: op.id, dataId: op.item ? op.item.id : "" };
              }),
            },
            driverAssignmentModel: this.props.auto_s ? "automatic" : "manual",
            driverId: this.props.auto_s
              ? null
              : isEmpty(this.props.manual_id_s)
              ? null
              : this.props.manual_id_s,
          }
        : null,
    };
  };

  getPickuporDeliveryObject = () => {
    let rule = this.props.activeRules || this.props.initialRule;
    let canAttachData = !rule.NotshowDriverPanel;
    return {
      status: rule.to,
      dispatcherId: get_id(),
      dispatchAt: this.state.canEdit
        ? this.state.dispatchLater
          ? this.state.selectedTimeStamp
          : Date.now()
        : null,
      ...(canAttachData && {
        vehicleType: {
          id: this.props.auto
            ? this.props.auto_activeVehicleType
              ? this.props.auto_activeVehicleType.id
              : null
            : this.props.manual_activeVehicleType
            ? this.props.manual_activeVehicleType.id
            : null,
          options: this.props.options_f.map((op) => {
            return { id: op.id, dataId: op.item ? op.item.id : "" };
          }),
        },
        driverAssignmentModel: this.props.auto ? "automatic" : "manual",
        driverId: this.props.auto
          ? null
          : isEmpty(this.props.manual_id)
          ? null
          : this.props.manual_id,
      }),
    };
  };

  PickupDeliverySend = () => {
    this.props.dispatch(
      Update_Order_PickupDelivery_Status_Action(
        this.props.dispatch,
        {
          id: this.props.OrderPureData.id,
          data: this.removeNull(this.getPickupDeliveryObject()),
        },
        (call) => {
          this.setState({
            request: call.request,
            openEditDriver: call.open,
            requested: false,
          });
          this.onChangeOpenDialoge();
        }
      )
    );
  };

  PickuporDeliverySend = () => {
    this.props.orderType == "Pickup"
      ? this.props.dispatch(
          Update_Order_Pickup_Status_Action(
            this.props.dispatch,
            {
              id: this.props.OrderPureData.id,
              data: this.removeNull(this.getPickuporDeliveryObject()),
            },
            (call) => {
              this.setState({
                request: call.request,
                openEditDriver: call.open,
                requested: false,
              });
              this.onChangeOpenDialoge();
            }
          )
        )
      : this.props.dispatch(
          Update_Order_Delivery_Status_Action(
            this.props.dispatch,
            {
              id: this.props.OrderPureData.id,
              data: this.removeNull(this.getPickuporDeliveryObject()),
            },
            (call) => {
              this.setState({
                request: call.request,
                openEditDriver: call.open,
              });
              this.onChangeOpenDialoge();
            }
          )
        );
  };

  sendRequest = () => {
    this.setState({
      requested: true,
    });

    if (this.props.orderType == "Ondemand") {
      return this.OndemandSend();
    } else if (this.props.orderType == "PickupDelivery") {
      return this.PickupDeliverySend();
    } else {
      return this.PickuporDeliverySend();
    }
  };

  removeNull = (objIn) => {
    let objOut = objIn;
    Object.keys(objOut).forEach((key) => {
      if (objOut[key] === null) {
        delete objOut[key];
      }
    });
    return objOut;
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Dialog
          style={{}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={true}
          onClose={this.onChangeOpenDialoge}
          disableAutoFocus
          disableEnforceFocus 
          disableRestoreFocus 
        >
          <div className="EditDriverContainer">
            <div className="header">
              <img
                className="close"
                src={cancell}
                onClick={this.onChangeOpenDialoge}
              />
              <span className="title">
                {this.props.t("EditStatusandDriver")}
              </span>
            </div>

            {this.isLoading() ? (
              <Loading />
            ) : (
              <React.Fragment>
                <StatusSelector />
                {this.props.orderType == "Ondemand" &&
                  (this.props.activeRules || this.props.initialRule) && (
                    <OndemandDriver />
                  )}
                {["PickupDelivery"].indexOf(this.props.orderType) > -1 &&
                  (this.props.activeRules
                    ? this.props.activeRules.showDriver
                    : this.props.initialRule
                    ? this.props.initialRule.showDriver
                    : false) && <PickupDriver />}

                {["PickupDelivery"].indexOf(this.props.orderType) > -1 &&
                  this.props.PickupOrderInfo && (
                    <PickupDispatchTime
                      handleCanEdit={this.handleCanEdit}
                      canEdit={this.state.pickupcanEdit}
                      handleSelectedTimeStamp={
                        this.handlePickupSelectedTimeStamp
                      }
                      selectedTimeStamp={this.state.pickupSelectedTimeStamp}
                      dispatchLater={this.state.pickupDispatchLater}
                      handleDispatchLater={this.handlePickupDispatchLater}
                    />
                  )}

                {["PickupDelivery"].indexOf(this.props.orderType) > -1 &&
                  (this.props.activeRules
                    ? this.props.activeRules.showDriver
                    : this.props.initialRule
                    ? this.props.initialRule.showDriver
                    : false) && <DeliveryDriver />}
                {["Pickup", "Delivery"].indexOf(this.props.orderType) > -1 && (
                  <PickupandDeliveryDriver />
                )}
                {["Ondemand", "Pickup", "Delivery"].indexOf(
                  this.props.orderType
                ) > -1 && (
                  <DispatchTime
                    handleCanEdit={this.handleCanEdit}
                    canEdit={this.state.canEdit}
                    handleSelectedTimeStamp={this.handleSelectedTimeStamp}
                    selectedTimeStamp={this.state.selectedTimeStamp}
                    dispatchLater={this.state.dispatchLater}
                    handleDispatchLater={this.handleDispatchLater}
                  />
                )}
                {["PickupDelivery"].indexOf(this.props.orderType) > -1 &&
                  this.props.DliveryOrderInfo && (
                    <DeliveryDispatchTime
                      handleCanEdit={this.handleCanEdit}
                      canEdit={this.state.deliverycanEdit}
                      handleSelectedTimeStamp={
                        this.handleDeliverySelectedTimeStamp
                      }
                      selectedTimeStamp={this.state.deliverySelectedTimeStamp}
                      dispatchLater={this.state.deliveryDispatchLater}
                      handleDispatchLater={this.handleDeliveryDispatchLater}
                    />
                  )}
              </React.Fragment>
            )}

            <div className="footer">
              {this.showChangeOptions() && !this.isLoading() && (
                <React.Fragment>
                  <img src={ic_warning} className="icWarningEditImg" />
                  <span className="icWarningEditSpan">
                    {" "}
                    <Trans i18nKey={"icWarningEdit"}> </Trans>{" "}
                  </span>
                </React.Fragment>
              )}
              <sendOrder
                className={`saveChangeEditDriver ${
                  (!this.CanSaveChange() || this.state.requested) && "grey"
                }`}
                style={{ marginTop: "17px" }}
                onClick={
                  this.CanSaveChange() &&
                  !this.state.requested &&
                  this.sendRequest
                }
              >
                <Trans i18nKey={"Savechanges"}> </Trans>
              </sendOrder>
            </div>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  dispatchAt: state.ViewTravel.pureData
  ? state.ViewTravel.pureData.dispatchAt
    ? state.ViewTravel.pureData.dispatchAt
    : null
  : null,
  orderType: state.ViewTravel.pureData.type,
  pureData: state.ViewTravel.pureData,
  PickupOrderInfo: state.AdvancedAssaigning.PickupOrderInfo,
  PickupOrderInfoOptions: state.AdvancedAssaigning.PickupOrderInfo
    ? state.AdvancedAssaigning.PickupOrderInfo.vehicleType.options
    : [],

  DliveryOrderInfo: state.AdvancedAssaigning.DliveryOrderInfo,
  DliveryOrderOptions: state.AdvancedAssaigning.DliveryOrderInfo
    ? state.AdvancedAssaigning.DliveryOrderInfo.vehicleType.options
    : [],

  vehicleTypes: state.AdvancedAssaigning.vehicleTypes,
  orderType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
      ? state.ViewTravel.pureData.type
      : null
    : null,
  OrderPureData: state.ViewTravel.pureData ? state.ViewTravel.pureData : null,
  OrderPureDataOptions: state.ViewTravel.pureData
    ? !isEmpty(state.ViewTravel.pureData.vehicleType)
      ? state.ViewTravel.pureData.vehicleType.options
      : []
    : [],

  options_f:
    state.AdvancedAssaigning.firstDriver[
      state.AdvancedAssaigning.firstDriver.autoManualSwitch ? "auto" : "manual"
    ].options,
  options_S:
    state.AdvancedAssaigning.secondDriver[
      state.AdvancedAssaigning.secondDriver.autoManualSwitch ? "auto" : "manual"
    ].options,

  mapRef: state.Map.mapRef,
  center: state.Map.center,

  service: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.service
      ? state.ViewTravel.pureData.service.id
      : ""
    : "",

  customerId: state.ViewTravel.customerId,
  deliveryOrderId: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.deliveryOrderId
      ? state.ViewTravel.pureData.deliveryOrderId
      : null
    : null,
  pickupOrderId: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.pickupOrderId
      ? state.ViewTravel.pureData.pickupOrderId
      : null
    : null,
  activeRules: state.AdvancedAssaigning.activeRules,
  initialRule: state.AdvancedAssaigning.initialRule,

  auto_activeVehicleType:
    state.AdvancedAssaigning.firstDriver.auto.auto_activeVehicleType,
  auto: state.AdvancedAssaigning.firstDriver.autoManualSwitch,
  manual_activeVehicleType:
    state.AdvancedAssaigning.firstDriver.manual.manual_activeVehicleType,
  manual_id: state.AdvancedAssaigning.firstDriver.manual.manual_id,

  auto_activeVehicleType_s:
    state.AdvancedAssaigning.secondDriver.auto.auto_activeVehicleType,
  auto_s: state.AdvancedAssaigning.secondDriver.autoManualSwitch,
  manual_activeVehicleType_s:
    state.AdvancedAssaigning.secondDriver.manual.manual_activeVehicleType,
  manual_id_s: state.AdvancedAssaigning.secondDriver.manual.manual_id,
  status: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.status
      ? state.ViewTravel.pureData.status
      : null
    : null,
  isDispatching: state.ViewTravel.pureData.isDispatching,
  driverAssignmentModel: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.driverAssignmentModel
      ? state.ViewTravel.pureData.driverAssignmentModel
      : null
    : null,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
