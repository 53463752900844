import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import arrow from "../../../../../../../assets/arrow-triangle-down.svg";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withTranslation, Trans } from "react-i18next";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  Edit_Travel_PaymentMethod,
  Edit_Travel_Operate_Changed,
} from "../../../../../../../../src/Redux/actions/actionNames";
import SenderWalletic from "../../../../../../../assets/customer-ic-wallet.svg";
import SenderCashic from "../../../../../../../assets/ic-cash.svg";
import ReceiverCashic from "../../../../../../../assets/customer-ic-by-receiver.svg";
import balance from "../../../../../../../assets/mutual-wallet-balance.svg";
import _debounce from "lodash/debounce";
import { useCallback } from "react";
import { get_id, get_settings_dispatcher } from "../../../../../../../helper/UserData/userdate";
import {
  OrderEditondemandAction,
  OrderEditPriceondemandAction,
} from "../../../../../../../Redux/actions/actions";
import PaymentIcon from "react-payment-icons";
import Stripe_Add_Card from "./changeMethod/stripe";
import { Business_account_http, Get_Stripe_Cards } from "../../../../../../../helper/api/index";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cards: [],
      showpoperMethod: false,
      showpoperSide: false,
      canWalletBeNegative:false,
      cardsReady: false,
      paymentSide: [
        {
          i18: "SenderWallet",
          data: {
            paymentSide: "Sender",
            paymentMethod: "Wallet",
            paymentProvider: null,
            cardInfo: null,
            paymentMethodId: null,
          },
        },
        {
          i18: "SenderCash",
          data: {
            paymentSide: "Sender",
            paymentMethod: "Cash",
            paymentProvider: null,
            cardInfo: null,
            paymentMethodId: null,
          },
        },
        {
          i18: "ReceiverCash",
          data: {
            paymentSide: "Receiver",
            paymentMethod: "Cash",
            paymentProvider: null,
            cardInfo: null,
            paymentMethodId: null,
          },
        },
      ],
    };
    this.paymentSide = React.createRef();
    this.paymentMethod = React.createRef();
    this.getCards = this.getCards.bind(this) ;
    this.WalletBalanceBussinessCheck = this.WalletBalanceBussinessCheck.bind(this) ;
    this.debounceAwayListener = _debounce(
      this.handleshowpoperPaymentSide.bind(this),
      300,
      { leading: false, trailing: true }
    );
  }

  getImageOfPaymentMeyhod = (name) => {
    if (name == "SenderWallet") {
      return SenderWalletic
    } else if (name == "SenderCash") {
      return SenderCashic
    } else if (name == "ReceiverCash") {
      return ReceiverCashic
    } else {
      return SenderWalletic
    }
  }

 


  handleshowpoperPaymentMethod = () => {
    this.setState({
      showpoperMethod: !this.state.showpoperMethod,
    });
  };


  handleSelectedItemStripe = (item) => {
    this.props.changeData({
      paymentSide: "Sender",
      paymentMethod: "Card",
      paymentProvider: "stripe",
      cardInfo: item,
      paymentMethodId: item.id,
    })

    this.setState({
      showpoperSide: false,
      showpoperMethod: false,
    });

  };


  setCards = (cards) => {
    this.setState({
      cards,
      cardsReady:true
    })
  }

  componentDidMount() {
    if (!this.state.cardsReady && (get_settings_dispatcher().stripePublicApiKey && get_settings_dispatcher().stripePublicApiKey.length > 0 ) && this.props.customerId && this.props.customerId.length > 2 && this.state.cards.lenght == 0) {
      Get_Stripe_Cards(this.setCards, this.props.customerId)
    }


  }


  componentWillReceiveProps(nextProps) {
    if ((get_settings_dispatcher().stripePublicApiKey && get_settings_dispatcher().stripePublicApiKey.length > 0 ) &&  nextProps.customerId && nextProps.customerId.length > 2 && this.state.cards.length == 0) {
      Get_Stripe_Cards(this.setCards, nextProps.customerId)
    }

    
  

    // if((nextProps.paymentSide + nextProps.paymentMethod == "SenderWallet") ){
    //   if(nextProps.customerType != "individual" ? nextProps.businessDataFetched : true){


    //       if ((nextProps.balance + nextProps.price) >= nextProps.minimumBalance) {
    //           //nothing
    //       }else{
    //         if (Number(nextProps.price ) > Number(nextProps.balance)) {
    //           if(nextProps.serviceSettings.isCashBySenderActive){
    //             this.handleSelectedItem(this.state.paymentSide[1])
    //           }else if(nextProps.serviceSettings.enableReceiverCash){
    //             this.handleSelectedItem(this.state.paymentSide[2])
    //           }
    //         }
    //       }

    //   }
    
    // }
  }


  handleshowpoperPaymentSide = () => {
    this.setState({
      showpoperSide: !this.state.showpoperSide,
    });
  };

  handleSelectedItem = (item) => {
    this.SendOrder(item.data.paymentMethod, item.data.paymentSide);
    // this.props.dispatch(Edit_Travel_Operate_Changed())
    // this.props.dispatch(Edit_Travel_PaymentMethod(item.data))
    this.setState({
      showpoperSide: false,
      showpoperMethod: false,
    });
  };

  WalletCheck = (item) => {
    let payment = this.props.changedData.changed ? this.props.changedData : null
    

    if (
      (item ? item.paymentSide + item.paymentMethod : payment ? payment.paymentSide + payment.paymentMethod : this.props.paymentSide + this.props.paymentMethod) == "SenderWallet"
    ) {
      

      return this.WalletBalanceBussinessCheck(this.props)

    } else {
      

      return "grey";
    }

    // return "grey";

  };


  WalletBalanceBussinessCheck = () => {
    

    if (Number(this.props.price) > Number(this.props.balance)) {
        if (Number(Number(this.props.balance) - Number(this.props.price))  >= this.props.minimumBalance) {
          return "green";
        } else {
          return "red";
        }
      } else {
        return "green";
      }

  };




  SendOrder = (paymentMethod, paymentSide) => {
    this.props.changeData({
      paymentSide: paymentSide,
      paymentMethod: paymentMethod,
    })
    // this.props.dispatch(
    //   OrderEditPriceondemandAction(
    //     this.props.dispatch,
    //     {

    //       baseFarePrice: this.props.baseFarePrice,
    //       surcharges: this.props.surcharges,
    //       dispatcherId: get_id(),
    //     },
    //     null,
    //     null,
    //     this.props.Data.id
    //   )
    // );
  };

  getHeightPoper = () => {
    try {
      return Number(document.getElementById('fuckingPopper').clientHeight / 2)
    } catch (error) {
      return 150
    }
  }

  getCards(autoSelect) {
    
    Get_Stripe_Cards((cards)=>{
      if (typeof autoSelect == "boolean" && cards.length > 0) {

        this.props.changeData({
          paymentSide: "Sender",
          paymentMethod: "Card",
          paymentProvider: "stripe",
          cardInfo: cards[0],
          paymentMethodId: cards[0].id,
        })
      
      }
      this.setState({
        cards
      })
      
    }, this.props.customerId)
  }
  render() {

    const { t } = this.props;
    let payment = this.props.changedData.changed ? this.props.changedData : null
    return (
      <React.Fragment>


        <paymentMethodContainerNewOrder onClick={() => this.handleshowpoperPaymentSide(true)} style={{marginTop:'16px',marginLeft:"11px",marginRight:"11px"}}
        >
          {this.props.changedData.changed ? (
            <div className="opdrtmff">

              {"SenderCard" == this.props.changedData.paymentSide + this.props.changedData.paymentMethod ? (
                <PaymentIcon
                  id={this.props.changedData.cardInfo.brand}
                  className="dgieh"
                />
              ) : (
                <img className="dgieh" src={this.getImageOfPaymentMeyhod(this.props.changedData.paymentSide + this.props.changedData.paymentMethod)} />
              )}

              <span className="pfkwuhh"> {"SenderCard" == this.props.changedData.paymentSide + this.props.changedData.paymentMethod ? `**** ${this.props.changedData.cardInfo.lastNumbers}` : <Trans i18nKey={this.props.changedData.paymentSide + this.props.changedData.paymentMethod}></Trans>} </span>
              <img className={`fowiaaroow ${this.state.showpoperSide && "rotate180"}`} src={arrow} />

            </div>
          ) : (
            <div className="opdrtmff">

            {"SenderCard" == this.props.Data.paymentSide + this.props.Data.paymentMethod ? (
              <PaymentIcon
                id={this.props.Data.cardInfo.brand}
                className="dgieh"
              />
            ) : (
              <img className="dgieh" src={this.getImageOfPaymentMeyhod(this.props.Data.paymentSide + this.props.Data.paymentMethod)} />
            )}

            <span className="pfkwuhh"> {"SenderCard" == this.props.Data.paymentSide + this.props.Data.paymentMethod ? `**** ${this.props.Data.cardInfo.lastNumbers}` : <Trans i18nKey={this.props.Data.paymentSide + this.props.Data.paymentMethod}></Trans>} </span>
            <img className={`fowiaaroow ${this.state.showpoperSide && "rotate180"}`} src={arrow} />

          </div>
          )

          }


          <key>
            <Trans i18nKey={"paymentmethod"}> </Trans>
          </key>

        </paymentMethodContainerNewOrder>




        {/* <clickableservice
          className={`clickableChangeMethod marginR10 EditPriceChangeMethod  ${
            this.WalletCheck() == "green"
              ? "SenderWallet"
              : this.WalletCheck() == "red"
              ? "SenderWalletred"
              : ""
          }  ${this.WalletCheck() == "red" ? "clickableserviceRed" : ""}
        `}
          style={{
            marginRight: "0px",
          }}
          ref={this.paymentSide}
          id={this.props.id}
          onClick={() => this.handleshowpoperPaymentSide(true)}
        >
          {
            <img
              src={arrow}
              className={`${this.state.showpoperSide && "rotate180"} ${
                this.WalletCheck() == "red" ? "mrt10" : ""
              }`}
            />
          }
          <span
            className={`paymentmethodspan ${
              this.props.paymentSide + this.props.paymentMethod !=
              "SenderWallet"
                ? "paymentmethodspanoedited"
                : this.WalletCheck() != "red"
                ? "paymentmethodspanoedited"
                : ""
            }`}
          >
            {" "}
            <Trans i18nKey={ payment ? payment.paymentSide + payment.paymentMethod : this.props.paymentSide + this.props.paymentMethod}>
              {" "}
            </Trans>
          </span>
          {this.props.paymentSide + this.props.paymentMethod ==
          "SenderWallet" ? (
            this.WalletCheck() == "red" ? (
              <span className="lowBalance">
                {" "}
                <Trans i18nKey={"lowBalance"}></Trans>
              </span>
            ) : null
          ) : null}
        </clickableservice> */}

        <Popper
          open={this.state.showpoperSide}
          role={undefined}
          style={{
            zIndex: 6,
            marginTop: "4px",
            border: "solid 1px rgba(128, 128, 128, 0.2)",
            borderRadius: "8px",
            boxShadow: "0 2px 8px 0 rgba(128, 128, 128, 0.24)",
            left: "50%",
            top: `50%`,
            transform: "translate(-50%, -50%)"
          }}
          id="fuckingPopper"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              timeout={{ enter: 150, exit: 150 }}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper className="paymentMethodsPopper">
                <ClickAwayListener
                  onClickAway={() => this.debounceAwayListener(false)}
                >
                  <div>
                    <div className="BalanceContainer">
                      <img src={balance} className="balanceicon" />

                      <span className="BalanceText">
                        {" "}
                        <Trans i18nKey={"Balance"}> </Trans>
                      </span>
                      <span className="BalanceNumberText">
                        {" "}
                        {this.props.balance}
                      </span>
                    </div>

                    <span className="Methods" style={{ marginTop: "10px" }}>
                      {" "}
                      <Trans i18nKey={"Methods"}> </Trans>{" "}
                    </span>

                    <div
                      className={`MethodsItem ${this.state.paymentSide[0].i18 ==
                          (payment ? payment.paymentSide + payment.paymentMethod : this.props.paymentSide + this.props.paymentMethod)
                          ? "MethodsItemActive"
                          : ""
                        } ${this.WalletCheck(this.state.paymentSide[0].data) ==
                        "red" && "redborder"
                        }`}
                      style={{
                        cursor:
                          this.WalletCheck(this.state.paymentSide[0].data) ==
                            "red"
                            ? "not-allowed"
                            : "pointer",
                      }}
                      onClick={() =>
                        this.WalletCheck(this.state.paymentSide[0].data) !==
                        "red" &&
                        this.handleSelectedItem(this.state.paymentSide[0])
                      }
                    >
                      <img src={SenderWalletic} />
                      <span>
                        {" "}
                        <Trans i18nKey={"CustomerWallet"}> </Trans>{" "}
                      </span>
                    </div>

                    {/* {this.WalletCheck(this.state.paymentSide[0].data) ==
                    "red" ? (
                      <span className="balanceError">
                        {" "}
                        <Trans i18nKey={"balanceError"}> </Trans>{" "}
                      </span>
                    ) : null} */}

                    {this.props.serviceSettings.isCashBySenderActive && (
                      <div
                        className={`MethodsItem ${this.state.paymentSide[1].i18 ==
                            (payment ? payment.paymentSide + payment.paymentMethod : this.props.paymentSide + this.props.paymentMethod)
                            ? "MethodsItemActive"
                            : ""
                          }`}
                        onClick={() =>
                          this.handleSelectedItem(this.state.paymentSide[1])
                        }
                      >
                        <img src={SenderCashic} />
                        <span>
                          {" "}
                          <Trans i18nKey={"SenderCash"}> </Trans>{" "}
                        </span>
                      </div>
                    )}

                    {this.props.serviceSettings.enableReceiverCash && (
                      <div
                        className={`MethodsItem ${this.state.paymentSide[2].i18 ==
                            (payment ? payment.paymentSide + payment.paymentMethod : this.props.paymentSide + this.props.paymentMethod)
                            ? "MethodsItemActive"
                            : ""
                          }`}
                        onClick={() =>
                          this.handleSelectedItem(this.state.paymentSide[2])
                        }
                      >
                        <img src={ReceiverCashic} />
                        <span>
                          {" "}
                          <Trans i18nKey={"ReceiverCash"}> </Trans>{" "}
                        </span>
                      </div>
                    )}

                    {this.state.cards.map((card) => {
                      return <div
                        className={`MethodsItem ${("SenderCard" ==
                          this.props.paymentSide + this.props.paymentMethod
                        ) && this.props.paymentMethodId == card.id ? "MethodsItemActive"
                          : ""
                          }`}
                        onClick={() =>
                          this.handleSelectedItemStripe(card)
                        }

                      >
                        <PaymentIcon
                          id={card.brand}
                          style={{ height: '16px' }}
                        />
                        <span>
                          {`**** ${card.lastNumbers}`}
                        </span>
                      </div>
                    })}

                   {(get_settings_dispatcher().stripePublicApiKey && get_settings_dispatcher().stripePublicApiKey.length > 0 && this.props.customerId) &&  <Stripe_Add_Card onClose={this.getCards} />}
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  Data: state.ViewTravel.pureData ? state.ViewTravel.pureData : false,
  customerId: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.customer
      ? state.ViewTravel.pureData.customerId
      : null
    : null,
  paymentMethod: ownProps.changedData.changed ? ownProps.changedData.paymentMethod : ownProps.Data.paymentMethod,
  paymentSide:ownProps.changedData.changed ? ownProps.changedData.paymentSide : ownProps.Data.paymentSide,
  paymentMethodId: ownProps.changedData.changed ? ownProps.changedData.paymentMethodId :ownProps.Data.paymentMethodId ,

  cardInfo: ownProps.changedData.cardInfo,
  direction: state.LocalData.direction,
  serviceSettings: state.ViewTravel.pureData.service.setting
    ? state.ViewTravel.pureData.service.setting
    : { enableReceiverCash: false, isCashBySenderActive: false },
  price:   state.ViewTravel.pureData.price/* ownProps.fee */,
  balance: state.ViewTravel.pureData.customer.balance,
  minimumBalance: state.ViewTravel.pureData.customer.minimumBalance,
  customerType:state.ViewTravel.pureData ? state.ViewTravel.pureData.customerType : "individual",
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
