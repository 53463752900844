import { useState } from 'react';
import styles from './style.module.css';
import Collapse from "@material-ui/core/Collapse";
import icPin from "../../../../../assets/ic-pin.svg";
import iconArrowTriangleUp from "../../../../../assets/icon-arrow-triangle-up.svg";
import icArrowDown from "../../../../../assets/ic-arrow-down.svg";
import { OrderListItem } from "../../../../shared/order-Item";
import { useGetFiltersList } from '../../../../../hooks/useGetFiltersList';
import { useCategoryData } from '../../../../../hooks/useCategoryData';
import { useEffect } from 'react';
import { getCategoryList } from './action/getCategoryList';
import { useTranslation } from 'react-i18next';
import { useOrderListCanShowMore } from '../../../../../hooks/useOrderListCanShowMore';
import { useDispatch, useSelector } from 'react-redux';
import { DecreaseStatsCategoryNumber, IncreaseStatsCategoryNumber, ResetCategoryOrderListData, SetCategoryCollapse, SetCategoryOrderListData, SetCategoryOrderListLoading, SetSearchOrderListRegexLoading, SetSearchOrderListRegexRequested } from '../../../../../Redux/actions/actionNames';
import { useGetSearchListFilter } from '../../../../../hooks/useGetSearchListFilter';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { CircularProgress, Grow } from '@material-ui/core';
import { OrderApi } from '../../../../../service/API/order';
import { GetCategoryListData } from '../../../../../hooks/GetCategoryListData';
import { setCategoryOrderListData } from '../../../../../Redux/actions/actionTypes';


export const CategoryCollapseList = ({ categoryName, stats, icon, openDefault, canPinOrder, canUnPinOrder }) => {
    // const [openCollapse, setOpenCollapse] = useState(openDefault);
    const [loadmoreLoading, setLoadmoreLoading] = useState(false);
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    let { cursor, cursorSort, DateRange, selectedServices, selectedStatus, selectedVehicleType, isScheduled, changed, zones } = useGetFiltersList()
    let { regexText } = useGetSearchListFilter()
    let { loading  } = useCategoryData({ cursor, cursorSort, categoryName })
    let { data ,categoryIsEmpty} = GetCategoryListData({ cursor, cursorSort, categoryName })
    let { canShowMore } = useOrderListCanShowMore({ categoryName, data })
    const openCollapse = useSelector((state) => state.OrderList.list[categoryName].openCollapse)
    const categories = useSelector((state) => state.OrderList.categories)
    const ActiveOrderType = useSelector((state) => state.OrderList.ActiveOrderType)
    
    function setOpenCollapse(bool) {
        dispatch(SetCategoryCollapse(categoryName ,bool))
      }
  
    useEffect(() => {
        console.log("fetsfcvs ",categoryName , stats[categoryName])
        if (stats[categoryName] > 0 && regexText.length == 0 && !loading && openCollapse && categoryIsEmpty ) {
            getCategoryList(categoryName, null, null, {  })
        }
    }, [openCollapse, stats[categoryName] , regexText, cursor, cursorSort, changed, regexText, DateRange, zones, selectedServices, selectedStatus, selectedVehicleType, isScheduled])


    useEffect(() => {
        if (!openCollapse && stats[categoryName] == 0) {
            dispatch(SetSearchOrderListRegexLoading(false))
            dispatch(SetSearchOrderListRegexRequested(true))
        }
    }, [openCollapse, stats])


    useEffect(() => {
        if (!openCollapse && stats[categoryName] > 0) {
            dispatch(ResetCategoryOrderListData(categoryName))
        }
    }, [openCollapse, stats])


    useEffect(() => {       
        

        if (stats[categoryName] > 0  && categoryIsEmpty && openCollapse && data.length > 0) {

            getCategoryList(categoryName, null, null, { })
        }
    }, [ActiveOrderType, stats])

    useEffect(() => {       
        

        if (stats[categoryName] > 0  ) {

            getCategoryList(categoryName, null, false, { })
        }
    }, [stats[categoryName] ])

    useEffect(() => {
        if(loadmoreLoading){
            setLoadmoreLoading(false)        
        }

    }, [data])

    const openCloseCollapse = () => {
        if (openCollapse) {
            setOpenCollapse(false)
            setTimeout(() => {
                dispatch(SetCategoryOrderListData({
                    key: categoryName, loadMore: false, data: {},
                    dataLength: data.length,
                    startCursor: null,
                    endCursor: null,
                    nextCursor: null
                }))
                dispatch(SetCategoryOrderListLoading({ key: categoryName, loading: false, error: false }))
            }, 500);
        } else {
            setOpenCollapse(true)
        }

    }

    const loadMore = () => {
        setLoadmoreLoading(true)        
        getCategoryList(categoryName, data, true)
    }

    const OnPin = (id) => {
        let Order = new OrderApi()
        Order.Pin(id)
        dispatch(IncreaseStatsCategoryNumber("Pinned"))

    }


    const OnUnPin = (id) => {
        let Order = new OrderApi()
        Order.UnPin(id)
        dispatch(DecreaseStatsCategoryNumber("Pinned"))

    }

    console.log("daaadada ",cursor, cursorSort, categoryName ,data)

    if (categories.indexOf(categoryName) == -1 || stats[categoryName] == 0 || String(regexText).length > 0) {
        return null
    } else {
        return  <div className={styles.categoryCollapseList} id={categoryName} key={categoryName}>
        <div className={styles.headerCategoryCollapseList} onClick={openCloseCollapse}>
            <img src={icon} className={styles.CollapseIcon} />
            <span className={[styles.CollapseTitle, styles[`${categoryName}CategoryTitle`]].join(' ')}>{categoryName}</span>
            <img src={iconArrowTriangleUp} className={styles.CategoryCollapseIcon} style={{ transform: !openCollapse && "rotate(180deg)" }} />
        </div>
        <Collapse
            className={styles.CategoryCollapse}
            in={openCollapse /* && data.length > 0 */}
            timeout={350}
            unmountOnExit
        >
            {data.map((item, i) => {
                return <OrderListItem lastItem={i == data.length - 1} canPinOrder={canPinOrder} canUnPinOrder={canUnPinOrder} OnPin={() => { OnPin(item.id) }} OnUnPin={() => { OnUnPin(item.id) }} data={item} categoryName={categoryName} key={i} showDelayLate={true} />
            })}


            {loadmoreLoading  && <div className={styles.looadmoreContainer} >
                <CircularProgress size={18} className={styles.CircularProgress} /></div>

            }

            {(canShowMore && !loadmoreLoading )&& <div className={styles.looadmoreContainer} onClick={loadMore}>
                    <span className={styles.looadmoreText}>
                        {t("loadMore")}
                    </span>
                    <img className={styles.looadmoreIcon} src={icArrowDown} alt="" />
                </div>}


           {/* {canShowMore &&  <Grow
                in={canShowMore && !loading}
                timeout={{ enter: 300, exit: 300 }}

            ><div className={styles.looadmoreContainer} onClick={loadMore}>
                    <span className={styles.looadmoreText}>
                        {t("loadMore")}
                    </span>
                    <img className={styles.looadmoreIcon} src={icArrowDown} alt="" />
                </div>
            </Grow>} */}



        </Collapse>
    </div>
    }

};




