import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";


const init = {
  openAddressBookInMenu: false,
  openEditAddressBookInMenu: false,
  openAddAddressBookInMenu: false,
  openAddressBookInNewOrder: false,
  sourceOfAddressBookInNewOrder: null,
  openNewAddressBookInNewOrder: false,
  sourceOfNewAddressBookInNewOrder: null,
  NewAddressBookInNewOrderDetails: {
    title: "",
    code: "",
    address: "",
    adresss_loc: null,
    block: "",
    floor: "",
    unit: "",
    FullName: "",
    PhoneNumber: "",
  },
  EditAddressBookInMenuDetails: {
    title: "",
    id: 0,
    address: "",
    adresss_loc: null,
    block: "",
    floor: "",
    unit: "",
    FullName: "",
    PhoneNumber: "",
  },
  list: [],
  listLoading: false ,
  listError: false,
  perpage:20,
  page:1,
  searchRegex:"",
  defaultPickup: {},
};
export const Addressbook = (state = init, action) => {
  switch (action.type) {
    case actionTypes.setopenAddressBookInNewOrder:
      return {
        ...state,
        openAddressBookInNewOrder: action.state,
        sourceOfAddressBookInNewOrder: {
          type: action.Stype,
          id: action.id,
          i18: action.i18,
        },
      };

    case actionTypes.setsourceOfAddressBookInNewOrder:
      return {
        ...state,
        sourceOfAddressBookInNewOrder: action.state,
      };



      case actionTypes.SetAddressBookListData:
        return {
          ...state,
          ...action.state,
          list:( action.state.hasOwnProperty('update') && action.state.update ) ? [...state.list , ...action.state.list] : action.state.list || state.list
        };

        case actionTypes.SetAddressBookSearchRegex:
          return {
            ...state,
            searchRegex:action.state
          };
  



              
       
                 

  
    default:
      return state;
  }
};
