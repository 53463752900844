import * as actionTypes from "../actions/actionTypes";

let sampleDropOff = {
  address: "",
  adresss_loc: { lng: "", lat: "" },
  block: "",
  floor: "",
  unit: "",
  email:"",
  recieverFullName: "",
  recieverPhoneNumber: "",
  scheduleDateBefore:null,
  scheduleDateAfter:null,
};

const init = {
  OpenDialoge: false,
  forceCalculateCost : false ,
  list: [],
  operatorChanged: false,
  paymentMethod: {
    paymentMethod: "",
    paymentSide: "",
  },
  baseFarePrice:"",
  
  editOrder: {
    id: null,
    price: {
      loading: false,
      price: null,
      done: false,
      operatorSubmit: false,
    },
    editOrder: {
      loading: false,
      canSaveOrder :false
    },
    suggestListPickUpDropOffLoading: false,
    suggestListPickUpDropOffOrdered: false,
    suggestPickUpDropOffListData: [],
    customer: {
      person: true,
      BusinessName: "",
      BusinessNumber: "",
      BusinessId: "",
      avatarPhoto: "",
      name: "",
      number: "",
      id: "",
      balance: "",
    },
    service: {
      serviceVehicleTypes: [],
      activeVehicleType: null,
      loading: false,
      options: [],
    },
    driver: {
      auto: true,
      options: [],
      auto_driver: {
        auto_activeVehicleType: null,
        auto_suggestListLoading: true,
        auto_suggestListData: [],
        auto_suggestListOrdered: false,
      },
      manual_driver: {
        manual_activeVehicleType: null,
        manual_name: "",
        manual_family: "",
        manual_number: "",
        manual_id: "",
        manual_code: "",
        manual_suggestListLoading: false,
        manual_suggestListData: [],
        manual_suggestListOrdered: false,
      },
    },
    activePickupDropOff: {
      type: null,
      id: "",
    },
    pickup: {
      address: "",
      placeId: "",
      adresss_loc: { lng: "", lat: "" },
      block: "",
      floor: "",
      unit: "",
      senderFullName: "",
      senderPhoneNumber: "",
      noteForDriver: "",
      list: [],
      loading: false,
      scheduleDateBefore:null,
      scheduleDateAfter:null,
    },
    dropOffs: {
      firstDropOff: {
        address: "",
        adresss_loc: { lng: "", lat: "" },
        block: "",
        floor: "",
        unit: "",
        recieverFullName: "",
        recieverPhoneNumber: "",
        noteForDriver: "",
        scheduleDateBefore:null,
        scheduleDateAfter:null,
      },
      otherDropOffs: [],
      list: [],
      loading: false,
    },
  },
};
export const EditOrder = (state = init, action) => {
  switch (action.type) {
    case actionTypes.OPENCLOSEEDITTRAVEL:
      return {
        ...state,
        OpenDialoge: !state.OpenDialoge,
        editOrder: {
          ...state.editOrder,
          id: action.state ? action.state : state.editOrder.id,
        },
      };

      
      case actionTypes.setEditRequestForceCalculateCost:
        return {
          ...state,
            forceCalculateCost : action.state
        };
  
    case actionTypes.EDITTRAVELSETPRICE:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          price: {
            ...state.editOrder.price,
            price: action.state.price,
            currencyCode: action.state.currencyCode,
          },
        },
      };

    case actionTypes.Edit_travel_set_service_VehicleTypes:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          service: {
            ...state.editOrder.service,
            serviceVehicleTypes: action.state,
          },
        },
      };
    case actionTypes.Edit_travel_set_service_activeVehicleType:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          service: {
            ...state.editOrder.service,
            activeVehicleType: action.state,
            options: [],
          },
        },
      };
    case actionTypes.Edit_travel_add_service_option:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          service: {
            ...state.editOrder.service,
            options: [...state.editOrder.service.options, action.state],
          },
        },
      };
    case actionTypes.Edit_travel_remove_service_option:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          service: {
            ...state.editOrder.service,
            options: state.editOrder.service.options.filter(
              (item) => item.id !== action.state
            ),
          },
        },
      };
    case actionTypes.Edit_Travel_Set_Customer:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          customer: action.state,
        },
      };
    case actionTypes.set_driver_auto_vehicleTypes:
      if(action.state.length > 0){
        return {
          ...state,
          editOrder: {
            ...state.editOrder,
            driver: {
              ...state.editOrder.driver,
              auto_driver: {
                ...state.editOrder.driver.auto_driver,
                auto_activeVehicleType: state.editOrder.driver.auto_driver
                  .auto_activeVehicleType
                  ? state.editOrder.driver.auto_driver.auto_activeVehicleType
                  : action.state[0],
                auto_suggestListLoading: false,
                auto_suggestListData: action.state,
                auto_suggestListOrdered: true,
              },
            },
          },
        };
      }else{
        return {
          ...state,
          editOrder: {
            ...state.editOrder,
            driver: {
              ...state.editOrder.driver,
              auto_driver: {
                ...state.editOrder.driver.auto_driver,
                auto_activeVehicleType: null,
                auto_suggestListLoading: false,
                auto_suggestListData: [],
                auto_suggestListOrdered: true,
              },
            },
          },
        };
      }

    case actionTypes.Edit_Travel_Set_Driver_Auto:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          driver: {
            ...state.editOrder.driver,
            auto: action.state,
          },
        },
      };
    case actionTypes.Edit_Travel_Set_Manual_Driver:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          driver: {
            ...state.editOrder.driver,
            manual_driver: {
              ...state.editOrder.driver.manual_driver,
              ...action.state,
            },
          },
        },
      };

    case actionTypes.Edit_travel_set_activePickupDropOff:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          activePickupDropOff:
            state.editOrder.activePickupDropOff.id === action.state.id
              ? {
                  type: null,
                  id: "",
                }
              : action.state,
        },
      };

    case actionTypes.Edit_Travel_Set_Manual_ActiveVehicleType:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          driver: {
            ...state.editOrder.driver,
            manual_driver: {
              ...state.editOrder.driver.manual_driver,
              ...action.state,
            },
          },
        },
      };

    case actionTypes.Edit_Travel_SetAutoActiveVehicleType:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          driver: {
            ...state.editOrder.driver,
            auto_driver: {
              ...state.editOrder.driver.auto_driver,
              auto_activeVehicleType: action.state,
            },
          },
        },
      };
    case actionTypes.Edit_Travel_Set_Auto_Driver:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          driver: {
            ...state.editOrder.driver,
            auto_driver: {
              ...state.editOrder.driver.auto_driver,
              ...action.state,
            },
          },
        },
      };

    case actionTypes.EditDriverAddDriverOption:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          driver: {
            ...state.editOrder.driver,
            options: [...state.editOrder.driver.options, action.state],
          },
        },
      };
    case actionTypes.EditDriverRemoveDriverOption:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          driver: {
            ...state.editOrder.driver,
            options: state.editOrder.driver.options.filter(
              (item) => item.id !== action.state
            ),
          },
        },
      };

    case actionTypes.EditDriverRemoveDriverOption:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          driver: {
            ...state.editOrder.driver,
            options: state.editOrder.driver.options.filter(
              (item) => item.id !== action.state
            ),
          },
        },
      };

    case actionTypes.EditTravelresetdriveroptions:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          driver: {
            ...state.editOrder.driver,
            options: [],
          },
        },
      };
    case actionTypes.EditTravelSetPickup:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          pickup: {
            ...state.editOrder.pickup,
            ...action.state,
          },
        },
      };
    case actionTypes.EditTravelSetDropoffs:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          dropOffs: {
            ...state.editOrder.dropOffs,
            firstDropOff : {...sampleDropOff,...action.state.firstDropOff},
            otherDropOffs : action.state.otherDropOffs.map((item)=>{
              return {...sampleDropOff,...item}
            })
          },
        },
      };
    case actionTypes.EditTravelsetpickupinputs:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          pickup: {
            ...state.editOrder.pickup,
            [action.state.name]: action.state.value,
          },
        },
      };
    case actionTypes.EditTravelset_pickup:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          pickup: {
            ...state.editOrder.pickup,
            ...action.state,
          },
        },
      };

    case actionTypes.EditTravelsetDropoffinputs:
      if (action.state.firstDropOff) {
        return {
          ...state,
          editOrder: {
            ...state.editOrder,
            dropOffs: {
              ...state.editOrder.dropOffs,
              firstDropOff: {
                ...sampleDropOff,
                ...state.editOrder.dropOffs.firstDropOff,
                [action.state.name]: action.state.value,
              },
            },
          },
        };
      } else {
        return {
          ...state,
          editOrder: {
            ...state.editOrder,
            dropOffs: {
              ...state.editOrder.dropOffs,
              otherDropOffs: state.editOrder.dropOffs.otherDropOffs.map(
                (DropOff) =>
                  DropOff.id === action.state.id
                    ? {
                        ...DropOff,
                        [action.state.name]: action.state.value,
                      }
                    : DropOff
              ),
            },
          },
        };
      }

    case actionTypes.set_SearchAutoComplete:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          suggestListPickUpDropOffLoading: false,
          suggestPickUpDropOffListData: action.state,
        },
      };
    case actionTypes.set_SearchAutoCompleteLoading:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
            suggestListPickUpDropOffLoading: action.state,
            suggestListPickUpDropOffOrdered:
              !state.editOrder.suggestListPickUpDropOffOrdered && true,
        },
      };

    case actionTypes.EditTraveladd_dropoff:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          dropOffs: {
            ...state.editOrder.dropOffs,
            otherDropOffs: [
              ...state.editOrder.dropOffs.otherDropOffs,
              { ...sampleDropOff, id: action.state ,newDropOff:true },
            ],
          },
        },
      };
    case actionTypes.EditTravelremove_dropoff:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          activePickupDropOff:
            state.editOrder.activePickupDropOff.id === action.state
              ? {
                  type: null,
                  id: "",
                }
              : state.editOrder.activePickupDropOff,
          dropOffs: {
            ...state.editOrder.dropOffs,
            otherDropOffs: state.editOrder.dropOffs.otherDropOffs.filter(
              (drop) => drop.id !== action.state
            ),
          },
        },
      };
    case actionTypes.EditTravelSetPrice:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          price: {
            ...state.editOrder.price,
            price: action.state,
            operatorSubmit: action.opertarSubmit,
          },
        },
      };

    case actionTypes.setpriceneworder:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          price: {
            ...state.editOrder.price,
            ...action.state2,
          },
        },
      };
    case actionTypes.EditTravelOperateChanged:
      return {
        ...state,
        operatorChanged: true,
      };

    case actionTypes.EditTravelReset:
      return {
        ...init,
      };
    case actionTypes.EditTravelPaymentMethod:
      return {
        ...state,
        paymentMethod: { ...state.paymentMethod, ...action.state },
      };
    case actionTypes.Edit_Travel_SetCanSaveTravel:
      return {
        ...state,
        editOrder: {
          ...state.editOrder,
          editOrder: {
            ...state.editOrder.editOrder,
            loading: action.state,
          },
        },
      };
      case actionTypes.setCanSaveEditOrder:
        return {
          ...state,
          editOrder: {
            ...state.editOrder,
            editOrder: {
              ...state.editOrder.editOrder,
              canSaveOrder :action.state,
            },
          },
        };
      
      case actionTypes.feeChange:
        return {
          ...state ,
          baseFarePrice:action.state
        };
        case actionTypes.surcharge:
          return {
            ...state ,
            surcharges:action.state
          };
        case actionTypes.surchargeChange:
          return {
            ...state ,
            surcharges: state.surcharges.map((item)=>{
              return item.id == action.state.id ? {...action.state} : item
            })
          };

          case actionTypes.editTravelPickupSetReserve:
            return {
              ...state,
              editOrder: {
                ...state.editOrder,
                pickup: {
                  ...state.editOrder.pickup,
                  [action.state.name]: action.state.value,
                },
              },
            };
      
          case actionTypes.editTravelDropOffSetReserve:
            if (action.state.firstDropOff) {
              return {
                ...state,
                editOrder: {
                  ...state.editOrder,
                  dropOffs: {
                    ...state.editOrder.dropOffs,
                    firstDropOff: {
                      ...state.editOrder.dropOffs.firstDropOff,
                      [action.state.name]: action.state.value,
                    },
                  },
                },
              };
            } else {
              return {
                ...state,
                editOrder: {
                  ...state.editOrder,
                  dropOffs: {
                    ...state.editOrder.dropOffs,
                    otherDropOffs: state.editOrder.dropOffs.otherDropOffs.map(
                      (DropOff) =>
                        DropOff.id === action.state.id
                          ? {
                              ...DropOff,
                              [action.state.name]: action.state.value,
                            }
                          : DropOff
                    ),
                  },
                },
              };
            }

            
            case actionTypes.set_DropOff_fields_Dnd_EditAddress:
              if (action.state.firstDropOff) {
                return {
                  ...state,
                  editOrder: {
                    ...state.editOrder,
                    dropOffs: {
                      ...state.editOrder.dropOffs,
                      firstDropOff: {
                        ...state.editOrder.dropOffs.firstDropOff,
                        ...action.state.value,
                      },
                    },
                  },
                };
              } else {
                return { 
                  ...state, 
                  editOrder: {
                    ...state.editOrder,
                    dropOffs: {
                      ...state.editOrder.dropOffs,
                      otherDropOffs: state.editOrder.dropOffs.otherDropOffs.map(
                        (DropOff,i) =>
                        i === action.state.index ? {...action.state.value}
                        : DropOff
                      ),
                    },
                  },
               }
              }

    default:
      return state;
  }
};
