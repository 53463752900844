export const PerssianLabel = {
    dispatcher: "اپراتور",
    customer: "موبایل",
    business: "تجاری",
    otherdropoffs: "مقصد دیگر",
    Enter_the_following_information_to_enter: "برای ورود اطلاعات زیر را وارد کنید",
    username: "نام کاربری",
    password: "رمزعبور",
    rememberme: "مرا به خاطر بسپار",
    login: "ورود",
    Neworder: "درخواست جدید",
    SendOrder: "ذخیره تغییرات",
    EditOrder: "ویرایش درخواست",
    Firstname: "نام",
    Lastname: "نام خانوادگی",
    Phonenumber: "شماره تلفن",
    Passenger: "مشتری",
    Service: "بسته",
    Pickup: "مبدا",
    address: "آدرس",
    Floor: "طبقه",
    Unit: "واحد",
    notesfordriver: "یادداشت برای راننده",
    DropOff: "مقصد",
    Adddropoff: "اضافه کردن مقصد",
    driver: "راننده",
    Auto: "اتوماتیک",
    Manual: "دستی",
    code: "کد",
    Fname: "نام",
    Lname: "نام خانوادگی",
    Pnumber: "شماره تلفن",
    poperLoading: "لطفا صبر کنید",
    fetchingdata: "درحال دریافت اطلاعات...",
    Noinformationfound: "اطلاعاتی یافت نشد",
    Pleasetryanotherkeyword: "لطفا کلید واژه دیگری را امتحان کنید",
    noorder: "بدون درخواست",
    Pending: "در انتظار",
    Unassigned: "واگذار نشده",
    Assigned: "در حال انجام",
    PickedUp: "در حال انجام",
    Started: "در حال انجام",
    statusInProgress: "در حال انجام",
    statusCanceled: "لغو",
    Done: "اتمام",
    CustomerCanceled: "لغو ",
    DriverCanceled: "لغو ",
    SupportCanceled: "لغو ",
    Arrived: "در حال انجام",
    Close: "بستن",
    Orders: "درخواست ها",
    pleaseSellectParsel: "لطفا نوع بسته را انتخاب کنید",
    Createdsuccessfully: "با موفقیت ایجاد شد",
    new: "جدید",
    Order: "درخواست",
    Details: "جزئیات",
    History: "تاریخچه",
    Balance: "اعتبار",
    Code: "کد",
    Username: "نام کاربری",
    Phone: "شماره تلفن",
    Price: "قیمت",
    paymentmethod: "شیوه پرداخت",
    Receiver: "توسط گیرنده",
    Sender: "توسط فرستنده",
    Balance: "اعتبار",
    Wallet: "اعتبار",
    Cash: "نقد",
    Copylink: "کپی کردن لینک",
    Edit: "ویرایش",
    EditPrice: "قیمت و درآمد",
    EditPrice2: "قیمت",
    EditEarning: "درآمد",
    Endtrip: "اتمام درخواست",
    Cancel: "لغو درخواست",
    Estime: "زمان تخمینی",
    Espath: "مسافت تخمینی",
    Promocode: "کد تخفیف",
    orderInfoError: "خطا در دریافت اطلاعات",
    Editorder: "ویرایش درخواست",
    Updatedsuccessfully: "با موفقیت ویرایش شد",
    monitoring: "مانیتورینگ",
    Estimated: "تخمینی",
    Search: "جست و جو",

    all: "همه",
    statusPending: "در انتظار",
    statusUnassigned: "واگذار نشده",
    statusAssigned: "واگذار شده",
    statusStarted: "در حال انجام",
    "statusPickedUp": "برداشت",
    statusDone: "انجام شده",
    statusCustomerCanceled: "لغو توسط مشتری",
    statusDriverCanceled: "لغو توسط راننده",
    statusArrived: "رسیدن به مبدا",
    statusSupportCanceled: "لغو توسط پشتیبانی",

    Canceltravel: "لغو درخواست؟",
    CancellText: "بعد از لغو، درخواست برای راننده و مشتری لغو می شود ",
    Cancel: "بیخیال",
    confirmCancel: "بله ,لغو درخواست",

    Endtravel: "اتمام درخواست؟",
    EndlText: "بعد از اتمام، درخواست برای مشتری و راننده با موفقیت به اتمام رسیده و مبلغ از حساب مشتری کسر می شود.",
    End: "بیخیال",
    confirmEnd: "بله ,اتمام درخواست",

    Noaddressavailable: "آدرسی یافت نشد",
    Managefavoriteaddresses: "مدیریت آدرس ها",
    Addresses: "آدرس ها",
    Title: "عنوان",
    Address: "آدرس",
    "Block/Building": "بلوک / ساختمان",
    ContactFullname: "نام کامل مخاطب",
    Contactphonenumber: "شماره تماس مخاطب",
    Saveaddress: "ذخیره آدرس",
    Editaddress: "ویرایش آدرس",
    Savedaddresses: "آدرس های ذخیره شده",
    Saved: "ذخیره شده",
    Pickedupat: "انجام شده در ساعت   ",
    Deliveredat: "تحویل در ساعت  ",
    pod: "اثبات تحویل",
    Notes: "یادداشت",
    Photo: "عکس",
    Signature: "امضا",
    Pricedetails: "جزئیات قیمت",
    Fee: "هزینه",
    Commission: "کمیسیون",
    surcharge: "هزینه اضافی ",
    Nonotes: "بدون یادداشت",
    Nosignature: "بدون امضا",
    Nophoto: "بدون عکس",
    Fornow: "برای الان",
    now:"اکنون",
    Scheduled: "رزرو",
    before: "قبل از :",
    after: "بعد از :",
    confirm: "تایید",
    Settime: "انتخاب زمان",
    for: "برای ",
    Logout: "خروج",
    Language: "زبان",
    en: "انگلیسی",
    fa: "فارسی",
    fr: "فرانسوی",

    logotTitle: "خروج از حساب کاربری؟",
    logoutDescription: " برای ورود مجدد باید با استفاده از نام کاربری و رمز عبور خود اقدام کنید",
    confirmLogout: "بله، خروج",
    skipLogout: "لغو",
    English: "انگلیسی",
    Persian: "فارسی",
    French: "فرانسوی",
    Receipt: "رسید",
    Person: "شخصی",
    Business: "تجاری",
    Businessname: "نام کسب و کار",
    phonenumber: "شماره تلفن",
    SenderWallet: "کیف پول",
    SenderCash: "نقدی",
    ReceiverCash: "پرداخت توسط گیرنده",
    Haveorder: "دارای درخواست",
    StartDate: "تاریخ شروع",
    EndDate: "تاریخ پایان",
    Today: "امروز",
    Yesterday: "دیروز",
    ThisWeek: "هفته جاری",
    LastWeek: "هفته قبل",
    Last7Days: "7 روز گذشته",
    ThisMonth: "ماه جاری",
    LastMonth: "ماه گذشته",
    Last3Month:"3 ماه گذشته",
    filter: "فیلتر",
    AllVehicletypes: "همه ی سرویس ها",
    AllServices: "همه بسته ها",
    SelectDateRange: "بازه ی تاریخ",
    clear: "پاک کردن",
    status: "وضعیت",
    Vehicletype: "نوع وسیله نقلیه",
    Service: "سرویس",
    clear: "پاک کردن",
    repeatOrder: "تکرار درخواست",
    Shipmentlabel:"برچسب حمل و نقل",
    noVehicleType: "سرویس تایپی برای نمایش وجود ندارد",
    AllKey: "همه",
    MeKey: "من",
    regexcode: "کد درخواست",
    regexcustomerPhone: "شماره مشتری",
    regexcustomerName: "نام مشتری",
    regexdriverPhone: "شماره راننده",
    regexdriverName: "نام راننده",
    regexdriverUsername: "نام کاربری راننده",
    ascending: "صعودی",
    descending: "نزولی",
    "sort[createdAt]": "تاریخ",
    "sort[pickupScheduleDate]": "تاریخ رزرو",
    "sort[statusUpdatedAt]": "آخرین بروزرسانی",
    pickupDescription: "جزئیات مبدا",
    Pickupnote:"نت وانت",
    km: "کیلومتر",
    Drivers: "راننده ها",
    Orders: "درخواست ها",
    Online: "آنلاین",
    Offline: "آفلاین",
    Info: "اطلاعات",
    email: "ایمیل",
    vehicletypes: "سرویس تایپ ها",
    loginstatus: "وضعیت ورود",
    lastonlinetime: "آخرین زمان آنلاین",
    haslogined: "لاگین شده",
    Notloggedin: "لاگین نشده",
    enterDataCorrectly: "اطلاعات را به درستی وارد کنید",
    controlThatbeforeLoweEqualAfter: `زمان  "قبل از :" نمیتواند کوچیکتر از  "بعد از :" باشد`,
    Schedule: "رزرو",
    Date: "تاریخ",
    Time: "زمان",
    noDateToShow: "تاریخی برای نمایش وجود ندارد",
    noTimeToShow: "هیچ زمانی برای نمایش وجود ندارد",
    Apply: "تایید",
    lowBalance: "عدم موجودی کافی",
    balanceError: "موجودی باید حداقل به میزان هزینه درخواست باشد",
    Methods: "روش های پرداخت",

    Addressbook: "دفترچه آدرس",
    AddressbookSearchbar: "جستجو بر اساس آدرس، عنوان و شناسه",
    AddressbookDropOf: "کتاب آدرس (رها کردن)",
    AddressbookPickup: "دفترچه آدرس (وانت)",
    hide: "پنهان شدن",
    Nosavedaddress: "آدرس ذخیره نشده",

    Romanian: "اهل رومانیایی",

    ForceAssign: "اعطای نیرو",
    Allvehicletypes: "تمام انواع خودرو",
    waitforconfirm: "xxx (منتظر تایید)",
    waitforconfirm2: "منتظر تایید",

    Dispatching: "اعزام",
    NoDriver: "بدون راننده",
    OtherDetails: "جزئیات دیگر",
    StatusandDriver: "وضعیت & راننده",
    EditStatusandDriver: "ویرایش وضعیت و راننده",

    Current: "جاری",
    EDAS_Pending: "در انتظار ",
    EDAS_Assigned: "اختصاص داده",
    EDAS_PickedUp: "برداشت",
    EDAS_Unassigned: "غیر رسمی",
    EDAS_Started: "آغاز شده",
    EDAS_Done: "انجام شده",
    EDAS_CustomerCanceled: "مشتری لغو شد",
    EDAS_DriverCanceled: "راننده لغو شد",
    EDAS_SupportCanceled: "پشتیبانی لغو شد",
    EDAS_Arrived: "ظهور",


    Pleasechooseadrivertochangestatus: "لطفا یک راننده را انتخاب کنید تا وضعیت را تغییر دهید ",
    icWarningEdit: "تغییر گزینه ها ممکن است بر قیمت تاثیر بگذارد",

    Vehicletypewillbechanged: "نوع خودرو تغییر خواهد کرد",
    Yourcurrentvehicletypeanditwillbechangedandeffectsonprice: "نوع خودرو فعلی شما تغییر و اثرات بر قیمت",
    Rejected: "رد",
    Rejected2: "(رد شده)",

    DriverVehicleTypeError: "هیچ نوع خودرو برای راننده وجود ندارد",
    CodeNameFamilyPhonenumber: "کد، نام، خانواده، شماره تلفن",
    youcanselectdriverintheedit: "شما می توانید راننده را در درایور ویرایش و وضعیت انتخاب کنید",


    Earning: "درآمد",
    savePrice: "ذخیره قیمت",
    saveEarning: "ذخیره درآمد",
    Total: "جمع",
    Pricedetails: "جزئیات قیمت",
    EditPickupDropOff: "آدرس ها",

    noMoreData: "اطلاعات بیشتری وجود ندارد",
    Dutch:"هلندی",
    Ondemand: "On Demand",
    PD: "P & D",
    PD2: "P&D",
    PickupDropoff: "Pickup , Dropoff",
    PickupFlag: "P",
    DropOffFlag: "D",
    PickupandDelivery: "Pickup and Delivery",
    PickupDelivery: "Pickup and Delivery",
    Delivery: "Delivery",
    ServiceOptions: "Service Options",
    Searchbynameorphonenumber: "Search by name or phone number",
    Fullname: "نام و نام خانوادگی",
    type: "نوع",
    only: "فقط xxx",
    ChooseDriver: "انتخاب راننده",
    Events: "مناسبت ها",
    Collecting: "مجموعه",
    Transit: "ترانزیت",
    Delivering: "تحویل",
    EDAS_Collecting: "مجموعه",
    EDAS_Transit: "ترانزیت",
    EDAS_Delivering: "تحویل",
    statusCollecting: "مجموعه",
    statusTransit: "ترانزیت",
    statusDelivering: "تحویل",
    status2: "وضع",
    Creator: "منبع",
    Creationdate: "تاریخ ایجاد",
    Orderid: "شماره سفارش",
    Pickup2: "سوار کردن",
    Dropoff2: "مقصد",
    senderfullname: "نام فرستنده",
    block: "بلوک",
    senderphonenumber: "شماره فرستنده",
    Recieverfullname: "نام گیرنده",
    Recieverphonenumber: "شماره گیرنده",
    PleaseSelect: " انتخاب کنید",
    CreateEvent: "ایجاد رویداد",
    Description: "شرح",
    detailsAboutEvent: "جزئیات در مورد رویداد",
    CustomerVisibility: "دید مشتری",
    LocationOnMap: "محل روی نقشه" ,


    event_Created:"ایجاد شده",
    event_Unassigned:"غیر رسمی",
    event_Assigned:"اختصاص داده",
    event_Started:"آغاز شده",
    event_Arrived:"بهار",
    event_PickedUp:"پیمانکار",
    event_OrderDone:"سفارش انجام شده",
    event_DropoffDone:"Dropoff انجام شده است",
    event_CustomerFeedback:"بازخورد مشتری",
    event_AddPod:"اضافه کردن غلاف",
    event_CustomerCanceled:"مشتری لغو شد",
    event_DriverCanceled:"راننده لغو شد",
    event_SupportCanceled:"پشتیبانی لغو شد",
    event_Updated:"به روز شده",
    event_DriverAssignment:"تخصیص درایور",
    event_DriverReject:"راننده رد می شود",
    event_Collecting:"جمع آوری",
    event_InTransit:"در حمل و نقل",
    event_Delivering:"تحویل",
    event:"رویداد" ,

    tab_Delivery:"تحویل",
    tab_Ondemand:"بر اساس تقاضا",
    tab_Pickup:"سوار کردن",
    tab_PickupDelivery :"P & D" ,
    CurrentStatus:"وضعیت فعلی",
    Changestatus:"تغییر وضعیت",
    Newstatus:"وضعیت جدید",
    Savechanges:"ذخیره تغییرات",
    Other:"دیگر" ,
    serviceCustomerError1 :"xxx برای این کاربر در دسترس نیست ." ,
    serviceCustomerError2 :"سرویس های xxx برای این کاربر در دسترس است ",
    serviceCustomerError3 :" نوع وسیله نقلیه های xxx برای این کاربر در دسترس است ",
    OrderCode:"کد سفارش" ,


    CopyOrderlink:"کپی لینک درخواست",
    CopyPickuplink:"کپی لینک برداشت" ,
    CopyDeliverylink:"کپی لینک تحویل" ,
    Bulkimport:"واردات فله",
    Downloadsamplecsv:"دانلود نمونه CSV",
    processing:"در حال پردازش" ,
    done :"تمام شده" ,
    failed:"ناموفق",
    Draft:"پیش نویس" ,
    Uploading:"آپلود کردن" ,
    Draganddropyourfile:"فایل خود را بکشید و رها کنید",
    Onlycsvformatissupported:"فقط فرمت .csv پشتیبانی می شود" ,
    ORyoucan:"یا شما می توانید",
    Openfile:"فایل باز" ,
    Noimportsyet:"هنوز وارد نشده است" ,
    NoimportsyetDesc :"شما می توانید لیست واردات خود را در اینجا ببینید و وضعیت خود را بررسی کنید",
    nosupportdialogeTitle:"فرمت فایل پشتیبانی نمی شود",
    nosupportdialogeText : "فایل xxx پشتیبانی نمی شودشما فقط می توانید فایل CSV را از جمله اطلاعات سفارش آپلود کنید" ,
    ok:"خوب" ,
    Import  :"وارد كردن",
    previewTitle :"پیش نمایش و گزینه ها را انتخاب کنید",
    previewDesc:"این اولین مورد از فایل های شما برای بررسی اطلاعات شما در ساختار مناسب است" ,
    Upload:"بارگذاری", 
    Remove:"برداشتن" ,
    ServiceandOrdertype:"نوع خدمات و سفارش" ,
    Fileisproccessing:"فایل پردازش است ...",
    "proccessingDesc":"ما به خوبی به شما اطلاع می دهیم وقتی که انجام می شود",
    serverError:"خطای سرور",
    isRequired:"xxx مورد نیاز است" ,
    Result:"نتیجه",
    ResultText:"شما می توانید مشکلات اینجا را ببینید و این ردیف ها را به طور جداگانه ثابت کنید",
    ErrorDetails:"سفارشات با خطا",
    ErrorDetailsText:"سفارشات با اشتباهات در فایل ذکر شده است که می توانید در زیر دانلود کنید.این شامل جزئیات خطا برای هر ردیف است.",
    DownloadErroredRows:"ردیف های خطا را دانلود کنید" ,
    importedsuccessfully:"xxx سفارش با موفقیت وارد می شوند" ,
    ordershaserrors:"xxx سفارش دارای خطاهای است",
    refresh:"تازه کردن",
    CashOnDelivery:"پرداخت نقدی هنگام تحویل",
    EnterAmount:"مقدار را وارد کنید",
    COD:"پرداخت نقدی هنگام تحویل",
    Surchargexxx:"اضافه کردن xxx",
    AddCreditCard:"افزودن کارت اعتباری",
    CustomerWallet:"کیف پول مشتری ",
    Add:"اضافه کردن",
    Addedsuccessfully: "با موفقیت اضافه شد",
    NoRecentAddress:"آدرس اخیر",
    NoRecentAddressD:"آدرس های اخیر مشتری در اینجا ذکر شده است",
    CustomerIsNotChoosed:"مشتری انتخاب نشده است",
    CustomerIsNotChoosedD:"مشتری را انتخاب کنید تا آدرس اخیر خود را نشان دهد",
    SearchAddress:"آدرس جستجو",
    NoCustomerfound: "هیچ مشتری یافت نشد" ,
    NoCustomerfoundD:"شما می توانید مشتری را ایجاد کنید اگر این یک جدید است",
    NewCustomer : "مشتری جدید",
    Existing:"موجود",
    EmailOtional:"ایمیل (اختیاری)" ,
    phonenumberisnotcorrect:"شماره تلفن درست نیست",
    Emailisnotcorrect:"ایمیل درست نیست" ,
    Registercustomer:"مشتری ثبت نام کنید",
    incoming:"تماس ورودی",
    outgoing:"تماس خروجی",
    recording:"تماس ضبط",
    driveroperatorCallStatus:"راننده به نام اپراتور",
    customeroperatorCallStatus:"مشتری به نام اپراتور",
    newCustomeroperatorCallStatus:"مشتری جدید به نام اپراتور",
    operatordriverCallStatus:"اپراتور به نام درایور",
    operatorcustomerCallStatus:"اپراتور به نام مشتری",
    operatornewCustomerCallStatus:"اپراتور به نام مشتری جدید",
    dispatcher_Voip:"اپراتور",
    driver_Voip:"راننده",
    customer_Voip:"مشتری",
    activeorders:"سفارشات فعال xxx",
    Noactiveorder :"بدون سفارش فعال" ,
    CreateOrder:"سفارش ایجاد کنید",
    call:"تماس گرفتن",
    spanish:"اسپانیایی",
    es : "اسپانیایی",
    networkError:"بدون اتصال به اینترنت",
    createdAt:"ایجاد شده در",
    dueDate:"موعد مقرر",
    Noresultfound:"هیچ نتیجه ای یافت نشد",
    loadMore:"بارگذاری بیشتر",
    Searchresult:"نتیجه جستجو",
    "PinTop":"پین بالا",
    "UnPin":"یک کاج",
    "Itemsonmap":"موارد موجود در نقشه",
    "Orders":"سفارش",
    "Drivers":"راننده",
    "min":"xxx دقیقه" ,
    Confirming:"تأیید کننده",
    delay:"تاخیر",
    late:"دیر",
    OrderDetails:"جزئیات سفارش",
    Profile:"مشخصات",
    Waitingforavailabledrivers:"منتظر در دسترس بودن رانندگان",
    Queue:"صف",
    OfferingToDrivers:"ارائه به رانندگان xxx",
    WaitforAccept:"منتظر پذیرش باشید" ,


    pleaseSellectService: 'لطفا نوع سرویس را انتخاب کنید',
    SendOrderr: 'ارسال سفارش',
    todayat: 'امروز',
    tomorrowat: 'فردا',
    nextWeekat: 'هفته بعد در',
    lastDayat: 'روز گذشته در',
    lastWeekat: 'هفته گذشته در',
    "AssignedAt":"در دقیقه xxx اختصاص دهید",
    "StartedAt":"در دقیقه xxx شروع کنید",
    "ArrivedAt":"در دقیقه xxx وارد شوید",
    "PickedUpAt":"وانت در xxx min",
    "DoneAt":"در دقیقه xxx به پایان برسد",
    mindelay: 'تأخیر xxxm',
    minlate: 'xxxm دیر',
    Thereisnoorder:"هیچ نظمی وجود ندارد",
    mapitems:"موارد رسم",
    Offerings:"پیشنهادات",
    Reject:"رد کردن",
    dropoffCustomerDescription:"یادداشت کردن",
    customerPhoto:"عکس مشتری" ,
    driverPhoto:"عکس راننده" ,
    NocustomerPhoto:"بدون عکس مشتری" ,
    NodriverPhoto:"بدون عکس راننده",
    initial:"اولیه",
    notifying:"ابلاغ",
    received:"اخذ شده",
    assigned:"اختصاص داده",
    rejected:"رد شده",
    canceled:"لغو شد",
    standby:"پیشانی",
    terminated:"خاتمه یافته",
    urgentTitle:"تغییر در اعزام فوری؟",
    urgentDesc:"با فعال کردن فوری ، سفارش در اولویت اول اعزام خواهد بود",
    urgentConfirm:"بله ، فوری را فعال کنید",
    urgentClose:"نه ، لغو",
    Urgent:"فوری",
    pop:"اثبات وانت",
    photos :"عکس",
    Portuguese:"پرتغالی",
    Dispatchat:"اعزام در xxx",
    AutoDispatch:"اعزام خودکار" ,
    Manual:"کتابچه راهنمای",
    Dispatchtime:"زمان اعزام" ,
    Later:"بعد",
    DispatchingNow:"اعزام اکنون",
    Required:"ضروری" ,

    NoResultfromLocalDatabase:"نتیجه ای از پایگاه داده محلی وجود ندارد",
    NoResultfromLocalDatabaseD:"لطفاً دوباره آن را بررسی کنید یا یک کلمه کلیدی دیگر را امتحان کنید",
    NoResultfromMapService:"نتیجه ای از سرویس نقشه وجود ندارد",

    SearchinMapService:"در سرویس نقشه جستجو کنید" ,
    SearchinLocalDatabase:"در پایگاه داده محلی جستجو کنید",
    Creditcardnotadded:"کارت اعتباری اضافه نشده است",
    OptimizeDropoffs:"بهینه سازی قطره ها",
    "Greek":"یونانی",
    "el":"یونانی",
    "ReferenceID":"شناسه مرجع",
    "EnterId":"وارد شناسه شوید",
    "de":"آلمانی",
    "German":"آلمانی",
    "AustrianGerman":"آلمانی اتریشی",
    "Options":"گزینه ها",
    "VehicleOptions":"گزینه های وسیله نقلیه",
    "EditCustomer":"ویرایش مشتری",
    "NoDriverfound":"هیچ راننده ای پیدا نکرد",
    "NoDriverfoundDesc":"اطلاعات خود را بررسی کنید و چیز دیگری را امتحان کنید",
    "ClearField":"میدان پاک",
    "Map":"نقشه",
    "Local":"محلی",
    "CompleteRequiredFeileds":"زمینه های کامل مورد نیاز",
    "Senderinfo":"اطلاعات فرستنده",
    "SchedulePickup(Optional)":"وانت برنامه (اختیاری)",
    "ScheduleDelivery(Optional)": "Schedule Delivery (Optional)",

    "AddDropoff":"اضافه کردن",
    "EditPrice":"قیمت ویرایش",
    "Promotion":"ترویج",
    "tax":"مالیات",
    "surcharge":"اضافه کردن xxx",
    "Receiverinfo":"اطلاعات گیرنده",
    "tryagain":"Something went wrong, please try again",



    "Selected date and time":"Selected date and time",
    "Reorder stops":"Reorder stops",
    "Bulk Edit Status Summary":"Bulk Edit Status Summary",
    "Successful":"Successful",
    "Failed":"Failed",
    "Order ID":"Order ID",
    "Details":"Details",
    "Result":"Result",
    "Pickup Routed":"Pickup Routed",
    "Delivery Routed":"Delivery Routed",
    "Ref. xxx":"Ref. xxx",
    "Zone":"Zone",
    "Clear":"Clear",
    "Apply":"Apply",
    "No result found":"No result found",
    "Type your keyword":"Type your keyword",
    "Type in the input to start search":"Type in the input to start search",
    "We are sorry ...":"We are sorry ...",
    "The page you are trying to access has restricted access.":"The page you are trying to access has restricted access.",
    "Please refer to your system administrator.":"Please refer to your system administrator.",
    "Failed":"Failed",
    "Done": "Done",
    "Schedule":"Schedule",
    "Proof of delivery":"Proof of delivery",
    "Signature":"Signature",
    "Driver Photo":"Driver Photo",
    "Notes":"Notes",
    "Order Info":"Order Info",
    "Order":"Order",
    "Price":"Price",
    "P_HUB_D Orders":'P_HUB_D Orders',
    "Routes":"Routes",
    "Default pickup":"Default pickup",
    'More':'More',
    'Route name':'Route name',
    'Driver':'Driver',
    'Pickup Orders':'Pickup Orders',
    'Delivery Orders':'Delivery Orders',
    'Ready For Pickup':'Ready For Pickup'

};