import * as actionTypes from "./actionTypes";

export const enqueueSnackbar = (notification) => {
  const key = notification.options && notification.options.key;

  return {
    type: actionTypes.ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

export const closeSnackbar = (key) => ({
  type: actionTypes.CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = (key) => ({
  type: actionTypes.REMOVE_SNACKBAR,
  key,
});

export const ChangeNewTravelDialoge = (state = "") => ({
  type: actionTypes.OPENCLOSENEWTRAVEL,
  state,
});
export const Set_TravelList_page = (state, where) => ({
  type: actionTypes.set_TravelList_page,
  state,
  where,
});

export const Set_TravelList_loading = (state) => ({
  type: actionTypes.set_TravelList_loading,
  state,
});
export const Set_TravelList = (state) => ({
  type: actionTypes.set_TravelList,
  state,
});

export const OpenCloseCollapseFilter = (state) => ({
  type: actionTypes.openCloseFilterCollapse,
  state,
});

export const Set_TravelListWithPage = (state) => ({
  type: actionTypes.set_TravelListWithPage,
  state,
});

export const Reset_Passenger = () => ({
  type: actionTypes.reset_passenger,
});

export const Set_Passenger_Na_Fa_Ph = (state) => ({
  type: actionTypes.set_passenger_name_family_phoneNumber,
  state,
});

export const Set_Service_VehicleTypes = (state) => ({
  type: actionTypes.set_service_VehicleTypes,
  state,
});

export const Set_Driver_auto_VehicleTypes = (state) => ({
  type: actionTypes.set_driver_auto_vehicleTypes,
  state,
});

export const Set_Service_ActiveVehicleType = (state) => ({
  type: actionTypes.set_service_activeVehicleType,
  state,
});

export const Add_Service_Option = (state) => ({
  type: actionTypes.add_service_option,
  state,
});

export const Remove_Service_Option = (state) => ({
  type: actionTypes.remove_service_option,
  state,
});

export const Set_Auto_Driver = (state) => ({
  type: actionTypes.set_auto_driver,
  state,
});

export const Set_Auto_DriverـPickupDeliveryType_Pickup = (state) => ({
  type: actionTypes.set_auto_driverـPickupDeliveryType_Pickup,
  state,
});

export const Set_Auto_DriverـPickupDeliveryType_Dropoff = (state) => ({
  type: actionTypes.set_auto_driverـPickupDeliveryType_Dropoff,
  state,
});



export const Set_Auto_Driver_EditOrder = (state) => ({
  type: actionTypes.set_auto_driver_EditOrder,
  state,
});



export const Set_Force_Assaigning = (state) => ({
  type: actionTypes.set_Force_Assaigning,
  state,
});


export const Set_Force_AssaigningـPickupDeliveryType_Pickup = (state) => ({
  type: actionTypes.set_Force_AssaigningـPickupDeliveryType_Pickup,
  state,
});


export const Set_Force_AssaigningـPickupDeliveryType_Dropoff = (state) => ({
  type: actionTypes.set_Force_AssaigningـPickupDeliveryType_Dropoff,
  state,
});




export const Set_Auto_ActiveVehicleType = (state) => ({
  type: actionTypes.set_auto_activeVehicleType,
  state,
});

export const Set_Auto_ActiveVehicleTypeـPickupDeliveryType_Pickup = (state) => ({
  type: actionTypes.set_auto_activeVehicleTypeـPickupDeliveryType_Pickup,
  state,
});


export const Set_Auto_ActiveVehicleTypeـPickupDeliveryType_Dropoff = (state) => ({
  type: actionTypes.set_auto_activeVehicleTypeـPickupDeliveryType_Dropoff,
  state,
});


export const Set_Auto_ActiveVehicleType_EditOrder = (state) => ({
  type: actionTypes.set_auto_activeVehicleType_EditOrder,
  state,
});

export const Edit_Travel_Set_Auto_ActiveVehicleType = (state) => ({
  type: actionTypes.Edit_Travel_SetAutoActiveVehicleType,
  state,
});

export const Set_Manual_ActiveVehicleType = (state) => ({
  type: actionTypes.set_manual_activeVehicleType,
  state,
});

export const Set_auto_suggestListData = (state) => ({
  type: actionTypes.set_auto_suggestListData,
  state,
});

export const Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Pickup = (state) => ({
  type: actionTypes.set_manual_activeVehicleTypeـPickupDeliveryType_Pickup,
  state,
});

export const Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Dropoff = (state) => ({
  type: actionTypes.set_manual_activeVehicleTypeـPickupDeliveryType_Dropoff,
  state,
});

export const Set_PureـPickupDeliveryType_Pickup = (state) => ({
  type: actionTypes.set_PureـPickupDeliveryType_Pickup,
  state,
});

export const Set_PureـPickupDeliveryType_Dropoff = (state) => ({
  type: actionTypes.set_PureـPickupDeliveryType_Dropoff,
  state,
});




export const Set_Manual_ActiveVehicleType_EditOrder = (state) => ({
  type: actionTypes.set_manual_activeVehicleType_EditOrder,
  state,
});

export const Add_Driver_Option = (state) => ({
  type: actionTypes.add_driver_option,
  state,
});

export const Add_Driver_OptionـPickupDeliveryType_Pickup = (state) => ({
  type: actionTypes.add_driver_optionـPickupDeliveryType_Pickup,
  state,
});


export const Add_Driver_OptionـPickupDeliveryType_Dropoff = (state) => ({
  type: actionTypes.add_driver_optionـPickupDeliveryType_Dropoff,
  state,
});


export const Remove_Driver_Option = (state) => ({
  type: actionTypes.remove_driver_option,
  state,
});

export const Remove_Driver_OptionـPickupDeliveryType_Pickup = (state) => ({
  type: actionTypes.remove_driver_optionـPickupDeliveryType_Pickup,
  state,
});


export const Remove_Driver_OptionـPickupDeliveryType_Dropoff = (state) => ({
  type: actionTypes.remove_driver_optionـPickupDeliveryType_Dropoff,
  state,
});


export const Add_Driver_Option_EditOrder = (state) => ({
  type: actionTypes.add_driver_option_EditOrder,
  state,
});

export const Remove_Driver_Option_EditOrder = (state) => ({
  type: actionTypes.remove_driver_option_EditOrder,
  state,
});


export const Set_Pickup_Inputs = (state) => ({
  type: actionTypes.set_pickup_inputs,
  state,
});

export const Set_Pickup_address = (state) => ({
  type: actionTypes.set_pickup,
  state,
});

export const Add_dropoff = (state) => ({
  type: actionTypes.add_dropoff,
  state,
});

export const Remove_dropoff = (state) => ({
  type: actionTypes.remove_dropoff,
  state,
});

export const Set_ActivePickupDropOff = (state) => ({
  type: actionTypes.set_activePickupDropOff,
  state,
});

export const Set_DropOff_Inputs = (state) => ({
  type: actionTypes.set_dropOff_inputs,
  state,
});

export const Set_DropOff_Inputs_bulk = (state) => ({
  type: actionTypes.set_dropOff_inputs_bulk,
  state,
});

export const Set_Zoom = (state) => ({
  type: actionTypes.set_Zoom,
  state,
});

export const Set_latlng = (state) => ({
  type: actionTypes.set_latlng,
  state,
});

export const Set_realtimePlaceName = (state) => ({
  type: actionTypes.set_realtimePlaceName,
  state,
});

export const Set_realtimePlaceNameLoading = (state) => ({
  type: actionTypes.set_realtimePlaceNameLoading,
  state,
});

export const Set_SearchAutoCompleteLoading = (state) => ({
  type: actionTypes.set_SearchAutoCompleteLoading,
  state,
});

export const Set_SearchAutoComplete = (state) => ({
  type: actionTypes.set_SearchAutoComplete,
  state,
});
export const Set_SearchAutoCompleteNewFav = (state) => ({
  type: actionTypes.Set_SearchAutoCompleteNewFav,
  state,
});

export const Set_AccessToken = (state) => ({
  type: actionTypes.set_accesstoken,
  state,
});

export const SuggestCustomerList = (state) => ({
  type: actionTypes.suggestcustomerlist,
  state,
});

export const SuggestCustomerListLoading = (state) => ({
  type: actionTypes.suggestcustomerlistloading,
  state,
});

export const SetPriceNewOrder = (state, state2) => ({
  type: actionTypes.setpriceneworder,
  state,
  state2,
});

export const SetCanSaveTravel = (state) => ({
  type: actionTypes.setcansavetravel,
  state,
});

export const SetCanSaveEditOrder = (state) => ({
  type: actionTypes.setCanSaveEditOrder,
  state,
});


export const ResetNewOrder = (state) => ({
  type: actionTypes.resetneworder,
  state,
});

export const rebuildNewOrder = (state) => ({
  type: actionTypes.rebuildNewOrder,
  state,
});

export const AddNewOrderToList = (state) => ({
  type: actionTypes.addnewordertoList,
  state,
});

export const ChangeViewTravelDialoge = (state, order, customerId) => ({
  type: actionTypes.OPENCLOSEVIEWTRAVEL,
  state,
  order,
  customerId,
});

export const SetSocket = (state) => ({
  type: actionTypes.SETSOCKET,
  state,
});

export const UpdateTravelInList = (state) => ({
  type: actionTypes.UPDATETRAVELINLIST,
  state,
});

export const LoadingOrderInfo = (state) => ({
  type: actionTypes.LOADINGORDERINFO,
  state,
});

export const SetOrderInfo = (state) => ({
  type: actionTypes.SETORDERINFO,
  state,
});

export const SetOrderInfoError = (state) => ({
  type: actionTypes.SETORDERINFOERROR,
  state,
});

export const OpenCloseEditTravel = (state) => ({
  type: actionTypes.OPENCLOSEEDITTRAVEL,
  state,
});

export const EditTravelSetPrice = (state) => ({
  type: actionTypes.EDITTRAVELSETPRICE,
  state,
});

export const EditTRravelSetServiceVehicleTypes = (state) => ({
  type: actionTypes.Edit_travel_set_service_VehicleTypes,
  state,
});

export const EditTravelSetServiceActiveVehicleType = (state) => ({
  type: actionTypes.Edit_travel_set_service_activeVehicleType,
  state,
});

export const EditTravelAddServiceOption = (state) => ({
  type: actionTypes.Edit_travel_add_service_option,
  state,
});

export const EditTravelRemoveServiceOption = (state) => ({
  type: actionTypes.Edit_travel_remove_service_option,
  state,
});

export const EditTravelSetCustomer = (state) => ({
  type: actionTypes.Edit_Travel_Set_Customer,
  state,
});

export const DeleteTravelInList = (state) => ({
  type: actionTypes.DELETETRAVELINLIST,
  state,
});


export const EditTravelSetDriverAuto = (state) => ({
  type: actionTypes.Edit_Travel_Set_Driver_Auto,
  state,
});

export const EditTravelSetManualDriver = (state) => ({
  type: actionTypes.Edit_Travel_Set_Manual_Driver,
  state,
});
export const SetMonitoringHaveOrder = (state) => ({
  type: actionTypes.setMonitoringHaveOrder,
  state,
});

export const EditTravelSetAutoDriver = (state) => ({
  type: actionTypes.Edit_Travel_Set_Auto_Driver,
  state,
});

export const EditTravelSetActivePickup = (state) => ({
  type: actionTypes.Edit_travel_set_activePickupDropOff,
  state,
});

export const EditTravelSetManualActiveVehicleType = (state) => ({
  type: actionTypes.Edit_Travel_Set_Manual_ActiveVehicleType,
  state,
});

export const Edit_Driver_Add_Driver_Option = (state) => ({
  type: actionTypes.EditDriverAddDriverOption,
  state,
});

export const Edit_Driver_Remove_Driver_Option = (state) => ({
  type: actionTypes.EditDriverRemoveDriverOption,
  state,
});

export const Edit_Travel_reset_driver_options = (state) => ({
  type: actionTypes.EditTravelresetdriveroptions,
  state,
});

export const Edit_Travel_Set_Pickup = (state) => ({
  type: actionTypes.EditTravelSetPickup,
  state,
});

export const Edit_Travel_Set_Dropoffs = (state) => ({
  type: actionTypes.EditTravelSetDropoffs,
  state,
});

export const Edit_Travel_set_pickup_inputs = (state) => ({
  type: actionTypes.EditTravelsetpickupinputs,
  state,
});

export const Edit_Travel_set_pickup = (state) => ({
  type: actionTypes.EditTravelset_pickup,
  state,
});

export const Edit_Travel_set_Dropoff_inputs = (state) => ({
  type: actionTypes.EditTravelsetDropoffinputs,
  state,
});

export const Edit_Travel_set_Dropoff = (state) => ({
  type: actionTypes.EditTravelset_Dropoff,
  state,
});

export const Edit_Travel_add_dropoff = (state) => ({
  type: actionTypes.EditTraveladd_dropoff,
  state,
});

export const Edit_Travel_remove_dropoff = (state) => ({
  type: actionTypes.EditTravelremove_dropoff,
  state,
});

export const Edit_Travel_Set_Price = (state, opertarSubmit) => ({
  type: actionTypes.EditTravelSetPrice,
  state,
  opertarSubmit,
});

export const Edit_Travel_Operate_Changed = () => ({
  type: actionTypes.EditTravelOperateChanged,
});

export const Edit_Travel_Reset = () => ({
  type: actionTypes.EditTravelReset,
});

export const Edit_Travel_PaymentMethod = (state) => ({
  type: actionTypes.EditTravelPaymentMethod,
  state,
});

export const New_Travel_PaymentMethod = (state) => ({
  type: actionTypes.NewTravelPaymentMethod,
  state,
});

export const EditTravelSetCanSaveTravel = (state) => ({
  type: actionTypes.Edit_Travel_SetCanSaveTravel,
  state,
});

export const SetmonitoringOnOff = () => ({
  type: actionTypes.monitoringOnOff,
});

export const MonitoringAddVehicleType = (state) => ({
  type: actionTypes.monitoringAddVehicleType,
  state,
});

export const MonitoringRemoveVehicleType = (state) => ({
  type: actionTypes.monitoringRemoveVehicleType,
  state,
});

export const SetMonitoringDrivers = (state) => ({
  type: actionTypes.setMonitoringDrivers,
  state,
});

export const ResetViewTravel = (state) => ({
  type: actionTypes.resetViewTravel,
  state,
});

export const ResetNewFav = (state) => ({
  type: actionTypes.resetNewFav,
  state,
});

export const SetEstimatedRoute = (state) => ({
  type: actionTypes.setViewTravelEstimatedRoute,
  state,
});

export const AddListOrderState = (state) => ({
  type: actionTypes.addListOrderState,
  state,
});



export const SetJustMeFilter = (state) => ({
  type: actionTypes.setjustme,
  state,
});


export const RemoveListOrderState = (state) => ({
  type: actionTypes.removeListOrderState,
  state,
});

export const ResetListOrderState = (state) => ({
  type: actionTypes.resetListOrderState,
  state,
});

export const RequsetListRegex = (state) => ({
  type: actionTypes.requsetListRegex,
  state,
});

export const RequsetListRegexFields = (state) => ({
  type: actionTypes.requsetListRegexFields,
  state,
});


export const SetNewFav = (state) => ({
  type: actionTypes.setNewFav,
  state,
});

export const OpenNewFav = (state) => ({
  type: actionTypes.openNewFav,
  state,
});

export const SetListNewFav = (state) => ({
  type: actionTypes.setListNewFav,
  state,
});

export const DeleteFavAddress = (state) => ({
  type: actionTypes.deleteFavAddress,
  state,
});

export const AddFavAddress = (state) => ({
  type: actionTypes.addFavAddress,
  state,
});

export const resetInputNewfav = (state) => ({
  type: actionTypes.resetInputNewfav,
  state,
});

export const EditTravelFeeChange = (state) => ({
  type: actionTypes.feeChange,
  state,
});

export const EditTravelSurcharges = (state) => ({
  type: actionTypes.surcharge,
  state,
});

export const EditTravelSurchargesChange = (state) => ({
  type: actionTypes.surchargeChange,
  state,
});

export const NewTravelPickupSetReserve = (state) => ({
  type: actionTypes.newTravelPickupSetReserve,
  state,
});

export const NewTravelDropOffSetReserve = (state) => ({
  type: actionTypes.newTravelDropOffSetReserve,
  state,
});

export const NewTravelResetReserve = (state) => ({
  type: actionTypes.newTravelResetReserve,
  state,
});

export const ChangePersonBusiness = (state) => ({
  type: actionTypes.changePersonBusiness,
  state,
});

export const ChangeViewTravelHistoryMode = (state) => ({
  type: actionTypes.changeViewTravelHistoryMode,
  state,
});

export const SetHistoryData = (state) => ({
  type: actionTypes.setHistoryData,
  state,
});

export const ListFilterVehicleTypeAdd = (state) => ({
  type: actionTypes.listFilterVehicleTypeAdd,
  state,
});

export const ListFilterVehicleTypeRemove = (state) => ({
  type: actionTypes.listFilterVehicleTypeRemove,
  state,
});

export const ListFilterServicesAdd = (state) => ({
  type: actionTypes.listFilterServicesAdd,
  state,
});


export const ListFilterServicesReset = (state) => ({
  type: actionTypes.listFilterServicesReset,
  state,
});

export const ListFilterServicesRemove = (state) => ({
  type: actionTypes.listFilterServicesTypeRemove,
  state,
});

export const ListFilterSetDateRange = (state) => ({
  type: actionTypes.listFilterSetDateRange,
  state,
});

export const SetServiceVehicleTypesRepeatData = (serviceId , options , service) => ({
  type: actionTypes.set_service_VehicleTypes_RepeatData,
  serviceId , options ,service
});



export const SetAdvancedSortKey = (state) => ({
  type: actionTypes.setAdvancedSortKey,
  state,
});



export const SetAdvancedSortValue = (state) => ({
  type: actionTypes.setAdvancedSortvalue,
  state,
});


export const ToggleDriverList = (state) => ({
  type: actionTypes.toggleDriverList,
  state,
});



export const SetActiveDriverList = (state) => ({
  type: actionTypes.setActiveDriverList,
  state,
});



export const SetDriverListloadingOrderedPage = (state) => ({
  type: actionTypes.setDriverListloadingOrderedPage,
  state,
});


export const UpdateOrdersCountInDriversList = (state) => ({
  type: actionTypes.updateOrdersCountInDriversList,
  state,
});


export const SetDriverListData = (state , loadMore) => ({
  type: actionTypes.setDriverListData,
  state, loadMore
});


export const SetDriverOrdersListData = (state) => ({
  type: actionTypes.setDriverOrdersListData,
  state,
});


export const EditTravelPickupSetReserve = (state) => ({
  type: actionTypes.editTravelPickupSetReserve,
  state,
});

export const EditTravelDropOffSetReserve = (state) => ({
  type: actionTypes.editTravelDropOffSetReserve,
  state,
});

export const SetEditRequestForceCalculateCost = (state) => ({
  type: actionTypes.setEditRequestForceCalculateCost,
  state,
});





export const SetopenAddressBookInNewOrder = (state , Stype , id , i18) => ({
  type: actionTypes.setopenAddressBookInNewOrder,
  state, Stype , id, i18
});


export const SetsourceOfAddressBookInNewOrder = (state) => ({
  type: actionTypes.setsourceOfAddressBookInNewOrder,
  state,
});



export const SetAddressBookListData = (state ) => ({
  type: actionTypes.SetAddressBookListData,
  state
});



export const SetAddressBookSearchRegex = (state ) => ({
  type: actionTypes.SetAddressBookSearchRegex,
  state
});




export const Set_searchDriverInput = (state ) => ({
  type: actionTypes.set_searchDriverInput,
  state
});


export const Set_searchDriverInputـPickupDeliveryType_Pickup = (state ) => ({
  type: actionTypes.set_searchDriverInputـPickupDeliveryType_Pickup,
  state
});



export const Set_searchDriverInputـPickupDeliveryType_Dropoff = (state ) => ({
  type: actionTypes.set_searchDriverInputـPickupDeliveryType_Dropoff,
  state
});

export const Set_searchDriverInput_EditOrder = (state ) => ({
  type: actionTypes.set_searchDriverInput_EditOrder,
  state
});





export const Set_Edit_Order_DriverAndStatus = (state ) => ({
  type: actionTypes.set_Edit_Order_DriverAndStatus,
  state
});






export const Reset_DriverAndStatus = (state ) => ({
  type: actionTypes.reset_DriverAndStatus,
  state
});




export const Set_OrderList_OrderTypes = (state ) => ({
  type: actionTypes.requsetListorderTypes,
  state
});






export const Set_NewOrder_OrderType = (state ) => ({
  type: actionTypes.set_new_order_OrderType,
  state
});




export const show_eventTimeLine = (state ) => ({
  type: actionTypes.show_event_TimeLine,
  state
});





export const Set_viewOrder_events = (state ) => ({
  type: actionTypes.set_viewOrder_events,
  state
});


export const Set_New_Event = (state ) => ({
  type: actionTypes.set_New_Event,
  state
});



export const New_Order_Reset_Pickup = (state ) => ({
  type: actionTypes.new_Order_Reset_Pickup,
  state
});


export const New_Order_Reset_DropOffs = (state ) => ({
  type: actionTypes.new_Order_Reset_DropOffs,
  state
});



export const New_Order_Reset_DropOff = (state ) => ({
  type: actionTypes.new_Order_Reset_DropOff,
  state
});


export const Set_Map_Ref = (state ) => ({
  type: actionTypes.set_REF,
  state
});




export const Set_Order_Sub_Id = (state ) => ({
  type: actionTypes.set_Order_Sub_Id,
  state
});




export const AdvancedAssaigning_handleDialoge = (state ) => ({
  type: actionTypes.advancedAssaigning_handleDialoge,
  state
});




export const AdvancedAssaigning_reset = (state ) => ({
  type: actionTypes.advancedAssaigning_reset,
  state
});


export const AdvancedAssaigning_setVehicleType = (state ) => ({
  type: actionTypes.advancedAssaigning_setVehicleType,
  state
});

export const AdvancedAssaigning_setRule = (state ) => ({
  type: actionTypes.advancedAssaigning_setRule,
  state
});

export const AdvancedAssaigning_firstDriver_auto = (state ) => ({
  type: actionTypes.advancedAssaigning_firstDriver_auto,
  state
});

export const AdvancedAssaigning_firstDriver_auto_switch = (state ) => ({
  type: actionTypes.advancedAssaigning_firstDriver_auto_switch,
  state
});


export const AdvancedAssaigning_firstDriver_manual = (state ) => ({
  type: actionTypes.advancedAssaigning_firstDriver_manual,
  state
});


export const AdvancedAssaigning_firstDriver_options_add = (state ,flag ) => ({
  type: actionTypes.advancedAssaigning_firstDriver_options_add,
  state,flag
});


export const AdvancedAssaigning_firstDriver_options_remove = (state,flag ) => ({
  type: actionTypes.advancedAssaigning_firstDriver_options_remove,
  state,flag
});
export const AdvancedAssaigning_firstDriver_options_reset = (flag ) => ({
  type: actionTypes.advancedAssaigning_firstDriver_options_reset,
  flag
});


export const MonitoringSet_VehicleTypes = (state ) => ({
  type: actionTypes.monitoringSet_VehicleTypes,
  state
});

export const AdvancedAssaigning_setIniRule = (state ) => ({
  type: actionTypes.advancedAssaigning_setIniRule,
  state
});






export const AdvancedAssaigning_secondDriver_auto = (state ) => ({
  type: actionTypes.advancedAssaigning_secondDriver_auto,
  state
});

export const AdvancedAssaigning_secondDriver_auto_switch = (state ) => ({
  type: actionTypes.advancedAssaigning_secondDriver_auto_switch,
  state
});


export const AdvancedAssaigning_secondDriver_manual = (state ) => ({
  type: actionTypes.advancedAssaigning_secondDriver_manual,
  state
});


export const AdvancedAssaigning_secondDriver_options_add = (state ,flag ) => ({
  type: actionTypes.advancedAssaigning_secondDriver_options_add,
  state,flag
});


export const AdvancedAssaigning_secondDriver_options_remove = (state,flag ) => ({
  type: actionTypes.advancedAssaigning_secondDriver_options_remove,
  state,flag
});
export const AdvancedAssaigning_secondDriver_options_reset = (flag ) => ({
  type: actionTypes.advancedAssaigning_secondDriver_options_reset,
  flag
});

export const Set_Error_CheckServiceByCustomer = (state , flag) => ({
  type: actionTypes.set_CheckServiceByCustomer,
  state  , flag
});


export const Set_Error_CheckVehicleByCustomer = (state , flag) => ({
  type: actionTypes.set_CheckVehicleByCustomer,
  state  , flag
});


export const OrderListFilterService = (state ) => ({
  type: actionTypes.orderListFilterService,
  state
});


export const OrderListFilterVehicletypes = (state ) => ({
  type: actionTypes.orderListFilterVehicletypes,
  state
});



export const CreateNewDraft = (state ) => ({
  type: actionTypes.createNewDraft,
  state
});


export const SetActiveImport = (state ,bType) => ({
  type: actionTypes.setActiveImport,
  state , bType
});



export const SetActiveImportData = (state ,id) => ({
  type: actionTypes.setActiveServiceImport,
  state ,id
});

export const Create_new_BulkImport_List = (state ) => ({
  type: actionTypes.create_new_BulkImport_List,
  state 
});


export const SetActiveImportDataList = (state ) => ({
  type: actionTypes.setActiveServiceImportList,
  state
});




export const Add_Service_Option_bulkImport = (state , id) => ({
  type: actionTypes.add_Service_Option_bulkImport,
  state,id
});

export const Remove_Service_Option_bulkImport = (state , id) => ({
  type: actionTypes.remove_Service_Option_bulkImport,
  state , id
});

export const Add_VehicleType_Option_bulkImport = (state , id) => ({
  type: actionTypes.add_VehicleType_Option_bulkImport,
  state , id
});

export const Remove_VehicleType_Option_bulkImport = (state , id) => ({
  type: actionTypes.remove_VehicleType_Option_bulkImport,
  state , id
});


export const Remove_draft_bulkImport = (state ) => ({
  type: actionTypes.remove_draft_bulkImport,
  state 
});



export const SetBulkImportList = (state ) => ({
  type: actionTypes.setBulkImportList,
  state 
});


export const SetCodAmount = (state ) => ({
  type: actionTypes.setCodAmount,
  state 
});

export const SetReferenceID = (state ) => ({
  type: actionTypes.setReferenceID,
  state 
});

export const SetNotesOrder = (state ) => ({
  type: actionTypes.setNotesOrder,
  state 
});


export const SetRecentAddress = (state ) => ({
  type: actionTypes.setRecentAddress,
  state 
});

export const SetVoipIncommingCallAction = (state ) => ({
  type: actionTypes.setVoipIncommingCallAction,
  state 
});

export const SetVoipIncommingCall = (state ) => ({
  type: actionTypes.setVoipIncommingCall,
  state 
});



export const SetOrderListStatsLoading = (state ) => ({
  type: actionTypes.setOrderListStatsLoading,
  state 
});


export const SetOrderListStatsData = (state ) => ({
  type: actionTypes.setOrderListStatsData,
  state 
});

export const SetCategoryOrderListLoading = (state ) => ({
  type: actionTypes.setCategoryOrderListLoading,
  state 
});

export const SetCategoryOrderListData = (state ) => ({
  type: actionTypes.setCategoryOrderListData,
  state 
});

export const ResetCategoryOrderListData = (state ) => ({
  type: actionTypes.resetCategoryOrderListData,
  state 
});

export const SetOrderListCursor = (state ) => ({
  type: actionTypes.setOrderListCursor,
  state 
});

export const SetOrderListCursorSort = (state ) => ({
  type: actionTypes.setOrderListCursorSort,
  state 
});

export const SetSearchOrderListRegexFields = (state ) => ({
  type: actionTypes.setSearchOrderListRegexFields,
  state 
});

export const SetSearchOrderListRegexText = (state ) => ({
  type: actionTypes.setSearchOrderListRegexText,
  state 
});


export const SetSearchOrderListRegexLoading= (state ) => ({
  type: actionTypes.setSearchOrderListLoading,
  state 
});



export const SetSearchOrderListRegexRequested = (state ) => ({
  type: actionTypes.setSearchOrderListRequested,
  state 
});


export const SetSearchOrderListData = (state ) => ({
  type: actionTypes.setSearchOrderListData,
  state 
});

export const SetSharedDataServices = (state ) => ({
  type: actionTypes.setSharedDataServices,
  state 
});

export const SetSharedDataZone = (state ) => ({
  type: actionTypes.setSharedDataZone,
  state 
});

export const SetSharedDataVehicleTypes = (state ) => ({
  type: actionTypes.setSharedDataVehicleTypes,
  state 
});

export const SetOrderListFiltersDueTime = (state ) => ({
  type: actionTypes.setOrderListFiltersDueTime,
  state 
});

export const SetOrderListFiltersStatus = (state ) => ({
  type: actionTypes.setOrderListFiltersStatus,
  state 
});

export const SetOrderListFiltersServices = (state ) => ({
  type: actionTypes.setOrderListFiltersServices,
  state 
});

export const SetOrderListFiltersVehicleTypes = (state ) => ({
  type: actionTypes.setOrderListFiltersVehicleTypes,
  state 
});
export const SetOrderListFiltersZone = (state ) => ({
  type: actionTypes.setOrderListFiltersZone,
  state 
});


export const SetOrderListFiltersChanged = (state ) => ({
  type: actionTypes.setOrderListFiltersChanged,
  state 
});

export const OpenCloseFilterDialoge = (state ) => ({
  type: actionTypes.openCloseFilterDialoge,
  state 
});

export const ResetSearchFilters = (state ) => ({
  type: actionTypes.resetSearchFilters,
  state 
});


export const UpdateSearchOrdersList = (state ) => ({
  type: actionTypes.updateSearchOrdersList,
  state 
});


export const UpdateCategoryOrdersList = (state ) => ({
  type: actionTypes.updateCategoryOrdersList,
  state 
});

export const UpdateCategoryOrdersListSorted = (state ) => ({
  type: actionTypes.updateCategoryOrdersListSorted,
  state 
});


export const DeleteCategoryOrdersList = (state ) => ({
  type: actionTypes.deleteCategoryOrdersList,
  state 
});

export const MonitoringHandleActiveOrders = (state ) => ({
  type: actionTypes.monitoringHandleActiveOrders,
  state 
});

export const MonitoringHandleActiveDrivers = (state ) => ({
  type: actionTypes.monitoringHandleActiveDrivers,
  state 
});

export const MonitoringSetDrivers = (state ) => ({
  type: actionTypes.monitoringSetDrivers,
  state 
});

export const MonitoringSetOrders = (state ) => ({
  type: actionTypes.monitoringSetOrders,
  state 
});



export const IncreaseStatsCategoryNumber = (state ) => ({
  type: actionTypes.increaseStatsCategoryNumber,
  state 
});



export const DecreaseStatsCategoryNumber = (state ) => ({
  type: actionTypes.decreaseStatsCategoryNumber,
  state 
});




export const SetDriverListRegex = (state ) => ({
  type: actionTypes.setDriverListRegex,
  state 
});

export const SetSelectedMonitoringOrder = (state ) => ({
  type: actionTypes.setSelectedMonitoringOrder,
  state 
});


export const SetSelectedMonitoringDriver = (state ) => ({
  type: actionTypes.setSelectedMonitoringDriver,
  state 
});

export const SetDriverPopUp = (state ) => ({
  type: actionTypes.setDriverPopUp,
  state 
});


export const SetOrderPinPopUp = (state ) => ({
  type: actionTypes.setOrderPinPopUp,
  state 
});


export const SetOrderPinPopUpPosition = (state ) => ({
  type: actionTypes.setOrderPinPopUpPosition,
  state 
});


export const SetDriverListOpenDialoge = (state ) => ({
  type: actionTypes.setDriverListOpenDialoge,
  state 
});


export const UpdateMonitoringOrdersList = (state ) => ({
  type: actionTypes.updateMonitoringOrdersList,
  state 
});

export const AddMonitoringOrdersList = (state ) => ({
  type: actionTypes.addMonitoringOrdersList,
  state 
});


export const RemoveMonitoringOrders = (state ) => ({
  type: actionTypes.removeMonitoringOrders,
  state 
});

export const AddMonitoringOrdersBounding = (state ,where) => ({
  type: actionTypes.addMonitoringOrdersBounding,
  state ,where
});


export const SetDriverStatistics = (state ) => ({
  type: actionTypes.setDriverStatistics,
  state 
});



export const SetDriverListLoading = (state ) => ({
  type: actionTypes.setDriverListLoading,
  state 
});


export const ResetOrderListData = (state ) => ({
  type: actionTypes.resetOrderListData,
  state 
});


export const Set_DropOff_fields_Dnd = (state) => ({
  type: actionTypes.set_DropOff_fields_Dnd,
  state,
});

export const Set_DropOff_fields_Dnd_EditAddress = (state) => ({
  type: actionTypes.set_DropOff_fields_Dnd_EditAddress ,
  state,
});

export const PriceDetailsOpen = (state) => ({
  type: actionTypes.priceDetailsOpen ,
  state,
});

export const OfferingDetailsOpen = (state) => ({
  type: actionTypes.offeringDetailsOpen ,
  state,
});


export const SetViewOrderPickupDropoffDetails = (state) => ({
  type: actionTypes.setViewOrderPickupDropoffDetails ,
  state,
});


export const SetMainLoaded = (state) => ({
  type: actionTypes.setMainLoaded ,
  state,
});

export const SetCategoryCollapse = (state , bool) => ({
  type: actionTypes.setCategoryCollapse ,
  state,bool
});


export const SetDriverLocation = (state , bool) => ({
  type: actionTypes.setDriverLocation ,
  state,bool
});

export const SetGoogleMapLoaded = (state ) => ({
  type: actionTypes.setGoogleMapLoaded ,
  state
});


export const SetOrderNotFound = (state ) => ({
  type: actionTypes.setOrderNotFound ,
  state
});

export const SetorderError = (state ) => ({
  type: actionTypes.setorderError ,
  state
});

export const SetTime = (state ) => ({
  type: actionTypes.setTime ,
  state
});

export const NewCustomerHandleChange = (state ) => ({
  type: actionTypes.newCustomerHandleChange ,
  state
});

export const OpenEditCustomer = (state ) => ({
  type: actionTypes.openEditCustomer ,
  state
});


export const EdittPriceNewOrder = (state ) => ({
  type: actionTypes.editPriceNewOrder ,
  state
});

export const EdittPriceNewOrder_manualy = (state ) => ({
  type: actionTypes.editPriceNewOrder_manualy ,
  state
});


export const EdittPriceNewOrderChanged = (state ) => ({
  type: actionTypes.editPriceNewOrderChanged ,
  state
});

export const EditPrice_PromoCode_NewOrderChanged = (state ) => ({
  type: actionTypes.editPrice_PromoCode_NewOrderChanged ,
  state
});

export const Set_uid_newOrder = (state ) => ({
  type: actionTypes.set_uid_newOrder ,
  state
});

export const SetSettings = (state ) => ({
  type: actionTypes.setSettings ,
  state
});

export const Table_Init = (state ) => ({
  type: actionTypes.Table_Init ,
  state
});


export const Table_SelectOrder = (state ) => ({
  type: actionTypes.Table_SelectOrder ,
  state
});

export const Table_DeSelectOrder = (state ) => ({
  type: actionTypes.Table_DeSelectOrder ,
  state
});


export const Table_ClearSelectedOrders = ( ) => ({
  type: actionTypes.Table_ClearSelectedOrders 
});


export const Table_SelecteMultiOrders = ( state) => ({
  type: actionTypes.Table_SelecteMultiOrders ,
  state
});

export const Table_setData = ( state) => ({
  type: actionTypes.Table_setData ,
  state
});

export const Table_setStatus = ( state) => ({
  type: actionTypes.Table_setStatus ,
  state
});
export const Table_reset = ( state) => ({
  type: actionTypes.Table_reset ,
  state
});
export const Table_setSelectedBulkEditStatusSummary = ( state) => ({
  type: actionTypes.Table_setSelectedBulkEditStatusSummary ,
  state
});


export const Table_setRouteList = ( state) => ({
  type: actionTypes.table_setRouteList ,
  state
});




export const TableInputContainFilter_Delete = ( state) => ({
  type: actionTypes.TableInputContainFilter_Delete ,
  state
});

export const TableInputContainFilter_Add = ( state) => ({
  type: actionTypes.TableInputContainFilter_Add ,
  state
});

export const TableInputContainFilter_Update = ( state) => ({
  type: actionTypes.TableInputContainFilter_Update ,
  state
});


export const TableCheckboxFilter_Update = ( state) => ({
  type: actionTypes.TableCheckboxFilter_Update ,
  state
});

export const TableRadioButtonFilter_Update = ( state) => ({
  type: actionTypes.TableRadioButtonFilter_Update ,
  state
});
 

export const TableRangeFilter_Update = ( state) => ({
  type: actionTypes.TableRangeFilter_Update ,
  state
});
 
export const TableSubRangeFilter_Update = ( state) => ({
  type: actionTypes.TableSubRangeFilter_Update ,
  state
});
 

export const Add_ActiveFilter = ( state) => ({
  type: actionTypes.Add_ActiveFilter ,
  state
});
export const clearAll_ActiveFilter = ( state) => ({
  type: actionTypes.clearAll_ActiveFilter ,
  state
});
 
export const handleEditRouteFilter_Name = ( state) => ({
  type: actionTypes.handleEditRouteFilter_Name ,
  state
});
 
export const clearAll_Route_ActiveFilter = ( state) => ({
  type: actionTypes.clearAll_Route_ActiveFilter ,
  state
});



export const Set_newOrder_draft = ( state) => ({
  type: actionTypes.set_newOrder_draft ,
  state
});



export const Set_autoDriver_vehicles_auto_suggestListData = ( state) => ({
  type: actionTypes.set_autoDriver_vehicles_auto_suggestListData ,
  state
});


export const Set_map_loaded = ( state) => ({
  type: actionTypes.set_map_loaded ,
  state
});

