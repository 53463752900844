import CustomerNewOrder from '../../components/new-order/customer';
import NewCustomerRegister from '../../components/new-order/customer/components/new-customer';
import ServiceNewOrder from '../../components/new-order/service';
import styles from './NewOrder.module.css';
import React, { useCallback, useEffect, useState } from 'react';
import { InitialNewOrder } from './initial';
import EditCustomerRegister from '../../components/new-order/customer/components/edit-customer';
import DriverNewOrder from '../../components/new-order/driver';
import PickupNewOrder from '../../components/new-order/pickup';
import { useDispatch, useSelector } from 'react-redux';
import { Add_dropoff, Remove_dropoff, ResetNewOrder, Set_ActivePickupDropOff, SetDriverListOpenDialoge } from '../../Redux/actions/actionNames';
import DeliveryNewOrder from '../../components/new-order/delivery';
import DeliveryContainers from '../../components/new-order/deliveryContainers';
import MapNewOrderSelectPin from '../../components/shared/Map-NewOrder-Select-pin';
import AddressbookNewOrder from '../../components/new-order/addressBook/index';
import FooterNewOrder from '../../components/new-order/footer';
import CanSaveOrder from '../../components/new-order/canSaveOrder';
import CalculatePrice from '../../components/new-order/calculatePrice';
import Others from '../../components/new-order/other';
import Voip from '../../Entries/Voip';
import { useCSSTOTEXT } from '../../hooks/useCSSTOTEXT';
import { NewOrderEstimate } from '../../components/new-order/estimate-path-time';
import { MapContainer } from '../../components/shared/map-container';
import { MapNewOrderPinOnMap } from '../../components/shared/Map-NewOrder-pin-onMap';
import { useMapContainerWidthHeight } from '../../hooks/useMapContainerWidthHeight';
import { InitialMain } from '../shared/initial-main';
import StatusNewOrder from '../../components/new-order/status';
import { DriverList } from '../../components/main/driver-list';
import { RouteListMonitoring } from '../../components/route-details/monitoring';
import { Monitoring } from '../../components/main/moitoring';
import { DRIVER_COLOR } from '../../assets';
import { useTranslation } from 'react-i18next';
import Typography from '../../design-system/lib/Typography';




export const NewOrderLayout = () => {
  const [loaded, setLoaded] = useState(true);
  const [idArrays, setIdArrays] = useState([]);
  const [dropoffs, setDropoffs] = useState([]);
  const orderTypeActive = useSelector((state) => state.NewOrder.newOrder.orderTypeActive)
  const rebuildNewOrder = useSelector((state) => state.NewOrder.rebuildNewOrder)
  const MapContainerWidth = useMapContainerWidthHeight();
  const mainLoaded = useSelector((state) => state.SharedData.mainLoaded)
  const { t } = useTranslation();
  const Globalsettings = useSelector((state) => state.SharedData.settings)

  let CSSTOTEXT = useCSSTOTEXT()

  const dispatch = useDispatch();

  const handleCollapse = React.useCallback((id) => {
    dispatch(Set_ActivePickupDropOff(id));
  }, [idArrays]);

  const setId = React.useCallback((id) => {


  }, [idArrays]);


  const addDropOff = React.useCallback(() => {
    dispatch(Add_dropoff(idgenerator()));

  }, []);

  const idgenerator = React.useCallback(() => {
    var number = Math.random(); // 0.9394456857981651
    number.toString(36); // '0.xtis06h6'
    var id = number.toString(36).substr(2, 9); // 'xtis06h6'
    return id.toString();
  }, []);


  const removeDropOff = React.useCallback((id) => {
    dispatch(Remove_dropoff(id));

  }, []);

  useEffect(() => {
    InitialNewOrder((call) => {
      setLoaded(call)
    })
  }, []);

  // useEffect(() => {
  //   if (rebuildNewOrder) {
  //     setTimeout(() => {
  //       dispatch(ResetNewOrder())
  //     }, 200);
  //   }

  // }, [rebuildNewOrder]);



  function mapOnCenterChange(value) {

  }


  let defaultMapStyle = MapContainerWidth


  if (loaded || mainLoaded) {
    return <div
      className={styles.App}
    > <div className={styles.newOrderContainer}>

        {(loaded && !rebuildNewOrder) ? <>
          <ServiceNewOrder />

          <div className={styles.newOrderInnerContainer}>
            <StatusNewOrder />
            <CustomerNewOrder />
            {["Delivery", "Pickup", "Ondemand"].indexOf(
              orderTypeActive
            ) > -1 && <DriverNewOrder />}
            <DeliveryContainers handleCollapse={handleCollapse} orderTypeActive={orderTypeActive}
              removeDrop={removeDropOff} addDropOff={addDropOff} />
            <Others />
          </div>

          <NewCustomerRegister />
          <EditCustomerRegister />
          <AddressbookNewOrder />
          <FooterNewOrder />
          <CanSaveOrder />
          <CalculatePrice />


        </> : <span class="loader2"></span>}
        <Voip />


      </div>

     { ["Ondemand"].indexOf(
        orderTypeActive
      ) == -1 &&  <div className={styles.monitoringEsContainer} style={{ [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "24px" }}>
        <NewOrderEstimate />
      </div>}

      {(loaded && !rebuildNewOrder) && <MapNewOrderSelectPin FORCE={true} />}
      {/* {loaded && <MapContainer customBounds={null} style={{ width: "calc(100vw - 466px)", height: "100vh", zIndex: "5", position: "relative" }} className={styles.mainMapContainer} onCenterChange={mapOnCenterChange} >
        <Monitoring force={true} />

        <MapNewOrderPinOnMap />
      </MapContainer>} */}
      <DriverList className={styles.driversList} style={{ [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "0px" }} />
      {["Ondemand"].indexOf(
        orderTypeActive
      ) > -1 && <div className={styles.monitoringContainer} style={{ [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "24px" }}>
          <RouteListMonitoring />
        </div>}

      {["Ondemand"].indexOf(
        orderTypeActive
      ) > -1 && <div className={(Globalsettings && Globalsettings.dispatcherHomeMessage && String(Globalsettings.dispatcherHomeMessage).length > 0) ? styles.driversBtnToggle2 :styles.driversBtnToggle} onClick={() => { dispatch(SetDriverListOpenDialoge(true)) }}>
          <img src={DRIVER_COLOR} />
          <Typography text={t("Drivers")} weight='Body_Middle_Bold' style={{ color: "rgba(72, 72, 72, 1)" }} />
        </div>}

    </div>
  } else {
    return <div style={{ float: "left", marginLeft: "calc(50vw - 40px)", marginTop: 'calc(50vh - 40px)' }}>
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>


  }
}



