import React from "react";
import { Map, Marker, TileLayer, Popup, Polyline, FeatureGroup } from "react-leaflet";
import L from "leaflet";
import { connect } from "react-redux";
import "./index.css";
import { debounce } from "lodash";
import { AddMonitoringOrdersBounding, SetDriverPopUp, SetOrderPinPopUp, SetSelectedMonitoringDriver, SetSelectedMonitoringOrder } from "../../../../Redux/actions/actionNames";
import styles from '../style.module.css';
import IconProvider from "../../../../design-system/lib/IconProvider";
import Typography from "../../../../design-system/lib/Typography";
import { INFO_CIRCLE } from "../../../../assets";

var _ = require("lodash");

const Leaflet = window.L;


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: 14,
      finishedmoving: false,
    };

    this.handleMove = this.handleMove.bind(this);
    this.throttleHandleChange = debounce(
      this.throttleHandleChange.bind(this),
      350
    );

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.style.width != this.props.style.width) {

      setTimeout(() => {
        this.props.mapRef.current.leafletElement._onResize();
      }, 1000);
    }
  }

  throttleHandleChange(e) {
    this.props.onCenterChange({
      lng: this.props.mapRef.current.leafletElement.getCenter().lng,
      lat: this.props.mapRef.current.leafletElement.getCenter().lat,
    })
  }

  handleMove(e) {
    this.throttleHandleChange(e);
  }


  boundsGenerator = () => {
    if (this.props.MonitoringOrdersOpen && _.isEmpty(this.props.VRpureData)) {
      if (this.props.ordersBoundingList.length > 0) {

        let arr = [];

        if (this.props.ordersBoundingDriver && this.props.ordersBoundingDriver.location) {
          arr.push([this.props.ordersBoundingDriver.location[1], this.props.ordersBoundingDriver.location[0]]);
        }

        this.props.ordersBoundingList.forEach((order) => {
          if (order.pickup) {
            arr.push([order.pickup.coordinates[1], order.pickup.coordinates[0]]);
          }
          if (order.dropoff) {
            arr.push([order.dropoff.coordinates[1], order.dropoff.coordinates[0]]);
          }
          if (order.dropoffs) {
            order.dropoffs.forEach((drops, ii) => {
              arr.push([drops.coordinates[1], drops.coordinates[0]]);
            })
          }
        })

        return Leaflet.latLngBounds(arr);


      }
    } else if (["Delivery"].indexOf(this.props.orderType) > -1) {
      if (this.props.EROpenERDialoge) {
        if (
          String(this.props.ERdropOffsAdresss_loc.lng) !== "" &&
          String(this.props.ERdropOffsAdresss_loc.lng) !== ""
        ) {
          let arr = [
            [
              this.props.ERdropOffsAdresss_loc.lat,
              this.props.ERdropOffsAdresss_loc.lng,
            ],
          ];
          return Leaflet.latLngBounds(arr);
        } else {
          return null;
        }
      } else if (
        this.props.OpenVRDialoge &&
        !this.props.EROpenERDialoge &&
        !_.isEmpty(this.props.VRpureData) &&
        this.props.VRpureData.dropoff
      ) {
        let arr = [
          [
            this.props.VRpureData.dropoff.coordinates[1],
            this.props.VRpureData.dropoff.coordinates[0],
          ],
        ];

        if (this.props.driver_location) {
          arr.push(this.props.driver_location[1], this.props.driver_location[0]);
        }

        return Leaflet.latLngBounds(arr);
      } else if (isNewOrder()) {
        if (
          String(this.props.NRpickupAdresss_loc.lng) !== "" &&
          String(this.props.NRdropOffsAdresss_loc.lng) !== ""
        ) {
          let arr = [
            [
              this.props.NRpickupAdresss_loc.lat,
              this.props.NRpickupAdresss_loc.lng,
            ],
            [
              this.props.NRdropOffsAdresss_loc.lat,
              this.props.NRdropOffsAdresss_loc.lng,
            ],
          ];
          this.props.NRotherDropOffs.map((drop) => {
            if (String(drop.adresss_loc.lng) !== "") {
              arr.push([drop.adresss_loc.lat, drop.adresss_loc.lng]);
            }
          });

          return Leaflet.latLngBounds(arr);
        } else {
          return null;
        }
      }
    } else if (["Pickup"].indexOf(this.props.orderType) > -1) {
      if (this.props.EROpenERDialoge) {
        if (
          String(this.props.ERpickupAdresss_loc.lng) !== "" &&
          String(this.props.ERdropOffsAdresss_loc.lng) !== ""
        ) {
          let arr = [
            [
              this.props.ERpickupAdresss_loc.lat,
              this.props.ERpickupAdresss_loc.lng,
            ]
          ];

          return Leaflet.latLngBounds(arr);
        } else {
          return null;
        }
      } else if (
        this.props.OpenVRDialoge &&
        !this.props.EROpenERDialoge &&
        !_.isEmpty(this.props.VRpureData) &&
        this.props.VRpureData.pickup
      ) {
        let arr = [
          [
            this.props.VRpureData.pickup.coordinates[1],
            this.props.VRpureData.pickup.coordinates[0],
          ],
        ];

        if (this.props.driver_location) {
          arr.push(this.props.driver_location[1], this.props.driver_location[0]);
        }

        return Leaflet.latLngBounds(arr);
      } else if (isNewOrder()) {
        if (
          String(this.props.NRpickupAdresss_loc.lng) !== "" &&
          String(this.props.NRdropOffsAdresss_loc.lng) !== ""
        ) {
          let arr = [
            [
              this.props.NRpickupAdresss_loc.lat,
              this.props.NRpickupAdresss_loc.lng,
            ],
            [
              this.props.NRdropOffsAdresss_loc.lat,
              this.props.NRdropOffsAdresss_loc.lng,
            ],
          ];
          this.props.NRotherDropOffs.map((drop) => {
            if (String(drop.adresss_loc.lng) !== "") {
              arr.push([drop.adresss_loc.lat, drop.adresss_loc.lng]);
            }
          });

          return Leaflet.latLngBounds(arr);
        } else {
          return null;
        }
      }
    } else if (["PickupDelivery"].indexOf(this.props.orderType) > -1) {
      if (this.props.EROpenERDialoge) {
        if (
          String(this.props.ERpickupAdresss_loc.lng) !== "" &&
          String(this.props.ERdropOffsAdresss_loc.lng) !== ""
        ) {
          let arr = [
            [
              this.props.ERpickupAdresss_loc.lat,
              this.props.ERpickupAdresss_loc.lng,
            ],
            [
              this.props.ERdropOffsAdresss_loc.lat,
              this.props.ERdropOffsAdresss_loc.lng,
            ],
          ];
          this.props.ERotherDropOffs.map((drop) => {
            if (String(drop.adresss_loc.lng) !== "") {
              arr.push([drop.adresss_loc.lat, drop.adresss_loc.lng]);
            }
          });

          return Leaflet.latLngBounds(arr);
        } else {
          return null;
        }
      } else if (
        this.props.OpenVRDialoge &&
        !this.props.EROpenERDialoge &&
        !_.isEmpty(this.props.VRpureData) &&
        this.props.VRpureData.pickup
      ) {
        let arr = [
          [
            this.props.VRpureData.pickup.coordinates[1],
            this.props.VRpureData.pickup.coordinates[0],

          ],
          [
            this.props.VRpureData_delivery.coordinates[1],
            this.props.VRpureData_delivery.coordinates[0],
          ]
        ];

        // this.props.VRpureData.dropoffs.map((drop, i) => {
        //   arr.push([drop.coordinates[1], drop.coordinates[0]]);
        // });


        if (this.props.driver_location) {
          arr.push(this.props.driver_location[1], this.props.driver_location[0]);
        }

        return Leaflet.latLngBounds(arr);
      } else if (isNewOrder()) {
        if (
          String(this.props.NRpickupAdresss_loc.lng) !== "" &&
          String(this.props.NRdropOffsAdresss_loc.lng) !== ""
        ) {
          let arr = [
            [
              this.props.NRpickupAdresss_loc.lat,
              this.props.NRpickupAdresss_loc.lng,
            ],
            [
              this.props.NRdropOffsAdresss_loc.lat,
              this.props.NRdropOffsAdresss_loc.lng,
            ],
          ];
          this.props.NRotherDropOffs.map((drop) => {
            if (String(drop.adresss_loc.lng) !== "") {
              arr.push([drop.adresss_loc.lat, drop.adresss_loc.lng]);
            }
          });

          return Leaflet.latLngBounds(arr);
        } else {
          return null;
        }
      }
    } else {
      if (this.props.EROpenERDialoge) {
        if (
          String(this.props.ERpickupAdresss_loc.lng) !== "" &&
          String(this.props.ERdropOffsAdresss_loc.lng) !== ""
        ) {
          let arr = [
            [
              this.props.ERpickupAdresss_loc.lat,
              this.props.ERpickupAdresss_loc.lng,
            ],
            [
              this.props.ERdropOffsAdresss_loc.lat,
              this.props.ERdropOffsAdresss_loc.lng,
            ],
          ];
          this.props.ERotherDropOffs.map((drop) => {
            if (String(drop.adresss_loc.lng) !== "") {
              arr.push([drop.adresss_loc.lat, drop.adresss_loc.lng]);
            }
          });

          return Leaflet.latLngBounds(arr);
        } else {
          return null;
        }
      } else if (
        this.props.OpenVRDialoge &&
        !this.props.EROpenERDialoge &&
        !_.isEmpty(this.props.VRpureData) &&
        this.props.VRpureData.pickup
      ) {
        let arr = [
          [
            this.props.VRpureData.pickup.coordinates[1],
            this.props.VRpureData.pickup.coordinates[0],
          ],
        ];
        this.props.VRpureData.dropoffs.map((drop, i) => {
          arr.push([drop.coordinates[1], drop.coordinates[0]]);
        });


        if (this.props.driver_location) {
          arr.push(this.props.driver_location[1], this.props.driver_location[0]);
        }


        return Leaflet.latLngBounds(arr);
      } else if (isNewOrder()) {
        if (
          String(this.props.NRpickupAdresss_loc.lng) !== "" &&
          String(this.props.NRdropOffsAdresss_loc.lng) !== ""
        ) {
          let arr = [
            [
              this.props.NRpickupAdresss_loc.lat,
              this.props.NRpickupAdresss_loc.lng,
            ],
            [
              this.props.NRdropOffsAdresss_loc.lat,
              this.props.NRdropOffsAdresss_loc.lng,
            ],
          ];
          this.props.NRotherDropOffs.map((drop) => {
            if (String(drop.adresss_loc.lng) !== "") {
              arr.push([drop.adresss_loc.lat, drop.adresss_loc.lng]);
            }
          });

          return Leaflet.latLngBounds(arr);
        } else {
          return null;
        }
      }
    }

  };


  OsmOncLick = (e) => {
    this.props.dispatch(SetSelectedMonitoringOrder(null))
    this.props.dispatch(SetSelectedMonitoringDriver(null))
    this.props.dispatch(SetDriverPopUp(null))
    this.props.dispatch(SetOrderPinPopUp(null))
    // this.props.dispatch(AddMonitoringOrdersBounding({ordersBoundingList : [] , ordersBoundingDriver: null},"341"))

  }


  render() {
    return (
      <div
        className={this.props.className}
        style={{ ...this.props.style, position: "relative" }}
      >
        {(this.props.Globalsettings && this.props.Globalsettings.dispatcherHomeMessage && String(this.props.Globalsettings.dispatcherHomeMessage).length > 0) && <div className={styles.helloMessageContainer}>
          <IconProvider Icon={INFO_CIRCLE} />
          <Typography weight="Body_Middle_Regular" textColor="TextDark" text={this.props.Globalsettings.dispatcherHomeMessage} />
        </div>}
        <Map
          style={{
            zIndex: "1",
            width: "100%",
            height: "100%",
            margin: "0"
          }}
          maxZoom={18}
          inertia={true}
          animate={true}
          duration={0.5}
          onmoveend={this.handleMove.bind(this)}
          easeLinearity={0.25}
          zoomControl={false}
          boundsOptions={{
            paddingBottomRight: [150, 150],
            paddingTopLeft: [150, 150],
          }}
          length={4}
          // bounds={this.props.customBounds ? this.props.customBounds : this.boundsGenerator()}

          zoom={this.state.zoom}
          center={this.props.center}
          ref={this.props.mapRef}
          onclick={this.OsmOncLick}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {this.props.children}
   
        </Map>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  customBounds: ownProps.customBounds,

  mapRef: state.Map.mapRef,
  center: state.Map.center,
  FavAddressOpenDialoge: state.FavAddress.openDialoge,
  OpenNRDialoge: state.NewOrder.OpenDialoge,
  NRactivePickupDropOff: state.NewOrder.newOrder.activePickupDropOff,
  NRpickupAddress: state.NewOrder.newOrder.pickup.address,
  NRpickupAdresss_loc: state.NewOrder.newOrder.pickup.adresss_loc,
  NRdropOffAddress: state.NewOrder.newOrder.dropOffs.firstDropOff.address,
  NRdropOffsAdresss_loc:
    state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc,
  NRotherDropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,

  OpenVRDialoge: state.ViewTravel.OpenDialoge,
  VRpureData: state.ViewTravel.pureData,

  EROpenERDialoge: state.EditOrder.OpenDialoge,
  ERactivePickupDropOff: state.EditOrder.editOrder.activePickupDropOff,
  ERpickupAddress: state.EditOrder.editOrder.pickup.address,
  ERpickupAdresss_loc: state.EditOrder.editOrder.pickup.adresss_loc,
  ERdropOffAddress: state.EditOrder.editOrder.dropOffs.firstDropOff.address,
  ERdropOffsAdresss_loc:
    state.EditOrder.editOrder.dropOffs.firstDropOff.adresss_loc,
  ERotherDropOffs: state.EditOrder.editOrder.dropOffs.otherDropOffs,
  center: state.Map.center,
  mapRef: state.Map.mapRef,
  center: state.Map.center,
  showEventTimeLine: state.ViewTravel.eventTimeLine.show,

  orderType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
      ? state.ViewTravel.pureData.type
      : null
    : null,

  MonitoringOrdersOpen: state.Monitoring.orders.open,
  MonitoringDriverSelected: state.Monitoring.selectedOrderDriver,
  ordersBoundingList: state.Monitoring.ordersBoundingList,
  ordersBoundingDriver: state.Monitoring.ordersBoundingDriver,
  driver_location: state.ViewTravel.driver_location,
  Globalsettings: state.SharedData.settings,
  VRpureData_delivery: state.ViewTravel.pureData ? state.ViewTravel.pureData.delivery : null,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);




function isNewOrder () {
  if (String(window.location.href).includes("neworder")) {
    return true
  } else {
    return false
  }
}
