import React from 'react';
import styles from './style.module.css';
import statusAt from "../../../assets/statusAt.svg";
import delaytimer from "../../../assets/delay.svg";
import question from "../../../assets/question.svg";
import pending from "../../../assets/ic-pending.svg";
import collecting from "../../../assets/ic-collecting.svg";
import star from "../../../assets/star.svg";
import progress from "../../../assets/ic-in-progress-voip.svg";
import delivering from "../../../assets/ic-delivering.svg";
import canceled from "../../../assets/ic-canceled.svg";
import pin from "../../../assets/ic-pin.svg";
import done from "../../../assets/ic-done.svg";
import transit from "../../../assets/ic-in-transit.svg";
import { GetColorByStatus } from '../../../utils/get-colors-by-status';
import { useDispatch, useSelector } from 'react-redux';
import { GetCategoryByStatus } from '../../../utils/get-category-by-status';
import { useTranslation } from 'react-i18next';
import { GetNextStatusListItem } from '../../../utils/getNextStatus_ListItem';
import { get_calendarType, get_lang } from '../../../helper/UserData/userdate';
import momentJalaali from "moment-jalaali";
import { useCallback, useEffect, useState } from 'react';
import manual from "../../../assets/manual.svg";
import auto_dispatch from "../../../assets/auto_dispatch.svg";
import noDriver from '../../../assets/ic-driver-not-selected.svg';
import queue from '../../../assets/ic-queue.svg';
import radar from '../../../assets/ic-radar.svg';
import rejected from '../../../assets/ic-driver-rejected.svg';
import radar_color from '../../../assets/radar_color.svg';
import urgent from '../../../assets/urgent.svg';
import dispatch_later from "../../../assets/dispatch_later.svg";
import dispatching from "../../../assets/dispatching.svg";
import unassigned from "../../../assets/unassigned.svg";
import jalali from "jalali-moment";
import dispatch_later_tooltip from '../../../assets/dispatch_later_tooltip.svg';
import arrowDoenOrderId from '../../../assets/arrowDoenOrderId.svg';
import orderIDicon from '../../../assets/orderIDicon.svg';

import _, { isEmpty } from 'lodash';
import driver_wait_confirm from "../../../assets/driver_wait_confirm.svg";
import { OfferingDetailsOpen, PriceDetailsOpen, SetViewOrderPickupDropoffDetails, show_eventTimeLine } from '../../../Redux/actions/actionNames';
import { Dialog, DialogContent, DialogContentText, Tooltip, styled, tooltipClasses } from 'newmaterial-ui';
import { OrderApi } from '../../../service/API/order';
import clsx from 'clsx';
import ic_radar_urgent from "../../../assets/ic_radar_urgent.svg";
import queue_urgent from "../../../assets/queue_urgent.svg";
import radar_urgent from "../../../assets/radar_urgent.svg";

import WindowFocusHandler from '../../../hooks/useIsWindowFocused';
import useIsWindowFocused from '../../../hooks/useIsWindowFocused';
import Axios from 'axios';
import { Deliveryonly_deactive, PickupOnly_Deactive, TRUCK, refresh_dark } from '../../../assets';
import Typography from '../../../design-system/lib/Typography';


export const Status = () => {
    const status = useSelector((state) => state.ViewTravel.pureData.status)
    const pureData = useSelector((state) => state.ViewTravel.pureData)
    const statusUpdatedAt = useSelector((state) => state.ViewTravel.pureData.statusUpdatedAt)
    const referenceID = useSelector((state) => state.ViewTravel.pureData.referenceId)
    const orderType = useSelector((state) => state.ViewTravel.pureData.type)
    const realETA = useSelector((state) => state.ViewTravel.pureData.realETA)
    const isPossibleForDelay = useSelector((state) => state.ViewTravel.pureData.isPossibleForDelay)
    const delayAt = useSelector((state) => state.ViewTravel.pureData.delayAt)
    const isDelayed = useSelector((state) => state.ViewTravel.pureData.isDelayed)
    const customerRateText = useSelector((state) => state.ViewTravel.pureData.customerRateText)
    const customerRate = useSelector((state) => state.ViewTravel.pureData.customerRate)
    const cancellationReason = useSelector((state) => state.ViewTravel.pureData.cancellationReason)
    const driverAssignmentModel = useSelector((state) => state.ViewTravel.pureData.driverAssignmentModel)
    const candidateDriversCount = useSelector((state) => state.ViewTravel.pureData.candidateDriversCount)
    const currentCandidateDrivers = useSelector((state) => state.ViewTravel.pureData.currentCandidateDrivers)
    const isUrgent = useSelector((state) => state.ViewTravel.pureData.isUrgent)
    const offering_details = useSelector((state) => state.ViewTravel.offering_details)
    const [urgentOpenMenu, setUrgentOpenMenu] = useState(false)
    const driver = useSelector((state) => state.ViewTravel.pureData.driver)
    const dispatch = useDispatch();
    const orderId = useSelector((state) => state.ViewTravel.pureData.id)
    const [nextStatusMin, setNextStatusMin] = React.useState(0);
    const stage = useSelector((state) => state.ViewTravel.pureData.stage)
    const delivery = useSelector((state) => state.ViewTravel.pureData.delivery)
    const pickup = useSelector((state) => state.ViewTravel.pureData.pickup)


    const [minutes, setMinutes] = useState(0);

    const { t } = useTranslation();



    const getCategoryIcon = useCallback(() => {
        switch (GetCategoryByStatus(pureData.status, pureData.isDispatching, pureData.type, pureData.driverAssignmentModel, pureData.isDelayed, pureData.isPossibleForDelay)) {
            case "Assigned": return collecting; break;
            case "Pending": return pending; break;
            case "InProgress": return progress; break;
            case "Collecting": return collecting; break;
            case "Delivering": return delivering; break;
            case "Done": return done; break;
            case "Canceled": return canceled; break;
            case "Transit": return transit; break;
            case "DispatchLater": return dispatch_later; break;
            case "Dispatching": return dispatching; break;
            case "Unassigned": return unassigned; break;

        }
    }, [pureData]);


    const isDispatchLater = useCallback(() => {
        switch (GetCategoryByStatus(pureData.status, pureData.isDispatching, pureData.type, pureData.driverAssignmentModel, pureData.isDelayed, pureData.isPossibleForDelay)) {
            case "DispatchLater": return true;
            default: return false;
        }
    }, [pureData]);


    const canShowEtaDelay = useCallback(() => {
        return (["Pending", "Assigned", "Started", "Arrived", "PickedUp"].indexOf(status) > -1 && realETA)
    }, [status, realETA]);




    useEffect(() => {
        if (["Pending", "Assigned", "Started", "Arrived", "PickedUp"].indexOf(status) > -1) {
            if (realETA) {
                if (isPossibleForDelay) {
                    let min1 = Math.round(parseInt(Math.abs(realETA - delayAt) / 1000) / 60)
                    setMinutes(min1)
                } else if (isDelayed) {
                    let now2 = new Date();
                    let min2 = Math.round(parseInt(Math.abs(now2.getTime() - delayAt) / 1000) / 60)
                    setMinutes(min2)
                    const interval = setInterval(() => {
                        let now3 = new Date();
                        let min3 = Math.round(parseInt(Math.abs(now3.getTime() - delayAt) / 1000) / 60)
                        setMinutes(min3)
                    }, 50000);
                    return () => clearInterval(interval);
                }
            } else {
                setMinutes(0)
            }
        } else {
            setMinutes(0)
        }
    }, [status, realETA, isPossibleForDelay, isDelayed])


    function isNoDriver() {
        return (_.isEmpty(driver) && ["Pending"].indexOf(status) == -1)
    }

    function isQueue() {
        return (["Pending"].indexOf(status) > -1 && driverAssignmentModel == "automatic" && _.isEmpty(driver) && candidateDriversCount == 0 /* && _.isEmpty(currentCandidateDrivers) */) && !isDispatchLater()
    }

    function isOffering() {
        return (["Pending"].indexOf(status) > -1 && driverAssignmentModel == "automatic" && _.isEmpty(driver) && candidateDriversCount > 0) && !isDispatchLater()
    }

    function isWaitforAccept() {
        return (["Pending"].indexOf(status) > -1 && driverAssignmentModel == "manual" && _.isEmpty(driver) && currentCandidateDrivers[0] && ["received", "notifying", "initial"].indexOf(currentCandidateDrivers[0].status) > -1) && !isDispatchLater()
    }

    function isRejected() {
        return (["Pending"].indexOf(status) > -1 && driverAssignmentModel == "manual" && _.isEmpty(driver) && currentCandidateDrivers[0] && ["rejected"].indexOf(currentCandidateDrivers[0].status) > -1) && !isDispatchLater()
    }


    const offeringDialoge = useCallback(() => {
        dispatch(OfferingDetailsOpen(!offering_details))
        dispatch(show_eventTimeLine(false))
        dispatch(PriceDetailsOpen(false))
        dispatch(SetViewOrderPickupDropoffDetails(null))
    }, [offering_details]);




    const markUrgent = useCallback(() => {
        let Order = new OrderApi()
        Order.MarkUrgent(orderId)
        setUrgentOpenMenu(false)
    }, [isUrgent]);

    useEffect(() => {
        if (realETA && !isPossibleForDelay && !isDelayed) {
            let now4 = new Date();

            let from = Math.floor(now4.getTime() / 1000);
            let to = momentJalaali(realETA).locale(get_calendarType()).unix();
            if (to >= from) {
                let min = (to - from) / 60;
                setNextStatusMin(Math.round(min));
                const interval = setInterval(() => {
                    let now5 = new Date();

                    let from = Math.floor(now5.getTime() / 1000);
                    let min = (to - from) / 60;

                    setNextStatusMin(Math.round(min));
                }, 20000);
                return () => clearInterval(interval);
            }
        }
    }, [realETA, isPossibleForDelay, isDelayed]);


    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: ' #fff',
            borderRadius: "8px",
            padding: "0",
            border: 'none',
            boxShadow: "0 4px 12px 0 rgba(0, 0, 0, 0.24)",
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            marginBottom: "0px !important"
        },
    }));


    return <div className={styles.status}>
        {/* <img src={getCategoryIcon()} className={styles.statusLogo} /> */}
        <span className={styles.statusText} style={{ color: `#${GetColorByStatus(status)}` }}>{t("EDAS_" + status)}</span>


        {(stage) && <span className={styles.statusAt}> <img src={stage == "Pickup" ? PickupOnly_Deactive : Deliveryonly_deactive} /> <Typography weight='Body_Small_Regular' text={stage == "Pickup" ? "P" : "D"} /> </span>
        }
        {(delivery?.attemptsCount > 0) && <span className={styles.statusAt}> <img src={TRUCK} /> <Typography weight='Body_Small_Regular' text={`${delivery?.attemptsCount} Attempt`} /> </span>
        }


        {(!!canShowEtaDelay() && nextStatusMin > 0) && <span className={styles.statusAt}> <img src={statusAt} /> <span>{String(t(`${GetNextStatusListItem(status, orderType)}`)).replace("xxx", nextStatusMin)}</span> </span>
        }

        {/* {(!!canShowEtaDelay() && !isPossibleForDelay && isDelayed && Number(minutes) > 0) && <span className={styles.statusAt}> <img src={delaytimer} />
            <span>{String(t(`mindelay`)).replace("xxx", minutes)}</span> </span>
        } */}


        {(!!canShowEtaDelay() && !isPossibleForDelay && isDelayed) && <DelayComponent delayAt={delayAt} />
        }




        {(!!canShowEtaDelay() && isPossibleForDelay && Number(minutes) > 0) && <span className={styles.statusAt} >
            <img src={delaytimer} />
            <span>{String(t(`minlate`)).replace("xxx", minutes)}</span> </span>
        }
        <span className={styles.orderCode}>  {(statusUpdatedAt ? String(referenceID).length > 0 : false) && <><img src={refresh_dark} /> {jalali(statusUpdatedAt)
            .locale(get_lang())
            .format("D MMM HH:mm")}</>} </span>



        {(['CustomerCanceled', 'DriverCanceled', 'SupportCanceled'].indexOf(status) > -1 && cancellationReason) && <>   <hr className={styles.hr} />

            <img src={question} alt="" className={styles.star} />
            <span className={styles.rateText}>{cancellationReason.text}</span>
        </>
        }

        {(pickup?.failedReason && stage == "Pickup") && <>   <hr className={styles.hr} />

            <img src={question} alt="" className={styles.star} />
            <span className={styles.rateText}>{pickup?.failedReason?.text}</span>
        </>
        }


        {(delivery?.lastAttemptReason && !isEmpty(delivery?.lastAttemptReason) && stage == "Delivery") && <>   <hr className={styles.hr} />

            <img src={question} alt="" className={styles.star} />
            <span className={styles.rateText}>{delivery?.lastAttemptReason?.text}</span>
        </>
        }

        {['Pending'].indexOf(status) == 0 && <><hr className={styles.hr} />

            <div className={styles.dipatchRowContainer}>
                <div className={styles.dipatchRowDiv}>
                    <img src={driverAssignmentModel == "automatic" ? auto_dispatch : manual} alt="" />
                    <span className={styles.dipatchType}>{t(driverAssignmentModel == "automatic" ? "AutoDispatch" : "Manual")}</span>
                    {isNoDriver() && <span className={styles.driverStatus}> <img src={noDriver} /> {t("NoDriver")} </span>}
                    {isQueue() && <span className={styles.driverStatus}> <img src={isUrgent ? queue_urgent : queue} /> {t("Queue")} </span>}
                    {isOffering() && <span className={styles.driverStatus}> <img src={isUrgent ? radar_urgent : radar} /> {String(t("OfferingToDrivers")).replace("xxx", candidateDriversCount)} </span>}
                    {isWaitforAccept() && <span className={styles.driverStatus}> <img src={driver_wait_confirm} /> {t("waitforconfirm2")} </span>}
                    {isRejected() && <span className={styles.driverStatus}> <img src={rejected} /> {t("Rejected")} </span>}
                    {isDispatchLater() && <span className={styles.driverStatus}> <img src={dispatch_later_tooltip} /> {String(t("Dispatchat")).replace("xxx", jalali(pureData.dispatchAt)
                        .locale(get_lang())
                        .format("D MMM HH:mm"))} </span>}

                </div>



                <div className={styles.dipatchRowDivR}>
                    {(["Pending"].indexOf(status) > -1 && currentCandidateDrivers.length > 0) && <img className={clsx(styles.marginFleft, styles.urgent, offering_details && styles.isUrgent)} src={radar_color} onClick={offeringDialoge} />
                    }
                    {(["Pending"].indexOf(status) > -1 && driverAssignmentModel == "automatic" && !isUrgent) && <img className={clsx(styles.urgent, isUrgent && styles.isUrgent)} src={urgent} onClick={() => setUrgentOpenMenu(true)} />
                    }

                </div>

            </div></>
        }



        {
            (['Done'].indexOf(status) > -1 && (customerRate || customerRateText)) && <>   <hr className={styles.hr} />

                <img src={star} alt="" className={styles.star} />
                <span className={styles.rateText}>{`${customerRate} ${customerRate && ", "} ${customerRateText}`}</span>

            </>
        }


        <Dialog
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open={urgentOpenMenu}
            onClose={() => setUrgentOpenMenu(false)}
            className="logoutContainer"
            disableAutoFocus
            disableEnforceFocus
            disableRestoreFocus
        >
            {" "}
            <p
                className="logotTitle"
            >

                {t("urgentTitle")}
            </p>
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-description"
                    className="logoutDescription"
                >
                    {t("urgentDesc")}

                </DialogContentText>
            </DialogContent>
            <div style={{ direction: "ltr", height: "42px" }}>
                <span
                    onClick={() => markUrgent()}
                    className="confirmLogout"
                    variant="contained"
                >
                    {t("urgentConfirm")}

                </span>
                <span
                    onClick={() => setUrgentOpenMenu(false)}
                    variant="contained"
                    className="skipLogout"

                >
                    {t("urgentClose")}

                </span>
            </div>
        </Dialog>


    </div >

};





const DelayComponent = ({ delayAt }) => {
    const { t, i18n } = useTranslation();
    const [minutes, setMinutes] = useState(0);
    const windowIsActive = useIsWindowFocused();
    const timestamp = useSelector((state) => state.LocalData.timestamp)

    // function getMinute(ddd) {
    //     return Math.round(parseInt(Math.abs(ddd - delayAt) / 1000) / 60)
    // }


    // useEffect(() => {
    //     Axios.get('https://worldtimeapi.org/api/ip')
    //     .then((response)=> {
    //         // handle success
    //         setMinutes(getMinute(Number(response.data.unixtime + '000')))
    //     })
    //     .catch( (error)=> {
    //         // handle error

    //         setMinutes(getMinute(Date.now()))
    //     })
    // }, [windowIsActive]);


    // useEffect(() => {
    //     Axios.get('https://worldtimeapi.org/api/ip')
    //     .then((response)=> {
    //         // handle success
    //         setMinutes(getMinute(Number(response.data.unixtime + '000')))
    //     })
    //     .catch( (error)=> {
    //         // handle error

    //         setMinutes(getMinute(Date.now()))
    //     })
    //     const newIntervalId = setInterval(() => {
    //         Axios.get('https://worldtimeapi.org/api/ip')
    //         .then((response)=> {
    //             // handle success
    //             setMinutes(getMinute(Number(response.data.unixtime + '000')))
    //         })
    //         .catch( (error)=> {
    //             // handle error

    //             setMinutes(getMinute(Date.now()))
    //         })
    //     }, 50000);
    //     return () => clearInterval(newIntervalId);
    // }, [windowIsActive]);

    return <>
        {/* <span className={styles.tillAt}> <img className={styles.icTimer} src={icTimer} alt="" />  {String(t(`${GetNextStatusListItem(status, type)}`)).replace("xxx", momentJalaali(realETA).locale(get_calendarType()).format("HH:mm"))} </span> */}
        {Number(timestamp) > 0 &&

            <span className={styles.statusAt}><img src={delaytimer} />
                <span >{`${parseInt(Math.abs(timestamp - delayAt) / 1000 / 60)} m`}</span> </span>} </>
    // return <span className={styles.tillAt}> <span className={styles.delayRed}><img className={styles.icTimer} src={delaytimer} alt="" />  {String(t(`mindelay`)).replace("xxx", minutes)}</span> ({String(t(`${GetNextStatusListItem(status, type)}`)).replace("xxx", momentJalaali(realETA).locale(get_calendarType()).format("HH:mm"))}) </span>
}
