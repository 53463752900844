import styles from './style.module.css';
import { Logo } from './components/logo'
import { Divider } from './components/divider'
import { FilterButton } from './components/filter-button'
import DateRangePicker from './components/date-range-picker'
import { NewOrderButton } from './components/new-order-menu-button'
import { BulkImportButton } from './components/bulk-import-button'
import { OrderTypeFilter } from './components/order-type-filter'
import { MonitoringButton } from './components/monitoring-button'
import { Profile } from './components/profile'
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../design-system/lib/Button';
import { DRIVER_COLOR, Export, IMPORT_ICON, PLUS, Plus_Dark, Refresh_Table, Settings } from '../../../assets';
import { ChangeNewTravelDialoge, SetDriverListOpenDialoge } from '../../../Redux/actions/actionNames';
import { ServicesVehicletypeAction } from '../../../Redux/actions/actions';
import { Link } from "react-router-dom";
import Switch from '../../../design-system/lib/Switch';
import InputFilter from '../../../design-system/lib/InputFilter';
import PhoneFilter from '../../../design-system/lib/PhoneFilter';
import CheckBoxFilter from '../../../design-system/lib/CheckBoxFilter';
import RadioButtonFilter from '../../../design-system/lib/RadioButtonFilter';
import DateFilter from '../../../design-system/lib/DateFilter';
import { useState } from 'react';
import TableListColumnSetting from '../../table/table-list-column-setting';
import history from '../../../service/history';
import { getTableOrderList } from '../../shared/table/action/getTableOrderList';

export const Navbar = ({ isViewOrder }) => {
    const openNewOrder = useSelector((state) => state.NewOrder.OpenDialoge)
    const ActiveOrderType = useSelector((state) => state.OrderList.ActiveOrderType)
    const dispatch = useDispatch();
    const center = useSelector((state) => state.Map.center)
    // const [filter1, setFilter1] = useState(null);
    // const [filter1state, setFilter1state] = useState("Isequal");
    // const [filter2, setFilter2] = useState(null);
    // const [filter3, setFilter3] = useState([]);
    // const [filter4, setFilter4] = useState(null);
    // const [filter5, setFilter5] = useState({ from: null, to: null });
    const Globalsettings = useSelector((state) => state.SharedData.settings)
    const DriversListopenDialoge = useSelector((state) => state.DriversList.openDialoge)

    function NewTravelHandler() {
        dispatch(ServicesVehicletypeAction(dispatch, center))
        // dispatch(ChangeNewTravelDialoge());
        history.push("/neworder")
    };


    function isMapSectionPickupDelivery() {
        if (String(window.location.href).includes("/map")) {
            return true
          } else {
            return false
          }
    };


      

    return <div className={clsx(styles.navbarContainer /*, (openNewOrder || isViewOrder) && styles.hideNavbarContainer */)}>
        <div className={styles.leftItems}>
            {/* <Logo />
            <Divider /> */}
            {ActiveOrderType != "PickupDelivery" && <> <FilterButton />
                <DateRangePicker />
            </>}


            {ActiveOrderType == "PickupDelivery" && <>
                <Switch handleSelection={(e) => { e == 0 ? history.push("/PickupDelivery") : history.push("/PickupDelivery/map") }} index={isMapSectionPickupDelivery() ? 1 : 0} items={["Table", "Map"]} type='topMenu' />
            </>}
            {ActiveOrderType == "PickupDelivery" && <>
                <Button size='Medium' type='Secondary' icon={Refresh_Table} style={{ backgroundColor: "unset" }} onClick={()=>getTableOrderList(null , null ,"PickupDelivery")} />
              
            </>}




            {/* <InputFilter title='Input' placement='bottomStart' value={filter1} onChange={setFilter1} state={filter1state} stateOnChange={setFilter1state} />
            <PhoneFilter title='Phone' placement='bottomStart' defaultCountry={global.config.Country} value={filter2} onChange={setFilter2} />
            <CheckBoxFilter title='CheckBox' placement='bottomStart' list={[{ key: "1", value: "1" }, { key: "2", value: "2" }, { key: "3", value: "13" }]} value={filter3} onChange={setFilter3} />
            <RadioButtonFilter title='RadioButton' placement='bottomStart' list={[{ key: "1", value: "1" }, { key: "2", value: "2" }, { key: "3", value: "3" }]} value={filter4} onChange={setFilter4} />
            <DateFilter title='Date' placement='bottomStart' value={filter5} onChange={setFilter5} /> */}

            {/* <Divider /> */}
            {/* <NewOrderButton /> */}
        </div>
        {/* <div className={styles.middleItems}>
            <OrderTypeFilter/>
        </div> */}
        <OrderTypeFilter />

        <div className={styles.rightItems}>
            {/* <BulkImportButton /> */}
            {/* <MonitoringButton/> */}
     

          {ActiveOrderType == "PickupDelivery" &&  <TableListColumnSetting />}
            {/* <Divider /> */}
           {
                // Globalsettings.role?.haveAdminAccess && <a href={'admin/home'}> <Button size='Medium' type='Secondary' icon={Settings} text={"Admin"} /></a>
            }
            {/* <Button size='Medium' type='Secondary' text='Export' icon={Export} disabled={true} /> */}

            <Link to="/BulkImport" >
                <Button size='Medium' type='Secondary' text='Import' icon={IMPORT_ICON} />
            </Link>
            <Button size='Medium' type='Primary' text='New' icon={PLUS} onClick={NewTravelHandler} />

            <Divider />
            {/* <Profile/> */}
            <Button size='Medium' type='Secondary' text='Drivers' icon={DRIVER_COLOR} onClick={()=>{  dispatch(SetDriverListOpenDialoge(!DriversListopenDialoge))}} />

        </div>
    </div>

};




