export const Portuguese = {
    "Enter_the_following_information_to_enter": "Digite as informações para acessar",
    "login": "Login",
    "Username": "Nome de usuário",
    "password": "Senha",
    "Orders": "Pedidos",
    "Online": "Online",
    "Offline": "Offline",
    "Info": "Informação",
    "email": "E-mail",
    "vehicletypes": "Tipos de serviço",
    "loginstatus": "Status de Login",
    "lastonlinetime": "Última vez online",
    "fetchingdata": "Recebendo informação...",
    "poperLoading": "Por favor, aguarde",
    "Noinformationfound": "Nenhuma informação encontrada",
    "Drivers": "Entregadores",
    "otherdropoffs": "Outras entregas",
    "paymentmethod": "Forma de pagamento",
    "Receiver": "Por Destinatário",
    "Sender": "Por Solicitante",
    "Balance": "Saldo",
    "Cash": "Dinheiro",
    "Wallet": "Carteira",
    "Close": "Fechar",
    "noVehicleType": "Não há serviços",
    "Fname": "Primeiro nome",
    "Lname": "Sobrenome",
    "Pnumber": "Número de telefone",
    "driver": "Entregador",
    "Pleasetryanotherkeyword": "Por favor, tente outra senha",
    "DropOff": "Entrega",
    "Noaddressavailable": "Nenhum endereço disponível",
    "Managefavoriteaddresses": "Gerenciar endereços",
    "address": "Endereço",
    "Saved": "Salvo",
    "block": "Complemento",
    "Floor": "Piso",
    "Unit": "Unidade",
    "Recieverfullname": "Nome do destinatário",
    "Recieverphonenumber": "Número do destinatário",
    "notesfordriver": "Notas para ao entregador",
    "Price": "Preço",
    "EditOrder": "Editar pedido",
    "Firstname": "Primeiro nome",
    "Lastname": "Sobrenome",
    "Phonenumber": "Número de telefone",
    "senderfullname": "Nome do solicitante",
    "senderphonenumber": "Número do solicitante",
    "Search": "Pesquisa",
    "Service": "Serviço",
    "Passenger": "Cliente",
    "Pickup": "Coleta",
    "Pricedetails": "Detalhes do preço",
    "Fee": "Tarifa",
    "Fornow": "Para agora",
    "Scheduled": "Agendado",
    "before": "antes de :",
    "Settime": "Tempo definido",
    "after": "depois de :",
    "confirm": "Confirmar",
    "Cancel": "Cancelar",
    "Addresses": "Endereços",
    "Savedaddresses": "Endereços salvos",
    "new": "Novo",
    "Editaddress": "Editar endereço",
    "Saveaddress": "Salvar endereço",
    "Language": "Idioma",
    "en": "Inglês",
    "fa": "Persa",
    "English": "Inglês",
    "Persian": "Persa",
    "Logout": "Sair",
    "logotTitle": "Sair da conta?",
    "logoutDescription": "É necessário acessar novamente",
    "confirmLogout": "Sim, sair",
    "skipLogout": "Cancelar",
    "Ascending": "Crescente",
    "Descending": "Decrescente",
    "sort[createdAt]": "Data",
    "sort[pickupScheduleDate]": "Horário de coleta",
    "sort[statusUpdatedAt]": "Ordenar por atualização em",
    "SelectDateRange": "Intervalo de datas",
    "StartDate": "Data de início",
    "EndDate": "Data de fim",
    "Today": "Hoje",
    "Yesterday": "Ontem",
    "ThisWeek": "Esta Semana",
    "LastWeek": "Semana passada",
    "Last7Days": "Últimos 7 dias",
    "ThisMonth": "Este Mês",
    "LastMonth": "Último mês",
    "monitoring": "Monitoramento",
    "Haveorder": "Há pedido",
    "Estimated": "Estimado",
    "ReceiverCash": "Dinheiro (pelo Entregador)",
    "SenderCash": "Dinheiro",
    "SenderWallet": "Carteira",
    "Auto": "Auto",
    "Manual": "Manual",
    "SendOrderr": "Enviar pedido",
    "Neworder": "Novo pedido",
    "Person": "Pessoal",
    "Business": "Corporativo",
    "Businessname": "Nome comercial",
    "phonenumber": "Número de telefone",
    "Adddropoff": "Adicionar entrega",
    "Searchbynameorphonenumber": "Pesquisa por nome ou número de telefone",
    "Fullname": "Nome completo",
    "type": "tipo",
    "only": "xxx apenas",
    "ChooseDriver": "Escolher entregador",
    "AllKey": "Todos",
    "MeKey": "Eu",
    "Vehicletype": "Tipo de veículo",
    "status": "Estado",
    "statusInProgress": "Em curso",
    "statusCanceled": "Cancelado",
    "statusPending": "Pendente",
    "statusUnassigned": "Não atribuído",
    "statusDone": "OK",
    "dispatcher": "operadora",
    "customer": "móvel",
    "regexcode": "Código",
    "regexcustomerPhone": "Cliente Cancelado",
    "regexcustomerName": "Nome do cliente",
    "regexdriverPhone": "Telefone do entregador",
    "regexdriverName": "Nome do entregador",
    "regexdriverUsername": "Entregador Nome de usuário",
    "pickupDescription": "Descrição da coleta",
    "filter": "Filtro",
    "clear": "Limpar",
    "orderInfoError": "Erro ao receber informação",
    "Details": "Detalhes",
    "History": "Histórico",
    "Phone": "Telefone",
    "km": "km",
    "Espath": "Rota Es.",
    "Receipt": "Recibo",
    "Copylink": "Link para cópia",
    "repeatOrder": "Repetir o pedido",
    "Edit": "Editar",
    "EditPrice": "Editar preço",
    "Endtrip": "Pedido finalizado",
    "Canceltravel": "Cancelar pedido",
    "CancellText": "O pedido será cancelado e removido do entregador",
    "confirmCancel": "Sim",
    "Endtravel": "completo",
    "EndlText": "O pedido sendo finalizado com sucesso, o valor será debitado automaticamente do saldo",
    "confirmEnd": "Sim",
    "End": "Cancelar pedido",
    "Deliveredat": "Entregue em",
    "pod": "Comprovante de entrega",
    "Notes": "Notas",
    "Nonotes": "Sem notas",
    "Photo": "Foto",
    "Signature": "Assinatura",
    "Nosignature": "Sem assinatura",
    "Nophoto": "Sem foto",
    "ServiceOptions": "Opções de serviço",
    "Events": "Eventos",
    "status2": "status",
    "Creator": "Criador",
    "Creationdate": "Data de criação",
    "Orderid": "ID Pedido",
    "Pickup2": "Coleta",
    "Dropoff2": "Entrega",
    "PleaseSelect": "Selecione por favor",
    "CreateEvent": "Criar Evento",
    "Description": "Descrição",
    "detailsAboutEvent": "Detalhes sobre o evento",
    "CustomerVisibility": "Visibilidade do cliente",
    "LocationOnMap": "Localização no mapa",
    "Pending": "Pendente",
    "Unassigned": "Não atribuído",
    "Assigned": "Em curso",
    "PickedUp": "Em curso",
    "Started": "Em curso",
    "Done": "OK",
    "CustomerCanceled": "Cancelado",
    "DriverCanceled": "Cancelado",
    "SupportCanceled": "Cancelado",
    "Arrived": "Em curso",
    "Createdsuccessfully": "Criado com sucesso",
    "Estime": "Tempo Es.",
    "Promocode": "Código promocional",
    "Updatedsuccessfully": "Atualizado com sucesso",
    "statusAssigned": "Alocado",
    "statusStarted": "Iniciado em",
    "statusPickedUp": "Iniciado em",
    "statusDriverCanceled": "Entregador Cancelado",
    "statusArrived": "Chegou a",
    "statusSupportCanceled": "Suporte Cancelado",
    "Title": "Título",
    "ContactFullname": "Contato Nome completo",
    "Contactphonenumber": "Número de telefone de contato",
    "Commission": "Comissão",
    "surcharge": "Taxa adicional",
    "haslogined": "fez o login",
    "Notloggedin": "Não logado",
    "Schedule": "Horário",
    "Date": "Data",
    "Time": "Hora",
    "noDateToShow": "Não há data para mostrar",
    "noTimeToShow": "Não há tempo para exibir",
    "Apply": "Aplicar",
    "lowBalance": "saldo baixo",
    "balanceError": "O seu saldo deve ser pelo menos igual ao preço do pedido",
    "Methods": "Métodos",
    "Addressbook": "Endereços salvos",
    "AddressbookSearchbar": "Pesquisa por endereço, título e ID",
    "AddressbookDropOf": "Endereços salvos (Entrega)",
    "AddressbookPickup": "Endereços salvos (Coleta)",
    "hide": "Ocultar",
    "Nosavedaddress": "Nenhum endereço salvo",
    "Romanian": "Romeno",
    "business": "Empresa",
    "username": "Nome de usuário",
    "rememberme": "Lembrar dados",
    "SendOrder": "Enviar pedido",
    "code": "Código",
    "noorder": "Sem pedido",
    "pleaseSellectParsel": "Por favor selecione Serviço",
    "Order": "Pedido",
    "Code": "Código",
    "Editorder": "Editar Pedido",
    "all": "Todos",
    "Address": "Endereço",
    "Block/Building": "Complemento",
    "Pickedupat": "Coletado em",
    "for": "Para",
    "fr": "Francês",
    "AllVehicletypes": "Todos os tipos de Serviço",
    "AllServices": "Todos os serviços",
    "French": "Francês",
    "enterDataCorrectly": "Digite a informação corretamente.",
    "controlThatbeforeLoweEqualAfter": "O tempo \"Antes :\" não pode ser inferior a \"Depois :\".",
    "ForceAssign": "Forçar alocação",
    "Allvehicletypes": "Todos os tipos de veículos",
    "waitforconfirm": "xxx ( Aguardar confirmação )",
    "waitforconfirm2": "( Aguardar por confirmação )",
    "Dispatching": "Alocando",
    "NoDriver": "Nenhum entregador",
    "OtherDetails": "Outros detalhes",
    "StatusandDriver": "Status e Entregador",
    "EditStatusandDriver": "Editar Status e Entregador",
    "Current": "Atual",
    "EDAS_Pending": "Pendente",
    "EDAS_Assigned": "Alocado",
    "EDAS_PickedUp": "Coletou",
    "EDAS_Unassigned": "Não atribuído",
    "EDAS_Started": "Iniciado em",
    "EDAS_Done": "OK",
    "EDAS_CustomerCanceled": "Cliente Cancelado",
    "EDAS_DriverCanceled": "Entregador Cancelado",
    "EDAS_SupportCanceled": "Suporte Cancelado",
    "EDAS_Arrived": "Chegou em",
    "Pleasechooseadrivertochangestatus": "Alterando opções, o valor final será atualizado",
    "icWarningEdit": "O tipo de veículo será alterado",
    "Vehicletypewillbechanged": "O tipo de veículo atual será alterado e o preço atualizado",
    "Yourcurrentvehicletypeanditwillbechangedandeffectsonprice": "O tipo de veículo atual será alterado e o preço atualizado",
    "Rejected": "Rejeitado",
    "Rejected2": "(Rejeitado)",
    "DriverVehicleTypeError": "Não existe um tipo de veículo para o entregador",
    "CodeNameFamilyPhonenumber": "Código, Nome, Sobrenome, Número de telefone",
    "youcanselectdriverintheedit": "É possível selecionar o entregador em Edição e Status",
    "Earning": "A receber",
    "savePrice": "Salvar Preço",
    "saveEarning": "Salvar Ganhos",
    "Total": "Total",
    "EditPickupDropOff": "Endereços",
    "noMoreData": "Não há mais informação",
    "OnDemand": "Disponível",
    "PD": "C & E",
    "PD2": "C&E",
    "PickupDropoff": "Coletar, Entregar",
    "PickupFlag": "C",
    "DropOffFlag": "E",
    "PickupandDelivery": "Coleta e Entrega",
    "PickupDelivery": "Coleta e Entrega",
    "Delivery": "Entrega",
    "Collecting": "Coletando",
    "Transit": "Em Trânsito",
    "Delivering": "Entregando",
    "EDAS_Collecting": "Coletando",
    "EDAS_Transit": "Em Trânsito",
    "EDAS_Delivering": "Entregando",
    "statusCollecting": "Coletando",
    "statusTransit": "Em Trânsito",
    "statusDelivering": "Entregando",
    "serviceCustomerError1": "xxx não está disponível para este cliente.",
    "serviceCustomerError2": "Ele/ela só pode escolher o Serviço xxx",
    "serviceCustomerError3": "Ele/ela só pode escolher o Serviço xxx",
    "OrderCode": "Código do pedido",
    "event_Created": "Criado em",
    "event_Unassigned": "Não atribuído",
    "event_Assigned": "Alocado",
    "event_Started": "Iniciado em",
    "event_Arrived": "Chegou em",
    "event_PickedUp": "Coletou",
    "event_OrderDone": "Pedido finalizado",
    "event_DropoffDone": "Entrega finalizada",
    "event_CustomerFeedback": "Feedback do cliente",
    "event_AddPod": "Adicionar Comprovante",
    "event_CustomerCanceled": "Cliente Cancelado",
    "event_DriverCanceled": "Entregador Cancelado",
    "event_SupportCanceled": "Suporte Cancelado",
    "event_Updated": "Atualizado em",
    "event_DriverAssignment": "Alocação de Entregadores",
    "event_DriverReject": "Rejeição do entregador",
    "event_Collecting": "Coletando",
    "event_InTransit": "Em Trânsito",
    "event_Delivering": "Entregando",
    "event": "Evento",
    "tab_Delivery": "Entrega",
    "tab_Ondemand": "Por demanda",
    "tab_Pickup": "Coleta",
    "tab_PickupDelivery": "C&E",
    "CurrentStatus": "Status atual",
    "Changestatus": "Alterar o status",
    "Newstatus": "Novo status",
    "Savechanges": "Salvar alterações",
    "Other": "Outros",
    "Shipmentlabel": "Etiqueta de expedição",
    "pleaseSellectService": "Por favor, selecione o tipo de serviço",
    "EditPrice2": "Preço",
    "EditEarning": "A receber",
    "statusCustomerCanceled": "Cliente Cancelado",
    "Dutch": "holandês",
    "Ondemand": "Por demanda",
    "todayat": "Hoje em",
    "tomorrowat": "Amanhã em",
    "nextWeekat": "Na próxima semana em",
    "lastDayat": "Último dia em",
    "lastWeekat": "Semana passada em",
    "CopyOrderlink": "Link do pedido",
    "CopyPickuplink": "Link da coleta",
    "CopyDeliverylink": "Link da entrega",
    "Bulkimport": "Importar em massa",
    "Downloadsamplecsv": "Download exemplo CSV",
    "processing": "Processando",
    "done": "Concluído",
    "failed": "Falhou",
    "Draft": "Rascunho",
    "Uploading": "Carregando",
    "Draganddropyourfile": "Arraste e solte o seu arquivo",
    "Onlycsvformatissupported": "Apenas a extensão .csv é suportada",
    "ORyoucan": "OU poderá",
    "Openfile": "Abrir arquivo",
    "Noimportsyet": "Nenhuma importação realizada ainda",
    "NoimportsyetDesc": "É possível verificar o histórico e status de suas importações",
    "nosupportdialogeTitle": "Formato de arquivo não suportado",
    "nosupportdialogeText": "O arquivo xxx não é suportado. Permitido apenas arquivos com extensões .csv",
    "ok": "OK",
    "Import": "Importar",
    "previewTitle": "Pré-visualizar e escolher Opções",
    "previewDesc": "Este é o primeiro item dos seus arquivos para verificar se o layout está correto",
    "Upload": "Carregar",
    "Remove": "Remover",
    "ServiceandOrdertype": "Serviço e tipo de pedido",
    "Fileisproccessing": "Estamos processando o arquivo ...",
    "proccessingDesc": "Você pode atualizar a página para verificar se está tudo certo!",
    "serverError": "Erro do Servidor",
    "isRequired": "xxx é necessário",
    "Result": "Resultado",
    "ResultText": "Você pode verificar os erros e corrigi-los separadamente",
    "ErrorDetails": "Pedidos com erro",
    "ErrorDetailsText": "Os pedidos com erros estão listados no arquivo que você pode baixar. Contém os detalhes de erro para cada linha",
    "DownloadErroredRows": "Baixar linhas com erros",
    "importedsuccessfully": "xxx pedidos foram importados com sucesso",
    "ordershaserrors": "xxx pedidos contém erros",
    "refresh": "Atualizar",
    "CashOnDelivery": "Pagar em dinheiro",
    "EnterAmount": "Digite o valor",
    "COD": "Pagar em dinheiro",
    "Surchargexxx": "Taxa adicional xxx",
    "AddCreditCard": "Adicionar Cartão de Crédito/Débito",
    "CustomerWallet": "Carteira do cliente",
    "Add": "Acrescentar",
    "Addedsuccessfully": "Adicionado com sucesso",
    "NoRecentAddress": "Nenhum endereço recente",
    "NoRecentAddressD": "Os endereços recentes serão listados aqui",
    "CustomerIsNotChoosed": "Nenhum cliente selecionado",
    "CustomerIsNotChoosedD": "Selecione um cliente para mostrar os endereços recentes",
    "SearchAddress": "Pesquisar endereço",
    "NoCustomerfound": "Nenhum Cliente encontrado",
    "NoCustomerfoundD": "Se for um cliente novo, você pode criar os acessos",
    "NewCustomer": "Novo Cliente",
    "Existing": "Existente",
    "EmailOtional": "E-mail ( Opcional )",
    "phonenumberisnotcorrect": "o número de telefone não está correto",
    "Emailisnotcorrect": "O e-mail não está correto",
    "Registercustomer": "Cadastrar cliente",
    "incoming": "Chamada recebida",
    "outgoing": "Realizar chamada",
    "recording": "Gravar chamada",
    "driveroperatorCallStatus": "Entregador ligou para o suporte",
    "customeroperatorCallStatus": "Cliente ligou para o suporte",
    "newCustomeroperatorCallStatus": "Novo cliente ligou para o suporte",
    "operatordriverCallStatus": "Suporte ligou para o entregador",
    "operatorcustomerCallStatus": "Suporte ligou para o cliente",
    "operatornewCustomerCallStatus": "Suporte ligou para o novo cliente",
    "dispatcher_Voip": "Analista de suporte",
    "driver_Voip": "Entregador",
    "customer_Voip": "Cliente",
    "activeorders": "xxx pedidos ativos",
    "Noactiveorder": "Sem pedido ativo",
    "CreateOrder": "Criar pedido",
    "call": "Ligar",
    "spanish": "Espanhol",
    "es": "Espanhol",
    "networkError": "Sem conexão",
    "germany": "Alemanha",
    "de": "Alemanha",
    "Itisnotpossibletorepeattherequest": "Não é possível repetir o pedido" ,




  now: 'Agora',
  ascending: 'Ascendente',
  descending: 'descendente',
  Last3Month: 'Último 3 meses',
  Pickupnote: 'Nota de coleta',
  pop: 'Prova de coleta',
  createdAt: 'Criado em',
  dueDate: 'Data de vencimento',
  min: 'xxx Min',
  Confirming: 'Confirmando',
  delay: 'Atraso',
  late: 'Atrasada',
  OrderDetails: 'Detalhes do pedido',
  Profile: 'Perfil',
  Waitingforavailabledrivers: 'Esperando os drivers disponibilizando',
  Queue: 'Fila',
  OfferingToDrivers: 'Oferecendo aos drivers XXX',
  WaitforAccept: 'Espere por aceitar',
  Thereisnoorder: 'Não há ordem',
  mapitems: 'Itens de mapa',
  Offerings: 'Ofertas',
  Reject: 'Rejeitar',
  dropoffCustomerDescription: 'Deixe a nota',
  customerPhoto: 'Foto do cliente',
  driverPhoto: 'Foto do motorista',
  NocustomerPhoto: 'Nenhuma foto do cliente',
  NodriverPhoto: 'Sem foto do motorista',
  initial: 'inicial',
  notifying: 'notificação',
  received: 'recebida',
  assigned: 'atribuída',
  rejected: 'rejeitada',
  canceled: 'cancelada',
  standby: 'espera',
  terminated: 'terminada',
  urgentTitle: 'Mudança para despacho urgente?',
  urgentDesc: 'Ao ativar urgente, o pedido estará na primeira prioridade para o Dispating',
  urgentConfirm: 'Sim, habilite urgente',
  urgentClose: 'Não, cancele',
  Urgent: 'Urgente',
  photos: 'Fotos',
  Portuguese:"Português",
  Required:"Obrigatória",
  NoResultfromLocalDatabase:"No Result from Local Database",
  NoResultfromLocalDatabaseD:"Please check it again or try another keyword",
  NoResultfromMapService:"No Result from Map Service",
  SearchinMapService:"Search in Map Service" ,
  SearchinLocalDatabase:"Search in Local Database",
  Creditcardnotadded:"Cartão de crédito não adicionado",
  OptimizeDropoffs:"Otimize as quedas",
  "Greek":"Grega",
  "el":"Grega",
  "ReferenceID":"referência ID",
  "EnterId":"Digite ID",
  "de":"Alemã",
  "German":"Alemã",
  "AustrianGerman":"Alemão austríaco",
  "Options":"Opções",
  "VehicleOptions":"Opções do veículo",
  "EditCustomer":"Editar cliente",
  "NoDriverfound":"Nenhum motorista encontrado",
  "NoDriverfoundDesc":"Verifique suas informações e tente outra coisa",
  "ClearField":"Campo claro",
  "Map":"Mapa",
  "Local":"local",
  "CompleteRequiredFeileds":"Completar campos necessários" ,
  "Senderinfo":"Informações do remetente",
  "SchedulePickup(Optional)":"Pickup de agenda (opcional)",
  "ScheduleDelivery(Optional)": "Schedule Delivery (Optional)",

  "AddDropoff":"Adicione queda",
  "EditPrice":"Editar preço",
  "Promotion":"Promoção",
  "tax":"Imposto",
  "surcharge":"Sobretaxa XXX",
  "Receiverinfo":"Informações do receptor",
    "tryagain":"Something went wrong, please try again",



    "Selected date and time":"Selected date and time",
    "Reorder stops":"Reorder stops",
    "Bulk Edit Status Summary":"Bulk Edit Status Summary",
    "Successful":"Successful",
    "Failed":"Failed",
    "Order ID":"Order ID",
    "Details":"Details",
    "Result":"Result",
    "Pickup Routed":"Pickup Routed",
    "Delivery Routed":"Delivery Routed",
    "Ref. xxx":"Ref. xxx",
    "Zone":"Zone",
    "Clear":"Clear",
    "Apply":"Apply",
    "No result found":"No result found",
    "Type your keyword":"Type your keyword",
    "Type in the input to start search":"Type in the input to start search",
    "We are sorry ...":"We are sorry ...",
    "The page you are trying to access has restricted access.":"The page you are trying to access has restricted access.",
    "Please refer to your system administrator.":"Please refer to your system administrator.",
    "Failed":"Failed",
    "Done": "Done",
    "Schedule":"Schedule",
    "Proof of delivery":"Proof of delivery",
    "Signature":"Signature",
    "Driver Photo":"Driver Photo",
    "Notes":"Notes",
    "Order Info":"Order Info",
    "Order":"Order",
    "Price":"Price",
    "P_HUB_D Orders":'P_HUB_D Orders',
    "Routes":"Routes",
    "Default pickup":"Default pickup",
    'More':'More',
    'Route name':'Route name',
    'Driver':'Driver',
    'Pickup Orders':'Pickup Orders',
    'Delivery Orders':'Delivery Orders',
    'Ready For Pickup':'Ready For Pickup'
};