import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { Edit_Travel_Operate_Changed } from "../../../../../../../Redux/actions/actionNames";
import {
  get_currencyCode,
  get_id,
  get_lang,
} from "../../../../../../../helper/UserData/userdate";
import { OrderEditondemandAction, OrderEditPriceDeliveryAction, OrderEditPriceondemandAction, OrderEditPricePickupAction, OrderEditPricePickupDeliveryAction } from "../../../../../../../Redux/actions/actions";
import ChangeMethod from "./changeMethod";
import { CanEditPrice, CanEditTravel } from "../../../../../../../helper/module";
import { Business_account_http } from "../../../../../../../helper/api";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fee: "0",
      surcharges: [],
      priceChange: false,
      active: 1,
      codAmount: 0,
      canWalletBeNegative: false,
      minimumBalance: null,
      businessDataFetched: false,
      payment: {
        changed: false,
        paymentSide: null,
        paymentMethod: null,
        paymentProvider: null,
        cardInfo: null,
        paymentMethodId: null

      }
    };
  }

  savePrice = () => {

    let orderType = this.props.orderType


    if (orderType == "Ondemand") {

      this.props.dispatch(
        OrderEditPriceondemandAction(
          this.props.dispatch,
          {
            "paymentSide": this.state.payment.changed ? this.state.payment.paymentSide : this.props.paymentSide,
            "paymentMethod": this.state.payment.changed ? this.state.payment.paymentMethod : this.props.paymentMethod,
            paymentProvider: this.state.payment.changed ? this.state.payment.paymentProvider : this.props.paymentProvider,
            cardInfo: this.state.payment.changed ? this.state.payment.cardInfo : this.props.cardInfo,
            paymentMethodId: this.state.payment.changed ? this.state.payment.paymentMethodId : this.props.paymentMethodId,
            baseFarePrice: Number(this.state.fee),
            surcharges: this.state.surcharges.map((item) => {
              return { price: Number(item.price), id: item.id };
            }),
            dispatcherId: get_id(),
            codAmount: Number(this.state.codAmount)
          },
          null,
          null,
          this.props.Data.id
        )
      );
      this.props.close()
    } else if (orderType == "Pickup") {

      this.props.dispatch(
        OrderEditPricePickupAction(
          this.props.dispatch,
          {
            "paymentSide": this.state.payment.changed ? this.state.payment.paymentSide : this.props.paymentSide,
            "paymentMethod": this.state.payment.changed ? this.state.payment.paymentMethod : this.props.paymentMethod,
            paymentProvider: this.state.payment.changed ? this.state.payment.paymentProvider : this.props.paymentProvider,
            cardInfo: this.state.payment.changed ? this.state.payment.cardInfo : this.props.cardInfo,
            paymentMethodId: this.state.payment.changed ? this.state.payment.paymentMethodId : this.props.paymentMethodId,
            baseFarePrice: Number(this.state.fee),
            dispatcherId: get_id(),
            codAmount: Number(this.state.codAmount),
            surcharges: this.state.surcharges.map((item) => {
              return { price: Number(item.price), id: item.id };
            }),
          },
          null,
          null,
          this.props.Data.id
        )
      );
      this.props.close()
    } else if (orderType == "Delivery") {

      this.props.dispatch(
        OrderEditPriceDeliveryAction(
          this.props.dispatch,
          {
            "paymentSide": this.state.payment.changed ? this.state.payment.paymentSide : this.props.paymentSide,
            "paymentMethod": this.state.payment.changed ? this.state.payment.paymentMethod : this.props.paymentMethod,
            paymentProvider: this.state.payment.changed ? this.state.payment.paymentProvider : this.props.paymentProvider,
            cardInfo: this.state.payment.changed ? this.state.payment.cardInfo : this.props.cardInfo,
            paymentMethodId: this.state.payment.changed ? this.state.payment.paymentMethodId : this.props.paymentMethodId,
            baseFarePrice: Number(this.state.fee),

            dispatcherId: get_id(),
            codAmount: Number(this.state.codAmount),
            surcharges: this.state.surcharges.map((item) => {
              return { price: Number(item.price), id: item.id };
            }),
          },
          null,
          null,
          this.props.Data.id
        )
      );
      this.props.close()
    } else if (orderType == "PickupDelivery") {

      this.props.dispatch(
        OrderEditPricePickupDeliveryAction(
          this.props.dispatch,
          {
            "paymentSide": this.state.payment.changed ? this.state.payment.paymentSide : this.props.paymentSide,
            "paymentMethod": this.state.payment.changed ? this.state.payment.paymentMethod : this.props.paymentMethod,
            paymentProvider: this.state.payment.changed ? this.state.payment.paymentProvider : this.props.paymentProvider,
            cardInfo: this.state.payment.changed ? this.state.payment.cardInfo : this.props.cardInfo,
            paymentMethodId: this.state.payment.changed ? this.state.payment.paymentMethodId : this.props.paymentMethodId,
            baseFarePrice: Number(this.state.fee),
            dispatcherId: get_id(),
            codAmount: Number(this.state.codAmount)
          },
          null,
          null,
          this.props.Data.id
        )
      );
      this.props.close()
    }



  };

  handleTab = (num) => {
    this.setState({
      active: num,
    });
  };

  //   EditpriceOnChange = () => {
  //     if (this.state.priceChange) {
  //       this.setState({
  //         priceChange: !this.state.priceChange,
  //         surcharges: [],
  //         fee: "0",
  //       });
  //     } else {
  //       this.setState({
  //         priceChange: !this.state.priceChange,
  //         surcharges: this.props.surcharges,
  //         fee: this.props.baseFarePrice,
  //       });
  //     }
  //   };

  componentDidMount() {
    this.setState({
      priceChange: !this.state.priceChange,
      surcharges: this.props.surcharges?this.props.surcharges : [],
      fee: this.props.baseFarePrice,
      codAmount: this.props.Data.codAmount
    });

    if (this.props.customerType != "individual") {
      Business_account_http(this.props.dispatch, this.props.customerId, (call) => {
        
        this.setState({
          canWalletBeNegative: call.permissions.canWalletBeNegative,
          minimumBalance: call.minimumBalance,
          businessDataFetched: true
        })
      })
    }

  }

  feeOnchange = (e) => {
    this.props.dispatch(Edit_Travel_Operate_Changed());

    this.setState({
      fee: e.target.value,
    });
  };

  E_Surcharges_Onchange = (value, id) => {
    
    let updatedList = this.state.surcharges.map(item => {
      if (item.id == id) {
        return { ...item, price: value.target.value }; //gets everything that was already in item, and updates "done"
      }
      return item; // else return unmodified item 
    });


    this.setState({
      surcharges: updatedList,
    });
  };



  ChangeMethodChange = (data) => {
    
    this.setState({
      payment: {
        changed: true,
        ...data
      }
    })
  }

  render() {
    const { t } = this.props;
    let CanEditOrder = CanEditPrice(this.props.Data.status);
    let canEdit = ["PickupDelivery"].indexOf(this.props.Data.type) == -1;


    if (CanEditOrder && !this.props.noC) {
      return (
        <React.Fragment>
          <div
            style={{
              float: "left",
              width: "100%",
              height: "166px",
              overflowY: "auto",
            }}
          >
            <rowStuff>
              <textt>
                {" "}
                <Trans i18nKey={"Fee"}> </Trans> :
              </textt>
              <span>
                <input onChange={this.feeOnchange} value={this.state.fee} />
                <dolar>
                  {" "}
                  {String(
                    Number(90).toLocaleString(get_lang(), {
                      style: "currency",
                      currency: get_currencyCode(),
                    })
                  ).replace(/[0-9]/g, "").replace(/[۰-۹]/g, "")}{" "}
                </dolar>
              </span>
            </rowStuff>

            {this.state.surcharges.map((item, i) => {
              return (
                <rowStuff>
                  <textt> {`${item.title}`} :</textt>
                  <span>
                    <dolar>
                      {" "}
                      {String(
                        Number().toLocaleString(get_lang(), {
                          style: "currency",
                          currency: get_currencyCode(),
                        })
                      ).replace(/[0-9]/g, "").replace(/[۰-۹]/g, "")}{" "}
                    </dolar>
                    <input
                      onChange={(e) => {
                        canEdit && this.E_Surcharges_Onchange(e, item.id);
                        
                      }}
                      value={item.price}
                    />
                  </span>
                </rowStuff>
              );
            })}

          { this.props.orderType != "PickupDelivery"  && <rowStuff>
              <textt>
                {" "}
                <Trans i18nKey={"Promocode"}> </Trans> :
              </textt>
              <span>
                <dolar>
                  {Number(
                    this.props.Data ? this.props.Data.promoAmount : 0
                  ).toLocaleString(get_lang(), {
                    style: "currency",
                    currency: get_currencyCode(),
                  })}
                </dolar>
              </span>
            </rowStuff>}

            <rowStuff>
              <textt> {this.props.t("COD")} :</textt>
              <span>
                <dolar>
                  {" "}
                  {String(
                    Number().toLocaleString(get_lang(), {
                      style: "currency",
                      currency: get_currencyCode(),
                    })
                  ).replace(/[0-9]/g, "").replace(/[۰-۹]/g, "")}{" "}
                </dolar>
                <input
                  onChange={(e) => {
                    this.setState({
                      codAmount: Number(e.target.value)
                    })
                  }}
                  type="number"
                  min={0}
                  value={this.state.codAmount}
                />
              </span>
            </rowStuff>


          </div>
          {/* ["Ondemand"].indexOf(this.props.orderType) > -1 &&  */   <div
            className="footer"
            style={{
              position: "absolute",
              bottom: "65px",
              width: "calc(100% - 32px)",
              marginLeft: "16px",
              marginRight: "16px",
            }}
          >
            <costConrainer style={{ marginLeft: "0", marginRight: "0" }}>
              <cost style={{ marginTop: "13px" }}>
                {(this.props.Data &&
                  Number(this.props.Data.price - (this.props.Data ? (this.props.Data.promoAmount ? this.props.Data.promoAmount : 0 ) : 0)).toLocaleString(get_lang(), {
                    style: "currency",
                    currency: get_currencyCode(),
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })) ||
                  0}
              </cost>
              <price>
                {" "}
                <Trans i18nKey="Price"> </Trans>{" "}
              </price>
            </costConrainer>

            {<ChangeMethod
              Data={this.props.Data}
              baseFarePrice={Number(this.state.fee)}
              surcharges={this.state.surcharges.map((item) => {
                return { price: Number(item.price), id: item.id };
              })}
              fee={this.state.fee}
              canWalletBeNegative={this.state.canWalletBeNegative}
              minimumBalance={this.state.minimumBalance}
              businessDataFetched={this.state.businessDataFetched}
              changeData={(data) => { this.ChangeMethodChange(data) }}
              changedData={this.state.payment}
            />}
          </div>}

          {<div
            className="footer"
            style={{ position: "absolute", bottom: "0px" }}
          >
            <sendOrder
              className={` editPriceFooterBtn ${this.props.operatorChanged && "grey"
                }`}
              style={{ marginTop: "17px" }}
              onClick={this.savePrice}
            >
              <Trans i18nKey={"savePrice"}> </Trans>
            </sendOrder>
          </div>}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div
            style={{
              float: "left",
              width: "100%",
              height: "296px",
              overflowY: "auto",
            }}
          >
            <priceDetail>
              <titles>
                {" "}
                <Trans i18nKey={"Fee"}> </Trans>
              </titles>
              <value>
                {" "}
                {Number(this.props.baseFarePrice).toLocaleString(get_lang(), {
                  style: "currency",
                  currency: get_currencyCode(),
                })}
              </value>
            </priceDetail>

            <priceDetail>
              <titles>
                {" "}
                <Trans i18nKey={"Promocode"}> </Trans>
              </titles>
              <value>
                {" "}
                {Number(
                  this.props.Data ? this.props.Data.promoAmount : 0
                ).toLocaleString(get_lang(), {
                  style: "currency",
                  currency: get_currencyCode(),
                })}
              </value>
            </priceDetail>

            {this.state.surcharges.map((item, i) => {
              return (
                <priceDetail>
                  <titles> {`${item.title}`} </titles>
                  <value>
                    {Number(item.price).toLocaleString(get_lang(), {
                      style: "currency",
                      currency: get_currencyCode(),
                    })}
                  </value>
                </priceDetail>
              );
            })}

            <priceDetail>
              <titles style={{ color: "#484848" }}>
                {" "}
                <Trans i18nKey={"Total"}> </Trans>
              </titles>
              <value className="SenderWalletred">
                {" "}
                {Number(
                  this.props.Data ? this.props.Data.price : 0
                ).toLocaleString(get_lang(), {
                  style: "currency",
                  currency: get_currencyCode(),
                })}
              </value>
            </priceDetail>
          </div>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => ({

  Data: state.ViewTravel.pureData ? state.ViewTravel.pureData : false,
  customerId: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.customer
      ? state.ViewTravel.pureData.customerId
      : null
    : null,
  paymentMethod: ownProps.Data.paymentMethod,
  paymentSide: ownProps.Data.paymentSide,
  paymentMethodId: ownProps.Data.paymentMethodId,
  paymentProvider: ownProps.Data.paymentProvider,
  cardInfo: ownProps.Data.cardInfo,
  baseFarePrice: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.baseFarePrice
    : 0,
  surcharges: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.surcharges
    : [],

  orderType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
      ? state.ViewTravel.pureData.type
      : null
    : null,
  customerType: state.ViewTravel.pureData ? state.ViewTravel.pureData.customerType : "individual",

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
