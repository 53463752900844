import _ from "lodash";
import * as actionTypes from "../actions/actionTypes";

const status_category = {
  PickupDelivery: {
    status: ['Pending', 'Collecting', 'Transit', 'Delivering', 'Done', 'Unassigned', 'CustomerCanceled', 'SupportCanceled' ],
    category: ['Pinned', 'Delayed', 'Pending', 'Collecting', 'Delivering', 'Canceled', 'Done', 'Transit']
  },
  Ondemand: {
    status: ['Pending', 'Assigned', 'PickedUp', 'Started', 'Arrived', 'Done', 'Unassigned', 'CustomerCanceled', 'DriverCanceled', 'SupportCanceled'],
    category: ['Pinned', 'Delayed',   'InProgress', 'Canceled', 'Done', 'Dispatching', 'DispatchLater', 'Unassigned']
  },
  Pickup: {
    status: ['Pending', 'Assigned', 'Started', 'PickedUp', 'Done', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'],
    category: ['Pinned', 'Delayed',  'InProgress', 'Canceled', 'Done', 'Dispatching', 'DispatchLater', 'Unassigned']
  },
  Delivery: {
    status: ['Pending', 'Assigned', 'Started', 'Done', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'],
    category: ['Pinned', 'Delayed',   'InProgress', 'Canceled', 'Done', 'Dispatching', 'DispatchLater', 'Unassigned']
  }
}



const isOndemand = () => {
  if (String(window.location.href).includes("Ondemand")) {
    return true
  } else {
    return false
  }
}

const isPickupDelivery = () => {
  if (String(window.location.href).includes("PickupDelivery")) {
    return true
  } else {
    return false
  }
}

const isPickup = () => {
  if (String(window.location.href).includes("Pickup")) {
    return true
  } else {
    return false
  }
}

const isDelivery = () => {
  if (String(window.location.href).includes("Delivery")) {
    return true
  } else {
    return false
  }
}


export const getsetActiveOrderType = ()=>{
  if(isOndemand()){
    return "Ondemand"
  }else if(isPickupDelivery()){
    return "PickupDelivery"
  }else if(isPickup()){
    return "Pickup"
  }else if(isDelivery()){
    return "Delivery"
  }else{
    return "Ondemand"
  }
}


const init = {
  ActiveOrderType: getsetActiveOrderType(),
  status: [],
  selectedStatus: JSON.stringify([]),
  DateRange: null,
  regexFields: [],
  categories: [],
  regexFields: null,
  selectedServices: JSON.stringify([]),
  selectedVehicleType: JSON.stringify([]),
  zones: JSON.stringify([]),
  vehicleTypes: [],
  isScheduled: false,
  now: false,
  dueDate: null,
  cursor: "createdAt",
  cursorSort: "asc",
  size: 20,
  stats: {
    loading: false,
    data: null
  },
  list: {
    Unassigned: {
      openCollapse:true,
      data: {},
      sortedData: [],
      dataLength: 0,
      loading: false,
      error: false,
      startCursor: null,
      endCursor: null,
      nextCursor: null
    },

    DispatchLater: {
      openCollapse:true,
      data: {},
      sortedData: [],
      dataLength: 0,
      loading: false,
      error: false,
      startCursor: null,
      endCursor: null,
      nextCursor: null
    },
    Dispatching: {
      openCollapse:true,
      data: {},
      sortedData: [],
      dataLength: 0,
      loading: false,
      error: false,
      startCursor: null,
      endCursor: null,
      nextCursor: null
    },
    Pinned: {
      openCollapse:true,
      data: {},
      sortedData: [],
      dataLength: 0,
      loading: false,
      error: false,
      startCursor: null,
      endCursor: null,
      nextCursor: null
    },
    Transit: {
      openCollapse:true,
      data: {},
      sortedData: [], dataLength: 0,
      loading: false,
      error: false,
      startCursor: null,
      endCursor: null,
      nextCursor: null
    },
    Delayed: {
      openCollapse:true,
      data: {},
      sortedData: [], dataLength: 0,
      loading: false,
      error: false,
      startCursor: null,
      endCursor: null,
      nextCursor: null
    },
    Pending: {
      openCollapse:true,
      data: {},
      sortedData: [], dataLength: 0,
      loading: false,
      error: false,
      startCursor: null,
      endCursor: null,
      nextCursor: null
    },
    Assigned: {
      openCollapse:true,
      data: {},
      sortedData: [], dataLength: 0,
      loading: false,
      error: false,
      startCursor: null,
      endCursor: null,
      nextCursor: null
    },
    InProgress: {
      openCollapse:true,
      data: {},
      sortedData: [], dataLength: 0,
      loading: false,
      error: false,
      startCursor: null,
      endCursor: null,
      nextCursor: null
    },
    Collecting: {
      openCollapse:true,
      data: {},
      sortedData: [], dataLength: 0,
      loading: false,
      error: false,
      startCursor: null,
      endCursor: null,
      nextCursor: null
    },
    Delivering: {
      openCollapse:true,
      data: {},
      sortedData: [], dataLength: 0,
      loading: false,
      error: false,
      startCursor: null,
      endCursor: null,
      nextCursor: null
    },
    Canceled: {
      openCollapse:false,
      data: {},
      sortedData: [], dataLength: 0,
      loading: false,
      error: false,
      startCursor: null,
      endCursor: null,
      nextCursor: null
    },
    Done: {
      openCollapse:false,
      data: {},
      sortedData: [], dataLength: 0,
      loading: false,
      error: false,
      startCursor: null,
      endCursor: null,
      nextCursor: null
    },
  },
  search: {
    requested: false,
    loading: false,
    regexText: "",
    regexFields: {
      value: ['all'],
      i18: "all",
    },
    data: [],
    error: false,
    startCursor: null,
    endCursor: null,
    nextCursor: null
  },
  filters: {
    changed: false,
    openDialoge: false,
    userId: null,
    selectedServices: [],
    selectedZone: [],
    selectedStatus: [],
    selectedVehicleType: [],
    isScheduled: false
  }

};


export const OrderList = (state = init, action) => {
  switch (action.type) {



    case actionTypes.resetSearchFilters:
      return {
        ...state,
        filters: {
          ...init.filters,
        }
      };



    case actionTypes.openCloseFilterDialoge:
      return {
        ...state,
        filters: {
          ...state.filters,
          openDialoge: !state.filters.openDialoge
        }
      };

    case actionTypes.setSearchOrderListData:
      return {
        ...state,
        search: {
          ...state.search,
          dataLength: action.state.dataLength,
          data: action.state.loadMore ? [...state.search.data, ...action.state.orders] : action.state.orders,
          startCursor: action.state.startCursor,
          endCursor: action.state.endCursor,
          nextCursor: action.state.nextCursor
        }
      };


    case actionTypes.setOrderListFiltersDueTime:
      return {
        ...state,
        [action.state.key]: action.state.value
        // filters :{
        // ...state.filters ,
        // ...{userId: action.state.userId,
        // selectedServices:action.state.selectedServices ,
        // selectedStatus: action.state.selectedStatus  ,
        // selectedVehicleType:action.state.selectedVehicleType ,
        //  }
      };

    case actionTypes.setOrderListFiltersStatus:
      return {
        ...state,
        selectedStatus: JSON.stringify(action.state)
      };

    case actionTypes.setOrderListFiltersServices:
      return {
        ...state,
        selectedServices: JSON.stringify(action.state)
      };

    case actionTypes.setOrderListFiltersVehicleTypes:
      return {
        ...state,
        selectedVehicleType: JSON.stringify(action.state)
      };



    case actionTypes.setOrderListFiltersZone:
      return {
        ...state,
        zones: JSON.stringify(action.state)
      };


    case actionTypes.setSearchOrderListRegexText:
      if (String(action.state) == 0) {
        return {
          ...state,
          search: {
            ...init.search
          }
        };
      } else {
        return {
          ...state,
          search: {
            ...state.search,
            regexText: action.state,
          }
        };
      }


    case actionTypes.setOrderListFiltersChanged:
      return {
        ...state,
        filters: {
          ...state.filters,
          changed: action.state,
        }
      };

    case actionTypes.setSearchOrderListLoading:
      return {
        ...state,
        search: {
          ...state.search,
          loading: action.state,
        }
      };

    case actionTypes.setSearchOrderListRequested:
      return {
        ...state,
        search: {
          ...state.search,
          requested: action.state,
        }
      };

    case actionTypes.setSearchOrderListRegexFields:
      return {
        ...state,
        search: {
          ...state.search,
          regexFields: action.state,
        }
      };

    case actionTypes.listFilterSetDateRange:
      return {
        ...state,
        DateRange: action.state,
      };

    case actionTypes.setOrderListCursor:
      return {
        ...state,
        cursor: action.state,
        list: init.list
      };
    case actionTypes.setOrderListCursorSort:
      return {
        ...state,
        cursorSort: action.state,
        list: init.list
      };
    case actionTypes.requsetListorderTypes:
      return {
        ...state,
        ActiveOrderType: action.state,
        categories: [...status_category[action.state].category],
        status: [...status_category[action.state].status],
        list: init.list
      };

    case actionTypes.setOrderListStatsLoading:
      return {
        ...state,
        stats: {
          ...state.stats,
          loading: action.state
        }
      };

    case actionTypes.setOrderListStatsData:
      return {
        ...state,
        stats: {
          ...state.stats,
          data: action.state
        }
      };

    case actionTypes.increaseStatsCategoryNumber:
      return {
        ...state,
        stats: {
          ...state.stats,
          data: {
            ...state.stats.data,
            [action.state]: Number(state.stats.data[action.state]) + 1
          }
        }
      };

    case actionTypes.decreaseStatsCategoryNumber:
      return {
        ...state,
        stats: {
          ...state.stats,
          data: {
            ...state.stats.data,
            [action.state]: Number(state.stats.data[action.state]) - 1
          }
        }
      };

    case actionTypes.setCategoryOrderListLoading:
      return {
        ...state,
        list: {
          ...state.list,
          [action.state.key]: {
            ...state.list[action.state.key],
            loading: action.state.loading,
            error: action.state.error
          }
        }
      };


    case actionTypes.updateCategoryOrdersList:
      return {
        ...state,
        list: {
          ...state.list,
          [action.state.key]: {
            ...state.list[action.state.key],
            data: {
              ...state.list[action.state.key].data,
              [action.state.data.id]: {
                ...action.state.data,
              },
            },
          }
        }
      };


      case actionTypes.updateCategoryOrdersListSorted:
        return {
          ...state,
          list: {
            ...state.list,
            [action.state.key]: {
              ...state.list[action.state.key],
              sortedData: [
                ...action.state.data 
              ],
            }
          }
        };
  


    case actionTypes.deleteCategoryOrdersList:

      const newC = Object.fromEntries(
        Object.entries(state.list[action.state.key].data).filter(([key]) => key != action.state.data.id)
      )

      return {
        ...state,
        list: {
          ...state.list,
          [action.state.key]: {
            ...state.list[action.state.key],
            data: newC,
          }
        }
      };

    case actionTypes.updateSearchOrdersList:
      const index = state.search.data.findIndex(order => order.id == action.state.id)

      return {
        ...state,
        search: {
          ...state.search,
          data: [
            ...state.search.data.slice(0, index),
            {
              ...state.search.data[index],
              ...action.state
            },
            ...state.search.data.slice(index + 1),
          ]
        }
      };

      
      case actionTypes.resetCategoryOrderListData:
        let openCollapse = state.list[action.state].openCollapse
        return {
          ...state,
          list: {
            ...state.list,
            [action.state]: {...init.list[action.state] ,openCollapse:openCollapse}
          }
        };
    case actionTypes.setCategoryOrderListData:
      return {
        ...state,
        list: {
          ...state.list,
          [action.state.key]: {
            ...state.list[action.state.key],
            dataLength: action.state.dataLength,
            data: action.state.loadMore ? { ...state.list[action.state.key].data, ..._.keyBy(action.state.orders, "id") } : _.keyBy(action.state.orders, "id"),
            startCursor: action.state.startCursor,
            endCursor: action.state.endCursor,
            nextCursor: action.state.nextCursor,
            hasNextPage: action.state.hasNextPage

          }
        }
      };

      case actionTypes.setCategoryCollapse:
        return {
          ...state,
          list: {
            ...state.list,
            [action.state]: {
              ...state.list[action.state],
              openCollapse:action.bool,
            }
          }
        };


      case actionTypes.resetOrderListData:
        return {
          ...state,
          list: init.list
        };
    default:
      return state;
  }
};

