import { CircularProgress } from '@material-ui/core';
import { useSearchStatus } from '../../../../../hooks/useSearchStatus';
import styles from './style.module.css';
import noResultImg from '../../../../../assets/ic-search-not-found.svg';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';


export const SearchNoresult = ({stats}) => {
    let {  regexText, listNoResult , searchNoResult  ,loading , listLoading} = useSearchStatus()
    const { t, i18n } = useTranslation();


    function hasAtleastOneStat(stats) {
        if(!stats){
            return false
        }

        let has = false ;
        for (const [key, value] of Object.entries(stats)) {
            

            if(Number(value) > 0){
                has = true
            }
          }
          return has
          
    }



    if(String(regexText).length == 0 && listNoResult && !listLoading && hasAtleastOneStat(stats)){
        return<div className={styles.searchNoResultContainer}> <CircularProgress size={18} style={{margin:"20px calc(50% - 14px)",float:'left',marginTop:"40px"}}/></div>

    }else if(String(regexText).length == 0 && listNoResult && !listLoading){
        return  <div className={styles.searchNoResultContainer}>
        <img src={noResultImg} className={styles.searchNoResultImg}/>
        <p className={styles.searchNoResultTitle}> {t("Noresultfound")}</p>
    </div>
    }else if(String(regexText).length == 0 && listNoResult && listLoading){
        

        return<div className={styles.searchNoResultContainer}> <CircularProgress size={18} style={{margin:"20px calc(50% - 14px)",float:'left',marginTop:"40px"}}/></div>
    }else if(String(regexText).length > 0 && !loading && searchNoResult) {
        return <div className={styles.searchNoResultContainer}>
            <img src={noResultImg} className={styles.searchNoResultImg}/>
            <p className={styles.searchNoResultTitle}> {t("Noresultfound")}</p>
        </div>
    }else if(String(regexText).length > 0 && searchNoResult && loading ){
        

        return<div className={styles.searchNoResultContainer}> <CircularProgress size={18} style={{margin:"20px calc(50% - 14px)",float:'left',marginTop:"40px"}}/></div>

    }else{
        return null
    }


};




