import styles from './style.module.css';
import walletFooter from "../../../assets/walletFooter.svg";
import by_receiver from "../../../assets/by_receiver.svg";
import walletall from "../../../assets/walletall.svg";
import cash from "../../../assets/cash.svg";
import discountcircle from "../../../assets/discount-circle.svg";
import info from "../../../assets/info.svg";
import { useCallback } from 'react';
import { OfferingDetailsOpen, PriceDetailsOpen, SetViewOrderPickupDropoffDetails, show_eventTimeLine } from '../../../Redux/actions/actionNames';
import { useDispatch, useSelector } from 'react-redux';
import { get_currencyCode, get_lang } from '../../../helper/UserData/userdate';
import { useTranslation } from 'react-i18next';
import PaymentIcon from "react-payment-icons";
import clsx from 'clsx';


export const Footer = () => {
    const pricedetailsOpen = useSelector((state) => state.ViewTravel.pricedetailsOpen)
    const Data = useSelector((state) => state.ViewTravel.pureData)

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const ShowPriceDetailsDialoge = useCallback(() => {
        dispatch(show_eventTimeLine(false))
        dispatch(PriceDetailsOpen(!pricedetailsOpen))
        dispatch(SetViewOrderPickupDropoffDetails(null))
        dispatch(OfferingDetailsOpen(false))

    }, [pricedetailsOpen]);


    return <div className={styles.footer}>




        {Data.paymentSide + Data.paymentMethod == "SenderCard" ?
            <PaymentIcon
                id={Data.cardInfo.brand}
                className={styles.SenderCard}
            />
            :
            <img src={Data.paymentSide === "Sender"
                ? Data.paymentMethod === "Cash"
                    ? cash
                    : walletFooter
                : by_receiver} className={styles.walletFooter} />
        }

        <img className={clsx(styles.info, pricedetailsOpen && styles.infoActive)} src={info} onClick={ShowPriceDetailsDialoge} />
        <div className={styles.paymentPriceContainer}>
            <span className={styles.payment}>  {(Data &&
                Number(Data.price - (Data.promoAmount > 0 ? Data.promoAmount : 0)).toLocaleString(get_lang(), {
                    style: "currency",
                    currency: get_currencyCode(),
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                })) ||
                0}</span>
            <span className={styles.price}>

                {Data.paymentSide + Data.paymentMethod == "SenderCard" ? (


                    <span className="pfkwuhh"> {`**** ${Data.cardInfo.lastNumbers}`} </span>

                    // <div className="opdrtmff">
                    //     <PaymentIcon
                    //         id={Data.cardInfo.brand}
                    //         className="dgieh"
                    //     />


                    //     <span className="pfkwuhh"> {`**** ${Data.cardInfo.lastNumbers}`} </span>

                    // </div>


                ) : t(Data.paymentSide === "Sender"
                    ? Data.paymentMethod === "Cash"
                        ? "Cash"
                        : "Wallet"
                    : "Receiver")
                }
            </span>
        </div>

        {Data.promoAmount > 0 && <span className={styles.discount}> <img src={discountcircle} /> {Number(Number((100 * Data.promoAmount)) / Number(Data.price)).toFixed(1)}% </span>
        }
        {Data.codAmount > 0 && <span className={styles.walletall}> <img src={walletall} /> {Number(Data.codAmount).toLocaleString(get_lang(), {
            style: "currency",
            currency: get_currencyCode(),
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })} COD</span>
        }
    </div>

};




