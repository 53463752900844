import * as React from 'react'
import styles from './style.module.css';
import IconProvider from '../../../design-system/lib/IconProvider';
import { ADD_CUSTOMER, CALL, CLOSE_DARK, COPY, CUSTOMER_TYPE, EDIT, GROUP_1925, INFO_CIRCLE, PERSON, PROFILE, WALLET } from '../../../assets';
import Typography from '../../../design-system/lib/Typography';
import Button from '../../../design-system/lib/Button';
import SearchList from '../../../design-system/lib/SearchList';
import CustomerListItem from './components/list-Item';
import Avatar from '../../../design-system/lib/Avatar';
import Tag from '../../../design-system/lib/Tag';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NewCustomerHandleChange, New_Travel_PaymentMethod, OpenEditCustomer, SetRecentAddress, Set_Passenger_Na_Fa_Ph, Set_Pickup_Inputs, Set_latlng, SuggestCustomerList, SuggestCustomerListLoading } from '../../../Redux/actions/actionNames';
import { CustomerGetInfo, Get_User_Recent_Address, SearchCustomerAction, Voip_OutgoingCall } from '../../../Redux/actions/actions';
import { Get_Addressbook_list_Api } from '../../../helper/api';
import { getCenterMap } from '../../../helper/module';
import { get_currencyCode, get_id } from '../../../helper/UserData/userdate';
import getSymbolFromCurrency from 'currency-symbol-map'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { snack } from '../../../utils/SnackbarUtilsConfigurator';
import { isEmpty } from 'lodash';



function CustomerNewOrder() {
  const { t, i18n } = useTranslation();
  const person = useSelector((state) => state.NewOrder.newOrder.customer.person)
  const BusinessName = useSelector((state) => state.NewOrder.newOrder.customer.BusinessName)
  const BusinessNumber = useSelector((state) => state.NewOrder.newOrder.customer.BusinessNumber)
  const avatarPhoto = useSelector((state) => state.NewOrder.newOrder.customer.avatarPhoto)
  const BusinessId = useSelector((state) => state.NewOrder.newOrder.customer.BusinessId)
  const name = useSelector((state) => state.NewOrder.newOrder.customer.name)
  const type = useSelector((state) => state.NewOrder.newOrder.customer.type)
  const email = useSelector((state) => state.NewOrder.newOrder.customer.email)
  const canWalletBeNegative = useSelector((state) => state.NewOrder.newOrder.customer.canWalletBeNegative || false)
  const phone = useSelector((state) => state.NewOrder.newOrder.customer.phone)
  const id = useSelector((state) => state.NewOrder.newOrder.customer.id)
  const search_regex = useSelector((state) => state.NewOrder.newOrder.customer.search_regex)
  const suggestListLoading = useSelector((state) => state.NewOrder.newOrder.customer.suggestListLoading)
  const suggestListData = useSelector((state) => state.NewOrder.newOrder.customer.suggestListData)
  const suggestListOrdered = useSelector((state) => state.NewOrder.newOrder.customer.suggestListOrdered)
  const serviceSettings = useSelector((state) => state.NewOrder.newOrder.service.activeVehicleType
    ? state.NewOrder.newOrder.service.activeVehicleType.setting
    : { enableReceiverCash: false, isCashBySenderActive: false })
  const UserBalance = useSelector((state) => state.NewOrder.newOrder.customer.balance)
  const price = useSelector((state) => state.NewOrder.newOrder.price)
  const paymentMethod = useSelector((state) => state.NewOrder.newOrder.paymentMethod.paymentMethod)
  const paymentSide = useSelector((state) => state.NewOrder.newOrder.paymentMethod.paymentSide)
  const Allservice = useSelector((state) => state.NewOrder.newOrder.service.serviceVehicleTypes)
  const sendOrder = useSelector((state) => state.NewOrder.newOrder.sendOrder)
  const activeVehicleType = useSelector((state) => state.NewOrder.newOrder.service.activeVehicleType)
  const customer = useSelector((state) => state.NewOrder.newOrder.customer)
  const dispatch = useDispatch();
  const mapRef = useSelector((state) => state.Map.mapRef)
  const balance = useSelector((state) => state.NewOrder.newOrder.customer.balance)
  const newCustomerIsOpen = useSelector((state) => state.NewOrder.newOrder.newCustomer.open)
  const editCustomerIsOpen = useSelector((state) => state.NewOrder.newOrder.editCustomerIsOpen)



  const InputOnChange = (e) => {

    dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "search_regex",
        value: e?.target.value,
      })
    );

    String(e?.target.value).length > 0 && dispatch(
      SearchCustomerAction(dispatch, e?.target.value)
    );

    String(e?.target.value).length == 0 && dispatch(SuggestCustomerList([]));

  };

  const selectPassenger = (e) => {
    dispatch(OpenEditCustomer(false))
    dispatch(NewCustomerHandleChange({ key: "open", value: false }))

    dispatch(CustomerGetInfo(dispatch, e.id, (call) => {
      if (price.price && (call.balance - price.price.price) >= Number(call.minimumBalance)) {
        dispatch(New_Travel_PaymentMethod({
          paymentSide: "Sender",
          paymentMethod: "Wallet",
          paymentProvider: null,
          cardInfo: null,
          paymentMethodId: null,
        }));
      }
    }))


    dispatch(SetRecentAddress([]))

    dispatch(Get_User_Recent_Address(dispatch, e.id, null))


    dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "type",
        value: e.type,
      })
    );
    dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "name",
        value: e.name,
      })
    );
    dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "phone",
        value: String(e.phone).startsWith("00") ?
          "+" + String(e.phone).slice(2, e.phone.length) : String(e.phone),
      })
    );
    dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "id",
        value: e.id,
      })
    );
    dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "balance",
        value: e.balance,
      })
    );

    dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "minimumBalance",
        value: e.minimumBalance,
      })
    );

    dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "email",
        value: e.email,
      })
    );

    dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "familyName",
        value: e.familyName,
      })
    );

    dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "givenName",
        value: e.givenName,
      })
    );

    dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "avatarPhoto",
        value: e.avatarPhoto,
      })
    );

    Get_Addressbook_list_Api(
      dispatch,
      {
        customerId: e.id,
        page: 1,
        perpage: 20,
        searchRegex: "",
      },
      (call) => {
        call && checkandSekectDefaultPickup(call);
      }
    );
    checkPaymentMustBeCashOrNo(e.balance);
  };

  const checkandSekectDefaultPickup = (arr) => {
    arr.forEach((element) => {
      if (element.isDefaultPickupAddress) {
        try {
          getCenterMap(mapRef, {
            lng: element.coordinates[0],
            lat: element.coordinates[1],
          })
        } catch (error) {
          dispatch(
            Set_latlng({
              lng: element.coordinates[0],
              lat: element.coordinates[1],
            })
          );
        }

        dispatch(
          Set_Pickup_Inputs({
            name: "address",
            value: element.address,
          })
        );

        dispatch(
          Set_Pickup_Inputs({
            name: "adresss_loc",
            value: { lng: element.coordinates[0], lat: element.coordinates[1] },
          })
        );

        dispatch(
          Set_Pickup_Inputs({
            name: "block",
            value: element.buildingBlock,
          })
        );

        dispatch(
          Set_Pickup_Inputs({
            name: "floor",
            value: element.floor,
          })
        );

        dispatch(
          Set_Pickup_Inputs({
            name: "senderFullName",
            value: element.fullName,
          })
        );

        dispatch(
          Set_Pickup_Inputs({
            name: "senderPhoneNumber",
            value: element.phone,
          })
        );

        dispatch(
          Set_Pickup_Inputs({
            name: "unit",
            value: element.room,
          })
        );
      }
    });
  };

  const checkPaymentMustBeCashOrNo = React.useCallback(() => {

    if (!price.loading && price.done) {
      if (Number(UserBalance) >= Number(price.price.price)) {
      } else if (paymentSide + paymentMethod == "SenderWallet") {
        if (price.price && (customer.balance - price.price.price) >= customer.minimumBalance) {
        } else {
          if (serviceSettings.enableReceiverCash) {
            dispatch(
              New_Travel_PaymentMethod({
                paymentSide: "Sender",
                paymentMethod: "Cash",
                log: "from 353"
              })
            );
          }
        }
      }
    }
  }, [price, UserBalance, paymentSide, paymentMethod, customer, serviceSettings]);


  React.useEffect(() => {

    if (customer.id) {
      Get_Addressbook_list_Api(
        dispatch,
        {
          customerId: customer.id,
          page: 1,
          perpage: 20,
          searchRegex: "",
        },
        (call) => {
          call && checkandSekectDefaultPickup(call);
        }
      );
      checkPaymentMustBeCashOrNo(customer.balance);
    }

  }, [customer])


  const makeOutgoingCall = () => {
    dispatch(Voip_OutgoingCall(dispatch, {
      "to": phone,
      "orderId": null,
      "customerId": id,
      "driverId": "",
      "dispatcherId": get_id()
    }))
  }

  const resetCustomer = () => {
    dispatch(OpenEditCustomer(false))
    dispatch(NewCustomerHandleChange({ key: "open", value: false }))

    dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "search_regex",
        value: "",
      })
    );


    dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "recentAddreess",
        value: [],
      })
    );

    dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "suggestListData",
        value: [],
      })
    );



    dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "type",
        value: "",
      })
    );
    dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "name",
        value: "",
      })
    );
    dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "phone",
        value: "",
      })
    );
    dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "id",
        value: "",
      })
    );
    dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "balance",
        value: "",
      })
    );
  };

  const openNewCustomer = React.useCallback(() => {
    const myEvent = new CustomEvent("closeSliders", { detail: null });
    document.dispatchEvent(myEvent);


    setTimeout(() => {
      dispatch(NewCustomerHandleChange({ key: "open", value: !newCustomerIsOpen }))

    }, 100);
  }, [newCustomerIsOpen]);


  const openEditCustomer = React.useCallback(() => {
    const myEvent = new CustomEvent("closeSliders", { detail: null });
    document.dispatchEvent(myEvent);

    setTimeout(() => {
      dispatch(OpenEditCustomer(!editCustomerIsOpen))
    }, 100);
  }, [editCustomerIsOpen]);


  return (
    <div className={styles.CustomerNewOrderContainer}>
      {!id && <div className={styles.customerTitleContainer}>
        <IconProvider Icon={PERSON} size="16" />
        <Typography weight='Body_Middle_Bold' text={t("Passenger")} />
      </div>}

      {!id && <div className={styles.customerInputListContainer}>
        <SearchList style={{ zIndex: "4" }} onChange={InputOnChange} value={ !isEmpty(search_regex) ? search_regex :""} placeHolder={t("Searchbynameorphonenumber")} isloading={suggestListLoading} listItemOnclick={(DATA, I) => { selectPassenger(DATA) }}
          ListItemChildren={CustomerListItem} error={(suggestListOrdered && String(search_regex).length > 0) ? suggestListData.length == 0 : false}
          errorIcon={PROFILE}
          errorTitle={t("NoCustomerfound")}
          errorDescription={t("NoCustomerfoundD")}
          errorActionTitle={t("NewCustomer")}
          errorActionOnClick={openNewCustomer}
          list={(suggestListOrdered && String(search_regex).length > 0) ? (suggestListData.length > 0 ? suggestListData : null) : null}
        />
        <Button size={'Medium'} type='Secondary' icon={ADD_CUSTOMER} onClick={openNewCustomer} isActive={newCustomerIsOpen} />

      </div>}



      {id && <div className={styles.selectedUserContainer}>

        <div className={styles.avatarContainer}>
          <Avatar type='customer' imageUrl={avatarPhoto} />
          <IconProvider Icon={type == "business" ? GROUP_1925 : CUSTOMER_TYPE} className={styles.IconProvider} size='16' />
        </div>


        <div className={styles.namePhoneContainer}>
          <Typography weight='Body_Middle_Regular' text={name} style={{ marginInlineStart: "8px" }} />
          <CopyToClipboard text={phone}
            onCopy={() => snack.success(t("Saved"))}>
            <Typography weight='Subtitle_Tiny_Regular' text={<>{phone} <IconProvider Icon={COPY} size='12' /></>} style={{ marginInlineStart: "8px", display: "flex", fleDirection: "row", justifyContainer: "center", alignItems: "start", gap: "4px", cursor: "pointer" }} />
          </CopyToClipboard>
        </div>


        <Tag size='Large' text={`${getSymbolFromCurrency(get_currencyCode())}${balance}`} backgroundColor='GrayUltraLight' color='TextDark' icon={WALLET} style={{ marginInlineStart: "16px", marginInlineEnd: "auto" }} iconSize='16' weight='Body_Middle_Medium' />

        {type == "individual" && <Button isActive={editCustomerIsOpen} size={'Medium'} type='Secondary' icon={EDIT} style={{ marginInlineStart: "auto" }} onClick={openEditCustomer} />}
        {global.config.dispatcherSettings.isVoipActvie && <Button size={'Medium'} type='Secondary' icon={CALL} style={{ marginInlineStart: "12px" }} onClick={makeOutgoingCall} />}


        <Button size={'Small'} icon={CLOSE_DARK} type='Secondary' style={{ marginInlineStart: "12px", backgroundColor: "transparent", padding: "8px 8px" }} onClick={resetCustomer} />


      </div>}

    </div>
  )
}

export default CustomerNewOrder