import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import CalculatePrice from "./calculatePrice";
import CalculatePriceCheck_WithoutHttp from "./calculatePriceCheck_WithoutHttp";
import Pricedetails from "./components/Price details";
import CanSaveOrder from "./canSaveOrder";
import Header from "./components/header";
import Footer from "./components/footer";
import Passenger from "./components/customerInformation";
import Service from "./components/service";
import Pickup from "./components/pickup";
import Dropoff from "./components/dropoff";
import Driver from "./components/driver";
import Plus from "../../../../assets/iconfinder_plus_172525.svg";
import { withTranslation, Trans } from "react-i18next";
import Slide from "@material-ui/core/Slide";
import { Add_dropoff, Set_ActivePickupDropOff, Remove_dropoff, EditTravelSetActivePickup, Edit_Travel_add_dropoff, Edit_Travel_remove_dropoff, Edit_Travel_Operate_Changed } from "../../../../Redux/actions/actionNames";
import { ServicesVehicletypeAction } from "../../../../Redux/actions/actions";
import DropoffsContainer from "./components/dropoffs-container";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idArrays: [],
      dropoffs: [],
    };
  }
  handleCollapse = (id) => {
    this.props.dispatch(EditTravelSetActivePickup(id))
    this.setState({
      [id]: !this.state[id],
    });

    this.setState((state) => {
      let x = {};
      state.idArrays.map((id) => {
        x[id] = false;
      });

      return { ...x, [id]: !this.state[id] };
    });
  };

  setId = (id) => {
    this.setState((state) => {
      return { idArrays: [...state.idArrays, id + ""], [id]: false };
    });
  };

  addDropOff = () => {
    this.props.dispatch(Edit_Travel_Operate_Changed())

    this.props.dispatch(Edit_Travel_add_dropoff(this.idgenerator()))
  };

  idgenerator = () => {
    var number = Math.random(); // 0.9394456857981651
    number.toString(36); // '0.xtis06h6'
    var id = number.toString(36).substr(2, 9); // 'xtis06h6'
    return id.toString();
  };

  componentDidMount() {
    // 
    // this.props.dispatch(ServicesVehicletypeAction(this.props.dispatch , this.props.center))
  }


  removeDropOff = (id) => {
    this.props.dispatch(Edit_Travel_remove_dropoff(id))

  }

  render() {
    return (
      <Slide
        direction="down"
        in={this.props.OpenDialoge}
        mountOnEnter
        unmountOnExit
      >
        {/* <div className="NewOrder" style={{position:"unset",direction:"var(--main-inverseDirection)"}}> */}
        <div className="NewOrder" style={{zIndex:10,direction:"var(--main-inverseDirection)"}}>

          <Header />

          <itemContainers>
            {/* <Passenger />
            <Service /> */}
            {/* <Driver
              setId={this.setId}
              handleCollapse={this.handleCollapse}
              firstDropOff
              addDropOff={this.addDropOff}
            /> */}
            <div className="noboxshadow">

            { (["Ondemand" , "Pickup", "PickupDelivery"].indexOf(this.props.orderType) > -1) && <Pickup
                setId={this.setId}
                handleCollapse={this.handleCollapse}
              />}
           {/* { (["Ondemand" , "Delivery" , "PickupDelivery"].indexOf(this.props.orderType) > -1) &&  <Dropoff
                setId={this.setId}
                id={this.props.id}
                handleCollapse={this.handleCollapse}
                firstDropOff
                {...this.props}
                last={this.props.dropoffs.length === 0}
              />} */}


            {["PickupDelivery", "Delivery", "Ondemand"].indexOf(
                this.props.orderType
              ) > -1 && <DropoffsContainer setId={this.setId} removeDrop={this.removeDropOff}
                handleCollapse={this.handleCollapse}  />}



              {/* {this.props.dropoffs.map((drop, i) => {
                return (
                  <Dropoff
                    id={drop.id}
                    handleCollapse={this.handleCollapse}
                    {...drop}
                    number={i + 2}
                    last={i + 1 === this.props.dropoffs.length}
                    removeDrop={this.removeDropOff}
                  />
                );
              })} */}
            </div>
            {(["Ondemand"].indexOf(this.props.orderType) > -1) &&
              <addDropOff onClick={this.addDropOff}>
                <img
                  className="addDropOff"
                  src={Plus}
                />
                <span className="addDropOffText">
                  {" "}
                  <Trans i18nKey={"Adddropoff"}> </Trans>
                </span>
              </addDropOff>
            }

            {
              /*

<Pricedetails/>
              */
            }


          </itemContainers>
          <CalculatePrice />
          {/* <CalculatePriceCheck_WithoutHttp /> */}

          <CanSaveOrder />

          <Footer />
        </div>
      </Slide>
    );
  }
}

const mapStateToProps = (state) => ({
  orderType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
      ? state.ViewTravel.pureData.type
      : null
    : null,
  OpenDialoge: state.EditOrder.OpenDialoge,
  dropoffs: state.EditOrder.editOrder.dropOffs.otherDropOffs,
  address: state.EditOrder.editOrder.dropOffs.firstDropOff.address,
  adresss_loc: state.EditOrder.editOrder.dropOffs.firstDropOff.adresss_loc,
  block: state.EditOrder.editOrder.dropOffs.firstDropOff.block,
  email: state.EditOrder.editOrder.dropOffs.firstDropOff.email,
  floor: state.EditOrder.editOrder.dropOffs.firstDropOff.floor,
  unit: state.EditOrder.editOrder.dropOffs.firstDropOff.unit,
  recieverFullName: state.EditOrder.editOrder.dropOffs.firstDropOff.recieverFullName,
  recieverPhoneNumber: state.EditOrder.editOrder.dropOffs.firstDropOff.recieverPhoneNumber,
  noteForDriver: state.EditOrder.editOrder.dropOffs.firstDropOff.noteForDriver,
  center: state.Map.center,
  scheduleDateAfter: state.EditOrder.editOrder.dropOffs.firstDropOff.scheduleDateAfter,
  scheduleDateBefore:
    state.EditOrder.editOrder.dropOffs.firstDropOff.scheduleDateBefore,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));
