import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import close from "../../../../../assets/icon-chevron-down.svg";
import { ChangeNewTravelDialoge, ResetNewOrder, OpenCloseEditTravel, Edit_Travel_Reset } from "../../../../../Redux/actions/actionNames";
import { withTranslation, Trans } from "react-i18next";
import { ServicesVehicletypeAction } from "../../../../../Redux/actions/actions";

class App extends React.Component {
  NewTravelHandler = () => {
       if (this.props.OpenDialoge) {
         this.props.dispatch(Edit_Travel_Reset());
       } else {
         this.props.dispatch(OpenCloseEditTravel());
       }
//  this.props.dispatch(ResetNewOrder())
//     this.props.dispatch(ServicesVehicletypeAction(this.props.dispatch , this.props.center))
  };

  render() {
    return (
      <nRHeader>
        <img onClick={this.NewTravelHandler} className="close" src={close} />
        <span className="title">
          <Trans i18nKey={"EditPickupDropOff"}> </Trans>
        </span>
      </nRHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  center:state.Map.center ,
  OpenDialoge: state.EditOrder.OpenDialoge,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));
