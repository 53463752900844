import React, { useCallback, useEffect, useRef } from 'react'
import styles from './style.module.css';
import Typography from '../../../design-system/lib/Typography';
import IconProvider from '../../../design-system/lib/IconProvider';
import { BOX, Ic_danger } from '../../../assets';
import Button from '../../../design-system/lib/Button';
import { getTableOrderList } from '../table-list/action/getTableOrderList';







function TableNoOrder() {

  return (
    <div className='jojojoj'
      style={{ display: 'flex', position: 'relative', height: `calc(100vh - 104px)`, width: "100%", flexDirection: 'column', transition: "all .3s ease-in-out" ,justifyContent:"center",alignItems:"center"}}>
          <div className={styles.dialog}>
            <IconProvider Icon={BOX} style={{marginBottom:"16px"}} />
            <Typography weight='Body_Middle_Bold' text='Create your first order' style={{textAlign:"center" , marginBottom:"4px"}} />
            <Typography weight='Body_Tiny_Regular' text='Your orders will be shown here in table with full details and actions' style={{textAlign:"center",marginBottom:"16px"}} />
            <Button size='Medium' type='Primary' text='Create Order' onClick={()=>{}}/>
          </div>
    </div>
  )
}
export default TableNoOrder