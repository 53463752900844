import styles from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import estimer from "../../../assets/estimer.svg";
import espath from "../../../assets/espath.svg";
import { useTranslation } from 'react-i18next';



export const ViewOrderEstimate = () => {
    const Espath = useSelector((state) => state.ViewTravel.pureData
    ? state.ViewTravel.pureData.estimatedDistanceText
    : 0)
    const { t, i18n } = useTranslation();


    return <div className={styles.EstimateContainer}> 
            <p className={styles.EstimateItem}>
                <img src={espath} />    
                {t("Espath")}
                <span>{Espath}</span>
            </p>
            {/* <p className={styles.EstimateItem}>
                <img src={estimer} />    
                {t("Estime")}
            </p> */}

            
    </div>
};




