import onro from "../../assets/onro.png";
import pickup from "../../assets/pickup.png";
import asankar from "../../assets/asankar.png";
import acistant from "../../assets/acistant.png";
import deliro from "../../assets/deliro.svg";
import doorpost from "../../assets/doorpost.png";
import YONI from "../../assets/YONI.png";
import emrazgo from "../../assets/emrazgoLogo.png";
import hellocourier from "../../assets/hellocourier_logo.png";
import oto from "../../assets/oto_logotype.png";
import vee from "../../assets/vee_logotype.png";
import loco from "../../assets/loc_logotype.png";
import cartingo from "../../assets/cartingo_logotype.png";
import HiO from "../../assets/HiOlogotype.png";
import Fab from "../../assets/fablogotype.png";
import Geenstress from "../../assets/geenstress_logotype.png";
import TEZRAFTAAR from "../../assets/TEZRAFTAAR_logotype.png";
import PickGo from "../../assets/PickGoogotype.png";
import DroppX from "../../assets/DroppX_logotype.png";
import wadelivery from "../../assets/wadelivery_logotype.png";
import jalali from "jalali-moment";


import {
  get_lang,
  get_direction,
  get_calendarType,
} from "../UserData/userdate";
import dayjs from "dayjs";
import jalaliday from "jalaliday";
import {
  ChangeViewTravelDialoge,
  ResetViewTravel,
  ResetNewFav,
  ChangeNewTravelDialoge,
  ChangePersonBusiness,
  Set_Passenger_Na_Fa_Ph,
  SetServiceVehicleTypesRepeatData,
  Set_Manual_ActiveVehicleType,
  Set_Auto_Driver,
  Set_Auto_ActiveVehicleType,
  Set_Pickup_Inputs,
  NewTravelPickupSetReserve,
  Set_DropOff_Inputs,
  NewTravelDropOffSetReserve,
  Edit_Travel_add_dropoff,
  Add_dropoff,
  SetVoipIncommingCallAction,
} from "../../Redux/actions/actionNames";
import { ServicesVehicletypeAction, OrderInfoAction } from "../../Redux/actions/actions";
import { store } from "../../Redux/store/index";
import { repeatReuqestModule } from "./repeatReuqestModule";
import React from "react";

dayjs.extend(jalaliday);
const _ = require("lodash");

export const CanEditTravel = (status) => {
  switch (status) {
    case "Arrived": return true; break;
    case "PickedUp": return true; break;
    case "Started": return true; break;
    case "Assigned": return true; break;
    case "Pending": return true; break;
    case "Unassigned": return true; break;
    default: return false;
  }
};

export const CanEditEarning = (orderType, status) => {
  if (["Ondemand", "Delivery", "Pickup"].indexOf(orderType) > -1 /* &&  ["Done"].indexOf(status) == -1 */ ) {
    return true
  } else {
    return false
  }
};

export const CanEditPrice = (status, paymentSide, paymentMethod) => {
  if (["Done"].indexOf(status) == 0 && (["SenderWallet", "SenderCash"].indexOf(String(paymentSide + paymentMethod)) > -1)) {
    return true
  } if ([ "CustomerCanceled", "DriverCanceled", "SupportCanceled" ].indexOf(status) > -1) {
    return false
  } else {
    return true
  }
};

export const CanEditPriceEarning = (status, orderType, paymentSide, paymentMethod) => {
  return (CanEditEarning(orderType, status, paymentSide, paymentMethod) || CanEditPrice(status, paymentSide, paymentMethod))
}


export const CanRepeatTravel = (status) => {
  switch (status) {
    // case "Unassigned":
    //   return true;
    //   break;

    default: return true;
  }
};

export const PickupDone = (status) => {
  switch (status) {
    case "PickedUp": return true; break;
    case "Done": return true; break;
    default: return false;
  }
};

export const Is_InProgress_Travel = (status) => {
  switch (status) {
    case "Assigned": return true; break;
    case "PickedUp": return true; break;
    case "Started": return true; break;
    case "Arrived": return true; break;
    case "Done": return true; break;
    default: return false;
  }
};

export const CanTrackDriver = (status) => {
  switch (status) {
    case "PickedUp": return true; break;
    case "Started": return true; break;
    case "Arrived": return true; break;
    case "Assigned": return true; break;

    case "OutForDelivery": return true; break;
    case "ReadyForPickup": return true; break;
    case "DeliveryRouted": return true; break;
    case "PickupRouted": return true; break;

    default: return false;
  }
};

export const CanEndTravel = (status) => {
  switch (status) {
    case "PickedUp": return true; break;
    default: return false;
  }
};

export const CanCancelTravel = (status) => {
  switch (status) {
    case "Assigned": return true; break;
    case "PickedUp": return true; break;
    case "Started": return true; break;
    case "Arrived": return true; break;
    case "Pending": return true; break;
    default: return false;
  }
};



export const getProjectNameByUrl = (status) => {
  switch (window.location.hostname) {
    case "dispatcher.fab.com.np": return "Fab Bud dispatcher"; break;
    case "dispatcher.loco.direct": return "Loco Delivers";
    case "dispatcher.catrin-go.com": return "Catringo";

    case "dispatcher.pickup.global": return "Pickup"; break;
    case "dispatcher.onro.io": return "onro"; break;
    case "dispatcher.onro.org": return "آنرو"; break;
    case "dispach.acestant.xyz": return "acestant"; break;
    case "dispatcher.asankar.co": return "asankar"; break;
    case "dispatcher.deliro.ir": return "deliro"; break;
    case "dispatcher.doorpost.com.au": return "DoorPost"; break;
    case "dispatcher.yoni-app.sn": return "YONI APP"; break;
    case "dispatcher.emrazgo.com": return "EMRAZGO"; break;
    case "dispatcher.hellocourier.co.ke": return "Hello Courier"; break;
    case "dispatcher.droppx.com": return "DroppX"; break;

    case "dispatcher.otokh.com": return "OTO"; break;

    case "dispatcher.veedelivery.com": return "VEE Delivery Now"; break;

    case "dispatcher.hio.ro": return "HiO"; break;
    case "dispatcher.geenstress.amsterdam": return "Geen Stress"; break;
    case "dispatcher.trcs.pk":
      return "TEZRAFTAAR";
      break;
    case "dispatcher.pickngosg.com":
      return "Pick & Go";
    case "dispatcher.wadelivery.com":
      return "wa! Delivery";
      break;


    default: return "deliro";
  }
};

export const getLogoTypeByUrl = (status) => {
  switch (window.location.hostname) {
    case "dispatcher.fab.com.np": return Fab;

    case "dispatcher.loco.direct": return loco;
    case "dispatcher.catrin-go.com": return cartingo;

    case "dispatcher.pickup.global": return pickup; break;
    case "dispatcher.onro.io": return onro; break;
    case "dispatcher.onro.org": return onro; break;
    case "dispach.acestant.xyz": return acistant; break;
    case "dispatcher.asankar.co": return asankar; break;
    case "dispatcher.deliro.ir": return deliro; break;
    case "dispatcher.doorpost.com.au": return doorpost; break;
    case "dispatcher.yoni-app.sn": return YONI; break;

    case "dispatcher.emrazgo.com": return emrazgo;

    case "dispatcher.hellocourier.co.ke": return hellocourier;
    case "dispatcher.droppx.com": return DroppX;
    case "dispatcher.veedelivery.com": return vee; break;

    case "dispatcher.otokh.com": return oto; break;

    case "dispatcher.hio.ro": return HiO; break;

    case "dispatcher.geenstress.amsterdam": return Geenstress; break;
    case "dispatcher.trcs.pk":
      return TEZRAFTAAR;
      break;
    case "dispatcher.pickngosg.com":
      return PickGo;
      break;

    case "dispatcher.wadelivery.com":
      return wadelivery;

    default: return deliro;
  }
};

export const getBaseApiByUrl = (status) => {
  switch (window.location.hostname) {
    case "dispatcher.fab.com.np": return "https://rest.fab.com.np"; break;

    case "dispatcher.loco.direct": return "https://rest.loco.direct";

    case "dispatcher.catrin-go.com": return "https://rest.catrin-go.com";

    case "dispatcher.pickup.global": return "https://webservice.pickup.global"; break;
    case "dispatcher.onro.io": return "https://rest.onro.io"; break;
    case "dispatcher.onro.org": return "https://rest.onro.org"; break;
    case "dispach.acistant.xyz": return "https://rest.acistant.xyz"; break;
    case "dispatcher.asankar.co": return "https://rest.asankar.co"; break;
    case "dispatcher.deliro.ir": return "https://rest.deliro.ir"; break;
    case "dispatcher.doorpost.com.au": return "https://rest.doorpost.com.au"; break;
    case "dispatcher.yoni-app.sn": return "https://rest.yoni-app.sn"; break;
    case "dispatcher.emrazgo.com": return "https://rest.emrazgo.com"; break;
    case "dispatcher.hellocourier.co.ke": return "https://rest.hellocourier.co.ke";
    case "dispatcher.veedelivery.com": return "https://rest.veedelivery.com";
    case "dispatcher.otokh.com": return "https://rest.otokh.com";
    case "dispatcher.droppx.com": return "https://rest.droppx.com";

    case "localhost": return "https://rest.onro.org";
    // case "localhost": return "https://rest.onro.io"; break;

    case "dispatcher.hio.ro": return "https://rest.hio.ro";

    case "dispatcher.geenstress.amsterdam": return "https://rest.geenstress.amsterdam";
    case "dispatcher.trcs.pk":
      return "https://rest.trcs.pk";
      break;
    case "dispatcher.pickngosg.com":
      return "https://rest.pickngosg.com";
      break;

    case "dispatcher.wadelivery.com":
      return "https://rest.wadelivery.com";


    default: return "https://rest.onro.org";
  }
};

export const getBaseSocketByUrl = (status) => {
  switch (window.location.hostname) {
    case "dispatcher.fab.com.np": return "https://socket.fab.com.np"; break;


    case "dispatcher.loco.direct": return "https://socket.loco.direct";

    case "dispatcher.catrin-go.com": return "https://socket.catrin-go.com";

    case "dispatcher.pickup.global": return "https://socket.pickup.global"; break;
    case "dispatcher.onro.io": return "https://socket.onro.io"; break;
    case "dispatcher.onro.org": return "https://socket.onro.org"; break;
    case "dispach.acistant.xyz": return "https://socket.acistant.xyz"; break;
    case "dispatcher.asankar.co": return "https://socket.asankar.co"; break;
    case "dispatcher.deliro.ir": return "https://socket.deliro.ir"; break;
    case "dispatcher.doorpost.com.au": return "https://socket.doorpost.com.au"; break;
    case "dispatcher.yoni-app.sn": return "https://socket.yoni-app.sn"; break;
    case "dispatcher.emrazgo.com": return "https://socket.emrazgo.com"; break;
    case "dispatcher.hellocourier.co.ke": return "https://socket.hellocourier.co.ke"; break;
    case "dispatcher.veedelivery.com": return "https://socket.veedelivery.com";
    case "dispatcher.otokh.com": return "https://socket.otokh.com";
    // case "localhost": return "https://socket.onro.org";
    case "localhost": return "https://socket.onro.io";
    case "dispatcher.droppx.com": return "https://socket.droppx.com";

    case "dispatcher.hio.ro": return "https://socket.hio.ro";
    case "dispatcher.geenstress.amsterdam": return "https://socket.geenstress.amsterdam";
    case "dispatcher.trcs.pk":
      return "https://socket.trcs.pk";
      break;
    case "dispatcher.pickngosg.com":
      return "https://socket.pickngosg.com";
      break;
    case "dispatcher.wadelivery.com":
      return "https://socket.wadelivery.com";


    default: return "https://socket.onro.org";
  }
};

export const getBaseTrackByUrl = (status) => {
  switch (window.location.hostname) {
    case "dispatcher.fab.com.np": return "https://panel.fab.com.np "; break;
    case "dispatcher.loco.direct": return "https://portal.loco.direct";

    case "dispatcher.catrin-go.com": return "https://admin.catrin-go.com";

    case "dispatcher.pickup.global": return "https://panel.pickup.global"; break;
    case "dispatcher.onro.io": return "https://panel.onro.io"; break;
    case "dispatcher.onro.org": return "https://panel.onro.org"; break;
    case "dispach.acistant.xyz": return "https://panel.acistant.xyz"; break;
    case "dispatcher.asankar.co": return "https://panel.asankar.co"; break;
    case "dispatcher.deliro.ir": return "https://panel.deliro.ir"; break;
    case "dispatcher.doorpost.com.au": return "https://panel.doorpost.com.au"; break;
    case "dispatcher.yoni-app.sn": return "https://panel.yoni-app.sn"; break;
    case "dispatcher.emrazgo.com": return "https://panel.emrazgo.com";

    case "dispatcher.hellocourier.co.ke": return "https://panel.hellocourier.co.ke";

    case "dispatcher.otokh.com": return "https://panel.otokh.com";

    case "dispatcher.veedelivery.com": return "https://nowadmin.veedelivery.com";
    // case "localhost": return "https://panel.onro.org";
    case "localhost": return "https://panel.onro.io";

    case "dispatcher.hio.ro": return "https://panel.hio.ro";
    case "dispatcher.droppx.com": return "https://admin.droppx.com";

    case "dispatcher.geenstress.amsterdam": return "https://panel.geenstress.amsterdam";
    case "dispatcher.trcs.pk":
      return "https://manager.trcs.pk";
      break;
    case "dispatcher.pickngosg.com":
      return "https://panel.pickngosg.com";
      break;
    case "dispatcher.wadelivery.com":
      return "https://dashboard.wadelivery.com";

    default: return "https://panel.onro.org";
  }
};

export const getMapCenterByUrl = (status) => {
  switch (window.location.hostname) {
    case "dispatcher.fab.com.np": return { lat: "27.673402", lng: "85.427407", };

    case "dispatcher.loco.direct": return { lat: "3.146436", lng: "101.687713", };
    case "dispatcher.catrin-go.com": return { lat: "33.680788", lng: "-117.821799", };

    case "dispatcher.pickup.global": return { lat: "3.1475", lng: "101.693333", }; break;
    case "dispatcher.onro.io": return { lat: "3.1475", lng: "101.693333", }; break;
    case "dispatcher.onro.org": return { lat: "35.694654", lng: "51.404343", }; break;
    case "dispach.acistant.xyz": return { lat: "10.001389", lng: "53.565278", }; break;
    case "dispatcher.asankar.co": return { lat: "36.1128", lng: "44.033", }; break;

    case "dispatcher.deliro.ir": return { lat: "35.694654", lng: "51.404343", }; break;

    case "dispatcher.doorpost.com.au": return { lat: "46.13531111753044", lng: "-60.184021911779666", }; break;

    case "dispatcher.yoni-app.sn": return { lat: "14.730794", lng: "-17.468486", }; break;

    case "dispatcher.emrazgo.com": return { lat: "2.944190", lng: "101.698162", }; break;

    case "dispatcher.hellocourier.co.ke": return { lat: "-1.2864", lng: "36.8172", }; break;

    case "dispatcher.otokh.com": return { lat: "11.605189", lng: "104.911424", }; break;
    case "dispatcher.veedelivery.com": return { lat: "31.922914", lng: "35.911963", };
    case "dispatcher.droppx.com": return { lat: "60.169765", lng: "24.938556", };

    case "dispatcher.hio.ro": return { lat: "47.163279", lng: "27.586531", };
    case "dispatcher.geenstress.amsterdam": return { lat: "52.374656", lng: "4.901774", };
    case "dispatcher.trcs.pk":
      return {
        lat: "33.712284",
        lng: "73.091881",
      };
    case "dispatcher.pickngosg.com":
      return {
        lat: "1.364196",
        lng: "103.868759",
      }
      break;

    case "dispatcher.wadelivery.com":
      return {
        lat: "18.533517",
        lng: "73.864845",
      }

    default: return { lat: "35.694654", lng: "51.404343", };
  }
};

export const getLangByUrl = (status) => {
  switch (window.location.hostname) {

    case "dispatcher.fab.com.np": return "en";

    case "dispatcher.loco.direct": return "en";

    case "dispatcher.catrin-go.com": return "en";

    case "dispatcher.pickup.global": return "en"; break;
    case "dispatcher.onro.io": return "en"; break;
    case "dispatcher.onro.org": return "fa"; break;
    case "dispach.acistant.xyz": return "en"; break;
    case "dispatcher.asankar.co": return "en"; break;
    case "dispatcher.deliro.ir": return "fa"; break;
    case "dispatcher.doorpost.com.au": return "en"; break;
    case "dispatcher.yoni-app.sn": return "fr"; break;
    case "dispatcher.emrazgo.com": return "en"; break;
    case "dispatcher.hellocourier.co.ke": return "en"; break;
    case "dispatcher.otokh.com": return "en"; break;

    case "dispatcher.veedelivery.com": return "en"; break;

    case "dispatcher.hio.ro": return "en";
    case "dispatcher.droppx.com": return "en";

    case "dispatcher.geenstress.amsterdam": return "nl";
    case "dispatcher.trcs.pk":
      return "en";
    case "dispatcher.pickngosg.com":
      return "en";

    case "dispatcher.wadelivery.com":
      return "en";

    default: return "fa";
  }
};

export let getDirectionByUrl = (status) => {
  if (["ltr", "rtl"].indexOf(get_direction()) > -1) {
    return get_direction();
  } else {
    return global.config.Direction;
  }
};

export const getAllLanguages = (status) => {
  switch (window.location.hostname) {

    case "dispatcher.hio.ro": return [{ lang: "en", direction: "ltr", i18Lang: "English", }, { lang: "ro", direction: "ltr", i18Lang: "Romanian", },];
    case "dispatcher.geenstress.amsterdam": return [{ lang: "en", direction: "ltr", i18Lang: "English", },
    {
      lang: "nl",
      direction: "ltr",
      i18Lang: "Dutch",
    }
    ];
    case "dispatcher.trcs.pk":
      return [
        {
          lang: "en",
          direction: "ltr",
          i18Lang: "English",
        }
      ];

    case "dispatcher.pickngosg.com":
      return [
        {
          lang: "en",
          direction: "ltr",
          i18Lang: "English",
        }
      ];

    case "dispatcher.droppx.com":
      return [
        {
          lang: "en",
          direction: "ltr",
          i18Lang: "English",
        }
      ];

    case "dispatcher.wadelivery.com":
      return [
        {
          lang: "en",
          direction: "ltr",
          i18Lang: "English",
        }
      ];

    default: return [{ lang: "en", direction: "ltr", i18Lang: "English", }, { lang: "fa", direction: "rtl", i18Lang: "Persian", }, { lang: "fr", direction: "ltr", i18Lang: "French", }, { lang: "ro", direction: "ltr", i18Lang: "Romanian", }];



  }

};

export const isOrderInProgress = (status) => {
  switch (status) {
    case "Assigned": return true; break;
    case "PickedUp": return true; break;
    case "Started": return true; break;
    case "Arrived": return true; break;
    default: return false;
  }
};

export const isOrderInProgressOrCancelDone = (status) => {
  switch (status) {
    case "11111": return false; break;
    default: return true;
  }
};

export const isOrderInDone = (status) => {
  switch (status) {
    case "Done": return true; break;
    default: return false;
  }
};

export const HistoryRequsetDate = (date) => {
  return dayjs(date)
    .calendar(get_calendarType())
    .locale(get_lang())
    .format("D MMMM hh:mm"); // '13 Shahrivar 1397'
};


export const CheckSocketOrderOrderType = (type, call) => {
  let storeData = store.getState();
  let ActiveOrderTypes = storeData.OrderList.ActiveOrderType;
  if (ActiveOrderTypes == type) {
    call(true)
  } else {
    call(false)
  }
};


export const CheckOrdersToUpdatePANDD = (orderData) => {
  let storeData = store.getState();
  let Order_SubId = storeData.ViewTravel.Order_SubId;
  let ID = storeData.ViewTravel.ID;

  if (Order_SubId.indexOf(orderData.id) > -1) {
    store.dispatch(OrderInfoAction(store.dispatch, { orderId: ID, })
    );
  }
};



export const CheckSocketOrderState = (status, call) => {
  let storeData = store.getState();
  let ActiveStates = storeData.LocalData.orderList.activeStatus
    .map((status) => status.status)
    .join("|");
  var array = ActiveStates.split("|").filter((arr) => arr.length > 2);
  if (array.length == 0) {
    call(true);
  } else {
    const match = array.find((element) => element == status);
    if (match) {
      call(true);
    } else {
      call(false);
    }
  }
};

export const repeatReuqest = repeatReuqestModule;

export const FavIconWithDomain = (trans) => {
  switch (window.location.hostname) {
    case "dispatcher.fab.com.np": return "/fab.png";

    case "dispatcher.loco.direct": return "/loco.png";

    case "dispatcher.catrin-go.com": return "/cartingo.png";

    case "dispatcher.pickup.global": return "/pickup.png"; break;
    case "dispatcher.onro.io": return "/onro.png"; break;
    case "dispatcher.onro.org": return "/onro.png"; break;
    case "dispach.acistant.xyz": return "/acistant.png"; break;
    case "dispatcher.asankar.co": return "/asankar.png"; break;
    case "dispatcher.deliro.ir": return "/deliro.png"; break;
    case "dispatcher.doorpost.com.au": return "/doorpost.png"; break;
    case "dispatcher.yoni-app.sn": return "/yoni.png"; break;
    case "dispatcher.emrazgo.com": return "/emrazgo.png"; break;
    case "dispatcher.hellocourier.co.ke": return "/hellocourier.png"; break;
    case "dispatcher.otokh.com": return "/oto.png"; break;
    case "dispatcher.veedelivery.com": return "/vee.png"; break;
    case "dispatcher.hio.ro": return "/HiO.png";
    case "dispatcher.geenstress.amsterdam": return "/geenstress.png";
    case "dispatcher.trcs.pk":
      return "/TEZRAFTAAR.png";
    case "dispatcher.pickngosg.com":
      return "/PickGo.png";
    case "dispatcher.droppx.com":
      return "/DroppX.png"

    case "dispatcher.wadelivery.com":
      return "/wadelivery.png"

    default: return "/deliro.png";
  }
};

// export const faviconUpdate = (trans) => {
//   try {
//     const favicon = document.getElementById("favicon");
//     favicon.href = global.config.FavIcon;
//   } catch (error) { }
// };

export const getCountryWithDomain = (state) => {
  switch (window.location.hostname) {
    case "dispatcher.fab.com.np": return "NP";
    case "onro.test": return "US";
    case "dispatcher.onro.org": return "US";
    case "dispatcher.catrin-go.com": return "US";
    case "dispatcher.loco.direct": return "MY";
    case "dispatcher.onro.io": return "US";
    case "dispatcher.pickup.global": return "MY";
    case "dispatcher.deliro.ir": return "IR";
    case "dispatcher.yoni-app.sn": return "SN";
    case "dispatcher.emrazgo.com": return "MY";
    case "dispatcher.hellocourier.co.ke": return "KE";
    case "dispatcher.otokh.com": return "KH";
    case "dispatcher.veedelivery.com": return "JO";
    case "dispatcher.hio.ro": return "RO";
    case "dispatcher.geenstress.amsterdam": return "NL";
    case "dispatcher.trcs.pk":
      return "PK";
    case "dispatcher.pickngosg.com":
      return "SG";
    case "dispatcher.droppx.com":
      return "FI"
    case "dispatcher.wadelivery.com":
      return "IN"

    default: return "US";
  }
};

export function get_LeftRight() {
  switch (localStorage.getItem("direction")) {
    case "ltr": return "right"; break;
    case "rtl": return "left"; break;
    default: return "right";
  }
}

export function canChangeStatus(fromStatus , orderType) {
  if(orderType == "PickupDelivery"){
    return false
  }else if(orderType == "Ondemand"){
    return true

  }else{
    switch (fromStatus) {
      case "Done": return false; break;
      default: return true;
    }
  
  }
}


// New Order Types Workflow



// Pickup (P)

// pending - assigned - start - done (or failed)

// Delivery (D)

// pending - assigned - start - done (or failed)

// Pickup & Delivery (P&D)

// Pending → Collection → Transit → Delivering → Done


export function PickupRules(pureDataStatus, orderType, flag, flagCall) {
  // 'Pending' | 'Assigned' | 'Started' | 'Done' | 'Unassigned' | 'DriverCanceled' | 'CustomerCanceled' | 'SupportCanceled';

  const rules = [
    {
      from: ['Assigned', 'Started', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled', 'PickedUp'],
      to: "Pending", showDriver: true, mustSetDriver: false,
      NotshowDriverPanel: false,
      validate: (vehicleType) => { return vehicleType }
    },
    {
      from: ['Pending', 'Started', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled', 'PickedUp'],
      to: "Assigned", mustSetDriver: true, showDriver: true,
      NotshowDriverPanel: false,
      validate: (vehicleType, driver) => { return vehicleType && driver }
    },
    {
      from: ['Pending', 'Assigned', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled', 'PickedUp'],
      to: "Started", showDriver: true, mustSetDriver: true,
      NotshowDriverPanel: false,
      validate: (vehicleType, driver) => { return vehicleType && driver }
    },
    {
      from: ['Pending', 'Assigned', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled', "Started"],
      to: "PickedUp", showDriver: true, mustSetDriver: true,
      NotshowDriverPanel: false,
      validate: (vehicleType, driver) => { return vehicleType && driver }
    },
    {
      from: ['Pending', 'Assigned', 'Started', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled', 'PickedUp'],
      to: "Done", showDriver: true, mustSetDriver: true,
      NotshowDriverPanel: false,
      validate: (vehicleType, driver) => { return vehicleType && driver }
    },
    {
      from: ['Pending', 'Assigned', 'Started', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled', 'PickedUp'],
      to: "Unassigned", mustSetDriver: false, showDriver: false,
      NotshowDriverPanel: true,
      validate: () => { return true }
    },
    {
      from: ['Pending', 'Assigned', 'Started', 'Unassigned', 'DriverCanceled', 'SupportCanceled', 'PickedUp'],
      to: "CustomerCanceled", mustSetDriver: false, showDriver: false,
      NotshowDriverPanel: true,
      validate: () => { return true }
    },
    {
      from: ['Pending', 'Assigned', 'Started', 'Unassigned', 'CustomerCanceled', 'SupportCanceled', 'PickedUp'],
      to: "DriverCanceled", mustSetDriver: true, showDriver: true,
      NotshowDriverPanel: false,
      validate: (vehicleType, driver) => { return vehicleType && driver }
    },
    {
      from: ['Pending', 'Assigned', 'Started', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'PickedUp'],
      to: "SupportCanceled", mustSetDriver: false, showDriver: false,
      NotshowDriverPanel: true,
      validate: () => { return true }
    },
  ]



  let result = [];


  if (flag) {
    rules.forEach(element => {
      if (element.to == pureDataStatus.main) {
        flagCall(element)
      }
    });
  } else {
    rules.forEach(element => {
      if (element.from.indexOf(pureDataStatus.main) > -1) {
        result.push(element)
      }
    });

    return result;
  }

}

export function DeliveryRules(pureDataStatus, orderType, flag, flagCall) {
  // 'Pending' | 'Assigned' | 'Started' | 'Done' | 'Unassigned' | 'DriverCanceled' | 'CustomerCanceled' | 'SupportCanceled';

  const rules = [
    {
      from: ['Assigned', 'Started', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'],
      to: "Pending", showDriver: true, mustSetDriver: false,
      NotshowDriverPanel: false,
      validate: (vehicleType) => { return vehicleType }
    },
    {
      from: ['Pending', 'Started', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'],
      to: "Assigned", mustSetDriver: true, showDriver: true,
      NotshowDriverPanel: false,
      validate: (vehicleType, driver) => { return vehicleType && driver }
    },
    {
      from: ['Pending', 'Assigned', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'],
      to: "Started", showDriver: true, mustSetDriver: true,
      NotshowDriverPanel: false,
      validate: (vehicleType, driver) => { return vehicleType && driver }
    },
    {
      from: ['Pending', 'Assigned', 'Started', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'],
      to: "Done", showDriver: true, mustSetDriver: true,
      NotshowDriverPanel: false,
      validate: (vehicleType, driver) => { return vehicleType && driver }
    },
    {
      from: ['Pending', 'Assigned', 'Started', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'],
      to: "Unassigned", mustSetDriver: false, showDriver: false,
      NotshowDriverPanel: true,
      validate: () => { return true }
    },
    {
      from: ['Pending', 'Assigned', 'Started', 'Unassigned', 'DriverCanceled', 'SupportCanceled'],
      to: "CustomerCanceled", mustSetDriver: false, showDriver: false,
      NotshowDriverPanel: true,
      validate: () => { return true }
    },
    {
      from: ['Pending', 'Assigned', 'Started', 'Unassigned', 'CustomerCanceled', 'SupportCanceled'],
      to: "DriverCanceled", mustSetDriver: true, showDriver: true,
      NotshowDriverPanel: false,
      validate: (vehicleType, driver) => { return vehicleType && driver }
    },
    {
      from: ['Pending', 'Assigned', 'Started', 'Unassigned', 'DriverCanceled', 'CustomerCanceled'],
      to: "SupportCanceled", mustSetDriver: false, showDriver: false,
      NotshowDriverPanel: true,
      validate: () => { return true }
    },
  ]



  let result = [];


  if (flag) {
    rules.forEach(element => {
      if (element.to == pureDataStatus.main) {
        flagCall(element)
      }
    });
  } else {
    rules.forEach(element => {
      if (element.from.indexOf(pureDataStatus.main) > -1) {
        result.push(element)
      }
    });

    return result;
  }

}




export function PickupDeliveryRules(pureDataStatus, orderType, flag, flagCall) {
  //  'Pending' | 'Collecting' | 'Transit' | 'Delivering' | 'Done' | 'Unassigned' | 'CustomerCanceled' | 'SupportCanceled'


  //'Pending' , 'Assigned' , 'Started' , 'Done' , 'Unassigned' , 'DriverCanceled' , 'CustomerCanceled' , 'SupportCanceled'


  const rules = [
    {
      Pickup_from: ['Pending', 'Assigned', 'Started', 'PickedUp', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'], Delivery_from: ['Pending', 'Assigned', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'],
      canEditPickup: (state, Driver) => { return !!(['Pending', 'Started', 'Done', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'].indexOf(state) > -1) },
      canEditDelivey: (state, Driver) => { return !!(['Pending', 'Started', 'Done', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'].indexOf(state) > -1) },
      to: "Pending", showDriver: true, p_sendFields: true, d_SendFields: true, p_mustSetDriver: false, d_mustSetDriver: false,
      Pickup_SendDriverOptional: true, Delivery_SendDriverOptional: true, validate: (Pickup_vehicleType, Pickup_driver, Delivery_vehicleType, Delivery_driver) => { return Pickup_vehicleType && Delivery_vehicleType }
    },
    {
      Pickup_from: ['Pending', 'Assigned', 'Started', 'PickedUp', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'], Delivery_from: ['Pending', 'Assigned', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'],
      canEditPickup: (state, Driver) => { return true },
      canEditDelivey: (state, Driver) => { return false }, showDriver: true,
      to: "Collecting", p_sendFields: true, d_SendFields: false, p_mustSetDriver: true, d_mustSetDriver: false,
      Pickup_SendDriverOptional: true, Delivery_SendDriverOptional: true, validate: (Pickup_vehicleType, Pickup_driver) => { return Pickup_vehicleType && Pickup_driver }
    },
    {
      Pickup_from: ['Pending', 'Assigned', 'Started', 'PickedUp', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'], Delivery_from: ['Pending', 'Assigned', 'Started', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'],
      canEditPickup: (state, Driver) => { return true },
      canEditDelivey: (state, Driver) => { return true },
      to: "Transit", showDriver: true, p_sendFields: true, d_SendFields: true, p_mustSetDriver: true, d_mustSetDriver: true,
      Pickup_SendDriverOptional: true, Delivery_SendDriverOptional: true, validate: (Pickup_vehicleType, Pickup_driver, Delivery_vehicleType, Delivery_driver) => { return Pickup_vehicleType && Pickup_driver && Delivery_vehicleType && Delivery_driver }
    },
    {
      Pickup_from: ['Pending', 'Assigned', 'Started', 'PickedUp', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'], Delivery_from: ['Pending', 'Assigned', 'Started', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'],
      canEditPickup: (state, Driver) => { return !Driver },
      canEditDelivey: (state, Driver) => { return true },
      to: "Delivering", showDriver: true, p_sendFields: true, d_SendFields: true, p_mustSetDriver: true, d_mustSetDriver: true,
      Pickup_SendDriverOptional: true, Delivery_SendDriverOptional: true, validate: (Pickup_vehicleType, Pickup_driver, Delivery_vehicleType, Delivery_driver) => { return Pickup_vehicleType && Pickup_driver && Delivery_vehicleType && Delivery_driver }
    },
    {
      Pickup_from: ['Pending', 'Assigned', 'Started', 'PickedUp', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'], Delivery_from: ['Pending', 'Assigned', 'Started', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'],
      canEditPickup: (state, Driver) => { return true },
      canEditDelivey: (state, Driver) => { return true },
      to: "Done", showDriver: true, p_sendFields: true, d_SendFields: true, p_mustSetDriver: true, d_mustSetDriver: true,
      Pickup_SendDriverOptional: true, Delivery_SendDriverOptional: true, validate: (Pickup_vehicleType, Pickup_driver, Delivery_vehicleType, Delivery_driver) => { return Pickup_vehicleType && Pickup_driver && Delivery_vehicleType && Delivery_driver }
    },
    ,
    {
      Pickup_from: ['Done'], Delivery_from: ['Pending', 'Assigned', 'Started', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'],
      canEditPickup: (state, Driver) => { return false },
      canEditDelivey: (state, Driver) => { return true },
      to: "Transit", showDriver: true, p_sendFields: true, d_SendFields: true, p_mustSetDriver: true, d_mustSetDriver: true,
      Pickup_SendDriverOptional: true, Delivery_SendDriverOptional: true, validate: (Pickup_vehicleType, Pickup_driver, Delivery_vehicleType, Delivery_driver) => { return Pickup_vehicleType && Pickup_driver && Delivery_vehicleType && Delivery_driver }
    },
    {
      Pickup_from: ['Done'], Delivery_from: ['Pending', 'Assigned', 'Started', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'],
      canEditPickup: (state, Driver) => { return false },
      canEditDelivey: (state, Driver) => { return true },
      to: "Delivering", showDriver: true, p_sendFields: true, d_SendFields: true, p_mustSetDriver: true, d_mustSetDriver: true,
      Pickup_SendDriverOptional: true, Delivery_SendDriverOptional: true, validate: (Pickup_vehicleType, Pickup_driver, Delivery_vehicleType, Delivery_driver) => { return Pickup_vehicleType && Pickup_driver && Delivery_vehicleType && Delivery_driver }
    },
    {
      Pickup_from: ['Done'], Delivery_from: ['Pending', 'Assigned', 'Started', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'],
      canEditPickup: (state, Driver) => { return false },
      canEditDelivey: (state, Driver) => { return true },
      to: "Done", showDriver: true, p_sendFields: true, d_SendFields: true, p_mustSetDriver: true, d_mustSetDriver: true,
      Pickup_SendDriverOptional: true, Delivery_SendDriverOptional: true, validate: (Pickup_vehicleType, Pickup_driver, Delivery_vehicleType, Delivery_driver) => { return Pickup_vehicleType && Pickup_driver && Delivery_vehicleType && Delivery_driver }
    },


    // {
    //   Pickup_from: ['Pending', 'Assigned', 'Started', 'PickedUp', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'], Delivery_from: ['Pending', 'Assigned', 'Started', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'],
    //   canEditPickup: (state, Driver) => { return false },
    //   canEditDelivey: (state, Driver) => { return false }, showDriver: false,
    //   to: "Unassigned", p_sendFields: false, d_SendFields: false, p_mustSetDriver: false, d_mustSetDriver: false,
    //   Pickup_SendDriverOptional: true, Delivery_SendDriverOptional: true, validate: (Pickup_vehicleType, Pickup_driver, Delivery_vehicleType, Delivery_driver) => { return true }
    // },
    {
      Pickup_from: ['Done', 'Pending', 'Assigned', 'Started', 'PickedUp', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'], Delivery_from: ['Pending', 'Assigned', 'Started', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'],
      canEditPickup: (state, Driver) => { return false },
      canEditDelivey: (state, Driver) => { return false }, showDriver: false,
      to: "CustomerCanceled", p_sendFields: false, d_SendFields: false, p_mustSetDriver: false, d_mustSetDriver: false,
      Pickup_SendDriverOptional: true, Delivery_SendDriverOptional: true, validate: (Pickup_vehicleType, Pickup_driver, Delivery_vehicleType, Delivery_driver) => { return true }
    },
    {
      Pickup_from: ['Done', 'Pending', 'Assigned', 'Started', 'PickedUp', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'], Delivery_from: ['Pending', 'Assigned', 'Started', 'Unassigned', 'DriverCanceled', 'CustomerCanceled', 'SupportCanceled'],
      canEditPickup: (state, Driver) => { return false },
      canEditDelivey: (state, Driver) => { return false }, showDriver: false,
      to: "SupportCanceled", p_sendFields: false, d_SendFields: false, p_mustSetDriver: false, d_mustSetDriver: false,
      Pickup_SendDriverOptional: true, Delivery_SendDriverOptional: true, validate: (Pickup_vehicleType, Pickup_driver, Delivery_vehicleType, Delivery_driver) => { return true }
    },
  ]



  let result = [];


  if (flag) {
    rules.forEach(element => {
      if (element.to == pureDataStatus.main) {
        flagCall(element)
      }
    });
  } else {
    rules.forEach(element => {
      if (element.Pickup_from.indexOf(pureDataStatus.pickup) > -1 && element.Delivery_from.indexOf(pureDataStatus.delivery) > -1 && element.to != pureDataStatus.main) {
        result.push(element)
      }
    });

    return result;
  }

}

export function OndemandRules(pureDataStatus, orderType, flag, flagCall) {

  const rules = [
    { from: ["Assigned", "Unassigned", "CustomerCanceled", "DriverCanceled", "SupportCanceled", "Started", "PickedUp", "Arrived" ], to: "Pending", MustsetDriver: false, NotshowDriverPanel: false, SendV_D: true, SendDriverOptional: true, validate: (vehicleType) => { return !!vehicleType } },
    { from: ["Pending", "CustomerCanceled", "DriverCanceled", "SupportCanceled", "PickedUp", "Unassigned", "Started", "Arrived"], to: "Assigned", MustsetDriver: true, validate: (vehicleType, Driver) => { return vehicleType && Driver } },
    { from: ["Assigned", "Unassigned", "CustomerCanceled", "DriverCanceled", "SupportCanceled", "Started", "PickedUp", "Pending"], to: "Arrived", MustsetDriver: true, validate: (vehicleType, Driver) => { return vehicleType && Driver } },
    { from: ["Assigned", "Pending", "CustomerCanceled", "DriverCanceled", "SupportCanceled", "Unassigned", "Started", "Arrived"], to: "PickedUp", MustsetDriver: true, validate: (vehicleType, Driver) => { return vehicleType && Driver } },
    { from: ["Assigned", "Pending", "CustomerCanceled", "DriverCanceled", "SupportCanceled", "Unassigned", "PickedUp", "Arrived"], to: "Started", MustsetDriver: true, validate: (vehicleType, Driver) => { return vehicleType && Driver } },
    { from: ["Assigned", "Unassigned", "CustomerCanceled", "DriverCanceled", "SupportCanceled", "Started", "PickedUp", "Pending", "Arrived"], to: "Done", MustsetDriver: true, validate: (vehicleType, Driver) => { return vehicleType && Driver } },
    { from: ["Assigned", "Pending", "CustomerCanceled", "DriverCanceled", "SupportCanceled", "Started", "PickedUp", "Arrived"], to: "Unassigned", MustsetDriver: false, NotshowDriverPanel: true, canShowChnageOptionError: false, validate: () => { return true } },
    { from: ["Assigned", "Unassigned", "Started", "PickedUp", "Pending", "DriverCanceled", "SupportCanceled", "Arrived" ,"Done"], to: "CustomerCanceled", MustsetDriver: false, NotshowDriverPanel: true, canShowChnageOptionError: false, validate: () => { return true } },
    { from: ["Assigned", "Unassigned", "Started", "PickedUp", "Pending", "CustomerCanceled", "SupportCanceled", "Arrived" ,"Done"], to: "DriverCanceled", MustsetDriver: true, NotshowDriverPanel: false, validate: (vehicleType, Driver) => { return vehicleType && Driver } },
    { from: ["Assigned", "Unassigned", "Started", "PickedUp", "Pending", "CustomerCanceled", "DriverCanceled", "Arrived" ,"Done"], to: "SupportCanceled", MustsetDriver: false, NotshowDriverPanel: true, canShowChnageOptionError: false, validate: () => { return true } },
  ]


  let result = [];


  if (flag) {
    rules.forEach(element => {
      if (element.to == pureDataStatus.main) {
        flagCall(element)
      }
    });
  } else {
    rules.forEach(element => {
      if (element.from.indexOf(pureDataStatus.main) > -1) {
        result.push(element)
      }
    });

    return result;
  }


}

export function getAvailableListChangeStatus(pureDataStatus, orderType, flag, flagCall) {



  if (orderType == "Ondemand") {
    return OndemandRules(pureDataStatus, orderType, flag, flagCall)
  } else if (orderType == "PickupDelivery") {
    return PickupDeliveryRules(pureDataStatus, orderType, flag, flagCall)
  } else if (orderType == "Pickup") {
    return PickupRules(pureDataStatus, orderType, flag, flagCall)
  } else if (orderType == "Delivery") {
    return DeliveryRules(pureDataStatus, orderType, flag, flagCall)
  }



}








export function getCenterMap(mapRef, center) {
  // return {
  //   lng: mapRef.current.leafletElement.getCenter().lng,
  //   lat: mapRef.current.leafletElement.getCenter().lat,
  // }

  try {
    if (global.config.dispatcherSettings.applicationMap) {
      if (global.config.dispatcherSettings.applicationMap.type == "osm") {
        return {
          lng: mapRef.current.leafletElement.getCenter().lng,
          lat: mapRef.current.leafletElement.getCenter().lat,
        }
      } else {
        const newCenter = mapRef.getCenter();
        return {
          lng: newCenter.lng(),
          lat: newCenter.lat(),
        }
      }
    } else {
      return center ? center : {
        lng: 0,
        lat: 0
      }
    }
  } catch (error) {
    return {
      lng: 0,
      lat: 0,
    }
  }

}


export function setCenterMap(mapRef, e) {

  // mapRef.current.leafletElement.setView(e, 17);


  if (global.config.dispatcherSettings.applicationMap) {
    if (global.config.dispatcherSettings.applicationMap.type == "osm") {
      mapRef.current.leafletElement.setView(e, 17);
    }/* else {
      const bounds = new window.google.maps.LatLngBounds();
        var myLatLng = new window.google.maps.LatLng(e.lat, e.lng);
        bounds.extend(myLatLng);

        mapRef.fitBounds(bounds);

    }*/
  }

}


export function checkVoipCreateOrderAction(RequestBody) {
  const state = store.getState();

  if (state.Voip.SelectedInCommingCallAction.type && state.Voip.SelectedInCommingCallAction.type == "NewOrder" && RequestBody.callId == state.Voip.SelectedInCommingCallAction.callId) {
    store.dispatch(SetVoipIncommingCallAction({
      type: null,
      Data: null
    }))
  }

}



export const RtlLtrCssSelector = (props) => {


  return (
    <div className="App">
      <React.Suspense fallback={<></>}>
        <style>{Sass_Font(props)}</style>
      </React.Suspense>
      {props.children}
    </div>
  );
};



export const Sass_Font = () => {
  if (global.config.customFont) {
    
    const css = require(`!!raw-loader!../RtlLtrCssSelector/${global.config.customFont}/${getDirectionByUrl()}.css`);
    

    return css
  }
};


export const get_available_pick_dates = () => {
  let arr = [
    {
      month: Number(jalali(new Date()).locale(get_lang()).startOf("month").format("x")),
      from: Number(jalali(new Date()).locale(get_lang()).startOf("day").format("DD")), to: Number(jalali(new Date()).locale(get_lang()).endOf("month").endOf("day").format("DD"))
    },
    {
      month: Number(jalali(new Date()).locale(get_lang()).add(1,"month").startOf("month").format("x")),
      from: Number(jalali(new Date()).locale(get_lang()).add(1,"month").startOf("month").format("DD")), to: Number(jalali(new Date()).locale(get_lang()).add(1,"month").endOf("month").format("DD"))
    },
    {
      month: Number(jalali(new Date()).locale(get_lang()).add(2,"month").startOf("month").format("x")),
      from: Number(jalali(new Date()).locale(get_lang()).add(2,"month").startOf("month").format("DD")), to: Number(jalali(new Date()).locale(get_lang()).add(2,"month").endOf("month").format("DD"))
    },
    {
      month: Number(jalali(new Date()).locale(get_lang()).add(3,"month").startOf("month").format("x")),
      from: Number(jalali(new Date()).locale(get_lang()).add(3,"month").startOf("month").format("DD")), to: Number(jalali(new Date()).locale(get_lang()).add(3,"month").endOf("month").format("DD"))
    },
    {
      month: Number(jalali(new Date()).locale(get_lang()).add(4,"month").startOf("month").format("x")),
      from: Number(jalali(new Date()).locale(get_lang()).add(4,"month").startOf("month").format("DD")), to: Number(jalali(new Date()).locale(get_lang()).add(4,"month").endOf("month").format("DD"))
    },
    {
      month: Number(jalali(new Date()).locale(get_lang()).add(5,"month").startOf("month").format("x")),
      from: Number(jalali(new Date()).locale(get_lang()).add(5,"month").startOf("month").format("DD")), to: Number(jalali(new Date()).locale(get_lang()).add(5,"month").endOf("month").format("DD"))
    },
    {
      month: Number(jalali(new Date()).locale(get_lang()).add(6,"month").startOf("month").format("x")),
      from: Number(jalali(new Date()).locale(get_lang()).add(6,"month").startOf("month").format("DD")), to: Number(jalali(new Date()).locale(get_lang()).add(6,"month").endOf("month").format("DD"))
    },
  ]
  return arr;
};



export const pickup_isPickuped = (status) => {
  switch (status) {
    case "PickedUp": return true; break;
    case "Done": return true; break;
    case "Transit": return true; break;
    default: return false;
  }
};





export const isRouteListActive = () => {
  if (String(window.location.href).includes("/routes")) {
    return true
  } else {
    return false
  }
}