import * as React from 'react'
import { useState, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import defaultCreditCard from "../../../../src/assets/defaultCreditCard.png";
import ErrorCreditCard from "../../../../src/assets/ErrorCreditCard.png";
import styles from './style.module.css';
import PaymentIcon from "react-payment-icons";
import clsx from 'clsx';

var creditcardutils = require('creditcardutils');



function CreditCardElement({onChange}) {

  const [cardNumber, setCardNumber] = useState("");
  const [cardError, setCardError] = useState(false);

  const [expired, setExpired] = useState("");
  const [expiredError, setExpiredError] = useState(false);

  const [cvc, setCvc] = useState("");
  const [cvcError, setCvcError] = useState(false);

  const [cardType, setCardType] = useState(null);


  React.useEffect(() => {

   checkCardIsValid()

  }, [cardNumber  , expired , cvc])

  const checkCardIsValid = React.useCallback(() => {


      if (String(cardNumber).length > 0 && String(expired).length > 0 && String(cvc).length > 0) {
        if (creditcardutils.validateCardNumber(cardNumber),
          creditcardutils.validateCardExpiry(String(expired).substring(0, 2),
            String(expired).substring(2)), creditcardutils.validateCardCVC(String(cvc), cardType)) {
              onChange(true , {
                cardNumber,expired,cvc
              })
        } else {
          onChange(false , null)
        }
      } else {
        onChange(false , null)
      }
 


  }, [cardNumber, cardError, expired, expiredError, cvc, cvcError, cardType]);


  const CardNumberHandleChange = (event) => {
    let { value } = event.target;

    setCardError(false)

    value = value.replace(/ /gi, "");
    if (isNaN(value)) {
      setCardType(null)

      return;
    } else {
      const cardType = creditcardutils.parseCardType(value);
      setCardType(cardType)
      setCardNumber(value);
      
    }
    
  };

  const expiredInputHandleChange = (event) => {
    let { value } = event.target;

    setExpiredError(false)
    value = value.replace(/\D/g, '');
    if (isNaN(value)) {
      return;
    } else {
      setExpired(value);
    }

  };


  const cvcInputHandleChange = (event) => {
    let { value } = event.target;

    setCvcError(false)
    value = value.replace(/\D/g, '');
    if (isNaN(value)) {
      return;
    } else {
      setCvc(value);
    }

  };



  const checkCardNumber = React.useCallback(() => {

    if (String(cardNumber).length > 0) {
      if (String(cardNumber).length > 0 && creditcardutils.validateCardNumber(String(cardNumber))) {
        setCardError(false)
      } else {
        setCardError(true)
      }
    } else {
      setCardError(false)

    }


  }, [cardNumber]);


  const checkExpired = React.useCallback(() => {

    if (String(expired).length > 0) {
      if (creditcardutils.validateCardExpiry(String(expired).substring(0, 2), String(expired).substring(2))) {
        setExpiredError(false)
      } else {
        setExpiredError(true)
      }
    } else {
      setExpiredError(false)

    }


  }, [expired]);



  const checkCVC = React.useCallback(() => {


    if (String(cvc).length > 0) {
      if (String(cvc).length > 0 && (cardType ? creditcardutils.validateCardCVC(String(cvc), cardType) : creditcardutils.validateCardCVC(String(cvc)))) {
        setCvcError(false)
      } else {
        setCvcError(true)
      }
    } else {
      setCvcError(false)
    }


  }, [cvc, cardType]);



  const formatCardNumber = value => {
    let v = String(value);
    if (v.length > 2) {
      v = v.substring(0, 2) + " / " + v.substring(2);
      return v
    }
    return v
  };




  return (
    <div className={clsx(styles.CreditCardContainer, (cardError || cvcError || expiredError) && styles.redBorder)}>
      {!cardType && <img src={cardError ? ErrorCreditCard : defaultCreditCard} className={styles.defaultCreditCard} />}
      {cardType && <PaymentIcon
        id={cardType}
        className={styles.CreditCard}
      />}
      <input
        className={clsx(styles.numberInput, cardError && styles.redColor)}
        type="text"
        onChange={CardNumberHandleChange}
        onBlur={checkCardNumber}
        value={creditcardutils.formatCardNumber(cardNumber)}
        maxLength="19"
        placeholder='Card number'
        autofocus
      />

      <input
        className={clsx(styles.expiredInput, expiredError && styles.redColor)}
        type="text"
        onChange={expiredInputHandleChange}
        onBlur={checkExpired}
        value={formatCardNumber(expired)}
        maxLength="7"
        placeholder='MM / YY'
      />

      <input
        className={clsx(styles.CVC, cvcError && styles.redColor)}
        type="text"
        onChange={cvcInputHandleChange}
        onBlur={checkCVC}
        value={cvc}
        maxLength="4"
        placeholder='CVC'
      />
    </div>
  )
}

export default CreditCardElement