import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './style.module.css';
import Button from '../../../design-system/lib/Button';
import { DRIVER_COLOR, IC_SEARCH, PLUS, Plus_Dark, Search_Table } from '../../../assets';
import DateRangePicker from '../../main/navbar/components/date-range-picker';
import { Divider } from '../../main/navbar/components/divider';
import TableAddRoute from '../selectedOrders/components/addRoute';
import Input from '../../../design-system/lib/Input';
import { SetDriverListOpenDialoge, handleEditRouteFilter_Name } from '../../../Redux/actions/actionNames';
import { useDispatch, useSelector } from 'react-redux';


function RouteListNavbar() {
  const [showAddRoute, setShowAddRoute] = useState(false);
  const name = useSelector((state) => state.Route.filters.name)
  const dispatch = useDispatch();
  const DriversListopenDialoge = useSelector((state) => state.DriversList.openDialoge)





  useEffect(() => {
  try {
      if (showAddRoute) {
        const element = document.getElementById('googlemapUniqeId');
        element.style.zIndex = '6';
      } else {
        const element = document.getElementById('googlemapUniqeId');
        element.style.zIndex = '7';
      }
  } catch (error) {
    
  }


  }, [showAddRoute]);


  function handleAddRote(e, route) {
    setShowAddRoute(e)
  }


  function handleEditName(e) {
    dispatch(handleEditRouteFilter_Name({ key: "name", value: (e.target.value && String(e.target.value).length > 0) ? e.target.value : "" }))
  }



  return (
    <div className={styles.navbarContainer}>
      <div className={styles.leftItems}>

        <Input type={"text"} iconStart={IC_SEARCH} value={name} onChange={handleEditName} placeHolder='Search route name' />

        {/* <DateRangePicker />
        <Divider />
        <Button size='Medium' type='Secondary' text='Status' icon={Plus_Dark} />
        <Button size='Medium' type='Secondary' text='Driver' icon={Plus_Dark} />
        <Button size='Medium' type='Secondary' icon={Search_Table} /> */}
      </div>

      <div className={styles.rightItems}>
        <Button size='Medium' type='Primary' text='New' icon={PLUS} onClick={() => { setShowAddRoute(true) }} />
        <Divider />

        <Button size='Medium' type='Secondary' text='Drivers' icon={DRIVER_COLOR} onClick={() => { dispatch(SetDriverListOpenDialoge(!DriversListopenDialoge)) }} />

      </div>

      {showAddRoute && <TableAddRoute onClose={handleAddRote} />}
    </div>
  )
}


export default RouteListNavbar